import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { axiosInstance } from "../api/APIs";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { truncateString } from "../ReuseableCompounts/TruncateString";
import { formatPrice } from "../ReuseableCompounts/PriceFormat";
import { formatSQPrice } from "../ReuseableCompounts/PriceSQFormat";
import star from '../images/star.svg';
import goldtick from '../images/goldtick.png';
import loaction from '../../compounts/images/location.png';

import { getResponsiveSettings } from "../ReuseableCompounts/CarouselResponsive";

interface Props {
    lookingToValue: any
}

interface Image {
    id: number;
    isCover: any;
    isvisible: any;
    name: any;
    uri: any;
}

interface Rows {
    id: number;
    post_id: number;
    property_type?: any;
    looking_to?: string;
    rent_or_lease?: string;
    property_available_for?: string;
    images?: Image[];
    view_post_count: number;
    bhk: string;
    city: string;
    building_name: string;
    landmark_street: string;
    build_up_area?: string;
    built_up_area?: string;
    plot_area?: string;
    PriceperSqft?: string;
    price: number;
    cost?: number;
    monthly_rent?: number;
    enter_lease_amount?: number;
    lease_amount?: any;
    liked?: any;
    user_id?: any;
    district?: string;
    kbl_property: any;
}

const RecommendedProperties: React.FC<Props> = ({ lookingToValue }) => {
    const [cart, setCart] = useState<Rows[]>([]);
    const [projects, setProjects] = useState<any[]>([]);
    const loginData = useSelector((item: any) => item.userData.userLoginData);

    

    const responsiveSettings = getResponsiveSettings(4.2, 3, 2.5);

    const handleChange = async (id: number, property: string, looking_to?: string) => {
        const body = {
            post_id: id,
            selected_property_type: property,
            looking_to: looking_to || ''
        };

        try {
            const response = await axiosInstance.post("/filter/get/postDetail", body);

            // Convert response data to a query string
            const queryString = new URLSearchParams({
                data: JSON.stringify(response.data),
                look: looking_to || ''
            }).toString();

            // Open a new window with the query parameters
            const newWindow = window.open(`/PropertyDetails?${queryString}`, '_blank');

            if (newWindow) {
                newWindow.focus(); // Focus the new window/tab if needed
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        async function handleCityFilter() {
            const url = `/filter/city/filter/Res_Sell/`;
            const body: any = {
                user_id: loginData.user_id,
                district: "Vellore",
                rent_or_sell: lookingToValue
            };

            try {
                const response = await axiosInstance.post(url, body);
                const data = response.data.message;
                if (Array.isArray(data)) {
                    setCart(data);
                } else {
                    console.error('Expected an array but got:', data);
                    setCart([]);
                }
            } catch (error) {
                console.error('Error fetching city filter data:', error);
            }
        }
        handleCityFilter();
    }, [lookingToValue]);

    return (
        <div className="xl:mx-[20%] msm:mx-2 max-w-full mt-">
            <p className='text-xl font-bold text-[#202020]'>Recommended Properties for Sale</p>
            <div>
                <Carousel
                    swipeable
                    draggable
                    showDots={false}
                    responsive={responsiveSettings}
                    ssr // means to render carousel on server-side.
                    infinite
                    autoPlay={false} // Stop auto-scrolling
                    keyBoardControl
                    customTransition="transform 500ms ease-in-out"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-20-px" // Adjusted padding
                >
                    {cart && cart.length > 0 ? (
                        cart.map((item, index) => {
                            const price = item.price || item.cost || item.enter_lease_amount || item.lease_amount || item.monthly_rent;
                            const formattedPrice = formatPrice(price);
                            const coverimg = item.images && item.images.length > 0 ? item.images.find(img => img.isCover) || "" : "";
                            const locality = truncateString(item.city, 20);
                            const sqtv: any = item.PriceperSqft ? item.PriceperSqft : "";
                            const priceSQTV = formatSQPrice(sqtv);
                            return (
                                <div key={index} className='relative mt- bg-[#F5F4F8]  p-2 rounded-2xl w-[95%] h-[100%]'>
                                    <div className='relative'>
                                        <p className='absolute right-0 mr-1 top-3 bg-[#2E368F] text-white py-1 px-3 rounded-2xl'>{item.looking_to || item.rent_or_lease || item.property_available_for}</p>
                                        <img onClick={() => { handleChange(item.post_id, item.property_type, item.looking_to || item.rent_or_lease || item.property_available_for) }}
                                            className='w-[15vw] h-[20vh] flex justify-center bg-black items-center object-contain rounded-3xl' src={typeof coverimg === 'object' && coverimg?.uri ? coverimg.uri : ''} />
                                        <div className={`${item.kbl_property ? "flex" : "hidden"} items-center bg-[#6135DD] absolute bottom-0 rounded-bl-3xl p-1 rounded-tr-3xl left-0`}>
                                            <img className="w-5 h-5" src={star} />
                                            <p className="text-white text-sm mr-1">VATTARA VERIFIED</p>
                                        </div>
                                    </div>
                                    <div className='ml-1'>
                                        <div className='flex items-center'>
                                            <p className='text-lg flex font-bold'>{item.bhk} {item.property_type} </p>
                                            <img src={goldtick} />
                                        </div>
                                        <div className='flex gap-2 items-center'>
                                            <img className="h-5" src={loaction} />
                                            <p className='text-sm capitalize'>{locality}</p>
                                        </div>
                                        <div className='flex items-center w-[100%]'>
                                            <p className='text-xl font-bold text-[#404040] w-[55%]'>₹ {formattedPrice} </p>
                                            <div className='border-[#404040] h-6 border-1 border-l'></div>
                                            <div className='ml-1'>
                                                <div className='flex items-center text-sm font-medium text-[#404040] w-[45%]'>
                                                    <p>{item.built_up_area || item.plot_area} </p>
                                                    {item.built_up_area || item.plot_area ? <p className='text-sm'>sq.ft</p> : ""}
                                                </div>
                                                <div className='flex items-center text-sm font-medium text-[#404040]'>
                                                    {item.PriceperSqft ? <p>₹ {priceSQTV}</p> : ""}
                                                    {item.PriceperSqft ? <p>/sq.ft</p> : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    ) : (
                        <p className="text-center text-gray-500">No listings available.</p>
                    )}
                </Carousel>
            </div>
        </div>
    );
}

export default RecommendedProperties;
