import { Button } from '@mui/material';
import React, { useState } from 'react';
import image1 from '../images/login-2.png';
import image2 from '../images/log-1.png';
import profile from '../images/profile.png';
import { axiosInstance } from "../api/APIs";
import { useDispatch } from "react-redux";
import { setUserLoginData } from '../../slices/userDataSlice'
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

interface ListProps {
    onClose: () => void;
    phone: any;
    UserId?: any
    token?: any
    encryptedToken?:any
}

const data = [
    { label: "Owner" },
    { label: "Agent" },
    { label: "Builder" },
    { label: "Promoter" }
];

interface FormErrors {
    name?: string;
    email?: string;
    companyName?: string;
}
interface BodyType {
    user_id: string;
    name: string;
    email: string;
    lang: string;
    company_logo?: string;
    category: string | undefined;
    company_name?: string;

}
interface UserLoginData {
    user_id: any;
    name: string;
    phone: any;
    email: string;
    category: any;
    lang: string;
    token?: any;
    encryptedToken?: any

}

const List: React.FC<ListProps> = ({ onClose, phone, UserId, token, encryptedToken }) => {
    const loginData =useSelector((item:any)=>(item.userData.userLoginData))
    const [details, setDetails] = useState(false);
    const [profileImage, setProfileImage] = useState<string>();
    const [category, setCategory] = useState<string | undefined>();
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [companyName, setCompanyName] = useState<string>("");
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const dispatch = useDispatch();
    const Navigate = useNavigate()

    console.log(loginData)

    function handleChange(value: string) {
        if (value !== "Owner") {
            setDetails(true);
        } else {
            setDetails(false);
        }
        setCategory(value);
    }



    function handleName(event: React.ChangeEvent<HTMLInputElement>) {
        setName(event.target.value);
    }

    function handleEmail(event: React.ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value);
    }




    async function handleSubmitForm() {
        console.log("hg");

        let errors: FormErrors = {};

        if (!name) {
            errors.name = 'Please enter your name';
        }

        if (!email) {
            errors.email = 'Please enter your email';
        }
        


        // Check if the category requires a company name


        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 2000);
            return;
        }

        setFormErrors({});

        const body: UserLoginData = {
            user_id: UserId,
            name: name,
            email: email,
            phone: phone,
            lang: "en",
            category: "",
            token: loginData.token,
            encryptedToken: loginData.encryptedToken
        };

        console.log(body);

        try {
            const response = await axiosInstance.put("/user/update/userNameEmail", body);
            console.log('Response:', response);
            dispatch(setUserLoginData(body)) // Dispatch a single object
            Navigate('/')
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }



    return (
        <div className="fixed flex z-10 left-0 top-0 w-full h-full overflow-auto">
            <div className="bg-white m-auto p-6 border border-gray-300 border-solid w-3/4 max-w-4xl overflow-hidden max-h-[58%] h-[75%] rounded-[2%] flex flex-col relative sm:flex sm:flex-col sm:gap-2 sm:align-middle">

                <div className="flex flex-row h-full p-0">
                    <div className='relative z-10'>
                        <div className="absolute top-[-100px] left-[-80px] mt-4 w-60 h-60 bg-[#FFF8DC] rounded-full"></div>
                    </div>
                    <div className='z-30 w-[50%] '>
                        <div className="absolute right-4 top-3 text-gray-500 text-2xl font-bold cursor-pointer z-20 md" onClick={onClose}>&times;</div>
                        <div className='mt-[42%] ml-[20%]'>
                            <p className='mb-3 font-bold'>Enter your contact information</p>
                            <div className='mb-3'>
                                <div className='flex gap-3 mb-1'>
                                    <p>Name</p>
                                    <input onChange={handleName} className='border-none outline-none' />
                                </div>
                                {showAlert && formErrors.name && (
                                    <div className='absolute ml-4 z-10 font-semibold text-red-500'>
                                        {formErrors.name}
                                    </div>
                                )}
                                <div className='border border-1 border-[#1F1F1F] w-64'></div>
                            </div>
                            <div className='mb-3'>
                                <div className='flex gap-3 mb-1'>
                                    <p>E-Mail</p>
                                    <input onChange={handleEmail} className='border-none outline-none' />
                                </div>
                                {showAlert && formErrors.email && (
                                    <div className='absolute ml-4 z-10 font-semibold text-red-500'>
                                        {formErrors.email}
                                    </div>
                                )}
                                <div className='border border-1 border-[#1F1F1F] w-64'></div>
                            </div>
                            <div>
                                <div className='flex gap-3 mb-1'>
                                    <p>Phone</p>
                                    <input value={phone} className='border-none outline-none' readOnly />
                                </div>
                                <div className='border border-1 border-[#1F1F1F] w-64'></div>
                            </div>
                        </div>


                        <div className='flex mt-[30%] justify-center items-center flex-col'>
                            <div onClick={handleSubmitForm} className='flex justify-center items-center py-2 px-20 rounded-3xl bg-[#FBB337]'>
                                <button className='  '>Submit</button>
                            </div>
                            <div><p className='font-light text-sm'>This helps us personalize your experience.</p></div>
                        </div>
                    </div>
                    <div className="flex-1 flex justify-around items-center gap-4">
                        <div className="mt-72">
                            <img src={image1} alt='img' />
                        </div>
                        <div className="mt-40">
                            <img src={image2} alt='img' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default List;
