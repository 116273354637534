import { useEffect, useState } from "react";
import { Settings } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MessageIcon from "@mui/icons-material/Message";
import HomeIcon from "@mui/icons-material/Home";
import { useLocation, useNavigate } from "react-router-dom";

const MNavbar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeOption, setActiveOption] = useState<string>("");
  const [isTextChanged, setIsTextChanged] = useState<boolean>(false);

  const setActiveOptionFromPath = () => {
    const path = location.pathname;
    switch (path) {
      case "/":
        setActiveOption("home");
        break;
      case "/chat":
        setActiveOption("Chat");
        break;
      case "/services":
        setActiveOption("services");
        break;
      case "/profile":
        setActiveOption("Profile");
        break;
      default:
        setActiveOption("");
        break;
    }
  };

  useEffect(() => {
    setActiveOptionFromPath();
  }, [location]);

  const handleClick = (option: string) => {
    if (activeOption !== option) {
      switch (option) {
        case "home":
          navigate("/");
          break;
        case "Chat":
          navigate("/all-chat");
          break;
        case "services":
          navigate("/services");
          break;
        case "Profile":
          navigate("/profile");
          break;
        default:
          break;
      }
      setActiveOption(option);
      setIsTextChanged(true);
      setTimeout(() => {
        setIsTextChanged(false);
      }, 1000); // Reset the text change effect after 1 second
    }
  };

  return (
    <div className="h-20 flex items-center justify-center">
      <div className="relative mx-auto rounded-full p-3 w-[100vw] bg-[#E4E0EE] flex justify-between">
        <div
          id="btn"
          className={`absolute top-0 h-10 items-center justify-center mt-3 rounded-full transition-all duration-1000 ease-in-out ${
            activeOption === "home"
              ? "left-[1%] bg-[#2E368F] text-white "
              : activeOption === "Chat"
              ? "left-[25%] bg-[#2E368F] text-white"
              : activeOption === "services"
              ? "left-[50%] bg-[#2E368F] text-white"
              : activeOption === "Profile"
              ? "left-[70%] bg-[#2E368F] text-white"
              : ""
          }`}
          style={{ width: "28%" }}
        ></div>
        <div
          onClick={() => handleClick("home")}
          className={`nav-link flex flex-col items-center justify-center w-1/4 text-center p-2 outline-none bg-transparent cursor-pointer relative z-10 ${
            activeOption === "home" ? "text-white transition-all duration-1000 ease-in-out" : "text-[#2E368F]"
          }`}
        >
          <div className="flex flex-row gap-1">
            <HomeIcon />
            {activeOption === "home" && (
              <div
                className={`text-xs mt-1 transition-opacity duration-1000 ease-in-out ${
                  isTextChanged ? "opacity-0" : "opacity-100"
                }`}
              >
                Home
              </div>
            )}
          </div>
        </div>
        <div
          onClick={() => handleClick("Chat")}
          className={`nav-link flex flex-col items-center justify-center w-1/4 text-center p-2 outline-none bg-transparent cursor-pointer relative z-10 ${
            activeOption === "Chat" ? "text-white" : "text-[#2E368F]"
          }`}
        >
          <div className="flex flex-row gap-1">
            <MessageIcon />
            {activeOption === "Chat" && (
              <div className="text-xs mt-1">Chat</div>
            )}
          </div>
        </div>
        <div
          onClick={() => handleClick("services")}
          className={`nav-link flex flex-col items-center justify-center w-1/4 text-center p-2 outline-none bg-transparent cursor-pointer relative z-10 ${
            activeOption === "services" ? "text-white" : "text-[#2E368F]"
          }`}
        >
          <div className="flex flex-row gap-1">
            <Settings />
            {activeOption === "services" && (
              <div className="text-xs mt-1">services</div>
            )}
          </div>
        </div>
        <div
          onClick={() => handleClick("Profile")}
          className={`nav-link flex flex-col items-center justify-center w-1/4 text-center p-2 outline-none bg-transparent cursor-pointer relative z-10 ${
            activeOption === "Profile" ? "text-white" : "text-[#2E368F]"
          }`}
        >
          <div className="flex flex-row gap-1">
            <AccountCircleIcon />
            {activeOption === "Profile" && (
              <div className="text-xs mt-1">Profile</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MNavbar;
