import React, { useState } from "react";
import postproperty from "../images/Property post ad.svg";
import post from "../images/Property post Rent.svg";
import { Avatar, Box, Button, Modal, CardActions, CardContent, CardMedia, Checkbox, Divider, FormControlLabel, Icon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import RateingBox from "../RateingBox/RateingBox";

interface props {
  lookingToValue: any
}


const PropertyHousing: React.FC<props> = ({  lookingToValue}) => {
 


  return (
    <div className="flex justify-center items-center  msm:mx-2 xl:mx-[20%]  mt-3">
      <div className=" ">
        <img src={lookingToValue ==="Rent"? post:postproperty} className="" alt="Post Property" />
      </div>
      
    </div>
  );
};

export default PropertyHousing;
