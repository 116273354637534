// src/compounts/api/APIs.ts
import axios from 'axios';
import { setUpdateToken, setUpdateEncryptedToken } from '../../slices/userDataSlice';
import store from '../../app/store';


// Create an axios instance with a base URL
export const axiosInstance = axios.create({
  baseURL: 'http://13.233.86.164:3000', // Update this baseURL to your server's address
});

// Request interceptor to add Authorization header
axiosInstance.interceptors.request.use(
  (config) => {
    const { userLoginData } = store.getState().userData; // Assuming your slice is named `userData`
    const { token: accessToken } = userLoginData || {};

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
      console.log("Helllllo", accessToken)
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle token refresh and retries
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const { userLoginData } = store.getState().userData;
    const { encryptedToken, token } = userLoginData || {};

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        // Attempt to refresh the token
        const response = await axios.post(
          'http://13.233.86.164:3000/user/refreshToken',
          { refreshToken: userLoginData?.encryptedToken, user_id: userLoginData?.user_id }
        );
        console.log(response)
        const { token: newAccessToken, encryptedToken: newRefreshToken } = response.data;
        console.log("mera token", newAccessToken, newRefreshToken)

        // Update the tokens in the Redux store
        store.dispatch(setUpdateToken(newAccessToken));
        store.dispatch(setUpdateEncryptedToken(newRefreshToken));

        // Update the Authorization header for the original request
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

        // Retry the original request with the new access token
        return axiosInstance(originalRequest);
      } catch (err) {
        // If token refresh fails, log out the user and redirect to the login page
        // store.dispatch(logout());
        window.location.href = '/login';
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);
