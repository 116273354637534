import React, { useEffect, useState } from 'react';
import { useStepperContext } from './StepperContext';
import { MenuItem, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

interface DropDownProps {
  label: string;
  options: any;
  initial: any;
  inputId: number;
  formName: string;
  required: boolean;
  userselectedData?: any;
  baseData:any
}
interface InitialValues {
  [key: string]: string;
}

const DropDownBox: React.FC<DropDownProps> = ({
  label,
  options,
  inputId,
  formName,
  required,
  userselectedData,
  initial,
  baseData
}) => {
  const { addUserData, userData } = useStepperContext();


  const transformValue = (value: any) => {
    if (value === 'Rent' || value === 'Lease') {
      return 'Rent/Lease';
    } else if (value === 'Sale') {
      return 'Sell';
    } else {
      return value; // or return a default value if needed
    }
  }


  const getInitialValues = (baseData: any): InitialValues => {
    const initialValues: InitialValues = {};

    baseData.forEach((detail: any) => {
      const key = Object.keys(detail)[0];
     const value = detail[key] ||""
     initialValues[key] =transformValue(value)
    });

    return initialValues;
    
  };
  const initialValues = getInitialValues(baseData)
console.log(initialValues)
  
const Property_Type = initialValues['Property Type'] || '';
const Looking_To = initialValues['Looking To'] || '';
console.log("sqdcw",Property_Type,Looking_To)
  let items = [] as any;

  if (Property_Type && Looking_To) {
    let filteredData = [];
    if (Property_Type === 'Residential' && Looking_To === 'Rent/Lease') {
      filteredData = options.slice(1, 4);
    } else if (Property_Type === 'Residential' && Looking_To === 'Sell') {
      filteredData = options.slice(0, 4);
    } else if ((Property_Type === 'Commercial' && Looking_To === 'Rent/Lease') || (Property_Type === 'Commercial' && Looking_To === 'Sell')) {
      filteredData = options.slice(4, 9);
    }

    items = filteredData.map((item: any) => ({ label: item.title, value: item.title }));
  }

  const [dropdownItems, setDropdownItems] = useState(items);
  const [selectedValue, setSelectedValue] = useState(initial);

  useEffect(() => {
    setDropdownItems(items);
  }, [Property_Type, Looking_To, options]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
    const value = event.target.value as string;
    setSelectedValue(value);

    const data = {
      id: inputId,
      [label]: value,
      formName: formName,
      required: required,
    };
    addUserData(data as any);
  };

  const hasError = userselectedData?.includes(label);

  return (
    <div>
      <TextField
        select
        label={label}
        id={label}
        value={selectedValue}
        size="small"
        onChange={handleChange}
        sx={{ width: "100%" }}
        InputProps={{
          style: {
            borderRadius: "999px",
            borderColor: grey[500],
            backgroundColor: "#F5F4F8",
          },
        }}
      >
        {dropdownItems.map((option: any, index: any) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      {hasError && <Typography sx={{ color: "#C91C1C" }}>This field is required</Typography>}
    </div>
  );
};

export default DropDownBox;
