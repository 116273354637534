import { Button } from '@mui/material';
import React, { useState } from 'react';
import image1 from '../images/login-2.png';
import image2 from '../images/log-1.png';
import profile from '../images/profile.png';
import { axiosInstance } from "../api/APIs";
import { useDispatch } from "react-redux";
import { setUserLoginData } from '../../slices/userDataSlice'
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import AWS from 'aws-sdk';
import Resizer from 'react-image-file-resizer';

interface ListProps {
    onClose: () => void;
    phone: any;
    UserId?: any
}
// AWS S3 Configuration
AWS.config.update({
    region: 'us-east-1',
    accessKeyId: 'AKIAQ3EGVP6CLIGM245V',
    secretAccessKey: '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',
});
const S3 = new AWS.S3();
const data = [
    { label: "Owner" },
    { label: "Agent" },
    { label: "Builder" },
    { label: "Promoter" }
];

interface FormErrors {
    name?: string;
    email?: string;
    companyName?: string;
}
interface BodyType {
    user_id: string;
    name: string;
    email: string;
    lang: string;
    company_logo?: string;
    category: string | undefined;
    company_name?: string;

}
interface UserLoginData {
    user_id?: any;
    name?: string;
    phone?: any;
    email?: string;
    company_logo?: string;
    company_name?: string;
    lang?: string;
    category?: string | undefined;
    token?: string; // Ensure token is a string
    encryptedToken?: string; // Add encryptedToken if necessary
}
const handleClose = () => {


};

const List: React.FC = ({ }) => {
    const [details, setDetails] = useState(false);
    const [profileImage, setProfileImage] = useState<string>();
    const [category, setCategory] = useState<string | undefined>();
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [companyName, setCompanyName] = useState<string>("");
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const loginData = useSelector((state: any) => state.userData.userLoginData)
    console.log(loginData)

    function handleChange(value: string) {
        if (value !== "Owner") {
            setDetails(true);
        } else {
            setDetails(false);
        }
        setCategory(value);
        console.log(value);

    }

    // Function to handle profile image upload
    const handleProfileImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            // Resize the image
            Resizer.imageFileResizer(
                file,
                800, // maxWidth
                800, // maxHeight
                'JPEG', // compressFormat
                90, // quality
                0, // rotation
                (uri) => {
                    uploadFileToS3('vattara-image', uri as File).then((fileUrl) => {
                        setProfileImage(fileUrl);
                    }).catch((error) => {
                        console.error('Error uploading profile image:', error);
                    });
                },
                'blob' // outputType
            );
        }
    };

    const uploadFileToS3 = async (bucketName: string, file: Blob) => {
        const fileName = `profile_${Date.now()}`;
        const params = {
            Bucket: bucketName,
            Key: fileName,
            Body: file,
            ContentType: file.type,
        };
        try {
            const uploadResponse = await S3.upload(params).promise();
            return uploadResponse.Location;
        } catch (error) {
            throw new Error(`Error uploading ${fileName} to S3: ${error}`);
        }
    };

    function handleName(event: React.ChangeEvent<HTMLInputElement>) {
        setName(event.target.value);
    }

    function handleEmail(event: React.ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value);
    }

    function handleCompanyName(event: React.ChangeEvent<HTMLInputElement>) {
        setCompanyName(event.target.value);
    }


    async function handleSubmitForm() {


        let errors: FormErrors = {};

        if (!name && !loginData.name) {
            errors.name = 'Please enter your name';
        }

        if (!email && !loginData.email) {
            errors.email = 'Please enter your email';
        }
        if (email && loginData.email) {
            if (!email.endsWith("@gmail.com")) {
                errors.email = 'Email must end with gmail.com';
            }
        }


        // Check if the category requires a company name
        if (category !== "Owner" && !companyName) {
            errors.companyName = 'Please enter your company name';
        }

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 2000);
            return;
        }

        setFormErrors({});

        const body: UserLoginData = {
            user_id: loginData.user_id,
            name: loginData.name,
            email: loginData.email,
            phone: loginData.phone,
            lang: "en",
            company_logo: profileImage || '',
            company_name: category !== "Owner" ? companyName : undefined,
            category: category,
            token: loginData.token,
            encryptedToken:loginData. encryptedToken
        };

        console.log(body);

        try {
            const response = await axiosInstance.put("/user/update/userDetail", body);
            console.log('Response:', response);
            dispatch(setUserLoginData(body)) 
            navigate('/')
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }



    return (
        <div className="fixed flex z-10 left-0 top-0 w-full h-full overflow-auto">
            <div className="bg-white m-auto p-6 border border-gray-300 border-solid w-[78%] max-w-4xl overflow-hidden h-[70%] rounded-[2%] flex flex-col relative sm:flex sm:flex-col sm:gap-2 sm:align-middle">
                <div className="flex flex-row h-full p-0">
                    <div className='relative z-10'>
                        <div className="absolute top-[-100px] left-[-80px] mt-4 w-60 h-60 bg-[#FFF8DC] rounded-full"></div>
                    </div>
                    <div className='z-30 w-[50%] '>
                        <div className="absolute right-4 top-3 text-gray-500 text-2xl font-bold cursor-pointer z-20 md" onClick={handleClose}>&times;</div>
                        <div className='mt-[17%] ml-[20%]'>
                            <p className='mb-3 font-bold'>Enter your contact information</p>
                            <div className='mb-3'>
                                <div className='flex gap-3 mb-1'>
                                    <p>Name</p>
                                    <input value={loginData.name} onChange={handleName} className='border-none outline-none' />
                                </div>
                                {showAlert && formErrors.name && (
                                    <div className='absolute ml-4 z-10 font-semibold text-red-500'>
                                        {formErrors.name}
                                    </div>
                                )}
                                <div className='border border-1 border-[#1F1F1F] w-64'></div>
                            </div>
                            <div className='mb-3'>
                                <div className='flex gap-3 mb-1'>
                                    <p>E-Mail</p>
                                    <input value={loginData.email} onChange={handleEmail} className='border-none outline-none' />
                                </div>
                                {showAlert && formErrors.email && (
                                    <div className='absolute ml-4 z-10 font-semibold text-red-500'>
                                        {formErrors.email}
                                    </div>
                                )}
                                <div className='border border-1 border-[#1F1F1F] w-64'></div>
                            </div>
                            <div>
                                <div className='flex gap-3 mb-1'>
                                    <p>Phone</p>
                                    <input value={loginData.phone} className='border-none outline-none' readOnly />
                                </div>
                                <div className='border border-1 border-[#1F1F1F] w-64'></div>
                            </div>
                        </div>
                        <div className={``}>
                            <p className='flex items-end ml-44 mt-4'>I’m a {category}</p>
                            <div className='flex gap-3 mt-3'>
                                {
                                    data.map((item) => (
                                        <div key={item.label}>
                                            <p onClick={() => (handleChange(item.label))} className={`p-2 px-3 rounded-2xl ${category === item.label ? 'bg-yellow-400' : 'bg-[#FCEAB6]'}`}>{item.label}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className={`${details ? "" : "hidden"}`}>
                            <p className='flex justify-center items-center mt-10'>Company Details</p>
                            <div className='flex flex-col ml-20 mt-3'>
                                <input className='outline-none placeholder:font-light placeholder:pl-4 mb-1' onChange={handleCompanyName} placeholder='Company name ' />
                                {showAlert && formErrors.companyName && (
                                    <div className='absolute ml-4 z-10 font-semibold text-red-500'>
                                        {formErrors.companyName}
                                    </div>
                                )}
                                <div className='border border-1 flex justify-center items-center border-[#1F1F1F] w-64'></div>
                            </div>
                            <div className='flex items-center ml-20 mt-4 gap-4'>
                                <label htmlFor="profile-image-upload" className="cursor-pointer">
                                    <img src={profileImage} alt="Profile" className="rounded-full bg-slate-500 w-20 h-20 cursor-pointer" />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleProfileImageUpload}
                                        className="hidden"
                                        id="profile-image-upload"
                                    />
                                </label>
                                <div className="flex flex-col ml-2">
                                    <p>Company logo</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex mt-8 justify-center items-center flex-col'>
                            <div className='flex justify-center items-center py-2 px-20 rounded-3xl bg-[#FBB337]'>
                                <button onClick={handleSubmitForm} className='  '>Submit</button>
                            </div>
                            <div><p className='font-light text-sm'>This helps us personalize your experience.</p></div>
                        </div>
                    </div>
                    <div className="w-[50%] flex justify-start items-center gap-3">
                        <div className="mt-60">
                            <img src={image1} className='h-[50vh]' alt='img' />
                        </div>
                        <div className="mt-40">
                            <img src={image2} className='h-[65vh]' alt='img' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default List;
