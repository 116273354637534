import React from 'react'
import { useStepperContext } from './StepperContext'
import { InputAdornment, TextField } from '@mui/material'
import { keyboard } from '@testing-library/user-event/dist/keyboard'

interface PriceTextFieldProps{
label?:any,
formName:string,
inputId?:number,
label2?:string,
keyboard:any,
required?:boolean,
userselectedData?:any
initial?:any

}

const PriceTextField:React.FC<PriceTextFieldProps> = ({label,formName,inputId,label2,keyboard,required,userselectedData,initial}) => {
const {addUserData}=useStepperContext()
const handleChange=(event: React.ChangeEvent<HTMLInputElement | { value: unknown }>)=>{
    const data={
         id:inputId,
         [label]:event.target.value,
         formName:formName,
         required:required

    }
    addUserData(data as any)
}
console.log("ll",label,initial)
const hasError=userselectedData?.includes(label)


  return (
    <div>
        <TextField id={label} label={label} 
        type={keyboard?keyboard:"text"}
        onChange={handleChange} defaultValue={initial} variant='standard' className='w-[100%]' InputProps={{
            endAdornment: <InputAdornment position="start">{label2}</InputAdornment>,
         }} >

        </TextField>

        {hasError && <div>This field is required</div>}



    </div>
  )
}

export default PriceTextField