import React, { useState } from "react";
import BG1 from './images/BG1.png';
import { HiBell } from "react-icons/hi";
import { FaSearch } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";
import sort from './images/sortby.svg';
import filter from './images/filter.svg';
import { ImInsertTemplate } from "react-icons/im";
import cancel from './images/cancel.svg';
import Filter from "./filter/Filter";
import SortBy from "./sortBy/SortBy";
import search from "./images/Search.svg"


interface SearchItem {
    id: number;
    place: string;
}

interface sort {
    sortOpen: any
    setValue: any
    setSortOpen: any
}

const Search: React.FC<sort> = ({ sortOpen, setValue, setSortOpen }) => {
    const [ename, setEname] = useState<string>("");
    const [sitem, setSitem] = useState<SearchItem[]>([
        { id: 1, place: "Adyar" },
        { id: 2, place: "Tambaram" }
    ]);
    const [filterProperty, setFilterProperty] = useState<string[]>([]);
    const [filterLoaction, setfilterLoaction] = useState<any[]>([])
    const [filterPrice, setFilterPrice] = useState<any>()
    const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
    const [kb, setKb] = useState<any>(false)
    const [receivedValue, setReceivedValue] = useState<any>(null);
    console.log(filterLoaction);
    console.log(filterProperty);
    console.log(filterPrice);




    const handgleinput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEname(event.target.value);
    }

    const addEname = () => {
        if (ename.trim() !== "") {
            setSitem([...sitem, { id: sitem.length + 1, place: ename }]);
        }
        setEname("");
    }

    const handgleinputdelete = (id: number) => {
        setSitem(sitem.filter(item => item.id !== id));
    }

    const toggleFilterVisibility = () => {
        setIsFilterVisible(!isFilterVisible);
        setSortOpen(false)

    }
    // const handleChildData = (data: any) => {
    //     setFilterProperty(data)
    //     console.log(data);
    // };

    function handleSort() {
        console.log("hello");
        setIsFilterVisible(false);
        setSortOpen(!sortOpen)
    }

    // function valueLabelFormat() {
    //     if (filterPrice >= 1000 && filterPrice < 100000) {
    //         return (filterPrice / 1000).toFixed(1) + 'k';
    //     }
    //     if (filterPrice >= 10000000) {
    //         return (filterPrice / 10000000).toFixed(2) + ' Cr';
    //     } else if (filterPrice >= 100000) {
    //         return (filterPrice / 100000).toFixed(2) + ' Lac';
    //     }
    //     return filterPrice;
    // }
    return (
        <div className="mt-3">
            <div className=" lg:block bg-[#FFFFFF] msm:hidden sm:hidden">

                <div className="flex items-center ml-2  ">
                    <div className="flex items-center gap-4 w-[100%] ">
                        <div className="relative  flex items-center w-[70%] rounded-2xl ">
                            <input
                                onClick={toggleFilterVisibility}
                                value={ename}

                                className="text-[#202020]  w-[100%] bg-[#ECEBEF] flex items-center h-12  rounded-l-3xl  outline-none border 
                                placeholder-[#9D9D9D]  placeholder:absolute placeholder:ml-6"
                                placeholder="Search Upto 3 locations"
                            />
                            <button
                                onClick={addEname}
                                className="bg-[#2E368F]   px-4 h-12 rounded-r-3xl  flex items-center justify-center"
                            >
                                <img className=" h-7 w-6 " src={search} />
                            </button>
                        </div>

                        <div onClick={handleSort} className="cursor-pointer flex  items-center text-[#2E368F] gap-1">
                            <img className="flex justify-center items-center" src={sort} alt="Sort" />
                            <p className="flex justify-center items-center text-lg font-semibold">Sort by</p>
                        </div>

                        <div onClick={toggleFilterVisibility} className="cursor-pointer flex items-center gap-1">
                            <img src={filter} alt="Filter" />
                            <p className="font-semibold text-lg text-[#2E368F]">Filter</p>
                        </div>
                    </div>


                </div>
                <div className="flex gap-4 ml-3 mt-3 items-center">
                    <div className="w-[40%]">
                        <p className=" font-semibold">You are currently searching a property at</p>
                        <div className="flex gap-4 mt-2">
                            {filterLoaction.map((item) => (
                                <div className="flex gap-1 items-center">
                                    <p className="text-[#252B5C] font-semibold">{item}</p>
                                    <img src={cancel} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="border-solid border-1  border-l border-gray-600 lg:h-14  my-"></div>
                    <div className={`w-[60%] ${filterProperty?.length > 0?"block":"hidden"}`}>
                        <p className=" font-semibold">Filter by</p>
                        <div>
                            {/* <div className={` gap-1 items-center ${filterPrice !== 0 ? "flex" : "hidden"}`}>
                                <p>Max Price Up to:</p>
                                <p className="text-[#252B5C] font-medium">{valueLabelFormat()}</p>
                            </div> */}
                            <div   onClick={toggleFilterVisibility} className="flex gap-3 mt-2 items-center ">
                                {filterProperty?.length > 0 &&
                                    <div className="flex bg-[#E2E2E2] items-center py-1 px-2 rounded-2xl gap-2">
                                        <p className="text-[#252B5C] font-medium">Property Type</p>
                                        <img src={cancel} />
                                    </div>

                                }

                                <div>
                                    {filterProperty?.length > 0 &&
                                        <div className="flex bg-[#E2E2E2] items-center py-1 px-2 rounded-2xl gap-2">
                                            <p className="text-[#252B5C] font-medium">Price Range</p>
                                            <img src={cancel} />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div className={`transition-all duration-1000 ${isFilterVisible ? 'flex' : 'hidden'}`}>
                <Filter kb={kb} toggleFilterVisibility={toggleFilterVisibility} setFilterProperty={setFilterProperty} setfilterLoaction={setfilterLoaction} setFilterPrice={setFilterPrice} />
            </div>

            <div className={`${sortOpen ? "flex" : "hidden"} `}
            >
                <SortBy setValue={setValue} handleSort={handleSort} />
            </div>
        </div>
    );
}

export default Search;
