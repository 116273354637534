import React, { useState } from "react";
import Backgroundimg from "../assets/homeimage.png";
import { TextField } from "@mui/material";
import background from "../../assets/homeeditbg.svg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import profile from "../../assets/Profile.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { axiosInstance } from '../api/APIs';
import { useDispatch } from "react-redux";
import { setUserLoginData } from "../../slices/userDataSlice";
import AWS from 'aws-sdk';
import Resizer from 'react-image-file-resizer';
import { Link } from "react-router-dom";



AWS.config.update({
  region: 'us-east-1',
  accessKeyId: 'AKIAQ3EGVP6CLIGM245V',
  secretAccessKey: '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',
});

const S3 = new AWS.S3();


const Edit: React.FC = () => {
  const loginData = useSelector((item: any) => item.userData.userLoginData);
  const [profileImage, setProfileImage] = useState<any>(loginData?.company_logo);
  const [name, setName] = useState<string>(loginData?.name || "");
  const [email, setEmail] = useState<string>(loginData?.email || "");
  const [phone, setPhone] = useState<any>("");
  const [category, setCategory] = useState<any>(loginData?.category || "");
  const [companyName, setCompanyName] = useState<string>(loginData?.company_name || "");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log(loginData);

  const handleProfileImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // Resize the image
      Resizer.imageFileResizer(
        file,
        800, // maxWidth
        800, // maxHeight
        'JPEG', // compressFormat
        90, // quality
        0, // rotation
        (uri) => {
          uploadFileToS3('vattara-image', uri as File).then((fileUrl) => {
            setProfileImage(fileUrl);
          }).catch((error) => {
            console.error('Error uploading profile image:', error);
          });
        },
        'blob' // outputType
      );
    }
  };

  const uploadFileToS3 = async (bucketName: string, file: Blob) => {
    const fileName = `profile_${Date.now()}`;
    const params = {
      Bucket: bucketName,
      Key: fileName,
      Body: file,
      ContentType: file.type,
    };
    try {
      const uploadResponse = await S3.upload(params).promise();
      return uploadResponse.Location;
    } catch (error) {
      throw new Error(`Error uploading ${fileName} to S3: ${error}`);
    }
  };
  const handleClick = () => {
    navigate("/profile");
  };

  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleCompanyName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(event.target.value);
  };

  const handleUpdate = async () => {


    const body = {
      user_id: loginData.user_id,
      name: name,
      email: email,
      phone: loginData.phone,
      lang: "en",
      company_logo: profileImage || '',
      company_name: category !== "Owner" ? companyName : "",
      category: category,
      token: loginData.token,
      encryptedToken: loginData.encryptedToken


    };
    console.log(body);

    const url = category !== "" ? "/user/update/userDetail" : "/user/update/userNameEmail";

    try {
      const responses = await axiosInstance.put(url, body);
      console.log(responses.data.message);
      dispatch(setUserLoginData(body));
      navigate('/profile')
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className="">
      <div className="lg:flex lg:flex-col lg:items-center lg:justify-center overflow-hidden">
        <ul className="">

        </ul>
        <div className="w-full  bg-white p-4 rounded-lg lg:mt-7 overflow-auto">
          <div className="sl:w-full h-full sl:relative xl:mx-[23%]">
            <img src={background} alt="Background" />
          </div>
          <div className=" mx-[30%] ">
            <p>Name</p>
            <form>
              <div className="mb-4">
                <label className="block text-gray-700  text-sm font-bold mb-2" htmlFor="username"></label>
                <input
                  value={name}
                  onChange={handleName}
                  className="shadow appearance-none border-1 border-black  w-[90%] rounded-full py-3 px-3 text-gray-700  "

                  id="username"
                  type="text"
                  placeholder="Username"
                />
              </div>
              <p>E-mail</p>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="example@gmail.com"></label>
                <input
                  value={email}
                  onChange={handleEmail}
                  className="shadow appearance-none border-1 border-black  w-[90%] rounded-full py-3 px-3 text-gray-700  "
                  id="username"
                  type="email"
                  placeholder="example@gamil.com"
                />
              </div>
              <p>Phone Number</p>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor=""></label>
                <input
                  value={loginData?.phone || ""}
                  className="shadow appearance-none border-1 opacity-60 border-black  w-[90%] rounded-full py-3 px-3 text-gray-700  "
                  id="username"
                  type="text"
                  placeholder="+91 9893737243"
                />
              </div>
              <div className={`${category !== "" ? "flex flex-col" : "hidden"}`}>
                <div className="mt-1">
                  <p>Company Details</p>
                  <div className="flex flex-row gap-2 mt-2">
                    <label htmlFor="profile-image-upload" className="cursor-pointer">
                      <img src={profileImage} alt="Profile" className="rounded-full bg-slate-500 w-20 h-20 cursor-pointer" />
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleProfileImageUpload}
                        className="hidden"
                        id="profile-image-upload"
                      />
                    </label>
                    <p className="mt-8 text-[#006CE7]  font-bold">Edit Image</p>
                  </div>
                </div>
                <div className="mb-4 mt-4">
                  <p>Company Name</p>
                  <label className="block text-gray-700 text-sm font-bold mb-2 ml" htmlFor=""></label>
                  <input
                    onChange={handleCompanyName}
                    value={companyName}
                    className="shadow appearance-none border-1 border-black  w-[90%] rounded-full py-3 px-3 text-gray-700  "
                    id="username"
                    type="text"
                    placeholder="example co LTD"
                  />
                </div>
              </div>
            </form>
            <div className="left-1/2 -translate-x-3/4 transform absolute bottom-5">
              <button
                onClick={handleUpdate}
                className="bg-[#2E368F] py-3 px-28 rounded-full text-white"
              >
                Save Details
              </button>
             <Link to={'/terms'}> <p className="mt-2 text-sm ">
                By clicking, you accept our{" "}
                <span className="underline text-[#2E368F]">Terms & Conditions</span>
              </p></Link>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
