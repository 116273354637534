import React, { useEffect, useState } from "react";
import search from '../images/Search.svg'
import mainbuilding from "../../assets/Banner_BG.png";

import Card from "./Card";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IoIosSearch } from "react-icons/io";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { districts, filterData } from '.././data'

import { axiosInstance } from '../api/APIs';
import { useDispatch } from 'react-redux';
import { setUsers } from '../../slices/filterSlice';
import { useNavigate } from "react-router-dom";

import { useSelector } from 'react-redux';

import { Box, Button, Grow, InputAdornment, TextField, Typography } from "@mui/material";


interface Props {
  setLookingToValue:any
}


const Navbar: React.FC<Props>  = ({setLookingToValue}) => {

  const [location, setLocation] = useState<string>("Vellore");
  const [cityVisable, setCityVisable] = useState<boolean>(false);
  const [selectedLook, setSelectedLook] = useState<string | null>("Buy");
  const [searchCity, setSearchCity] = useState<string>("");
  const [subCategory, setSubCategory] = useState<string | null>("Buy");
  const [selectedProperties, setSelectedProperties] = useState<any[]>([]);
  const dispatch = useDispatch();
  const Navigate = useNavigate()
  const loginData = useSelector((state: any) => state.userData.userLoginData)
  const lookData = filterData[0].look;
  const [error, setError] = useState<string>("");
  console.log(loginData);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => setError(""), 5000)
      return () => clearTimeout(timer)
    }
  }, [error])

  function handleLocation(value: any) {
    setCityVisable(!cityVisable)
    setLocation(value)
  }

  const handleChangeLook = (Id: number, value: string) => {
    setSelectedLook(selectedLook === value ? null : value);
    setLookingToValue(value)
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCity(event.target.value);
  };



  const handleChangeSub = (value: string) => {
    setSubCategory(subCategory === value ? null : value);
    
  };
  async function handleApply() {
    if (!searchCity) {
      setError("Please enter a city to search.");
      return;
    }
    const body: any = {
      district: location,
      city: [searchCity],
      property_type: selectedProperties.length === 0
        ? (selectedLook === "Commercial"
          ? ["Showroom", "Office Space", "Retail Shop", "Industrial Space", "Warehouse"]
          : (selectedLook === "Buy" || selectedLook === "Rent" || selectedLook === "Lease"
            ? ["Apartment", "Vacant Land", "Villa", "Independent House"]
            : selectedProperties))
        : selectedProperties,

      looking_to: selectedLook === "Commercial" ? subCategory : selectedLook,
      price: 100000000000,
      user_id: loginData.user_id,
      kbl_property: false
    };



    console.log(body);
    console.log(selectedLook);
    const url = selectedLook === "Commercial" ? "/filter/commercialFilter" : "/filter/resbuyfilterData";

    try {
      const response = await axiosInstance.post(url, body);
      dispatch(setUsers(response.data.message));
      console.log('Response:', response.data.message);
      Navigate('/Property')
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleBoxClick = () => {
    setCityVisable(!cityVisable);
  };



  return (
    <div>
    <div className=" sm:block msm:hidden">
      <Box sx={{ marginTop: "" }} >

        <Box sx={{ display: "flex", flexDirection: "row", width: "100%", height: "75vh" }}>
          <Box sx={{
            bottom: 0, width: "100%", display: "flex", justifyContent: "flex-end", flexDirection: "column", background: 'linear-gradient(90deg, #E2EFF6, #E7ECEF)', position: "relative",
          }}>
            <img
              src={mainbuilding}
              alt="Building"
              style={{ width: "100%", height: "100%", }}
            />
          </Box>

          <Box sx={{
            position: "absolute", width: "100%", top: "20%",
            // // left: "50%",
            // transform: "translateX(-50%)",
          }} >

            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>

              <Box sx={{ height: "100px", marginLeft:{sm:"10px",lg:"0px"}, width: "60%", display: "flex", flexDirection: "column", justifyContent: "left", mb: 7 }}>
                <Typography sx={{ fontSize: "28px", fontWeight: 500, textTransform: "uppercase", margin: 0, padding: 0, lineHeight: 1, fontFamily: "k2d" }}>
                  Your new Guide to get a
                </Typography>
                <Typography sx={{ fontSize: "50px", fontWeight: 900, textTransform: "uppercase", color: "#252B5C", margin: 0, padding: 0, lineHeight: 1 }}>
                  Comfortable Home
                </Typography>
              </Box>

            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>

              <Box sx={{ height: "40px", width: {lg:"60%",sm:'95%'}, display: "flex", flexDirection: "row", justifyContent: "left" }}>
                <Box sx={{ backgroundColor: "#fff", borderRadius: '10px 10px 0 0', }}>
                  {lookData.map((item) => (
                    <Button
                      variant="text"
                      onClick={() => handleChangeLook(item.id, item.button)}
                      style={{
                        padding: '6px 24px',
                        borderRadius: '10px 10px 0 0',

                        fontWeight: "600",
                        fontFamily: "k2d",
                        transition: "all 0.5s ease-in-out",
                        fontSize: "18px",
                        borderBottom: selectedLook === item.button
                          ? '4px solid #006CE7'
                          : '4px solid transparent',
                        backgroundColor: selectedLook === item.button ? '#Fff' : '#FFFFFF',
                        color: selectedLook === item.button ? '#000' : '#000000',
                        boxShadow: 'none',
                        textTransform: 'none'
                      }}
                    >
                      {item.button}

                    </Button>
                  ))}

                </Box>
                <Box sx={{ ml: 10, gap: 2, display: selectedLook === "Commercial" ? "flex" : "none", backgroundColor: "#fff", height: "30px", mt: 2, borderRadius: '10px 10px 0 0', }}>
                  {filterData[0].SubCategory.map((item) => (
                    <Box key={item.id}>
                      <Button
                        variant="text"
                        onClick={() => handleChangeSub(item.button)}
                        sx={{
                          padding: '6px 24px',
                          borderRadius: '10px 10px 0 0',

                          fontWeight: "600",
                          height: "30px",

                          fontFamily: "k2d",
                          transition: "all 0.5s ease-in-out",
                          fontSize: "18px",
                          borderBottom: subCategory === item.button
                            ? '4px solid #006CE7'
                            : '4px solid transparent',
                          backgroundColor: subCategory === item.button ? '#Fff' : '#FFFFFF',
                          color: subCategory === item.button ? '#000' : '#000000',
                          boxShadow: 'none',
                          textTransform: 'none'

                        }}
                      >
                        {item.button}
                      </Button>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>

              <Box sx={{ background: "#fff", height: "130px", width: {lg:"60%",sm:'95%'}, display: "flex", flexDirection: "row", justifyContent: "center", borderRadius: "0 15px 15px 15px", alignItems: "center", alignContent: "center" }}>

                <div>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Box
                      sx={{
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#FAFAFA",
                        alignContent: "center",
                        height: "50px",
                        alignItems: "center",
                        width: cityVisable ? "140px" : "220px",
                        borderRadius: "42px 0 0 42px",
                        transition: "width 0.5s ease-in-out",
                        cursor: "pointer"
                      }}
                      onClick={handleBoxClick}
                    >
                      <Typography
                        sx={{
                          color: "#000",
                          fontWeight: "600",
                          fontFamily: "k2d",
                          fontSize: cityVisable ? "14px" : "20px",
                          transition: "font-size 0.5s ease-in-out"
                        }}
                      >
                        {location} {cityVisable ?
                          <KeyboardArrowUpIcon
                            sx={{
                              color: "#000",
                              fontSize: "inherit",
                              transition: "font-size 0.5s ease-in-out"
                            }}
                          /> :
                          <KeyboardArrowDownIcon
                            sx={{
                              color: "#000",
                              fontSize: "inherit",
                              transition: "font-size 0.5s ease-in-out"
                            }}
                          />
                        }
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginLeft: "20px" }}>
                      <TextField
                        onChange={handleSearch}
                        placeholder="Search Your location"
                        value={searchCity}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IoIosSearch style={{ fontSize: "25px" }} />
                            </InputAdornment>
                          ),
                          disableUnderline: true,
                          style: {
                            padding: '5px',
                            color: 'black',
                            fontFamily: "K2D"
                          }
                        }}
                        variant="standard"
                        sx={{
                          border: 'none',
                          padding: '0',
                          width: {xl:"580px"},
                          outline: 'none',
                          '& .MuiInputBase-input': {
                            padding: '0'
                          }
                        }}
                      />
                      <Button
                        sx={{
                          backgroundColor: "#006CE7",
                          color: "#fff",
                          width: "150px",
                          height: "50px",
                          fontFamily: "K2D",
                          fontSize: "20PX",
                          textTransform: "capitalize",
                          borderRadius: "9px",

                          ':hover': {
                            backgroundColor: "#005BB5",
                          }
                        }}
                        onClick={handleApply}
                      >
                        Search
                      </Button>
                      {error && <p style={{ color: 'red', marginTop: '100px', position: "absolute" }}>{error}</p>}

                    </Box>
                  </Box>
                  <div style={{ height: '60px', position: "absolute", zIndex: "999" }}>
                    <Grow in={cityVisable}>
                      <div
                        style={{
                          position: "absolute",
                          height: '200px',
                          left: '40%',
                          top: '45%',
                          overflowY: 'scroll',
                          backgroundColor: 'white',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '8px',
                          transition: "all 0.5s ease-in-out"
                        }}
                      >
                        {districts.map((item) => (
                          <div
                            key={item}
                            onClick={() => handleLocation(item)}
                            style={{ color: 'black', padding: '8px 0' }}
                          >
                            {item}
                          </div>
                        ))}
                      </div>
                    </Grow>
                  </div>

                </div>
                <Box>

                </Box>


              </Box>
            </Box>




          </Box>
        </Box>


      </Box>
    </div>

    <div className="sm:hidden msm:block mt-2">
      <p className="text-black font-bold text-center mt-2">Innovate | Elevate | Reside</p>
      <div className="bg-[#E2E6F2] rounded-3xl p-2 mx-1 mt-2 ">
        <div className="flex gap-3 justify-center items-center mt-1 mb-2">
          {filterData[0].SubCategory.map((item: any) => (
            <div className={`py-2 px-8 rounded-3xl justify-center items-center ${subCategory == item.button ? 'bg-[#2E368F] text-white' : "bg-[#F5F4F8] text-black"} flex `}>
              <button onClick={() => handleChangeSub(item.button)} className="">{item.button}</button>
            </div>
          ))}
        </div>
        <div className="flex relative mt-2">
          <input
            placeholder="Search up to 3 locations"
            className="w-full bg-[#F5F4F8] rounded-3xl h-14 placeholder:text-gray-500 placeholder:ml-4 px-6 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <div className="bg-[#2E368F] absolute top-1 h-12 right-2  px-4 rounded-3xl flex items-center justify-center">
            <img className="   h-5 w-5  " src={search} />
          </div>

        </div>
      </div>
    </div>
  </div>
  );
};

export default Navbar;
