// ChatScreen.tsx
import React from 'react';
import ChatList from '../Chat/Chatlist'; // Adjust path as needed
import SingleChat from '../Chat/SingleChat'; // Adjust path as needed
import { useChat } from '../context/ChatContext'; // Adjust path as needed
import { useLocation } from 'react-router-dom';


const ChatScreen: React.FC = () => {
  const { selectedChat } = useChat();
  const location = useLocation();
  const {
    chatId,
    userId,
    postId,
    username,
    userpic,
    initialMessageText,
    details,
  } = location.state || {};

  console.log('Chat Details:', {
    chatId,
    userId,
    postId,
    username,
    userpic,
    initialMessageText,
    details,
  });

  // Use the details object as needed
  console.log('Additional Details:', details);

  return (
    <div className="chat-container grid grid-cols-3 h-screen ml-96">
      {/* Chat List Section */}
      <div className="chat-list border-r border-gray-200 col-span-1">
        <ChatList />
      </div>

      {/* Single Chat Section */}
      <div className="chat-content col-span-1">
        {selectedChat ? (
          <SingleChat chat={selectedChat} />
        ) : (
          <div className="flex items-center justify-center h-full text-gray-500">
            Select a chat to start messaging
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatScreen;
