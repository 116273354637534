
import React, { useState, useEffect, useCallback } from 'react';
import {
  GoogleMap,
  MarkerF as Marker,
  InfoWindow,
  useJsApiLoader,
  LoadScriptProps,
  useLoadScript,
} from '@react-google-maps/api';
import { useStepperContext } from './StepperContext';
import { Alert, Button, Snackbar } from '@mui/material';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";


interface MapProps{
  onClose:()=>void,
  onLocationSelect: (location: string) => void;
  formName:string
}



const Map:React.FC<MapProps> = ({onClose, onLocationSelect,formName}) => {
  const { isLoaded } =  useLoadScript({
    googleMapsApiKey: 'AIzaSyDz2gppwqEkyckilX-FDJkvgEtcoS2OEvE',
})
 



  const [currentLocation, setCurrentLocation] = useState<any>(null);
  const [selectedMarker, setSelectedMarker] = useState<any>(null);
  const [address, setAddress] = useState('');
  const [district, setDistrict] = useState('');
  const [state, setState] = useState('');
  const [error, setError] = useState<any>(null);
  const [value, setValue] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [subLocality,setSubLocality]=useState("")
  const { userData,addLocation,location,addUserData } = useStepperContext() as { userData: any; addLocation: any; location: any,addUserData:any };
  const mydistrict = userData.find((item: any) =>
    item.hasOwnProperty('City')
  )?.['City'];
  

  

  const mapStyles = {
    height: '60vh',
    width: '100%',
    borderRadius:10,
    marginTop:10,
    marginBottom:10
  };

  useEffect(()=>{
    if(location?.latitude ){
      setCurrentLocation({
        latitude:location?.latitude,
        longitude:location?.longitude
      })
      fetchAddress(location?.latitude , location?.longitude );
    }else{
      if (mydistrict) {
        fetchCoordinatesFromAddress(mydistrict);
      } else if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setCurrentLocation({
              latitude,
              longitude,
            });
            fetchAddress(latitude, longitude);
          },
          handleLocationError,
          { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
        );
      }
    }
   
    
  },[location])

  const fetchAddress = useCallback(async (lat: any, lng: any) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDz2gppwqEkyckilX-FDJkvgEtcoS2OEvE`
      );
      const data = await response.json();
      if (data.status === 'OK') {
        console.log("device",data)
        let townOrVillage=""
        const formattedAddress =
          data.results[0]?.formatted_address || 'Address not found';
        const addressComponents = data.results[0]?.address_components || [];

        const districtComponent = addressComponents.find(
          (component: any) =>
            component.types.includes('administrative_area_level_3')
        );
        addressComponents.forEach((component:any) => {
          if (component.types.includes('sublocality_level_1')) {
            townOrVillage = component.long_name; // Set to sublocality_level_1
          } else if (component.types.includes('sublocality') && !townOrVillage) {
            townOrVillage = component.long_name; // Set to sublocality if no sublocality_level_1
          } else if (component.types.includes('locality') && !townOrVillage) {
            townOrVillage = component.long_name; // Set to locality if no sublocality or sublocality_level_1
          }
        });

        const stateComponent = addressComponents.find((component: any) =>
          component.types.includes('administrative_area_level_1')
        );

        const isDistrictMatched = addressComponents.some(
          (component: any) => component.long_name === mydistrict
        );

        if(isDistrictMatched){
          setAddress(formattedAddress);
          setSubLocality(townOrVillage)
        setDistrict(districtComponent?.long_name || 'Not available');
        setState(stateComponent?.long_name || 'Not available');
        }else{
          setOpen(true)
          fetchCoordinatesFromAddress(mydistrict)
        }

        
      } else {
        setAddress('Address not found');
        setDistrict('Not available');
        setState('Not available');
        console.error('Geocoding API error:', data.status);
      }
    } catch (error) {
      console.error('Error fetching address:', error);
      setAddress('Error fetching address');
      setDistrict('Not available');
      setState('Not available');
    }
  }, [mydistrict]);

  const fetchCoordinatesFromAddress = useCallback(async (address: string) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=AIzaSyDz2gppwqEkyckilX-FDJkvgEtcoS2OEvE`
      );
      const data = await response.json();
      if (data.status === 'OK') {
        const location = data.results[0]?.geometry.location;
        if (location) {
          setCurrentLocation({
            latitude: location.lat,
            longitude: location.lng,
          });
          fetchAddress(location.lat, location.lng);
        } else {
          setError('Could not fetch coordinates for the provided address.');
          setCurrentLocation({
            latitude: 13.074664095946645,
            longitude: 80.28514350701904,
          });
          fetchAddress(13.074664095946645, 80.28514350701904);
        }
      } else {
        setError('Geocoding API error.');
        setCurrentLocation({
          latitude: 13.074664095946645,
          longitude: 80.28514350701904,
        });
        fetchAddress(13.074664095946645, 80.28514350701904);
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
      setError('Error fetching coordinates');
      setCurrentLocation({
        latitude: 13.074664095946645,
        longitude: 80.28514350701904,
      });
      fetchAddress(13.074664095946645, 80.28514350701904);
    }
  }, [fetchAddress]);

  const handleLocationError = useCallback(
    (error: any) => {
      console.error('Geolocation error:', error);
      let errorMessage = 'An unknown error occurred while fetching location.';
      switch (error.code) {
        case error.PERMISSION_DENIED:
          errorMessage = 'Permission denied. Please allow location access.';
          break;
        case error.POSITION_UNAVAILABLE:
          errorMessage =
            'Position unavailable. Unable to fetch your location. Please check your GPS settings and try again.';
          break;
        case error.TIMEOUT:
          errorMessage = 'Location request timed out. Please try again later.';
          break;
        default:
          errorMessage = 'An unexpected error occurred.';
          break;
      }
      setError(errorMessage);
      setCurrentLocation({
        latitude: 13.074664095946645,
        longitude: 80.28514350701904,
      });
      fetchAddress(13.074664095946645, 80.28514350701904);
    },
    [fetchAddress]
  );

 

  const onMarkerDragEnd = (event: any) => {
    const lat = event.latLng?.lat();
    const lng = event.latLng?.lng();
    if (lat && lng) {
      setCurrentLocation({
        latitude: lat,
        longitude: lng,
      });
      addLocation({
        latitude: lat,
        longitude: lng,
      })
      fetchAddress(lat, lng);
    }
  };
  console.log("new",currentLocation)

  const handleSave=(location: string)=>{
    onLocationSelect(location);
    
    const body1 = {
      id: 15001455,
      Sublocality: subLocality,
      formName: formName,
    };
    addUserData(body1)
    
    onClose()
  }

 


  const defaultCenter = {
    lat: currentLocation?.latitude || 0,
    lng: currentLocation?.longitude || 0,
  };
  const handleSelect = async (place:any) => {
    try {
    setValue(place.value.label)
      const results = await geocodeByAddress(place.value.description);
      const { lat, lng } = await getLatLng(results[0]);
       fetchCoordinatesFromAddress(place.value.description)
      fetchAddress(lat,lng)
    } catch (error) {
      console.error('Error fetching location details:', error);
    }
  };
  return (
    <div>
         <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        autocompletionRequest={{
          componentRestrictions: { country: ['in'] },
          
        }}
        selectProps={{
          value,
          onChange: (place) => handleSelect(place),
        }}
        debounce={500} // delay before sending requests
        // other props if needed
      />
      
      <Snackbar
      open={open}
      autoHideDuration={5000} // Duration in milliseconds (5 seconds)
      onClose={() => setOpen(false)}
      anchorOrigin={{ vertical: 'top', horizontal: "right" }}
    >
      <Alert
        onClose={() => setOpen(false)}
        severity="error"
        sx={{ width: '100%' }}
      >
        Selected Locality out of the City
      </Alert>
    </Snackbar>
      {isLoaded &&(
        <>
        
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={20}
          center={defaultCenter}
        >
          {currentLocation && (
            <Marker
              position={{
                lat: currentLocation.latitude,
                lng: currentLocation.longitude,
              }}
              onClick={() => setSelectedMarker(currentLocation)}
              draggable={true}
              onDragEnd={onMarkerDragEnd}
            />
          )}

          {selectedMarker && (
            <InfoWindow
              position={{
                lat: selectedMarker.latitude,
                lng: selectedMarker.longitude,
              }}
              onCloseClick={() => setSelectedMarker(null)}
            >
              <div>
                <p>
                  <strong>Latitude:</strong> {selectedMarker.latitude}
                </p>
                <p>
                  <strong>Longitude:</strong> {selectedMarker.longitude}
                </p>
                <p>
                  <strong>Address:</strong> {address}
                </p>
                <p>
                  <strong>District:</strong> {district}
                </p>
                <p>
                  <strong>State:</strong> {state}
                </p>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
       
        </>
       
      )

        }
       
      
      <Button variant="contained" onClick={()=>handleSave(address)}>Confirm Location</Button>
    </div>
  );
};

export default Map;

