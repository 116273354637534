import React, { useState } from 'react'
import editIcon from '../../assets/editForm.svg'
import { Box, Modal } from '@mui/material';
import UploadPhoto from './component/UploadPhoto';

interface AdditionalDetails {
    bathroom: string;
    balcony: string;
    preferred_tenant_type: string;
    parking: string;
    carpet_area: string;
    // Add other fields if necessary
}

interface User {
    name: string;
    mobile_no: string;
    category: string;
    company_logo: string;
    company_name: string;
}

interface PropertyImage {
    filename: string;
    id: number;
    isCover: boolean;
    name: string;
    type: string;
    uri: string;
}

interface Property {
    active: boolean;
    additional_details: AdditionalDetails;
    available_from: string;
    bhk: string;
    building_name: string;
    built_up_area: string;
    city: string;
    createdAt: string;
    delete_post_reason: string;
    district: string;
    enter_lease_amount: string;
    flat_furnishing: string;
    furnish_type: string;
    home_screen_post: boolean;
    id: number;
    images: PropertyImage[];
    kbl_property: boolean;
    landmark_street: string;
    latitude: string;
    liked: boolean;
    longitude: string;
    monthly_rent: string;
    negotiable: string;
    post_approval: string;
    post_id: string;
    propertyID: string;
    property_available_for: string;
    property_type: string;
    res_or_com: string;
    security_deposit: string;
    shareUrl: string;
    society_amenities: any; // Adjust type based on actual data or make it optional
    state: string | null;
    sublocality: string;
    tbl_user_reg: User;
    updatedAt: string;
    user_id: string;
    view_post_count: number;
    looking_to: any
    price: any
    cost: any
    lease_amount: any
}

interface DetailsProps {
    passedData: Property; // Use the Property interface here
}




const EditImages: React.FC<DetailsProps> = ({ passedData }) => {

    const [formVisible, setFormVisible] = useState<any>(false)
    const [FullData, setFullData] = useState<any>(passedData)

    console.log("hello array check", FullData)
    const handleForm = () => {
        console.log("check price popup")
        setFormVisible(true);

    };

    const closeModal = () => {
        setFormVisible(false);

    }



    return (
        <div className='mt-3 relative'>
            <div>
                <p className='text-xl font-semibold mb-3 text-[#2E368F]'>Photos</p>
                <img onClick={handleForm} src={editIcon} className='absolute top-0 right-0' />
                <div>
                    {passedData ? (
                        <div className='grid grid-cols-3'>
                            {FullData[0].images.map((item:any) => (
                                <div >
                                    <img className='w-40 h-40' src={item.uri} />
                                </div>
                            ))}
                        </div>
                    ) : ""}
                </div>
            </div>
            {/* <div>
                {passedData.res_or_com === 'Residential' &&
                    (passedData.property_type === 'Independent House' ||
                        passedData.property_type === 'Apartment' ||
                        passedData.property_type === 'Villa') &&
                    (passedData.property_available_for === 'Rent' ||
                        passedData.property_available_for === 'Lease') && (
                        <div className='flex gap-3'>
                            {passedData.images.map((item) => (
                                <div >
                                    <img className='w-40 h-40' src={item.uri} />
                                </div>
                            ))}
                        </div>
                    )}
            </div>

            <div>
                {passedData.res_or_com === "Residential" &&
                    passedData.property_type === "Vacant Land" &&
                    passedData.looking_to === "Sale" && (
                        <div className='flex gap-3'>
                            {passedData.images.map((item) => (
                                <div >
                                    <img className='w-40 h-40' src={item.uri} />
                                </div>
                            ))}
                        </div>
                    )}
            </div>
            <div>
                {passedData.res_or_com === "Commercial" &&
                    passedData.property_type === "Office Space" &&
                    (passedData.property_available_for === "Rent" ||
                        passedData.property_available_for === "Lease") && (
                        <div className='flex gap-3'>
                            {passedData.images.map((item) => (
                                <div >
                                    <img className='w-40 h-40' src={item.uri} />
                                </div>
                            ))}
                        </div>
                    )}
            </div>
            <div>
                {passedData.res_or_com === "Commercial" &&
                    passedData.property_type === "Warehouse" &&
                    (passedData.property_available_for === "Rent" ||
                        passedData.property_available_for === "Lease") && (
                            <div className='flex gap-3'>
                            {passedData.images.map((item) => (
                                <div >
                                    <img className='w-40 h-40' src={item.uri} />
                                </div>
                            ))}
                        </div>
                    )}
            </div>
            <div>
                {passedData.res_or_com === "Commercial" &&
                    passedData.property_type === "Industrial Space" &&
                    (passedData.property_available_for === "Rent" ||
                        passedData.property_available_for === "Lease") && (
                            <div className='flex gap-3'>
                            {passedData.images.map((item) => (
                                <div >
                                    <img className='w-40 h-40' src={item.uri} />
                                </div>
                            ))}
                        </div>
                    )}
            </div>

            <div>
                {passedData.res_or_com === "Commercial" &&
                    passedData.property_type === "Retail Shop" &&
                    (passedData.property_available_for === "Rent" ||
                        passedData.property_available_for === "Lease") && (
                            <div className='flex gap-3'>
                            {passedData.images.map((item) => (
                                <div >
                                    <img className='w-40 h-40' src={item.uri} />
                                </div>
                            ))}
                        </div>
                    )}
            </div>

            <div>
                {passedData.res_or_com === "Commercial" &&
                    passedData.property_type === "Office Space" &&
                    (passedData.looking_to === "Sale" 
                       ) && (
                            <div className='flex gap-3'>
                            {passedData.images.map((item) => (
                                <div >
                                    <img className='w-40 h-40' src={item.uri} />
                                </div>
                            ))}
                        </div>
                    )}
            </div>

            <div>
                {passedData.res_or_com === "Commercial" &&
                    passedData.property_type === "Warehouse" &&
                    (passedData.looking_to === "Sale" 
                       ) && (
                            <div className='flex gap-3'>
                            {passedData.images.map((item) => (
                                <div >
                                    <img className='w-40 h-40' src={item.uri} />
                                </div>
                            ))}
                        </div>
                    )}
            </div> */}

            <Modal
                open={formVisible}
                onClose={closeModal}
                aria-labelledby="basic-modal-title"
                aria-describedby="basic-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 800,
                        maxHeight: '90vh',
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                    }}
                >
                    <UploadPhoto setFullData={setFullData} passedData={FullData} />
                </Box>
            </Modal>


        </div>
    )
}

export default EditImages