import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import io from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import { useChat } from '../context/ChatContext';

const ENDPOINT = 'http://192.168.1.16:5000';
let socket: any;

interface User {
  user_id: string;
  name: string;
  user_pic: string;
  email: string;
}

interface Sender {
  name: string;
  user_pic: string;
  email: string;
  user_id: string;
}

interface LatestMessage {
  content: string;
  createdAt: string;
  sender: Sender;
  chatId: number;
}

interface UserType {
  chatId: number;
  users: User[];
  latestMessage: LatestMessage | null;
}

interface ChatlistProps {
  fetchAgain?: any;
}

const Chatlist: React.FC<ChatlistProps> = ({ fetchAgain }) => {
  const [users, setUsers] = useState<UserType[]>([]);
  const loginData = useSelector((state: any) => state.userData.userLoginData);
  const userid = loginData.user_id;
  const navigate = useNavigate();
  const { setSelectedChat } = useChat(); // Access setSelectedChat from context

  const chatListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    socket = io(ENDPOINT);
    fetchChats();

    socket.on('connect', () => {
      socket.emit('join', userid);
    });

    socket.on('update last message', (newMessage: any) => {
      console.log('New message received:', newMessage);
      fetchChats();
    });

    return () => {
      socket.off('update last message');
      socket.disconnect();
    };
  }, [userid, fetchAgain]);

  const fetchChats = async () => {
    try {
      const response = await axios.get(`${ENDPOINT}/api/chat/${userid}`);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching chat list: ', error);
    }
  };

  const handleChatClick = (item: UserType) => {
    const user = item.users[0];
    setSelectedChat({
      chatId: item.chatId,
      userId: user.user_id,
      username: user.name,
      userpic: user.user_pic,
    });
  };

  return (
    <div style={styles.flexContainer}>
      <div style={styles.container}>
        <h1 style={styles.title}>All Chats</h1>
        {users.length > 0 ? (
          users.map((item) => {
            const user = item.users[0];
            return (
              <div
                key={item.chatId}
                style={styles.boxContainer}
                onClick={() => handleChatClick(item)}
              >
                <img
                  src={user.user_pic || 'https://example.com/default-image.png'}
                  alt={user.name}
                  style={styles.image}
                />
                <div style={styles.messageContainer}>
                  <span style={styles.username}>{user.name}</span>
                  <br></br>
                  <span style={styles.message}>
                    {item.latestMessage?.content || 'No message yet'}
                  </span>
                  <br></br>
                  <span style={styles.time}>
                    {item.latestMessage?.createdAt
                      ? new Date(
                          item.latestMessage.createdAt
                        ).toLocaleTimeString()
                      : ''}
                  </span>
                </div>
              </div>
            );
          })
        ) : (
          <div style={styles.noChats}>No chats available</div>
        )}
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    height: '100%',
    backgroundColor: 'white',
    padding: '20px',
    marginTop: '30px',
  },
  flexContainer: {
    flex: 1,
    paddingLeft: '2%', // use paddingLeft and paddingRight instead
    paddingRight: '2%',
    backgroundColor: 'white',
  },
  title: {
    color: 'black',
    fontFamily: 'Arial, sans-serif',
    fontSize: '24px',
    marginTop: '5px', // use marginTop and marginBottom instead
    marginBottom: '5px',
    marginLeft: '1px', // use marginLeft and marginRight instead
    marginRight: '1px',
  },
  boxContainer: {
    backgroundColor: '#F5F4F8',
    height: '100px',
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    gap: '10px',
    cursor: 'pointer',
    marginTop: '20px',
  },
  image: {
    height: '50px',
    width: '50px',
    borderRadius: '50%',
    borderWidth: '1px',
    borderColor: 'white',
  },
  messageContainer: {
    flex: 1,
  },
  username: {
    fontSize: '14px',
    fontWeight: '600',
    marginBottom: '5px',
    color: '#000',
  },
  message: {
    fontSize: '12px',
    color: 'gray',
    marginBottom: '5px',
  },
  time: {
    fontSize: '10px',
    color: 'gray',
  },
  noChats: {
    textAlign: 'center',
    fontSize: '16px',
    color: 'gray',
    marginTop: '20px',
  },
};

export default Chatlist;
