import React, { useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { categorydata } from "../data";
import add from "../images/add.png";
import location1 from "../images/location_white.png";
import { Avatar } from "@mui/material";
import { axiosInstance } from "../api/APIs";
import { districts } from "../data";
import drop from "../images/arrow_drop_down.svg";
import { Modal, Box, Typography, Button } from '@mui/material'
import cancel from "../images/cancelBlack.png";
import phone from '../images/servicesphone.svg'
import whatsapp from '../images/whatsapp.png'
import { truncateString } from "../ReuseableCompounts/TruncateString";
import back from '../images/Back.svg'
import { Link } from 'react-router-dom';
import search from '../images/searchser.svg'



const ServicesDetails = () => {


    const [searchCity, setSearchCity] = useState<string>("");
    const [Location, setLocation] = useState<string>("District");
    const [cityVisable, setCityVisable] = useState<boolean>(false);
    const location = useLocation();
    const Data = location.state?.data
    const category = location.state?.category
    const [bgShow, setBgShow] = useState<any>(category)
    const navigate = useNavigate()
    console.log(category);
    const [data, setData] = useState<any>(Data)
    const [open, setOpen] = useState(false);
    const [model, setModel] = useState<any>("")


    console.log(data);

    function handlePost() {
        navigate('/postservice')
    }

    async function handleServices(label: any) {
        setOpen(false);
        setBgShow(label)

        try {

            const response = await axiosInstance.get(`/services/categoryBased/userlist/${label}`)
            console.log(response.data.message);
            setData(response.data.message)


        } catch (error) {
            console.log("error", error)
        }
    }

    function handleLocation(value: any) {
        setCityVisable(!cityVisable)
        setLocation(value)
        setCityVisable(false)
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchCity(event.target.value);
        setCityVisable(false)
    };

    function handleViewDetails(value: any) {
        console.log(value)
        setModel(value)
        setOpen(true)
    }
    const handleClose = () => {


        setOpen(false);
    }
    async function handleAdd() {
        const body = {
            district: Location,
            locality: searchCity,
            category: bgShow
        }
        try {
            const response = await axiosInstance.post('/services/districtBased/service/filter', body)
            console.log(response.data)
            setData(response.data.message)
        } catch (e) {
            console.log("error", e);

        }
    }



    return (
        <div className='bg-[#F5F4F8] w-full min-h-screen flex flex-col mt-1    relative'>
            <div className='sm:bg-white msm:bg-[#F5F4F8] msm:mt- sm:mx-[2%]  msm:mx-0  h-[100vh]'>
                <div className='custom-xl:mx-[18%]'>
                    <div className='flex justify-between items-center z-40 bg-white'>
                        <div className='flex flex-col gap-2 ml-'>
                            <p className='text-xl font-bold mt- msm:hidden sm:hidden md:flex'>Service Providers</p>
                            <p className='msm:hidden sm:hidden md:flex'>Select a service to see available providers</p>
                            <Link to={'/services'}>
                                <img className='msm:mb-5  ml-2 md:hidden msm:flex sm:hidden' src={back} />
                            </Link>
                        </div>
                        <div onClick={handlePost} className='flex absolute right-0 msm:mb-5  items-center bg-[#FBB337] md:p-3 msm:p-2 md:mt-10 sm:mt- rounded-l-full md:gap-3 msm:gap-1 msm:text-base sm:text-base md:text-lg font-semibold'>
                            <img src={add} />
                            <p className='text-white'>Add Your Service</p>
                        </div>
                    </div>
                    <div className="w-full flex overflow-x-scroll  xl:overflow-hidden gap-8 hide-scrollbar xl:mx-11 lg:mx-3 md:mx-3 sm:mx-3">
                        {categorydata.map((item) => (
                            <div key={item.id} className="flex-shrink-0">
                                <p
                                    onClick={() => handleServices(item.value)}
                                    className={`py-2 font-semibold md:mt-10 msm:mt-5 sm:mt-8 px-6 rounded-3xl whitespace-nowrap ${bgShow === item.value ? "bg-[#2E368F] text-white" : "msm:bg-[#D9D9D9] md:bg-[#F5F4F8] text-black"
                                        }`}
                                >
                                    {item.value}
                                </p>
                            </div>
                        ))}
                    </div>

                    <div className='border border-[#000000] opacity-50 mt-4 mx-2'></div>
                    <div className='p-2 relative'>

                        <div className={` flex  items-center  rounded-xl w-[100%] relative`}>

                            <div className="flex   items-start rounded-l-2xl bg-[#E4E0EE] justify-start  gap-2 py-3 px-2">
                                <p onClick={() => setCityVisable(!cityVisable)} className="text-black ">{Location}</p>
                                <img src={drop} onClick={() => setCityVisable(!cityVisable)} className="text-black" />
                            </div>
                            <input onChange={handleSearch} onKeyDown={(e) => {
                                if (e.key = "Enter") {
                                    handleAdd()
                                }
                            }} placeholder="Search Your location" value={searchCity} className="bg-[#F5F4F8] rounded-r-2xl    px-1 w-[85%] py-3 border-none outline-none text-black" />
                            <img onClick={handleAdd} className='absolute right-3 w-6 h-6 z-20' src={search}/>
                        </div>
 


                        <div className={`${cityVisable === true ? "flex flex-col gap-2" : "hidden"} absolute h-60 left-3 top-14 z-40  overflow-y-scroll bg-white`}>
                            {districts.map((item) => (
                                <div onClick={() => { handleLocation(item) }} className="text-black flex items-start cursor-pointer justify-start">
                                    {item}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='w-[100%] relative'>
                        <p className='text-xl font-bold msm:ml-1 '>Available {bgShow}</p>
                        <div className={`flex `}>
                            <div className=' lg:w-[50%] md:w-[90%]  msm:w-[100%] sm:w-[100%] md:mx-[15%] msm:mx-1  sm:mx-[5%] lg:mx-0 lg:ml-3  gap-4 flex flex-col   mt-4'>
                                {Array.isArray(data) && data.length > 0 ? (
                                    data.map((item: any, index: any) => {
                                        const value = truncateString(item.locality, 10)
                                        return (

                                            <div className='flex p-2  items-center justify-between  bg-[#F5F4F8] rounded-2xl' key={index}>
                                                <div className='w-[10%] '>
                                                    {item.business_profile && item.business_profile.length > 0 && item.business_profile ? (
                                                        <Avatar
                                                            alt={item.user_name}
                                                            src={item.business_profile}
                                                            sx={{ width: 56, height: 56 }}
                                                        />
                                                    ) : (
                                                        <div className=''>
                                                            <Avatar
                                                                sx={{ width: 56, height: 56, bgcolor: '#2E368F', color: '#FFFFFF' }}
                                                            >
                                                                {item.user_name ? item.user_name.charAt(0) : ''}
                                                            </Avatar>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className='xl:w-[40%] lg:w-[40%] md:w-[50%] msm:w-[45%] msm:ml-3 sm:ml-0 sm:w-[50%] flex flex-col items-start justify-start'>
                                                    <p className='xl:text-xl lg:text-xl md:text-xl msm:text-base sm:text-base font-semibold'>{item.user_name}</p>
                                                    <div className='flex md:flex-wrap msm:flex-wrap xl:flex-nowrap lg:flex-nowrap  items-center gap-1'>
                                                        <img src={location1} />
                                                        {/* <p className='text-[#8E8E8E]'>{item.locality}</p> */}
                                                        <p className='text-[#8E8E8E] msm:text-xs sm:text-base'>{value}</p>
                                                        <p className='text-[#8E8E8E] msm:text-xs sm:text-base'>,{item.district}</p>


                                                    </div>
                                                    <p className='msm:text-sm sm:text-base'>{item.service_category}</p>
                                                </div>
                                                <div className='bg-[#515AA8] rounded-lg'>
                                                    <button onClick={() => { handleViewDetails(item) }} className='  text-end text-white sm:p-4 xl:p-4 msm:p-2 lg:p-4 md:p-4 '>View Details</button>
                                                </div>
                                            </div>


                                        )
                                    })
                                ) : (
                                    <p className='text-center mt-4'>No {bgShow} available at the moment.</p>
                                )}

                            </div>
                            <div className={`border-1 md:hidden lg:flex  sm:hidden msm:hidden border-black  ${Array.isArray(data) && data.length > 0 ? "flex" : "hidden"}`}></div>
                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-title"
                                aria-describedby="modal-description"
                            >
                                <Box

                                >
                                    <div className="absolute lg:-translate-x-1/6 sm:left-10 lg:left-1/3 md:right-1/2 md:-translate-x-1/6 xl:translate-x-1/4 xl:left-1/2  sm:top-1/2 transform sm:-translate-y-1/3 
                                        msm:top-1/2 msm:-translate-y-1/2 msm:w-full xl:w-[25vw] sm:w-[80vw] md:w-[80vw] lg:w-[40vw] 
                                         bg-[#F5F4F8] rounded-lg shadow-2xl 
                                              p-4 max-h-[80vh] overflow-y-auto">
                                        <div className='bg-[#E4E7F4] flex p-3 gap-3 items-center'>
                                            <div>
                                                {model.business_profile ? (
                                                    <Avatar
                                                        alt={model.user_name}
                                                        src={model.business_profile}
                                                        sx={{ width: 56, height: 56 }}
                                                    />
                                                ) : (
                                                    <Avatar
                                                        sx={{ width: 56, height: 56, bgcolor: '#2E368F', color: '#FFFFFF' }}
                                                    >
                                                        {model.user_name ? model.user_name.charAt(0) : ''}
                                                    </Avatar>
                                                )}
                                            </div>
                                            <div className='flex flex-col items-start justify-start'>
                                                <p className='text-xl font-semibold'>{model.user_name}</p>
                                                <div className='flex items-center gap-1'>
                                                    <img src={location1} alt="Location" />
                                                    <p className='text-[#8E8E8E]'>{model.district}</p>
                                                </div>
                                                <p>{model.service_category}</p>
                                            </div>
                                            <div onClick={handleClose} className='absolute right-2 top-2 cursor-pointer'>
                                                <img src={cancel} alt="Cancel" />
                                            </div>
                                        </div>
                                        <div className='p-3'>
                                            <Typography variant="h6" component="div" gutterBottom>
                                                Job Description
                                            </Typography>
                                            <Typography>{model.job_description}</Typography>
                                        </div>
                                        <div className='p-3'>
                                            <Typography variant="h6" component="div" gutterBottom>
                                                Contact Number
                                            </Typography>
                                            <Typography>{model.mobile_no}</Typography>
                                        </div>
                                        <div className='flex gap-2 justify-between msm:p-2 md:p-3'>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<img src={phone} alt="Phone" />}
                                                sx={{ flex: 1, borderRadius: 2, py: 1 }}
                                            >
                                                Direct Call
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="success"
                                                startIcon={<img src={whatsapp} alt="WhatsApp" />}
                                                sx={{ flex: 1, borderRadius: 2, py: 1 }}
                                            >
                                                Chat
                                            </Button>
                                        </div>
                                    </div>
                                </Box>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicesDetails