import React from "react";
import { useLocation } from 'react-router-dom';

// Define the type for the image object
interface Image {
  id: number;
  filename: string;
  name: string;
  uri: string;
  isCover: boolean;
}

const UpComingProjects: React.FC = () => {
  const location = useLocation();
  const passedData = location.state as Image[]; // Typecast location.state to Image array

  console.log('Passed Data:', passedData);

  // Filter out non-cover images
  const nonCoverImages = passedData.filter((img: Image) => !img.isCover);

  return (
    <div className="md:py-4 w-full md:w-[90%] xl:w-[70%] mx-auto">
      <header className="px-2 py-3 text-base md:text-lg lg:text-xl flex items-center gap-2">
        <div className="font-bold">Upcoming Projects</div>
      </header>
      <div className=" w-[80%] h-[40%]">
        {nonCoverImages.map((image) => (
          <div key={image.id} className="border rounded shadow">
            <img
              src={image.uri}
              alt={image.name}
              className="w-full h-auto object-cover rounded"
            />
            <p className="text-center mt-2 text-sm">{image.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UpComingProjects;
