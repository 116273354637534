import React from 'react'
import less from "../images/Less.png";
import computers from "../images/Computers.png";
import mobile from '../images/phoneLog.png'
import laptop from '../images/laptop.png'
import amm from '../images/amm.png'


const LoginHistory = () => {
    return (
        <div className='min-h-screen w-full bg-[#F5F4F8]'>
            <div className='mx-[20%] py-8'>
                <div className='flex gap-2 items-center mt-[5%]'>
                    <img className='' src={less} alt="less icon" />
                    <p className='text-xl font-bold'>Login History</p>
                </div>
                <div className='flex mt-8'>
                    <div className='flex flex-col items-center mr-8'>
                        <img src={computers} alt="computers icon" />
                        <p className='font-semibold'>To log in on a new device, you must log out from one of the</p>
                        <p className='font-semibold'>devices you are currently logged in on.</p>
                    </div>
                    <div>
                        <p className='font-bold text-xl opacity-90'>Current Devices</p>
                        <div className='flex items-center justify-between gap-5 mt-4'>
                            <img className=' ' src={mobile} alt="mobile icon" />
                            <div className=' '>
                                <p className='font-semibold'>Android Phone/Tablet</p>
                                <p className='opacity-80'>Last Login: Today</p>
                            </div>
                            <button className='text-white py-2 px-3 rounded-xl  ml-14 bg-[#2E368F]'>Log Out</button>
                        </div>
                        <div className='flex items-center justify-between  mt-4'>
                            <img className='' src={laptop} alt="mobile icon" />
                            <div className=''>
                                <p className='font-semibold'>Android Phone/Tablet</p>
                                <p className='opacity-80'>Last Login: Today</p>
                            </div>
                            <button className='text-white py-2 px-3 rounded-xl  ml-14 bg-[#2E368F]'>Log Out</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='absolute bottom-10 w-[100%]'>
                <div className='border-1 border-black  mx-10 ml-14 '></div>
                <div className='flex justify-center items-center gap-2'>
                    <img src={amm} />
                    <p className='text-center'>You can be logged in on up to 4 devices at a time. To log in on a new device, please log out from one of the devices listed above.</p>
                </div>
            </div>
        </div>
    );
};

export default LoginHistory;
