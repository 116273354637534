// import React, { useState } from 'react'
// import { useStepperContext } from './StepperContext'
// // import {data} from '../data'
// import CustomisedButton from './CustomisedButton'
// import TextBox from './TextBox'
// import CustomButton from './CustomButton'
// import CustomBoxButton from './CustomBoxButton'
// import PriceTextField from './PriceTextField'
// import PriceTextField1 from './PriceTextField1'
// import PriceTextField2 from './PriceTextField2'
// import DropBox1 from './DropBox1'
// import FDropDown from './FDropDown'
// import DropDown1 from './DropDown1'
// import DatePick from './DatePick'
// import { Box, Button, Grid, Typography } from '@mui/material'
// import TextAreaBox from './TextAreaBox'
// import AccessButton from './AccessButton'
// import ModalDataForm from './ModalDataForm'
// import MultiSelectorButton from './MultiSelectorButton'
// import MultiSelectorAmenitiesButton from '../reusablecomponent/MultiSelectorAmenitiesButton'
// import MultiSelectorButton2 from '../reusablecomponent/MultiSelectorButton2'

// interface AdditionalDetailsProps {
//   onNext:()=>void
//   onBack:()=>void
//   onReset:()=>void
// }

// const AdditionalDetails = ({onNext,onBack,onReset}) => {
//   const{addUserData,userData,customFormDetails,addCustomFormDetails}=useStepperContext()
//    const [selectedValues, setSelectedValues] = useState<number[]>([]); 
//    const [userselectedData,setuserSelectedData]=useState([])

//    const generateValidationSchema=(customFormDetails,userData)=>{

//     const requiredLabels=customFormDetails.additional_details.filter((item)=>item.required).map(item=>item.label)
//     const existingLabels=requiredLabels.filter(item=>(
//       !userData.some(item=>item.hasOwnProperty(label))
//     ))
//     if(existingLabels.length>0){

//       setuserSelectedData(existingLabels)

//    }else{
//     onNext()
//    }
//   }

//    const getInitialValue = (label) => {
//     const entry = userData.find((obj) => obj.hasOwnProperty(label));
//     return entry ? entry[label] : '';
//   };
//   const handleSubmit=()=>{
//     generateValidationSchema(customFormDetails,userData)
//   }




//   return (
//     <div>
//       <Typography>Additional Details</Typography>

//       {customFormDetails.additional_details.map((dataItem,index)=>(
//         <div key={index}>
//           {/* {dataItem.formName==='Additional Details' && dataItem.fields.map((item,index)=>(
//             <div> */}
//                 {dataItem.Type==='Box Type' && (
//             <div className="my-6">
//               <Typography className="text-start">{dataItem.label}</Typography>
//               <div className='flex gap-7 my-5'>
//               {/* {dataItem.data && dataItem.data.map((buttonItem,buttonIndex)=>( */}
//                 <Box>
//                   <CustomisedButton data={dataItem.data}
//                   label={dataItem.label}
//                   inputId={dataItem.inputID}
//                   formName='Additional Details'
//                   required={dataItem.required}
//                   initial={getInitialValue(dataItem.label)}
                  
//                   />
//                 </Box>
//               {/* ))} */}
//               </div>
//             </div>
//           )}
//               {dataItem.Type==='Box Type2' && (
//             <div>
//               <Typography className='text-start'>{dataItem.label}</Typography>
//               <div className='flex gap-7 my-5'>
//               {/* {dataItem.data && dataItem.data.map((buttonItem,buttonIndex)=>( */}
//                 <Box>
//                   <CustomButton data={dataItem.data}
//                   label={dataItem.label}
//                   inputId={dataItem.inputID}
//                   formName='Additional Details'
//                   required={dataItem.required}
//                   initial={getInitialValue(dataItem.label)}/>
//                 </Box>
//               {/* ))} */}
//               </div>
//             </div>
//           )}  {dataItem.Type==='Box Type11' && (
//             <div>
//               <Typography className='text-start'>{dataItem.label}</Typography>
//               <div className='flex gap-7 my-5'>
//               {/* {dataItem.data && dataItem.data.map((buttonItem,buttonIndex)=>( */}
//                 <Box>
//                   <MultiSelectorButton2
//                   data={dataItem.data}
//                   label={dataItem.label}
//                   inputId={dataItem.inputID}
//                   formName='Additional Details'
//                   required={dataItem.required}
//                   initial={getInitialValue(dataItem.label)}/>
//                 </Box>
//               {/* ))} */}
//               </div>
//             </div>
//           )}
//              {dataItem.Type==='Custom Box Type' && (
//             <div>
//               <Typography className='text-start'>{dataItem.label}</Typography>
//               <div className='flex gap-7 my-5'>
//               {/* {dataItem.data && dataItem.data.map((buttonItem,buttonIndex)=>( */}
//                 <Box>
//                    <CustomBoxButton data={dataItem.data}
//                    label={dataItem.label}
//                    inputId={dataItem.inputID}
//                    formName='Additional Details'
//                    required={dataItem.required}
//                    initial={getInitialValue(dataItem.label)}
//                    />

//                 </Box>
//               {/* ))} */}
//               </div>
             
//             </div>
//           )} {dataItem.Type==='Box Type3' && (
//             <div>
//               <Typography className='text-start'>{dataItem.label}</Typography>
//               <div className='flex gap-5 my-7'>
//               {/* {dataItem.data && dataItem.data.map((buttonItem,buttonIndex)=>( */}
//                 <Box>
//                    <AccessButton data={dataItem.data}
//                    label={dataItem.label}
//                    inputId={dataItem.inputID}
//                    formName='Additional Details'
//                    required={dataItem.required}
//                    initial={getInitialValue(dataItem.label)}
//                    />

//                 </Box>
//               {/* ))} */}
//               </div>
             
//             </div>
//           )} 
 
//              {dataItem.Type==='Text Field' && (
//             <div className='my-6'>
//               <TextBox label={dataItem.label}
//               inputId={dataItem.inputID}
//               formName='Additional Details'
//                label2={dataItem.label2}
//               keyboard={dataItem.keyboard}
//               required={dataItem.required}
//               initial={getInitialValue(dataItem.label)}
//               />
             
//             </div>
//           )}
//                {dataItem.Type==='Price Text Field' && (
//                 <div className='my-6'>
//                   <Typography className='text-start'>{dataItem.label}</Typography>
//                   <PriceTextField label={dataItem.label}
//                   inputId={dataItem.inputID}
//                   formName='Additional Details'
//                   label2={dataItem.label2}
//                   keyboard={dataItem.keyboard}
//                   required={dataItem.required}
//                   initial={getInitialValue(dataItem.label)}
//                   />

//                 </div>

//           )}
//                {dataItem.Type==='Price Text Field1' && (
//                 <div>
//                   {/* <Typography>{dataItem.label}</Typography> */}
//                   <PriceTextField1 label={dataItem.label}
//                   inputId={dataItem.inputID}
//                   formName='Additional Details'
//                   label2={dataItem.label2}
//                   keyboard={dataItem.keyboard}
//                   required={dataItem.required}
//                   initial={getInitialValue(dataItem.label)}
//                   />

//                 </div>

//           )}                    



//                {dataItem.Type==='Price Text Field2' && (
//                 <div>
//                   {/* <label>{item.label}</label> */}
//                   <PriceTextField2 label={dataItem.label}
//                   inputId={dataItem.inputID}
//                   formName='Additional Details'
//                   label2={dataItem.label2}
//                   keyboard={dataItem.keyboard}
//                   required={dataItem.required}
//                   initial={getInitialValue(dataItem.label)}
//                   />

//                 </div>

//           )}
//              {dataItem.Type==='Drop down' && (
//                 <div>
//                   <DropBox1 label={dataItem.label}
//                    options={dataItem.data}
//                    inputId={dataItem.inputID}
//                    formName='Additional Details'
//                    required={dataItem.required}
//                    initial={getInitialValue(dataItem.label)}

//                   />
//                 </div>
//               )}
//                 {dataItem.Type==='FDrop down' && (
//                 <div>
//                   <FDropDown label={dataItem.label}
//                    options={dataItem.data}
//                    inputId={dataItem.inputID}
//                    formName='Additional Details'
//                    required={dataItem.required}
//                    initial={getInitialValue(dataItem.label)}

//                   />
//                 </div>
//               )}{dataItem.Type==='Drop down1' && (
//                 <div>
//                   <DropDown1 label={dataItem.label}
//                    options={dataItem.data}
//                    inputId={dataItem.inputID}
//                    formName='Additional Details'
//                    required={dataItem.required}
//                    initial={getInitialValue(dataItem.label)}

//                   />
//                 </div>
//               )}
//               {dataItem.Type==='calender' && (
//                 <div>
//                   <DatePick label={dataItem.label}
//                   inputId={dataItem.inputID}
//                   formName='Additional Details'
//                   required={dataItem.required}
//                   initial={getInitialValue(dataItem.label)}
                  
                  
//                   />
//                 </div>

//               )}
//                {dataItem.Type==='Text Area' && (
//                <div>
//                 <Typography className='text-start'>{dataItem.label}</Typography>
//                    <TextAreaBox  label={dataItem.label}
//                    inputId={dataItem.inputID}
//                    formName="Additional Details"
//                    required={dataItem.required}
//                    initial={getInitialValue(dataItem.label)}/>



//                </div>
                

//               )}
//               {
//                 dataItem.Type==='AMENITIES' && ( 
//                 <div>
//                   <Typography className='text-start'>{dataItem.label}</Typography>
//                   <div>
//                     {/* {dataItem.data && dataItem.data.map((buttonItem,buttonIndex)=>( */}
                      
                   
//                   <MultiSelectorButton2
                 
//                  data={dataItem.data}
//                   label={dataItem.label}
//                   inputId={dataItem.inputID}
                 
//                   // selectedValues={selectedValues}
//                   // setSelectedValues={setSelectedValues}
//                   formName='Additional Details'
//                   required={dataItem.required}
//                   initial={getInitialValue(dataItem.label)}
//  />
  
      
//  </div>
//            </div>

//                 )
//               }
           
//             </div>
//       ))}

//       <Button onClick={handleSubmit} type='submit'>Continue</Button>


        
//     </div>
//   )
// }

// export default AdditionalDetails

import React, { useState } from 'react';
import { useStepperContext } from './StepperContext';
import CustomisedButton from './CustomisedButton';
import TextBox from './TextBox';
import CustomButton from './CustomButton';
import CustomBoxButton from './CustomBoxButton';
import PriceTextField from './PriceTextField';
import PriceTextField1 from './PriceTextField1';
import PriceTextField2 from './PriceTextField2';
import DropBox1 from './DropBox1';
import FDropDown from './FDropDown';
import DropDown1 from './DropDown1';
import DatePick from './DatePick';
import { Box, Button, Typography } from '@mui/material';
import TextAreaBox from './TextAreaBox';
import AccessButton from './AccessButton';
import MultiSelectorButton2 from '../reusablecomponent/MultiSelectorButton2';


interface AdditionalDetailsProps {
  onNext: () => void;
  onBack: () => void;
  onReset: () => void;
}

interface AdditionalDetail {
  Type: string;
  label: string;
  label2?: string;
  data: any;
  inputID: number;
  required: boolean;
  keyboard?: string;
}



interface UserData {
  [key: string]: any;
}

const AdditionalDetails: React.FC<AdditionalDetailsProps> = ({ onNext, onBack, onReset }) => {
  const { addUserData, userData, customFormDetails, addCustomFormDetails } = useStepperContext();
  const [selectedValues, setSelectedValues] = useState<number[]>([]);
  const [userselectedData, setUserSelectedData] = useState<string[]>([]);

  const generateValidationSchema = (customFormDetails: any, userData: UserData[]) => {
    const requiredLabels = customFormDetails.additional_details
      .filter((item: any) => item.required)
      .map((item: any) => item.label);

    const existingLabels = requiredLabels.filter(
      (label: string) => !userData.some((item: UserData) => item.hasOwnProperty(label))
    );

    if (existingLabels.length > 0) {
      setUserSelectedData(existingLabels);
    } else {
      onNext();
    }
  };

  const getInitialValue = (label: string) => {
    const entry = userData.find((obj: UserData) => obj.hasOwnProperty(label));
    return entry ? entry[label] : '';
  };

  const handleSubmit = () => {
    generateValidationSchema(customFormDetails, userData);
  };

  return (
    <div>
      <Typography>Additional Details</Typography>

      {(customFormDetails as any)?.additional_details.map((dataItem: AdditionalDetail, index: number) => (
        <div key={index}>
          {dataItem.Type === 'Box Type' && (
            <div className="my-6">
              <Typography className="text-start">{dataItem.label}</Typography>
              <div className="flex gap-7 my-5">
                <Box>
                  <CustomisedButton
                    data={dataItem.data}
                    label={dataItem.label}
                    inputId={dataItem.inputID}
                    formName="Additional Details"
                    required={dataItem.required}
                    initial={getInitialValue(dataItem.label)}
                  />
                </Box>
              </div>
            </div>
          )}
          {dataItem.Type === 'Box Type2' && (
            <div>
              <Typography className="text-start">{dataItem.label}</Typography>
              <div className="flex gap-7 my-5">
                <Box>
                  <CustomButton
                    data={dataItem.data}
                    label={dataItem.label}
                    inputId={dataItem.inputID}
                    formName="Additional Details"
                    required={dataItem.required}
                    initial={getInitialValue(dataItem.label)}
                  />
                </Box>
              </div>
            </div>
          )}
          {dataItem.Type === 'Box Type11' && (
            <div>
              <Typography className="text-start">{dataItem.label}</Typography>
              <div className="flex gap-7 my-5">
                <Box>
                  <MultiSelectorButton2
                    data={dataItem.data}
                    label={dataItem.label}
                    inputId={dataItem.inputID}
                    formName="Additional Details"
                    required={dataItem.required}
                    initial={getInitialValue(dataItem.label)}
                  />
                </Box>
              </div>
            </div>
          )}
          {dataItem.Type === 'Custom Box Type' && (
            <div>
              <Typography className="text-start">{dataItem.label}</Typography>
              <div className="flex gap-7 my-5">
                <Box>
                  <CustomBoxButton
                    data={dataItem.data}
                    label={dataItem.label}
                    inputId={dataItem.inputID}
                    formName="Additional Details"
                    required={dataItem.required}
                    initial={getInitialValue(dataItem.label)}
                  />
                </Box>
              </div>
            </div>
          )}
          {dataItem.Type === 'Box Type3' && (
            <div>
              <Typography className="text-start">{dataItem.label}</Typography>
              <div className="flex gap-5 my-7">
                <Box>
                  <AccessButton
                    data={dataItem.data}
                    label={dataItem.label}
                    inputId={dataItem.inputID}
                    formName="Additional Details"
                    required={dataItem.required}
                    initial={getInitialValue(dataItem.label)}
                  />
                </Box>
              </div>
            </div>
          )}
          {dataItem.Type === 'Text Field' && (
            <div className="my-6">
              <TextBox
                label={dataItem.label}
                inputId={dataItem.inputID}
                formName="Additional Details"
                label2={dataItem.label2}
                keyboard={dataItem.keyboard}
                required={dataItem.required}
                initial={getInitialValue(dataItem.label)}
              />
            </div>
          )}
          {dataItem.Type === 'Price Text Field' && (
            <div className="my-6">
              <Typography className="text-start">{dataItem.label}</Typography>
              <PriceTextField
                label={dataItem.label}
                inputId={dataItem.inputID}
                formName="Additional Details"
                label2={dataItem.label2}
                keyboard={dataItem.keyboard}
                required={dataItem.required}
                initial={getInitialValue(dataItem.label)}
              />
            </div>
          )}
          {dataItem.Type === 'Price Text Field1' && (
            <div>
              <PriceTextField1
                label={dataItem.label}
                inputId={dataItem.inputID}
                formName="Additional Details"
                label2={dataItem.label2}
                keyboard={dataItem.keyboard}
                required={dataItem.required}
                initial={getInitialValue(dataItem.label)}
              />
            </div>
          )}
          {dataItem.Type === 'Price Text Field2' && (
            <div>
              <PriceTextField2
                label={dataItem.label}
                inputId={dataItem.inputID}
                formName="Additional Details"
                label2={dataItem.label2}
                keyboard={dataItem.keyboard}
                required={dataItem.required}
                initial={getInitialValue(dataItem.label)}
              />
            </div>
          )}
          {dataItem.Type === 'Drop down' && (
            <div>
              <DropBox1
                label={dataItem.label}
                options={dataItem.data}
                inputId={dataItem.inputID}
                formName="Additional Details"
                required={dataItem.required}
                initial={getInitialValue(dataItem.label)}
              />
            </div>
          )}
          {dataItem.Type === 'FDrop down' && (
            <div>
              <FDropDown
                label={dataItem.label}
                options={dataItem.data}
                inputId={dataItem.inputID}
                formName="Additional Details"
                required={dataItem.required}
                initial={getInitialValue(dataItem.label)}
              />
            </div>
          )}
          {dataItem.Type === 'Drop down1' && (
            <div>
              <DropDown1
                label={dataItem.label}
                options={dataItem.data}
                inputId={dataItem.inputID}
                formName="Additional Details"
                required={dataItem.required}
                initial={getInitialValue(dataItem.label)}
              />
            </div>
          )}
          {dataItem.Type === 'calender' && (
            <div>
              <DatePick
                label={dataItem.label}
                inputId={dataItem.inputID}
                formName="Additional Details"
                required={dataItem.required}
                initial={getInitialValue(dataItem.label)}
              />
            </div>
          )}
          {dataItem.Type === 'Text Area' && (
            <div>
              <Typography className="text-start">{dataItem.label}</Typography>
              <TextAreaBox
                label={dataItem.label}
                inputId={dataItem.inputID}
                formName="Additional Details"
                required={dataItem.required}
                initial={getInitialValue(dataItem.label)}
              />
            </div>
          )}
          {dataItem.Type === 'AMENITIES' && (
            <div>
              <Typography className="text-start">{dataItem.label}</Typography>
              <div>
                <MultiSelectorButton2
                  data={dataItem.data}
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName="Additional Details"
                  required={dataItem.required}
                  initial={getInitialValue(dataItem.label)}
                />
              </div>
            </div>
          )}
        </div>
      ))}

      <div className="flex gap-3 justify-end">
        <Button onClick={onBack}>Back</Button>
        <Button onClick={onReset}>Reset</Button>
        <Button onClick={handleSubmit}>Next</Button>
      </div>
    </div>
  );
};

export default AdditionalDetails;
