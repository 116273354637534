// import React, { useContext, useState } from 'react'

// import Basic from './Basic'
// import PropertyDetails from './PropertyDetails'
// import Price from './Price'
// import AdditionalDetails from './AdditionalDetails'
// import { Button, Grid, Step, StepContent, StepLabel, Stepper } from '@mui/material'
// import { useStepperContext } from './StepperContext'
// import UploadPhoto from './UploadPhoto'
// import Review from './Review'
// import { useTheme } from '@mui/material/styles';

// interface StepperFormProps{
//     label?:string;
//     description?:React.ReactNode;
//     required?:boolean;
    
// }

// const StepperForm: React.FC<StepperFormProps> = () => {

//   const {addUserData,userData}=useStepperContext()
//    const theme = useTheme();

//   const property=userData.find((item)=>item?.["Property Subtype"])?.["Property Subtype"]
 
  

    
//     const [activeStep, setActiveStep] = useState(0);
//     const handleNext = () => {
//      setActiveStep((prevActiveStep) => prevActiveStep + 1);
//    };
//     const handleBack = () => {
//      setActiveStep((prevActiveStep) => prevActiveStep - 1);
//    };


//     const handleReset = () => {
//     setActiveStep(0);
//   };

   

    

//     const step1:StepperFormProps[]=[
//         {
//             label:"Basic Details",
//             description: <Basic onNext={handleNext} onBack={handleBack} onReset={handleReset} />,
//             required:true,
//         },
//         {
//             label:"Property Details",
//             description:<PropertyDetails onNext={handleNext} onBack={handleBack} onReset={handleReset} />
//         },
//         {
//         label:"Price",
//         description:<Price onNext={handleNext} onBack={handleBack} onReset={handleReset}/>
//         }

       
//     ]

//   if (property !== 'Vacant Land') {
//     step1.push(
//       {
//         label: "Additional Details",
//         description: <AdditionalDetails onNext={handleNext} onBack={handleBack} onReset={handleReset}/>,
//       },
//       {
//         label: 'Upload Photos',
//         description: <UploadPhoto onNext={handleNext} onBack={handleBack} onReset={handleReset} />,
//       },
//       {
//         label: 'Review',
//         description: <Review onNext={handleNext} onBack={handleBack} onReset={handleReset} />,
//       }
//     );
//   } else {
//     step1.push(
     
//       {
//         label: 'Upload Photos',
//         description: <UploadPhoto onNext={handleNext} onBack={handleBack} onReset={handleReset}/>,
//       },
//       {
//         label: 'Review',
//         description: <Review onNext={handleNext} onBack={handleBack} onReset={handleReset}/>,
//       }
//     );
//   }




//   return (

   
//     <div style={{ display: "flex", alignItems: "flex-start" }} className='bg-customBlue h-dvh'>
//       <div className='flex justify-center  h-dvh gap-9 w-[100%] mt-8 p-6 fixed'>

      

//           <div className='md:w-[10%] bg-customDarkBlue  h-dvh rounded-t-3xl '>
      
//       <Stepper
//         activeStep={activeStep}
//         // alternativeLabel
//         // variant='progress'
//         orientation="vertical"
//         style={{ borderRight: "1px solid #ccc" }}
//       >
       
//         { step1.map((step, index) => (
//           <Step key={index}>
//             <StepLabel>{step.label}</StepLabel>
//           </Step>
//         ))}
//       </Stepper>
//       </div>
//       <div className='md:w-[40%] bg-customWhite   h-dvh rounded-t-3xl p-6 overflow-scroll '>
      
//       <div>{ step1[activeStep].description}</div>
//       </div>

//       {/* </div> */}
//       <div className='md:w-[20%] bg-customDarkBlue  h-dvh rounded-t-3xl p-6  '>


//       </div>
      
                  
//     </div>
//      <Button
//             variant="contained"
//             onClick={handleBack} // Call handleBack function on click
//             sx={{ mt: 1, mr: 1 }}
//             disabled={activeStep === 0} // Disable back button if on the first step
//           >
//             Back
//           </Button>
//       <Button
//                     variant="contained"
//                     onClick={handleNext}
//                     sx={{ mt: 1, mr: 1 }}
//                   >
//                     {activeStep === step1.length - 1 ? 'Finish' : 'Continue'}
//                   </Button>
//                   <Button onClick={handleReset}>
//                     Reset
//                   </Button>


//       </div>

      
     
       
    

   
//   );
// }

// export default StepperForm
import React, { useContext, useState } from 'react';
import Basic from './Basic';
import PropertyDetails from './PropertyDetails';
import Price from './Price';
import AdditionalDetails from './AdditionalDetails';
import UploadPhoto from './UploadPhoto';
import Review from './Review';
import { Button, Step, StepLabel, Stepper } from '@mui/material';
import { useStepperContext } from './StepperContext';
import { useTheme } from '@mui/material/styles';

interface StepperFormProps {
  label?: string;
  description?: React.ReactNode;
  required?: boolean;
}

const StepperForm: React.FC<StepperFormProps> = () => {
  const { addUserData, userData } = useStepperContext();
  const theme = useTheme();
  
  const property = userData.find((item) => item?.["Property Subtype"])?.["Property Subtype"];
  
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const steps: StepperFormProps[] = [
    {
      label: "Basic Details",
      description: <Basic onNext={handleNext} onBack={handleBack} onReset={handleReset} />,
      required: true,
    },
    {
      label: "Property Details",
      description: <PropertyDetails onNext={handleNext} onBack={handleBack} onReset={handleReset} />,
    },
    {
      label: "Price",
      description: <Price onNext={handleNext} onBack={handleBack} onReset={handleReset} />,
    },
    {
      label: "Additional Details",
      description: <AdditionalDetails onNext={handleNext} onBack={handleBack} onReset={handleReset} />,
    },
    {
      label: 'Upload Photos',
      description: <UploadPhoto onNext={handleNext} onBack={handleBack} onReset={handleReset} />,
    },
    {
      label: 'Review',
      description: <Review onNext={handleNext} onBack={handleBack} onReset={handleReset} />,
    }
  ];

  const filteredSteps = property === 'Vacant Land'
    ? steps.filter(step => step.label !== 'Additional Details')
    : steps;

  return (
    <div style={{ display: "flex", alignItems: "flex-start" }} className='bg-customBlue h-dvh'>
      <div className='flex justify-center h-dvh gap-9 w-[100%] mt-8 p-6 fixed'>
        <div className='md:w-[10%] bg-customDarkBlue h-dvh rounded-t-3xl'>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            style={{ borderRight: "1px solid #ccc" }}
          >
            {filteredSteps.map((step, index) => (
              <Step key={index}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className='md:w-[40%] bg-customWhite h-dvh rounded-t-3xl p-6 overflow-scroll'>
          <div>{filteredSteps[activeStep]?.description}</div>
        </div>
        <div className='md:w-[20%] bg-customDarkBlue h-dvh rounded-t-3xl p-6'>
          <Button
            variant="contained"
            onClick={handleBack}
            sx={{ mt: 1, mr: 1 }}
            disabled={activeStep === 0}
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={handleNext}
            sx={{ mt: 1, mr: 1 }}
          >
            {activeStep === filteredSteps.length - 1 ? 'Finish' : 'Continue'}
          </Button>
          <Button onClick={handleReset}>
            Reset
          </Button>
        </div>
      </div>
    </div>
  );
}

export default StepperForm;












