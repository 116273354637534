import React, { useState } from 'react';
import { useStepperContext } from './StepperContext';
import { Accept, useDropzone } from 'react-dropzone';
import { Button, LinearProgress, Typography } from '@mui/material';
import AWS from 'aws-sdk';
import siva from './Picture.jpg';
import { makeStyles } from '@mui/styles';
import RemoveComponent from './RemoveComponent';
import BackComponent from './BackComponent';
import { datafinder } from '../../PostForm/component';

interface UploadedImage {
  id: number;
  name: string;
  file: File;
  url: string;
  isCover: boolean;
  isVisible: boolean;
  selectedTag: string | null;
  progress: number;
  isUploaded: boolean;
  uri: any
}

interface UploadPhotoProps {
  // onNext: () => void;
  // onBack: () => void;
  // onReset: () => void;
  setFullData: any
  passedData: any
}

const useStyles = makeStyles({
  imagePreviewContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)', // Adjust the number of columns
    gap: '20px',
    marginTop: '20px',
  },
  imagePreview: {
    position: 'relative',
    border: '2px solid #cccccc',
    borderRadius: '10px',
    padding: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s, box-shadow 0.3s',
    height: '200px', // Fixed height for each image preview
    overflow: 'hidden',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
    },
  },
  imageWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: '10px',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover', // Ensure image covers the container
    cursor: 'pointer',
  },
  setCoverButton: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    fontSize: '12px',
    padding: '5px 10px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  removeButton: {
    position: 'absolute',
    top: '10px', // Adjusted for better positioning
    right: '10px', // Adjusted for better positioning
  },
  tagSelect: {
    width: '100%',
    marginTop: '10px',
    padding: '5px',
    borderRadius: '5px',
  },
  progressBar: {
    marginTop: '10px',
  },
  progressText: {
    textAlign: 'right',
    marginTop: '5px',
  },
});

const UploadPhoto: React.FC<UploadPhotoProps> = ({ setFullData, passedData

}) => {
  const classes = useStyles();
  const { propImages, addPropImage, userData } = useStepperContext();
  const [selectedFiles, setSelectedFiles] = useState<UploadedImage[]>(passedData[0].images);
  const propertyTypeItem = passedData.find((item: any) => item.property_type);
  const proptype = propertyTypeItem ? propertyTypeItem.property_type : undefined;
  const [mypass, setMypass] = useState(passedData)
  const imageTags = ['hall', 'kitchen', 'room', 'balcony'];

  const s3 = new AWS.S3({
    region: 'us-east-1',
    accessKeyId: 'AKIAQ3EGVP6CLIGM245V',
    secretAccessKey: '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',
  });

  const generateUniqueNumber = () => Math.floor(Math.random() * 100000);

  const handleUploadChange = async (files: File[]) => {
    if (files.length === 0) return;

    const maxFiles = 6;
    const newFiles: File[] = files.slice(0, maxFiles - selectedFiles.length);

    if (selectedFiles.length + newFiles.length > maxFiles) {
      alert(`You can upload a maximum of ${maxFiles} files.`);
      return;
    }

    const newSelectedFiles = await Promise.all(
      newFiles.map(async (file) => {
        const s3Url = await uploadImageToS3(file);

        return {
          id: generateUniqueNumber(),
          name: file.name,
          file,
          url: s3Url,
          isCover: false,
          isVisible: true,
          selectedTag: null,
          progress: 100,
          isUploaded: true,
        };
      })
    );

    setSelectedFiles((prevFiles: any) => {
      const updatedFiles = [...prevFiles, ...newSelectedFiles];
      addPropImage(updatedFiles);
      return updatedFiles;
    });
  };

  const uploadImageToS3 = async (file: File): Promise<string | null> => {
    const fileName = `image_${Date.now()}.jpg`;
    const params = {
      Bucket: 'vattara-image',
      Key: fileName,
      Body: file,
      ContentType: file.type,
    };

    try {
      const uploadPromise = s3
        .upload(params)
        .on('httpUploadProgress', (event) => {
          const percent = Math.round((event.loaded / event.total) * 100);
          setSelectedFiles((prevFiles) =>
            prevFiles.map((f) =>
              f.file === file ? { ...f, progress: percent } : f
            )
          );
        })
        .promise();

      const uploadResponse = await uploadPromise;
      return uploadResponse.Location;
    } catch (error) {
      console.error(`Error uploading ${file.name} to S3: ${error}`);
      return null;
    }
  };

  const handleSetCover = (id: number) => {
    const updatedFiles = selectedFiles.map((file) => ({
      ...file,
      isCover: file.id === id,
    }));
    setSelectedFiles(updatedFiles);
    addPropImage(updatedFiles);
  };

  const handleTagSelect = (id: number, tag: string) => {
    const updatedFiles = selectedFiles.map((file) => ({
      ...file,
      selectedTag: file.id === id ? tag : file.selectedTag,
    }));
    setSelectedFiles(updatedFiles);
    addPropImage(updatedFiles);
  };

  const handleRemove = (id: number) => {
    const updatedFiles = selectedFiles.filter((file) => file.id !== id);
    setSelectedFiles(updatedFiles);
    addPropImage(updatedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => handleUploadChange(acceptedFiles),
    accept: {
      'image/*': [],
    } as Accept,
    maxFiles: 6,
  });

  const handleReset = () => {
    setSelectedFiles([]);
    addPropImage([]);
    // onReset();
  };
  function handleSubmit() {
    console.log("image check", selectedFiles)
    const update = mypass.map((item:any)=>{
      return{
        ...item,
        images:selectedFiles
      }
    })
    console.log("for paassed", update)
  }
  return (
    <div className="min-h-[1000px] p-2 overflow-hidden">
      <Button
        // onClick={onBack}
        style={{
          position: 'relative',
          right: '20px',
        }}
      >
        <BackComponent />
      </Button>
      <Typography
        style={{
          fontWeight: '700',
          marginLeft: '40%',
          position: 'relative',
          bottom: '30px',
        }}
      >
        Upload Photos
      </Typography>
      <div
        {...getRootProps()}
        style={{
          border: '2px dashed #cccccc',
          padding: '50px',
          textAlign: 'center',
          marginTop: '10px',
        }}
      >
        <input {...getInputProps()} />
        {/* <img
          src={siva}
          alt="description"
          style={{ width: '100px', marginBottom: '10px', marginLeft: '40%' }}
        /> */}
        <p style={{ marginRight: '10%' }}>
          Drop your image here, or <span>browse</span>{' '}
        </p>
        <h4 style={{ marginRight: '10%' }}>Supports: PNG, JPG, JPEG</h4>
      </div>

      <div className={classes.imagePreviewContainer}>
        {selectedFiles.map((file) => (
          <div
            key={file.id}
            className={`${classes.imagePreview} ${file.isCover ? 'cover' : ''}`}
          >
            <div className={classes.imageWrapper}>
              <img
                src={file.url || file.uri}
                alt={file.name}
                className={classes.image}
                onClick={() => { }}
              />
              <Button
                className={classes.setCoverButton}
                onClick={() => handleSetCover(file.id)}
              >
                {file.isCover ? 'Cover Image' : 'Set as Cover'}
              </Button>
              <RemoveComponent
                className={classes.removeButton}
                onClick={() => handleRemove(file.id)}
              />
            </div>
            {proptype !== 'Vacant Land' && (
              <select
                className={classes.tagSelect}
                onChange={(e) => handleTagSelect(file.id, e.target.value)}
                value={file.selectedTag || ''}
              >
                <option value="">Select Tag</option>
                {imageTags.map((tag) => (
                  <option key={tag} value={tag}>
                    {tag}
                  </option>
                ))}
              </select>
            )}
            {file.isUploaded && (
              <div className={classes.progressBar}>
                <LinearProgress variant="determinate" value={file.progress} />
                <Typography className={classes.progressText}>
                  {file.progress}%
                </Typography>
              </div>
            )}
          </div>
        ))}
      </div>

      <div style={{ marginTop: '30px', textAlign: 'end' }}>
        {/* <Button
          onClick={handleReset}
          style={{
            margin: '0 15px',
            width: '150px',
            backgroundColor: '#2E368F',
            color: 'white',
            borderRadius: '20px',
          }}
        >
          Reset
        </Button> */}
        <Button
          style={{
            width: '150px',
            backgroundColor: '#2E368F',
            color: 'white',
            borderRadius: '20px',
          }}
          onClick={handleSubmit}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default UploadPhoto;
