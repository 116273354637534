// // // // // import React, { useState } from 'react';
// // // // // import { useStepperContext } from './StepperContext';
// // // // // import Axiosapi from './Axiosapi';
// // // // // import { ImageTagsR, ImageTagsC } from '../data';
// // // // // import { sub } from 'date-fns';
// // // // // import { Button } from '@mui/material';

// // // // // interface UploadedImage {
// // // // //   id: number;
// // // // //   name: string;
// // // // //   file: File;
// // // // //   url: string;
// // // // //   isCover: boolean;
// // // // //   isVisible: boolean;
// // // // //   selectedTag:string | null;
// // // // // }

// // // // // interface UploadPhotoProps {
// // // // //   onNext:()=>void
// // // // //   onBack:()=>void
// // // // //   onReset:()=>void
// // // // // }

// // // // // const UploadPhoto: React.FC = ({onNext,onBack,onSubmit}) => {
// // // // //   const { addPropImage, propImages, userData } = useStepperContext(); 

// // // // //   const [selectedFiles, setSelectedFiles] = useState<UploadedImage[]>([]);
// // // // //   const [previewIndex, setPreviewIndex] = useState<number | null>(null);

// // // // //   const PropertySubType = userData.find((item) => item['Property Type'])?.['Property Type'];

// // // // //   const subproperty=userData.find((item)=>item['Property Subtype'])?.['Property Subtype']
// // // // //   const imageTags = PropertySubType === 'Residential' ? ImageTagsR : ImageTagsC;




// // // // //   const handleUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
// // // // //     const files: File[] = Array.from(e.target.files || []);
// // // // //     const isSelected=selectedFiles.includes(files)

// // // // //     if (files.length === 0) {
// // // // //       return; 
// // // // //     }

// // // // //     const maxFiles = 6;
// // // // //      const newFiles: File[] = Array.from(files).slice(0, maxFiles);

// // // // //       if (selectedFiles.length + newFiles.length > maxFiles) {
// // // // //       alert(`You can upload a maximum of ${maxFiles} files.`);
// // // // //       return;
// // // // //     }
   
// // // // //      const formData=new FormData()
// // // // //      newFiles.forEach((file,index)=>{
// // // // //       const fieldId=generateId()
// // // // //       formData.append(`image`,file)
      
// // // // //        setSelectedFiles(prevFiles => [
// // // // //         ...prevFiles,
// // // // //         {
// // // // //           id: fieldId,
// // // // //           name: file.name,
// // // // //           file: file,
// // // // //           formName:"upload Image",
// // // // //           url: URL.createObjectURL(file),
// // // // //           isCover: false,
// // // // //           isVisible: true,
// // // // //           selectedTag:null,
// // // // //         }
// // // // //       ]);
// // // // //       addPropImage({
// // // // //         id: fieldId,
// // // // //         name: file.name,
// // // // //         file: file,
// // // // //         formName:"upload Image",
// // // // //         url: URL.createObjectURL(file),
// // // // //         isCover: false,
// // // // //         isVisible: true,
// // // // //         selectedTag:null
// // // // //       });
      
     

// // // // //      })
// // // // //     const uploadImage=async (formData)=>{
     
// // // // //       try{
// // // // //         const response=await Axiosapi.post(
// // // // //           '/user/imageUpload',formData,{
// // // // //             headers:{
// // // // //               'Content-Type':"multipart/form-data",
// // // // //             }
// // // // //           }
// // // // //          )
// // // // //          console.log('response',response.data)
          
// // // // //       if ( response.data.length > 0) {
// // // // //         setSelectedFiles(prevFiles => {
// // // // //           const updatedFiles = prevFiles.map(file => {
// // // // //             const foundItem = response.data.find(item => item.imagename === file.name);
// // // // //             console.log("new one",foundItem)
// // // // //             if (foundItem) {
// // // // //               return {
// // // // //                 ...file,
// // // // //                 url: foundItem.url 
// // // // //               };
// // // // //             }
            
           
// // // // //             return file;
// // // // //           });
// // // // //            console.log(updatedFiles)
// // // // //           return updatedFiles;
// // // // //         });
// // // // //       }
// // // // //       addPropImage(selectedFiles)
// // // // //        console.log('add',propImages)
// // // // //          console.log('upload success',response.data,selectedFiles)
// // // // //         }catch (error) {
// // // // //       console.error('Error uploading image:', error);
// // // // //     }
// // // // //     }
// // // // //     uploadImage(formData)
// // // // //     console.log('image',uploadImage)
 
      
    
// // // // //   };

// // // // //   const handleSetCover = (id: number) => {
// // // // //     const updatedFiles = selectedFiles.map(file => ({
// // // // //       ...file,
// // // // //       isCover: file.id === id,
// // // // //     }));
// // // // //     setSelectedFiles(updatedFiles);
// // // // //     addPropImage(updatedFiles);
// // // // //   };

// // // // //   const handleToggleView = () => {
// // // // //     const updatedFiles = selectedFiles.map(file => ({
// // // // //       ...file,
// // // // //       isVisible: !file.isVisible,
// // // // //     }));
// // // // //     setSelectedFiles(updatedFiles);
// // // // //     addPropImage(updatedFiles);
// // // // //   };
// // // // //    const handleTagSelect = (id: number, tag: string) => {
// // // // //     const updatedFiles = selectedFiles.map(file => ({
// // // // //       ...file,
// // // // //       selectedTag: file.id === id ? tag : file.selectedTag,
// // // // //     }));
// // // // //     setSelectedFiles(updatedFiles);
// // // // //     addPropImage(updatedFiles);
// // // // //   };

// // // // //   // Generates a unique ID for each image
// // // // //   const generateId = () => Math.floor(100000 + Math.random() * 900000);

// // // // //   // Handles preview click
// // // // //   const handlePreviewClick = (index: number) => {
// // // // //     setPreviewIndex(index);
// // // // //   };

// // // // //   // Handles removal of an image
// // // // //   const handleRemove = (id: number) => {
// // // // //     const updatedFiles = selectedFiles.filter(file => file.id !== id);
// // // // //     setSelectedFiles(updatedFiles);
// // // // //     addPropImage(updatedFiles);
// // // // //   };
// // // // //   console.log('Updated images:', selectedFiles);

// // // // //   const handleSubmit=()=>{
// // // // //  if(propImages.length>0){
// // // // //   handleSetCover()
// // // // //  }else{
// // // // //   onNext()
  
// // // // //  }

// // // // //   }

// // // // //   return (
// // // // //     <div>
// // // // //       <input type="file" accept="image/*" multiple onChange={handleUploadChange} />

// // // // //       <div style={{ display: 'flex', flexWrap: 'wrap' }}>
// // // // //         {selectedFiles.map((file, index) => (
// // // // //           <div key={file.id} style={{ margin: '10px', textAlign: 'center', cursor: 'pointer' }} onClick={() => handlePreviewClick(index)}>
// // // // //             <img
// // // // //               src={file.url}
// // // // //               alt={`Preview ${index}`}
// // // // //               style={{ width: '100px', height: '100px', objectFit: 'cover', marginBottom: '10px', border: file.isCover ? '2px solid blue' : 'none' }}
// // // // //             />
// // // // //             <button onClick={() => handleRemove(file.id)}>Remove</button>
// // // // //             <button onClick={() => handleSetCover(file.id)}>Set as Cover</button>
// // // // //             {/* Show or hide all images based on the isVisible flag */}

// // // // //             {subproperty!=='Plot'&&(
           
// // // // //               <select value={file.selectedTag || ''} onChange={(e) => handleTagSelect(file.id, e.target.value)}>
// // // // //                 <option value="">Select Tag</option>
// // // // //                 {Object.entries(imageTags).map(([key, tags]) => (
// // // // //                   <optgroup label={key} key={key}>
// // // // //                     {tags.map(tag => (
// // // // //                       <option key={tag.id} value={tag.label}>{tag.label}</option>
// // // // //                     ))}
// // // // //                   </optgroup>
// // // // //                 ))}
// // // // //               </select>
// // // // //               )}
          
// // // // //             <p>{file.name}</p>
// // // // //           </div>
// // // // //         ))}
// // // // //       </div>

// // // // //       {/* Display expanded preview */}
// // // // //       {previewIndex !== null && (
// // // // //         <div>
// // // // //           <img
// // // // //             src={selectedFiles[previewIndex]?.url}
// // // // //             alt={`Preview ${previewIndex}`}
// // // // //             style={{ width: '300px', height: '300px', objectFit: 'cover' }}
// // // // //           />
// // // // //         </div>
// // // // //       )}
// // // // //       <Button onClick={handleSubmit}>Continue</Button>
// // // // //     </div>
// // // // //   );
// // // // // };

// // // // // export default UploadPhoto;











// import React, { useState } from 'react';
// import { useStepperContext } from './StepperContext';
// import { useDropzone } from 'react-dropzone';
// import { Button, LinearProgress, Typography } from '@mui/material';
// import AWS from 'aws-sdk';

// interface UploadedImage {
//   id: number;
//   name: string;
//   file: File;
//   url: string;
//   isCover: boolean;
//   isVisible: boolean;
//   selectedTag: string | null;
//   progress: number;
// }

// interface UploadPhotoProps {
//   onNext: () => void;
//   onBack: () => void;
//   onReset: () => void;
// }

// const UploadPhoto: React.FC<UploadPhotoProps> = ({ onNext, onBack, onReset }) => {
//   const { addPropImage } = useStepperContext();
//   const [selectedFiles, setSelectedFiles] = useState<UploadedImage[]>([]);
//   const [previewIndex, setPreviewIndex] = useState<number | null>(null);

//   const imageTags = ['hall', 'kitchen', 'room', 'balcony'];

//   const s3 = new AWS.S3({
//     region: 'us-east-1',
//     accessKeyId: 'AKIAQ3EGVP6CLIGM245V',
//     secretAccessKey: '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',
//   });

//   const generateUniqueNumber = () => {
//     return Math.floor(Math.random() * 100000);
//   };

//   const handleUploadChange = (files: File[]) => {
//     if (files.length === 0) return;

//     const maxFiles = 6;
//     const newFiles: File[] = files.slice(0, maxFiles - selectedFiles.length);

//     if (selectedFiles.length + newFiles.length > maxFiles) {
//       alert(`You can upload a maximum of ${maxFiles} files.`);
//       return;
//     }

//     const newSelectedFiles = newFiles.map((file) => ({
//       id: generateUniqueNumber(),
//       name: file.name,
//       file,
//       url: URL.createObjectURL(file),
//       isCover: false,
//       isVisible: true,
//       selectedTag: null,
//       progress: 0,
//     }));

//     setSelectedFiles((prevFiles) => [...prevFiles, ...newSelectedFiles]);

//     newSelectedFiles.forEach((file) => uploadImageToS3('vattara-image', file));
//   };

//   const uploadImageToS3 = async (bucketName: string, file: UploadedImage) => {
//     const fileName = `image_${Date.now()}.jpg`;
//     const params = {
//       Bucket: bucketName,
//       Key: fileName,
//       Body: file.file,
//       ContentType: file.file.type,
//     };

//     try {
//       const uploadPromise = s3.upload(params)
//         .on('httpUploadProgress', (event) => {
//           const percent = Math.round((event.loaded / event.total) * 100);
//           setSelectedFiles((prevFiles) =>
//             prevFiles.map((f) =>
//               f.id === file.id ? { ...f, progress: percent } : f
//             )
//           );
//         })
//         .promise();

//       const uploadResponse = await uploadPromise;

//       setSelectedFiles((prevFiles) =>
//         prevFiles.map((f) =>
//           f.id === file.id ? { ...f, url: uploadResponse.Location } : f
//         )
//       );
//       addPropImage(selectedFiles);
//     } catch (error) {
//       console.error(`Error uploading ${fileName} to S3: ${error.message}`);
//     }
//   };

//   const handleSetCover = (id: number) => {
//     const updatedFiles = selectedFiles.map((file) => ({
//       ...file,
//       isCover: file.id === id,
//     }));
//     setSelectedFiles(updatedFiles);
//     addPropImage(updatedFiles);
//   };

//   const handleTagSelect = (id: number, tag: string) => {
//     const updatedFiles = selectedFiles.map((file) => ({
//       ...file,
//       selectedTag: file.id === id ? tag : file.selectedTag,
//     }));
//     setSelectedFiles(updatedFiles);
//     addPropImage(updatedFiles);
//   };

//   const handleRemove = (id: number) => {
//     const updatedFiles = selectedFiles.filter((file) => file.id !== id);
//     setSelectedFiles(updatedFiles);
//     addPropImage(updatedFiles);
//   };

//   const { getRootProps, getInputProps } = useDropzone({
//     onDrop: (acceptedFiles) => handleUploadChange(acceptedFiles),
//     accept: 'image/*',
//     maxFiles: 6,
//   });

//   return (
//     <div>
//       <div {...getRootProps()} style={{ border: '2px dashed #cccccc', padding: '20px', textAlign: 'center' }}>
//         <input {...getInputProps()} />
//         <p>Drag 'n' drop some files here, or click to select files</p>
//       </div>

//       <div className="image-preview-container">
//         {selectedFiles.map((file, index) => (
//           <div key={file.id} className="image-preview">
//             <img src={file.url} alt={file.name} onClick={() => setPreviewIndex(index)} />
//             <Button onClick={() => handleSetCover(file.id)}>
//               {file.isCover ? 'Cover Image' : 'Set as Cover'}
//             </Button>
//             <select onChange={(e) => handleTagSelect(file.id, e.target.value)} value={file.selectedTag || ''}>
//               <option value="" disabled>Select Tag</option>
//               {imageTags.map((tag) => (
//                 <option key={tag} value={tag}>{tag}</option>
//               ))}
//             </select>
//             <Button onClick={() => handleRemove(file.id)}>Remove</Button>
//             <LinearProgress variant="determinate" value={file.progress} />
//             <Typography variant="body2" color="textSecondary">{file.progress}%</Typography>
//           </div>
//         ))}
//       </div>

//       <div className="navigation-buttons">
//         <Button onClick={onBack}>Back</Button>
//         <Button onClick={onNext}>Next</Button>
//         <Button onClick={onReset}>Reset</Button>
//       </div>
//     </div>
//   );
// };

// export default UploadPhoto;


// // // // import React, { useState, useCallback } from 'react';
// // // // import { useStepperContext } from './StepperContext';
// // // // import { useDropzone } from 'react-dropzone';
// // // // import { Button, LinearProgress, Typography } from '@mui/material';
// // // // import AWS from 'aws-sdk';

// // // // interface UploadedImage {
// // // //   id: number;
// // // //   name: string;
// // // //   file: File;
// // // //   url: string;
// // // //   isCover: boolean;
// // // //   isVisible: boolean;
// // // //   selectedTag: string | null;
// // // //   progress: number;
// // // // }

// // // // interface UploadPhotoProps {
// // // //   onNext: () => void;
// // // //   onBack: () => void;
// // // //   onReset: () => void;
// // // // }

// // // // const UploadPhoto: React.FC<UploadPhotoProps> = ({ onNext, onBack, onReset }) => {
// // // //   const { addPropImage } = useStepperContext();
// // // //   const [selectedFiles, setSelectedFiles] = useState<UploadedImage[]>([]);
// // // //   const [previewIndex, setPreviewIndex] = useState<number | null>(null);
// // // //   const [uploadQueue, setUploadQueue] = useState<UploadedImage[]>([]);

// // // //   const imageTags = ['hall', 'kitchen', 'room', 'balcony'];

// // // //   const s3 = new AWS.S3({
// // // //     region: 'us-east-1',
// // // //     accessKeyId: 'AKIAQ3EGVP6CLIGM245V',
// // // //     secretAccessKey: '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',
// // // //   });

// // // //   const generateUniqueNumber = () => {
// // // //     return Math.floor(Math.random() * 100000);
// // // //   };

// // // //   const handleUploadChange = (files: File[]) => {
// // // //     if (files.length === 0) return;

// // // //     const maxFiles = 6;
// // // //     const newFiles: File[] = files.slice(0, maxFiles - selectedFiles.length);

// // // //     if (selectedFiles.length + newFiles.length > maxFiles) {
// // // //       alert(`You can upload a maximum of ${maxFiles} files.`);
// // // //       return;
// // // //     }

// // // //     const newSelectedFiles = newFiles.map((file) => ({
// // // //       id: generateUniqueNumber(),
// // // //       name: file.name,
// // // //       file,
// // // //       url: URL.createObjectURL(file),
// // // //       isCover: false,
// // // //       isVisible: true,
// // // //       selectedTag: null,
// // // //       progress: 0,
// // // //     }));

// // // //     setSelectedFiles((prevFiles) => [...prevFiles, ...newSelectedFiles]);
// // // //     setUploadQueue((prevQueue) => [...prevQueue, ...newSelectedFiles]);
// // // //   };

// // // //   const uploadImageToS3 = async (file: UploadedImage) => {
// // // //     const fileName = `image_${Date.now()}.jpg`;
// // // //     const params = {
// // // //       Bucket: 'vattara-image',
// // // //       Key: fileName,
// // // //       Body: file.file,
// // // //       ContentType: file.file.type,
// // // //     };

// // // //     try {
// // // //       const uploadPromise = s3.upload(params)
// // // //         .on('httpUploadProgress', (event) => {
// // // //           const percent = Math.round((event.loaded / event.total) * 100);
// // // //           setSelectedFiles((prevFiles) =>
// // // //             prevFiles.map((f) =>
// // // //               f.id === file.id ? { ...f, progress: percent } : f
// // // //             )
// // // //           );
// // // //         })
// // // //         .promise();

// // // //       const uploadResponse = await uploadPromise;

// // // //       setSelectedFiles((prevFiles) =>
// // // //         prevFiles.map((f) =>
// // // //           f.id === file.id ? { ...f, url: uploadResponse.Location } : f
// // // //         )
// // // //       );
// // // //       addPropImage(selectedFiles);
// // // //     } catch (error) {
// // // //       console.error(`Error uploading ${file.file.name} to S3: ${error.message}`);
// // // //     }
// // // //   };

// // // //   const processUploadQueue = useCallback(async () => {
// // // //     if (uploadQueue.length === 0) return;

// // // //     for (const file of uploadQueue) {
// // // //       await uploadImageToS3(file);
// // // //     }

// // // //     // Clear the queue after processing
// // // //     setUploadQueue([]);
// // // //   }, [uploadQueue, selectedFiles, addPropImage]);

// // // //   // Trigger the queue processing whenever new files are added
// // // //   React.useEffect(() => {
// // // //     processUploadQueue();
// // // //   }, [processUploadQueue]);

// // // //   const handleSetCover = (id: number) => {
// // // //     const updatedFiles = selectedFiles.map((file) => ({
// // // //       ...file,
// // // //       isCover: file.id === id,
// // // //     }));
// // // //     setSelectedFiles(updatedFiles);
// // // //     addPropImage(updatedFiles);
// // // //   };

// // // //   const handleTagSelect = (id: number, tag: string) => {
// // // //     const updatedFiles = selectedFiles.map((file) => ({
// // // //       ...file,
// // // //       selectedTag: file.id === id ? tag : file.selectedTag,
// // // //     }));
// // // //     setSelectedFiles(updatedFiles);
// // // //     addPropImage(updatedFiles);
// // // //   };

// // // //   const handleRemove = (id: number) => {
// // // //     const updatedFiles = selectedFiles.filter((file) => file.id !== id);
// // // //     setSelectedFiles(updatedFiles);
// // // //     addPropImage(updatedFiles);
// // // //   };

// // // //   const { getRootProps, getInputProps } = useDropzone({
// // // //     onDrop: (acceptedFiles) => handleUploadChange(acceptedFiles),
// // // //     accept: 'image/*',
// // // //     maxFiles: 6,
// // // //   });

// // // //   return (
// // // //     <div>
// // // //       <div {...getRootProps()} style={{ border: '2px dashed #cccccc', padding: '20px', textAlign: 'center' }}>
// // // //         <input {...getInputProps()} />
// // // //         <p>Drag 'n' drop some files here, or click to select files</p>
// // // //       </div>

// // // //       <div className="image-preview-container">
// // // //         {selectedFiles.map((file) => (
// // // //           <div key={file.id} className="image-preview">
// // // //             <img src={file.url} alt={file.name} onClick={() => setPreviewIndex(file.id)} />
// // // //             <Button onClick={() => handleSetCover(file.id)}>
// // // //               {file.isCover ? 'Cover Image' : 'Set as Cover'}
// // // //             </Button>
// // // //             <select onChange={(e) => handleTagSelect(file.id, e.target.value)} value={file.selectedTag || ''}>
// // // //               <option value="" disabled>Select Tag</option>
// // // //               {imageTags.map((tag) => (
// // // //                 <option key={tag} value={tag}>{tag}</option>
// // // //               ))}
// // // //             </select>
// // // //             <Button onClick={() => handleRemove(file.id)}>Remove</Button>
// // // //             <LinearProgress variant="determinate" value={file.progress} />
// // // //             <Typography variant="body2" color="textSecondary">{file.progress}%</Typography>
// // // //           </div>
// // // //         ))}
// // // //       </div>

// // // //       <div className="navigation-buttons">
// // // //         <Button onClick={onBack}>Back</Button>
// // // //         <Button onClick={onNext}>Next</Button>
// // // //         <Button onClick={onReset}>Reset</Button>
// // // //       </div>
// // // //     </div>
// // // //   );
// // // // };

// // // // export default UploadPhoto;




// // // // // import React, { useEffect, useState } from 'react';
// // // // // import { useStepperContext } from './StepperContext'; // Adjust import as needed
// // // // // import Axiosapi from './Axiosapi';
// // // // // import { forEachChild } from 'typescript';
// // // // // import axios from 'axios';



// // // // // const UploadPhoto: React.FC = () => {
// // // // //   const { addPropImage,propImages,userData } = useStepperContext(); // Assuming useStepperContext provides addPropImage

// // // // //   const [selectedFiles, setSelectedFiles] = useState<{ id: number; name: string; file: File; url: string; isCover: boolean; isVisible: boolean; selectedTag:string | null }[]>([]);
// // // // //   const [previewIndex, setPreviewIndex] = useState<number | null>(null);
// // // // //   //  const [pickerResponse, setPickerResponse] = useState(propImages || []);
// // // // //   // const { mutate, isPending, isError } = uploadImages();
// // // // //    const PropertySubType = userData.find((item) => item['Property Type'])?.['Property Type'];
// // // // //    const subproperty=userData.find((item)=>item['Property Subtype'])?.['Property Subtype']
// // // // //    const imageTags=PropertySubType==='Residential'?ImageTagsR:ImageTagsC

  

// // // // //   const handleUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
// // // // //     const files: File[] = Array.from(e.target.files || []);
// // // // //     const isSelected=selectedFiles.includes(files)

// // // // //     if (files.length === 0) {
// // // // //       return; // No files selected
// // // // //     }

// // // // //     const maxFiles = 6;
// // // // //      const newFiles: File[] = Array.from(files).slice(0, maxFiles);

// // // // //       if (selectedFiles.length + newFiles.length > maxFiles) {
// // // // //       alert(`You can upload a maximum of ${maxFiles} files.`);
// // // // //       return;
// // // // //     }
   
// // // // //      const formData=new FormData()
// // // // //      newFiles.forEach((file,index)=>{
// // // // //       const fieldId=generateId()
// // // // //       formData.append(`image`,file)
      
// // // // //        setSelectedFiles(prevFiles => [
// // // // //         ...prevFiles,
// // // // //         {
// // // // //           id: fieldId,
// // // // //           name: file.name,
// // // // //           file: file,
// // // // //           formName:"upload Image",
// // // // //           url: URL.createObjectURL(file),
// // // // //           isCover: false,
// // // // //           isVisible: true,
// // // // //           selectedTag:null,
// // // // //         }
// // // // //       ]);
// // // // //       addPropImage({
// // // // //         id: fieldId,
// // // // //         name: file.name,
// // // // //         file: file,
// // // // //         formName:"upload Image",
// // // // //         url: URL.createObjectURL(file)
// // // // //         // Add more properties as needed
// // // // //       });
      
     

// // // // //      })
// // // // //     const uploadImage=async (formData)=>{
     
// // // // //       try{
// // // // //         const response=await Axiosapi.post(
// // // // //           '/user/imageUpload',formData,{
// // // // //             headers:{
// // // // //               'Content-Type':"multipart/form-data",
// // // // //             }
// // // // //           }
// // // // //          )
// // // // //          console.log('response',response.data)
          
// // // // //       if ( response.data.length > 0) {
// // // // //         setSelectedFiles(prevFiles => {
// // // // //           const updatedFiles = prevFiles.map(file => {
// // // // //             const foundItem = response.data.find(item => item.imagename === file.name);
// // // // //             console.log("new one",foundItem)
// // // // //             if (foundItem) {
// // // // //               return {
// // // // //                 ...file,
// // // // //                 url: foundItem.url // Update URL with the new URL from backend
// // // // //               };
// // // // //             }
            
           
// // // // //             return file;
// // // // //           });
// // // // //            console.log(updatedFiles)
// // // // //           return updatedFiles;
// // // // //         });
// // // // //       }
// // // // //       addPropImage(selectedFiles)
// // // // //        console.log('add',propImages)
// // // // //          console.log('upload success',response.data,selectedFiles)
// // // // //         }catch (error) {
// // // // //       console.error('Error uploading image:', error);
// // // // //     }
// // // // //     }
// // // // //     uploadImage(formData)
// // // // //     console.log('image',uploadImage)
 
      
    
// // // // //   };
// // // // //   const handleLabelSelect = (label: string, id: string) => {
// // // // //     const updatedImages = pickerResponse.map((image) => ({
// // // // //       ...image,
// // // // //       ImageTag: image.id === id ? label : image.ImageTag,
// // // // //     }));
// // // // //     setPickerResponse(updatedImages);
// // // // //   };
// // // // //   const handlePress = (e): void => {
// // // // //     // my console log

// // // // //     const myData = pickerResponse.map((item) => {
// // // // //       if (item.id === e.id || item.isCover) {
// // // // //         return {
// // // // //           ...item,
// // // // //           isCover: !item.isCover,
// // // // //         };
// // // // //       }
// // // // //       return item;
// // // // //     });
// // // // //     console.log(myData);
// // // // //     setPickerResponse(myData);
// // // // //   };

// // // // // // console.log(addPropImage)

// // // // //   const generateId = () => {
// // // // //     return Math.floor(100000 + Math.random() * 900000);
// // // // //   };

   

// // // // //   const handlePreviewClick = (index: number) => {
// // // // //     setPreviewIndex(index);
// // // // //   };

// // // // //   const handleRemove = (id: number) => {
// // // // //     const updatedFiles = selectedFiles.filter((file) => file.id !== id);
// // // // //     setSelectedFiles(updatedFiles);
// // // // //     console.log(updatedFiles)
// // // // //     // Remove from context if needed
// // // // //   };
// // // // //   console.log('updated image',selectedFiles)
// // // // //   console.log(selectedFiles)
 
// // // // //   return (
// // // // //     <div>
// // // // //       <input type="file" accept="image/*" multiple onChange={handleUploadChange} />

// // // // //       {/* Display thumbnails of selected images */}
// // // // //       <div style={{ display: 'flex', flexWrap: 'wrap' }}>
// // // // //         {selectedFiles.map((file, index) => (
// // // // //           <div key={file.id} style={{ margin: '10px', textAlign: 'center', cursor: 'pointer' }} onClick={() => handlePreviewClick(index)}>
// // // // //             <img
// // // // //               src={file.url}
// // // // //               alt={`Preview ${index}`}
// // // // //               style={{ width: '100px', height: '100px', objectFit: 'cover', marginBottom: '10px', border: previewIndex === index ? '2px solid blue' : 'none' }}
// // // // //             />
// // // // //             <button onClick={() => handleRemove(file.id)}>Remove</button>
// // // // //             <p>{file.name}</p>
// // // // //           </div>
// // // // //         ))}
// // // // //       </div>

// // // // //       {/* Display expanded preview */}
// // // // //       {previewIndex !== null && (
// // // // //         <div>
// // // // //           <img
// // // // //             src={selectedFiles[previewIndex]?.url}
// // // // //             alt={`Preview ${previewIndex}`}
// // // // //             style={{ width: '300px', height: '300px', objectFit: 'cover' }}
// // // // //           />
// // // // //         </div>
// // // // //       )}
// // // // //     </div>
// // // // //   );
// // // // // };

// // // // // export default UploadPhoto;










// // // // // selectedFiles(uploadImage)
      
// // // //     //  Axiosapi.post('/user/imageUpload',formData,{
// // // //     //       headers:{
// // // //     //         'Content-Type':'multipart/form-data'
// // // //     //       }
// // // //     //  }).then(response=>{
// // // //     //   console.log("upload success",response.data)
// // // //     //  })
// // // //     //  .catch(error=>{
// // // //     //   console.error('upload error',error)
// // // //     //  })
// // // //     // }
// // // //     // console.log(handleUploadChange)

// // // //     // Prepare data for addPropImage
// // // //     // const updatedFiles = files.slice(0, maxFiles).map((file) => ({
// // // //     //   id: generateId(),
// // // //     //   name: file.name,
// // // //     //   file: file,
// // // //     //   url: URL.createObjectURL(file)
// // // //     // }));

// // // //     // setSelectedFiles((prevFiles) => [...prevFiles, ...updatedFiles]);

// // // //     // Update selectedFiles state with the selected files
// // // //     // setSelectedFiles((prevFiles) => {
// // // //     //   // Check if adding new files will exceed the maximum of 6 files
// // // //     //   if (prevFiles.length + updatedFiles.length > maxFiles) {
// // // //     //     // If exceeding, slice to only add up to 6 - prevFiles.length files
// // // //     //     alert('you have exceed 6 image')
// // // //     //     return [...prevFiles, ...updatedFiles.slice(0, maxFiles - prevFiles.length)];
// // // //     //   } else {
// // // //     //     // If not exceeding, add all updatedFiles
// // // //     //     return [...prevFiles, ...updatedFiles];
// // // //     //   }
// // // //     // });

// // // //     // Call addPropImage with the prepared data
// // // // //     updatedFiles.forEach((fileData) => {
// // // // //       addPropImage({
// // // // //         id: fileData.id,
// // // // //         name: fileData.name,
// // // // //         file: fileData.file,
// // // // //         url: fileData.url,
// // // // //         // Add more properties as needed
// // // // //       });
     
// // // // //     });






// // // // // import React, { useState } from 'react';
// // // // // import { useStepperContext } from './StepperContext';
// // // // //  // Replace with correct import path

// // // // // const UploadPhoto: React.FC = () => {
// // // // //   const { addPropImage } = useStepperContext(); // Assuming useStepperContext provides addPropImage

// // // // //   const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
// // // // //   const [previewIndex, setPreviewIndex] = useState([]); 
// // // // //   // const[selectedImage,setSelectedImage]=useState(null)

// // // // //    if (files.length === 0) {
// // // // //       return; // No files selected
// // // // //     }

// // // // //     const maxFiles = 6;

// // // // //     // Prepare data for addPropImage
// // // // //     const updatedFiles = files.slice(0, maxFiles).map((file) => ({
// // // // //       id: generateId(),
// // // // //       name: file.name,
// // // // //       file: file,
// // // // //       url: URL.createObjectURL(file)
// // // // //     }));

// // // // //     // Update selectedFiles state with the selected files
// // // // //     setSelectedFiles((prevFiles) => {
// // // // //       // Check if adding new files will exceed the maximum of 6 files
// // // // //       if (prevFiles.length + updatedFiles.length > maxFiles) {
// // // // //         // If exceeding, slice to only add up to 6 - prevFiles.length files
// // // // //         return [...prevFiles, ...updatedFiles.slice(0, maxFiles - prevFiles.length)];
// // // // //       } else {
// // // // //         // If not exceeding, add all updatedFiles
// // // // //         return [...prevFiles, ...updatedFiles];
// // // // //       }
// // // // //     });

// // // // //     // Call addPropImage with the prepared data
// // // // //     updatedFiles.forEach((fileData) => {
// // // // //       addPropImage({
// // // // //         id: fileData.id,
// // // // //         name: fileData.name,
// // // // //         file: fileData.file,
// // // // //         url: fileData.url,
// // // // //         // Add more properties as needed
// // // // //       });
// // // // //     });
// // // // //   };

// // // // //   const generateId = () => {
// // // // //     return Math.floor(100000 + Math.random() * 900000);
// // // // //   };

// // // // //   const handlePreviewClick = (index: number) => {
// // // // //     setPreviewIndex(index);
// // // // //   };

// // // // //   const handleRemove = (id: number) => {
// // // // //     const updatedFiles = selectedFiles.filter((file) => file.id !== id);
// // // // //     setSelectedFiles(updatedFiles);
// // // // //     // Remove from context if needed
// // // // //   };
// // // // //   return (
// // // // //     <div>
// // // // //        <input type='file' accept='image/*' multiple onChange={handleUploadChange}   />

// // // // //        <div>
// // // // //         {selectedFiles.map((file, index) => (
// // // // //           <div key={file.id} style={{ margin: '10px', textAlign: 'center', cursor: 'pointer' }} onClick={() => handlePreviewClick(index)}>
// // // // //             <img
// // // // //               src={file.url}
// // // // //               alt={`Preview ${index}`}
// // // // //               style={{ width: '300px', height: '300px', objectFit: 'cover', marginBottom: '20px', border: previewIndex.includes(index) ? '2px solid blue' : 'none' }}
// // // // //             />
// // // // //             <button onClick={() => handleRemove(file.id)}>Remove</button>
// // // // //             <p>{file.name}</p>
// // // // //           </div>
// // // // //         ))}
// // // // //       </div>

// // // // //       {/* Modal or expanded preview for selected indices */}
// // // // //       {previewIndex.map((previewIndex) => (
// // // // //         <div key={previewIndex} className="modal" style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(0, 0, 0, 0.7)', zIndex: 999 }}>
// // // // //           <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: '#fff', padding: '20px' }}>
// // // // //             <img
// // // // //               src={selectedFiles[previewIndex].url}
// // // // //               alt={`Preview ${previewIndex}`}
// // // // //               style={{ maxWidth: '100%', maxHeight: '100%' }}
// // // // //             />

// // // // //             {/* <button onClick={handleClosePreview}>Close</button> */}
// // // // //           </div>
// // // // //         </div>
// // // // //       ))}

      
// // // // //     </div>
// // // // //   );
// // // // // };

// // // // // export default UploadPhoto;


// // // //   // {selectedImageIndex !== null ? (
// // // //   //               <img
// // // //   //                 src={
// // // //   //                   previewImages[selectedImageIndex] ||
// // // //   //                   (data[selectedImageIndex] && data[selectedImageIndex].image)
// // // //   //                 }
// // // //   //                 alt={"Preview"}
// // // //   //                 style={{ width: "100%", height: "100%", objectFit: "cover" }}
// // // //   //               />
// // // //   //             ) : (
// // // //   //               /* Display the default image if selectedImageIndex is null */
// // // //   //               <img
// // // //   //                 src={img}
// // // //   //                 alt={"Default"}
// // // //   //                 style={{ width: "100%", height: "100%", objectFit: "cover" }}
// // // //   //               />
// // // //   //             )}














// // // // // import React, { useState } from 'react';
// // // // // import { Button, Grid, Typography } from '@mui/material'; // Import components from Material-UI or your preferred UI library
// // // // // import { useStepperContext } from './StepperContext';

// // // // // const ImageUploadComponent = () => {

// // // // //     const{propImages}=useStepperContext()
// // // // //   const [selectedFiles, setSelectedFiles] = useState([]);
// // // // //   const [previewImage,setPreviewImage]=useState([])

// // // // //   const handleShow=()=>{


// // // // //   }

// // // // //   const handleFileChange = (e) => {
// // // // //     const files = Array.from(e.target.files);

// // // // //     // Limit the number of selected files to 5
// // // // //     if (files.length > 5) {
// // // // //       alert('You can upload a maximum of 5 images.');
// // // // //       return;     
// // // // //     }

// // // // //     setSelectedFiles(files);
// // // // //   };

// // // // //   const handleUpload = () => {
// // // // //     // Logic to handle uploading selectedFiles array
// // // // //     console.log(selectedFiles);
// // // // //     // Implement upload logic as per your requirement (e.g., using FormData and API)
// // // // //   };

// // // // //   return (
// // // // //     <div>
// // // // //       <Typography variant="h6" gutterBottom>
// // // // //         Upload Images (up to 5)
// // // // //       </Typography>
// // // // //       <input
// // // // //         type="file"
// // // // //         accept="image/*"
// // // // //         multiple
// // // // //         onChange={handleFileChange}
// // // // //         style={{ display: 'none' }}
// // // // //         id="image-upload-input"
// // // // //       />
// // // // //       <label htmlFor="image-upload-input">
// // // // //         <Button variant="contained" component="span">
// // // // //           Select Images
// // // // //         </Button>
// // // // //       </label>
// // // // //       <Grid container spacing={2} style={{ marginTop: '10px' }}>
// // // // //         {selectedFiles.map((file, index) => (
// // // // //           <Grid item key={index}>
// // // // //             <img src={URL.createObjectURL(file)} alt={`Preview ${index}`} style={{ maxWidth: '100px' }} />
// // // // //           </Grid>
// // // // //         ))}
// // // // //       </Grid>
// // // // //       <Button
// // // // //         variant="contained"
// // // // //         color="primary"
// // // // //         onClick={handleUpload}
// // // // //         disabled={selectedFiles.length === 0}
// // // // //         style={{ marginTop: '10px' }}
// // // // //       >
// // // // //         Upload
// // // // //       </Button>
// // // // //     </div>
// // // // //   );
// // // // // };

// // // // // export default ImageUploadComponent;

// // // // // import React, { useState } from 'react';
// // // // // import { Button, Grid, Typography } from '@mui/material';
// // // // // import { useStepperContext } from './StepperContext';

// // // // // const ImageUploadComponent = () => {
// // // // //   const { addPropImage } = useStepperContext();
// // // // //   const [selectedFiles, setSelectedFiles] = useState([]);


// // // // //   const handleFileChange = (e:React) => {
// // // // //     const files = Array.from(e.target.files);

// // // // //     // Limit the number of selected files to 5
// // // // //     if (files.length > 5) {
// // // // //       alert('You can upload a maximum of 5 images.');
// // // // //       return;
// // // // //     }

// // // // //      const updateFile = files.map((file,index)=>({
// // // // //       id:generateId(),
// // // // //       file:file,
// // // // //       url: URL.createObjectURL(file)


// // // // //   }))

// // // // //     setSelectedFiles(files);
// // // // //     addPropImage(files)
// // // // //   };
// // // // //   const generateId=()=>{
// // // // //     return Math.floor(100000+Math.random()*900000)
// // // // //   }


// // // // //   const handleRemove=(id)=>{
// // // // //     const updateImage=selectedFiles.filter((file)=>file.id !==id)
// // // // //     setSelectedFiles(updateImage)
// // // // //   }
// // // // //   const handleUpload = () => {
// // // // //     // Example upload logic using fetch
// // // // //        selectedFiles((prevImg)=>(
// // // // //         prevImg.map((value,id)=>(id===index?true:value)
// // // // //        ))

// // // // //     // const formData = new FormData();
// // // // //     // selectedFiles.forEach((file) => {
// // // // //     //   formData.append('images[]', file.file);
// // // // //     // });

// // // // //     // fetch('http://your-api-endpoint/upload', {
// // // // //     //   method: 'POST',
// // // // //     //   body: formData,
// // // // //     // })
// // // // //     //   .then((response) => response.json())
// // // // //     //   .then((data) => {
// // // // //     //     console.log('Upload Success:', data);
// // // // //     //     // Optionally, handle success feedback or reset selectedFiles state
// // // // //     //   })
// // // // //     //   .catch((error) => {
// // // // //     //     console.error('Upload Error:', error);
// // // // //     //     // Optionally, handle error feedback
// // // // //     //   });
// // // // //   };

// // // // //   return (
// // // // //     <div>
// // // // //       <Typography variant="h6" gutterBottom>
// // // // //         Upload Images (up to 6)
// // // // //       </Typography>
// // // // //       <input
// // // // //         type="file"
// // // // //         accept="image/*"
// // // // //         multiple
// // // // //         onChange={handleFileChange}
// // // // //         style={{ display: 'none' }}
// // // // //         id="image-upload-input"
// // // // //       />
// // // // //       <label htmlFor="image-upload-input">
// // // // //         <Button variant="contained" component="span">
// // // // //           Select Images
// // // // //         </Button>
// // // // //       </label>
// // // // //       {selectedFiles.length > 0 && (
// // // // //         <div style={{ marginTop: '10px' }}>
// // // // //           {selectedFiles.map((image) => (
// // // // //             <div key={image.id} style={{ marginBottom: '10px' }}>
// // // // //               <img
// // // // //                 src={URL.createObjectURL(image.file)}
// // // // //                 alt={`Image ${image.id}`}
// // // // //                 style={{ maxWidth: '100px', maxHeight: '100px', marginRight: '10px' }}
// // // // //               />
// // // // //               <Button
// // // // //                 variant="outlined"
// // // // //                 color="secondary"
// // // // //                 onClick={() => handleRemove(image.id)}
// // // // //               >
// // // // //                 Remove
// // // // //               </Button>
// // // // //               <span style={{ marginLeft: '10px' }}>{image.id}</span>
// // // // //             </div>
// // // // //           ))}
// // // // //         </div>
// // // // //       )}
// // // // //       <Button
// // // // //         variant="contained"
// // // // //         color="primary"
// // // // //         onClick={handleUpload}
// // // // //         disabled={selectedFiles.length === 0}
// // // // //         style={{ marginTop: '10px' }}
// // // // //       >
// // // // //         Upload
// // // // //       </Button>
// // // // //     </div>
// // // // //   );
// // // // // };

// // // // // export default ImageUploadComponent;




// // // // // import React, { useState } from 'react';
// // // // // import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'; // Import components from Material-UI or your preferred UI library
// // // // // import { useStepperContext } from './StepperContext';

// // // // // interface ImageData {
// // // // //   id: string;
// // // // //   file: File;
// // // // // }

// // // // // const ImageUploadComponent: React.FC = () => {
// // // // //   const {addPropImage}=useStepperContext()
// // // // //   const [selectedFiles, setSelectedFiles] = useState<ImageData[]>([]);


// // // // //   // const handleUpload=()=>{

// // // // //   //      selectedFiles((prevImag)=>{

// // // // //   //      })

// // // // //   // }


// // // // //     const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
// // // // //     const files = e.target.files;
// // // // //     if (files) {
// // // // //       let newSelectedFiles: File[] = [];
// // // // //       // Limiting to a maximum of 6 files
// // // // //       for (let i = 0; i < Math.min(files.length, 6); i++) {
// // // // //         newSelectedFiles.push(files[i]);
// // // // //       }
// // // // //       setSelectedFiles(newSelectedFiles);
// // // // //     }
// // // // //   };

// // // // //   // const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
// // // // //   //   const files = e.target.files;

// // // // //   //   if (files) {
// // // // //   //     const selected: File[] = Array.from(files);

// // // // //   //     // Limit the number of selected files to 6
// // // // //   //     if (selected.length > 6) {
// // // // //   //       alert('You can select a maximum of 6 images.');
// // // // //   //       return;
// // // // //   //     }

// // // // //   //     const updatedSelectedFiles: ImageData[] = selected.map((file) => ({
// // // // //   //       id: generateId(),
// // // // //   //       file: file,
// // // // //   //     }));

// // // // //   //     setSelectedFiles(updatedSelectedFiles);

// // // // //   //     // Add images to addpropsImage function (assuming it adds images to context or props)
// // // // //   //     updatedSelectedFiles.forEach((imageData) => {
// // // // //   //       addPropImage(imageData);
// // // // //   //     });
// // // // //   //   }
// // // // //   // };

// // // // //   const generateId = (): string => {
// // // // //     // Generates a random 6-digit ID
// // // // //     return Math.floor(100000 + Math.random() * 900000).toString();
// // // // //   };

// // // // //   const handleRemoveImage = (id: string) => {
// // // // //     const updatedFiles = selectedFiles.filter((file) => file.id !== id);
// // // // //     setSelectedFiles(updatedFiles);
// // // // //   };

// // // // //    const PreviewImage = () => {
// // // // //     return (
// // // // //       <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
// // // // //         {selectedFiles.map((imageData) => (
// // // // //           <Card key={imageData.id} style={{ maxWidth: '300px', margin: '10px' }}>
// // // // //             <CardMedia
// // // // //               component="img"
// // // // //               height="200"
// // // // //               image={URL.createObjectURL(imageData.file)}
// // // // //               alt={`Image ${imageData.id}`}
// // // // //             />
// // // // //             <CardContent>
// // // // //               <Typography gutterBottom variant="h5" component="div">
// // // // //                 Image {imageData.id}
// // // // //               </Typography>
// // // // //               <Typography variant="body2" color="text.secondary">
// // // // //                 {imageData.file.name}
// // // // //               </Typography>
// // // // //             </CardContent>
// // // // //             <CardActions>
// // // // //               <Button
// // // // //                 size="small"
// // // // //                 color="secondary"
// // // // //                 onClick={() => handleRemoveImage(imageData.id)}
// // // // //               >
// // // // //                 Remove
// // // // //               </Button>
// // // // //             </CardActions>
// // // // //           </Card>
// // // // //         ))}
// // // // //       </div>
// // // // //     );
// // // // //   };


// // // // //   // const handleUpload = () => {
// // // // //   //   // Example upload logic using fetch
// // // // //   //   const formData = new FormData();
// // // // //   //   selectedFiles.forEach((imageData) => {
// // // // //   //     formData.append('images[]', imageData.file);
// // // // //   //   });

// // // // //   //   fetch('', {
// // // // //   //     method: 'POST',
// // // // //   //     body: formData,
// // // // //   //   })
// // // // //   //     .then((response) => response.json())
// // // // //   //     .then((data) => {
// // // // //   //       console.log('Upload Success:', data);
// // // // //   //       // Optionally, handle success feedback or reset selectedFiles state
// // // // //   //     })
// // // // //   //     .catch((error) => {
// // // // //   //       console.error('Upload Error:', error);
// // // // //   //       // Optionally, handle error feedback
// // // // //   //     });
// // // // //   // };
// // // // //  return (
// // // // //     <div>
// // // // //       <Typography variant="h6" gutterBottom>
// // // // //         Upload Images (up to 6)
// // // // //       </Typography>
// // // // //       <input
// // // // //         type="file"
// // // // //         accept="image/*"
// // // // //         multiple
// // // // //         onChange={handleFileChange}
// // // // //         style={{ display: 'none' }}
// // // // //         id="image-upload-input"
// // // // //       />
// // // // //       <label htmlFor="image-upload-input">
// // // // //         <Button variant="contained" component="span">
// // // // //           Select Images
// // // // //         </Button>
// // // // //       </label>
// // // // //       <PreviewImage />
// // // // //       <Button
// // // // //         variant="contained"
// // // // //         color="primary"
// // // // //         disabled={selectedFiles.length === 0}
// // // // //         style={{ marginTop: '10px' }}
// // // // //         onClick={() => console.log('Implement upload logic')}
// // // // //       >
// // // // //         Upload
// // // // //       </Button>
// // // // //     </div>
// // // // //   );
// // // // // };
// // // // // //   return (
// // // // // //     <div>
// // // // // //       <Typography variant="h6" gutterBottom>
// // // // // //         Upload Images (up to 6)
// // // // // //       </Typography>
// // // // // //       <input
// // // // // //         type="file"
// // // // // //         accept="image/*"
// // // // // //         multiple
// // // // // //         onChange={handleFileChange}
// // // // // //         style={{ display: 'none' }}
// // // // // //         id="image-upload-input"
// // // // // //       />
// // // // // //       <label htmlFor="image-upload-input">
// // // // // //         <Button variant="contained" component="span">
// // // // // //           Select Images
// // // // // //         </Button>
// // // // // //       </label>
// // // // // //       {selectedFiles.length > 0 && (
// // // // // //        <Card key={imageData.id} style={{ marginBottom: '10px', maxWidth: '300px' }}>
// // // // // //               <CardMedia
// // // // // //                 component="img"
// // // // // //                 height="200"
// // // // // //                 image={URL.createObjectURL(imageData.file)}
// // // // // //                 alt={`Image ${index + 1}`}
// // // // // //               />
// // // // // //               <CardContent>
// // // // // //                 <Typography gutterBottom variant="h5" component="div">
// // // // // //                   Image {index + 1}
// // // // // //                 </Typography>
// // // // // //                 <Typography variant="body2" color="text.secondary">
// // // // // //                   {imageData.file.name}
// // // // // //                 </Typography>
// // // // // //               </CardContent>
// // // // // //               <CardActions>
// // // // // //                 <Button size="small" color="secondary" onClick={() => handleRemoveImage(imageData.id)}>
// // // // // //                   Remove
// // // // // //                 </Button>
// // // // // //               </CardActions>
// // // // // //             </Card>
// // // // // //       )}
// // // // // //       <Button
// // // // // //         variant="contained"
// // // // // //         color="primary"
// // // // // //         onClick={handleUpload}
// // // // // //         disabled={selectedFiles.length === 0}
// // // // // //         style={{ marginTop: '10px' }}
// // // // // //       >
// // // // // //         Upload
// // // // // //       </Button>
// // // // // //     </div>
// // // // // //   );
// // // // // // };

// // // // // export default ImageUploadComponent;






  


// // // //     // Implement upload logic here (e.g., using FormData and API)
// // // //     // For demonstration, logging selectedFiles
// // // //     // console.log(selectedFiles);

// // // //     // Example of using FormData and fetch for uploading
// // // //     // const formData = new FormData();
// // // //     // selectedFiles.forEach((file, index) => {
// // // //     //   formData.append(`file${index}`, file);
// // // //     // });
// // // //     // fetch('your-upload-url', {
// // // //     //   method: 'POST',
// // // //     //   body: formData
// // // //     // })
// // // //   //   .then(response => response.json())
// // // //   //   .then(data => console.log('Upload Success:', data))
// // // //   //   .catch(error => console.error('Upload Error:', error));
// // // //   // };

// // // // //   return (
// // // // //     <div>
// // // // //       <Typography variant="h6" gutterBottom>
// // // // //         Upload Images (up to 5)
// // // // //       </Typography>
// // // // //       <input
// // // // //         type="file"
// // // // //         accept="image/*"
// // // // //         multiple
// // // // //         onChange={handleFileChange}
// // // // //         style={{ display: 'none' }}
// // // // //         id="image-upload-input"
// // // // //       />
// // // // //       <label htmlFor="image-upload-input">
// // // // //         <Button variant="contained" component="span">
// // // // //           Select Images
// // // // //         </Button>
// // // // //       </label>
// // // // //       <Grid container spacing={2} style={{ marginTop: '10px' }}>
// // // // //         {selectedFiles.map((file, index) => (
// // // // //           <Grid item key={index}>
// // // // //             <img
// // // // //               src={URL.createObjectURL(file)}
// // // // //               alt={`Preview ${index}`}
// // // // //               style={{ maxWidth: '100px', maxHeight: '100px' }}
// // // // //             />
// // // // //           </Grid>
// // // // //         ))}
// // // // //       </Grid>
// // // // //       <Button
// // // // //         variant="contained"
// // // // //         color="primary"
// // // // //         onClick={handleUpload}
// // // // //         disabled={selectedFiles.length === 0}
// // // // //         style={{ marginTop: '10px' }}
// // // // //       >
// // // // //         Upload
// // // // //       </Button>
// // // // //     </div>
// // // // //   );
// // // // // };

// // // // // export default ImageUploadComponent;







// // // // // // import React from 'react';
// // // // // // import { Typography, Grid, Paper } from '@mui/material'; // Import components from Material-UI or your preferred UI library
// // // // // // import { useStepperContext } from './StepperContext'; // Import context hook or use local state

// // // // // // const ReviewPage = () => {
// // // // // //   const { addUserData} = useStepperContext(); // Replace with your context or state hook
// // // // // //   const { images, ...otherData } = addUserData; // Assuming userData includes images and other form data

// // // // // //   return (
// // // // // //     <div>
// // // // // //       <Typography variant="h4" gutterBottom>
// // // // // //         Review Your Data
// // // // // //       </Typography>
// // // // // //       <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
// // // // // //         <Typography variant="h6">Uploaded Images</Typography>
// // // // // //         <Grid container spacing={2} style={{ marginTop: '10px' }}>
// // // // // //           {images.map((file, index) => (
// // // // // //             <Grid item key={index}>
// // // // // //               <img src={URL.createObjectURL(file)} alt={`Uploaded ${index}`} style={{ maxWidth: '100px' }} />
// // // // // //             </Grid>
// // // // // //           ))}
// // // // // //         </Grid>
// // // // // //       </Paper>
// // // // // //       <Paper elevation={3} style={{ padding: '20px' }}>
// // // // // //         <Typography variant="h6">Other Form Data</Typography>
// // // // // //         <Grid container spacing={2} style={{ marginTop: '10px' }}>
// // // // // //           {Object.entries(otherData).map(([key, value]) => (
// // // // // //             <Grid item xs={12} md={6} key={key}>
// // // // // //               <Typography>
// // // // // //                 <strong>{key}:</strong> {value}
// // // // // //               </Typography>
// // // // // //             </Grid>
// // // // // //           ))}
// // // // // //         </Grid>
// // // // // //       </Paper>
// // // // // //     </div>
// // // // // //   );
// // // // // // };

// // // // // // export default ReviewPage;



// // // // // // import React from 'react';
// // // // // // import { Typography, Grid, Paper } from '@mui/material';
// // // // // // import { useStepperContext } from './StepperContext'; // Import context hook or use local state

// // // // // // const ReviewPage = () => {
// // // // // //   const { addUserData } = useStepperContext(); // Replace with your context or state hook

// // // // // //   // Ensure addUserData is defined and contains images array
// // // // // //   if (!addUserData || !addUserData.images) {
// // // // // //     return null; // or handle the loading state or error condition as per your app's logic
// // // // // //   }

// // // // // //   const { images, ...otherData } = addUserData; // Destructure images and other data

// // // // // //   return (
// // // // // //     <div>
// // // // // //       <Typography variant="h4" gutterBottom>
// // // // // //         Review Your Data
// // // // // //       </Typography>
// // // // // //       <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
// // // // // //         <Typography variant="h6">Uploaded Images</Typography>
// // // // // //         <Grid container spacing={2} style={{ marginTop: '10px' }}>
// // // // // //           {images.map((file, index) => (
// // // // // //             <Grid item key={index}>
// // // // // //               <img src={URL.createObjectURL(file)} alt={`Uploaded ${index}`} style={{ maxWidth: '100px' }} />
// // // // // //             </Grid>
// // // // // //           ))}
// // // // // //         </Grid>
// // // // // //       </Paper>
// // // // // //       <Paper elevation={3} style={{ padding: '20px' }}>
// // // // // //         <Typography variant="h6">Other Form Data</Typography>
// // // // // //         <Grid container spacing={2} style={{ marginTop: '10px' }}>
// // // // // //           {Object.entries(otherData).map(([key, value]) => (
// // // // // //             <Grid item xs={12} md={6} key={key}>
// // // // // //               <Typography>
// // // // // //                 <strong>{key}:</strong> {value}
// // // // // //               </Typography>
// // // // // //             </Grid>
// // // // // //           ))}
// // // // // //         </Grid>
// // // // // //       </Paper>
// // // // // //     </div>
// // // // // //   );
// // // // // // };

// // // // // // export default ReviewPage;

// // // import React, { useState, useCallback, useEffect } from 'react';
// // // import { useStepperContext } from './StepperContext';
// // // import { useDropzone } from 'react-dropzone';
// // // import { Button, LinearProgress, Typography } from '@mui/material';
// // // import AWS from 'aws-sdk';

// // // interface UploadedImage {
// // //   id: number;
// // //   name: string;
// // //   file: File;
// // //   url: string;
// // //   isCover: boolean;
// // //   isVisible: boolean;
// // //   selectedTag: string | null;
// // //   progress: number;
// // // }

// // // interface UploadPhotoProps {
// // //   onNext: () => void;
// // //   onBack: () => void;
// // //   onReset: () => void;
// // // }

// // // const UploadPhoto: React.FC<UploadPhotoProps> = ({ onNext, onBack, onReset }) => {
// // //   const { addPropImage } = useStepperContext();
// // //   const [selectedFiles, setSelectedFiles] = useState<UploadedImage[]>([]);
// // //   const [previewIndex, setPreviewIndex] = useState<number | null>(null);
// // //   const [uploadQueue, setUploadQueue] = useState<UploadedImage[]>([]);

// // //   const imageTags = ['hall', 'kitchen', 'room', 'balcony'];

// // //   const s3 = new AWS.S3({
// // //     region: 'us-east-1',
// // //     accessKeyId: 'AKIAQ3EGVP6CLIGM245V',
// // //     secretAccessKey: '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',
// // //   });

// // //   const generateUniqueNumber = () => {
// // //     return Math.floor(Math.random() * 100000);
// // //   };

// // //   const handleUploadChange = (files: File[]) => {
// // //     if (files.length === 0) return;

// // //     const maxFiles = 6;
// // //     const newFiles: File[] = files.slice(0, maxFiles - selectedFiles.length);

// // //     if (selectedFiles.length + newFiles.length > maxFiles) {
// // //       alert(`You can upload a maximum of ${maxFiles} files.`);
// // //       return;
// // //     }

// // //     const newSelectedFiles = newFiles.map((file) => ({
// // //       id: generateUniqueNumber(),
// // //       name: file.name,
// // //       file,
// // //       url: URL.createObjectURL(file),
// // //       isCover: false,
// // //       isVisible: true,
// // //       selectedTag: null,
// // //       progress: 0,
// // //     }));

// // //     setSelectedFiles((prevFiles) => {
// // //       const updatedFiles = [...prevFiles, ...newSelectedFiles];
// // //       return updatedFiles;
// // //     });

// // //     setUploadQueue((prevQueue) => [...prevQueue, ...newSelectedFiles]);
// // //   };

// // //   const uploadImageToS3 = async (file: UploadedImage) => {
// // //     const fileName = `image_${Date.now()}.jpg`;
// // //     const params = {
// // //       Bucket: 'vattara-image',
// // //       Key: fileName,
// // //       Body: file.file,
// // //       ContentType: file.file.type,
// // //     };

// // //     try {
// // //       const uploadPromise = s3.upload(params)
// // //         .on('httpUploadProgress', (event) => {
// // //           const percent = Math.round((event.loaded / event.total) * 100);
// // //           setSelectedFiles((prevFiles) =>
// // //             prevFiles.map((f) =>
// // //               f.id === file.id ? { ...f, progress: percent } : f
// // //             )
// // //           );
// // //         })
// // //         .promise();

// // //       const uploadResponse = await uploadPromise;

// // //       setSelectedFiles((prevFiles) =>
// // //         prevFiles.map((f) =>
// // //           f.id === file.id ? { ...f, url: uploadResponse.Location } : f
// // //         )
// // //       );

// // //       addPropImage(selectedFiles); // Call addPropImage with current selected files
// // //     } catch (error) {
// // //       console.error(`Error uploading ${file.file.name} to S3: ${error.message}`);
// // //     }
// // //   };

// // //   const processUploadQueue = useCallback(async () => {
// // //     if (uploadQueue.length === 0) return;

// // //     for (const file of uploadQueue) {
// // //       await uploadImageToS3(file);
// // //     }

// // //     // Clear the queue after processing
// // //     setUploadQueue([]);
// // //   }, [uploadQueue, selectedFiles, addPropImage]);

// // //   // Trigger the queue processing whenever new files are added
// // //   useEffect(() => {
// // //     processUploadQueue();
// // //   }, [processUploadQueue]);

// // //   const handleSetCover = (id: number) => {
// // //     const updatedFiles = selectedFiles.map((file) => ({
// // //       ...file,
// // //       isCover: file.id === id,
// // //     }));
// // //     setSelectedFiles(updatedFiles);
// // //     addPropImage(updatedFiles);
// // //   };

// // //   const handleTagSelect = (id: number, tag: string) => {
// // //     const updatedFiles = selectedFiles.map((file) => ({
// // //       ...file,
// // //       selectedTag: file.id === id ? tag : file.selectedTag,
// // //     }));
// // //     setSelectedFiles(updatedFiles);
// // //     addPropImage(updatedFiles);
// // //   };

// // //   const handleRemove = (id: number) => {
// // //     const updatedFiles = selectedFiles.filter((file) => file.id !== id);
// // //     setSelectedFiles(updatedFiles);
// // //     addPropImage(updatedFiles);
// // //   };

// // //   const { getRootProps, getInputProps } = useDropzone({
// // //     onDrop: (acceptedFiles) => handleUploadChange(acceptedFiles),
// // //     accept: 'image/*',
// // //     maxFiles: 6,
// // //   });

// // //   // Load uploaded images from session storage on mount
// // //   useEffect(() => {
// // //     const storedImages = sessionStorage.getItem('uploadedImages');
// // //     if (storedImages) {
// // //       setSelectedFiles(JSON.parse(storedImages));
// // //     }
    
// // //     // Clear session storage on component mount
// // //     sessionStorage.removeItem('uploadedImages');
// // //   }, []);

// // //   // Reset uploaded images when user clicks the reset button
// // //   const handleReset = () => {
// // //     setSelectedFiles([]);
// // //     setUploadQueue([]);
// // //     sessionStorage.removeItem('uploadedImages');
// // //     onReset();
// // //   };

// // //   return (
// // //     <div>
// // //       <div {...getRootProps()} style={{ border: '2px dashed #cccccc', padding: '20px', textAlign: 'center' }}>
// // //         <input {...getInputProps()} />
// // //         <p>Drag 'n' drop some files here, or click to select files</p>
// // //       </div>

// // //       <div className="image-preview-container">
// // //         {selectedFiles.map((file) => (
// // //           <div key={file.id} className="image-preview">
// // //             <img src={file.url} alt={file.name} onClick={() => setPreviewIndex(file.id)} />
// // //             <Button onClick={() => handleSetCover(file.id)}>
// // //               {file.isCover ? 'Cover Image' : 'Set as Cover'}
// // //             </Button>
// // //             <select onChange={(e) => handleTagSelect(file.id, e.target.value)} value={file.selectedTag || ''}>
// // //               <option value="" disabled>Select Tag</option>
// // //               {imageTags.map((tag) => (
// // //                 <option key={tag} value={tag}>{tag}</option>
// // //               ))}
// // //             </select>
// // //             <Button onClick={() => handleRemove(file.id)}>Remove</Button>
// // //             <LinearProgress variant="determinate" value={file.progress} />
// // //             <Typography variant="body2" color="textSecondary">{file.progress}%</Typography>
// // //           </div>
// // //         ))}
// // //       </div>

// // //       <div className="navigation-buttons">
// // //         <Button onClick={onBack}>Back</Button>
// // //         <Button onClick={onNext}>Next</Button>
// // //         <Button onClick={handleReset}>Reset</Button>
// // //       </div>
// // //     </div>
// // //   );
// // // };

// // // export default UploadPhoto;
// import React, { useState, useCallback, useEffect } from 'react';
// import { useStepperContext } from './StepperContext';
// import { useDropzone } from 'react-dropzone';
// import { Button, LinearProgress, Typography } from '@mui/material';
// import AWS from 'aws-sdk';

// interface UploadedImage {
//   id: number;
//   name: string;
//   file: File;
//   url: string;
//   isCover: boolean;
//   isVisible: boolean;
//   selectedTag: string | null;
//   progress: number;
// }

// interface UploadPhotoProps {
//   onNext: () => void;
//   onBack: () => void;
//   onReset: () => void;
// }

// const UploadPhoto: React.FC<UploadPhotoProps> = ({ onNext, onBack, onReset }) => {
//   const { propImages, addPropImage } = useStepperContext();
//   const [selectedFiles, setSelectedFiles] = useState<UploadedImage[]>(propImages);
//   const [uploadQueue, setUploadQueue] = useState<UploadedImage[]>([]);

//   const imageTags = ['hall', 'kitchen', 'room', 'balcony'];

//   const s3 = new AWS.S3({
//     region: 'us-east-1',
//     accessKeyId: 'AKIAQ3EGVP6CLIGM245V',
//     secretAccessKey: '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',
//   });

//   const generateUniqueNumber = () => Math.floor(Math.random() * 100000);

//   const handleUploadChange = (files: File[]) => {
//     if (files.length === 0) return;

//     const maxFiles = 6;
//     const newFiles: File[] = files.slice(0, maxFiles - selectedFiles.length);

//     if (selectedFiles.length + newFiles.length > maxFiles) {
//       alert(`You can upload a maximum of ${maxFiles} files.`);
//       return;
//     }

//     const newSelectedFiles = newFiles.map((file) => ({
//       id: generateUniqueNumber(),
//       name: file.name,
//       file,
//       url: URL.createObjectURL(file),
//       isCover: false,
//       isVisible: true,
//       selectedTag: null,
//       progress: 0,
//     }));

//     setSelectedFiles((prevFiles) => {
//       const updatedFiles = [...prevFiles, ...newSelectedFiles];
//       addPropImage(updatedFiles); // Update context
//       return updatedFiles;
//     });

//     setUploadQueue((prevQueue) => [...prevQueue, ...newSelectedFiles]);
//   };

//   const uploadImageToS3 = async (file: UploadedImage) => {
//     const fileName = `image_${Date.now()}.jpg`;
//     const params = {
//       Bucket: 'vattara-image',
//       Key: fileName,
//       Body: file.file,
//       ContentType: file.file.type,
//     };

//     try {
//       const uploadPromise = s3.upload(params)
//         .on('httpUploadProgress', (event) => {
//           const percent = Math.round((event.loaded / event.total) * 100);
//           setSelectedFiles((prevFiles) =>
//             prevFiles.map((f) =>
//               f.id === file.id ? { ...f, progress: percent } : f
//             )
//           );
//         })
//         .promise();

//       const uploadResponse = await uploadPromise;

//       setSelectedFiles((prevFiles) =>
//         prevFiles.map((f) =>
//           f.id === file.id ? { ...f, url: uploadResponse.Location } : f
//         )
//       );

//       addPropImage(selectedFiles); // Update context with current selected files
//     } catch (error) {
//       console.error(`Error uploading ${file.file.name} to S3: ${error.message}`);
//     }
//   };

//   const processUploadQueue = useCallback(async () => {
//     if (uploadQueue.length === 0) return;

//     for (const file of uploadQueue) {
//       await uploadImageToS3(file);
//     }

//     setUploadQueue([]); // Clear the queue after processing
//   }, [uploadQueue, selectedFiles, addPropImage]);

//   useEffect(() => {
//     processUploadQueue();
//   }, [processUploadQueue]);

//   const handleSetCover = (id: number) => {
//     const updatedFiles = selectedFiles.map((file) => ({
//       ...file,
//       isCover: file.id === id,
//     }));
//     setSelectedFiles(updatedFiles);
//     addPropImage(updatedFiles); // Update context
//   };

//   const handleTagSelect = (id: number, tag: string) => {
//     const updatedFiles = selectedFiles.map((file) => ({
//       ...file,
//       selectedTag: file.id === id ? tag : file.selectedTag,
//     }));
//     setSelectedFiles(updatedFiles);
//     addPropImage(updatedFiles); // Update context
//   };

//   const handleRemove = (id: number) => {
//     const updatedFiles = selectedFiles.filter((file) => file.id !== id);
//     setSelectedFiles(updatedFiles);
//     addPropImage(updatedFiles); // Update context
//   };

//   const { getRootProps, getInputProps } = useDropzone({
//     onDrop: (acceptedFiles) => handleUploadChange(acceptedFiles),
//     accept: 'image/*',
//     maxFiles: 6,
//   });

//   const handleReset = () => {
//     setSelectedFiles([]);
//     setUploadQueue([]);
//     addPropImage([]); // Clear images from context
//     onReset();
//   };

//   return (
//     <div>
//       <div {...getRootProps()} style={{ border: '2px dashed #cccccc', padding: '20px', textAlign: 'center' }}>
//         <input {...getInputProps()} />
//         <p>Drag 'n' drop some files here, or click to select files</p>
//       </div>

//       <div className="image-preview-container">
//         {selectedFiles.map((file) => (
//           <div key={file.id} className="image-preview">
//             <img src={file.url} alt={file.name} onClick={() => {}} />
//             <Button onClick={() => handleSetCover(file.id)}>
//               {file.isCover ? 'Cover Image' : 'Set as Cover'}
//             </Button>
//             <select onChange={(e) => handleTagSelect(file.id, e.target.value)} value={file.selectedTag || ''}>
//               <option value="" disabled>Select Tag</option>
//               {imageTags.map((tag) => (
//                 <option key={tag} value={tag}>{tag}</option>
//               ))}
//             </select>
//             <Button onClick={() => handleRemove(file.id)}>Remove</Button>
//             <LinearProgress variant="determinate" value={file.progress} />
//             <Typography variant="body2" color="textSecondary">{file.progress}%</Typography>
//           </div>
//         ))}
//       </div>

//       <div className="navigation-buttons">
//         <Button onClick={onBack}>Back</Button>
//         <Button onClick={onNext}>Next</Button>
//         <Button onClick={handleReset}>Reset</Button>
//       </div>
//     </div>
//   );
// };

// export default UploadPhoto;
import React, { useState, useEffect } from 'react';
import { useStepperContext } from './StepperContext';
import { Accept, useDropzone } from 'react-dropzone';
import { Button, LinearProgress, Typography } from '@mui/material';
import AWS from 'aws-sdk';

interface UploadedImage {
  id: number;
  name: string;
  file: File;
  url: string;
  isCover: boolean;
  isVisible: boolean;
  selectedTag: string | null;
  progress: number;
  isUploaded: boolean;
}

interface UploadPhotoProps {
  onNext: () => void;
  onBack: () => void;
  onReset: () => void;
}

const UploadPhoto: React.FC<UploadPhotoProps> = ({ onNext, onBack, onReset }) => {
  const { propImages, addPropImage,userData } = useStepperContext();
  const [selectedFiles, setSelectedFiles] = useState<UploadedImage[]>(propImages);
const proptype=userData.find((item) => item?.["Property Subtype"])?.[
  "Property Subtype"
];
  const imageTags = ['hall', 'kitchen', 'room', 'balcony'];

  const s3 = new AWS.S3({
    region: 'us-east-1',
    accessKeyId: 'AKIAQ3EGVP6CLIGM245V',
    secretAccessKey: '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',
  });

  const generateUniqueNumber = () => Math.floor(Math.random() * 100000);

  const handleUploadChange = async (files: File[]) => {
    if (files.length === 0) return;
  
    const maxFiles = 6;
    const newFiles: File[] = files.slice(0, maxFiles - selectedFiles.length);
  
    if (selectedFiles.length + newFiles.length > maxFiles) {
      alert(`You can upload a maximum of ${maxFiles} files.`);
      return;
    }
  
    const newSelectedFiles = await Promise.all(
      newFiles.map(async (file) => {
        const s3Url = await uploadImageToS3(file); // Upload the file to S3 and get the URL
  
        return {
          id: generateUniqueNumber(),
          name: file.name,
          file,
          url: s3Url, // Set the S3 URL in the object
          isCover: false,
          isVisible: true,
          selectedTag: null,
          progress: 100, // Set progress to 100% since the upload is complete
          isUploaded: true, // Mark as uploaded
        };
      })
    );
  
    setSelectedFiles((prevFiles:any) => {
      const updatedFiles = [...prevFiles, ...newSelectedFiles];
      addPropImage(updatedFiles); // Update context
      return updatedFiles;
    });
  };
  
  const uploadImageToS3 = async (file: File): Promise<string | null> => {
    const fileName = `image_${Date.now()}.jpg`;
    const params = {
      Bucket: 'vattara-image',
      Key: fileName,
      Body: file,
      ContentType: file.type,
    };
  
    try {
      const uploadPromise = s3.upload(params)
        .on('httpUploadProgress', (event) => {
          const percent = Math.round((event.loaded / event.total) * 100);
          setSelectedFiles((prevFiles) =>
            prevFiles.map((f) =>
              f.file === file ? { ...f, progress: percent } : f
            )
          );
        })
        .promise();
  
      const uploadResponse = await uploadPromise;
      return uploadResponse.Location; // Return the S3 URL
  
    } catch (error) {
      console.error(`Error uploading ${file.name} to S3: ${error}`);
      return null; // Return null if there's an error
    }
  };
  
  console.log(propImages)
  const handleSetCover = (id: number) => {
    const updatedFiles = selectedFiles.map((file) => ({
      ...file,
      isCover: file.id === id,
    }));
    setSelectedFiles(updatedFiles);
    addPropImage(updatedFiles); // Update context
  };

  const handleTagSelect = (id: number, tag: string) => {
    const updatedFiles = selectedFiles.map((file) => ({
      ...file,
      selectedTag: file.id === id ? tag : file.selectedTag,
    }));
    setSelectedFiles(updatedFiles);
    addPropImage(updatedFiles); // Update context
  };

  const handleRemove = (id: number) => {
    const updatedFiles = selectedFiles.filter((file) => file.id !== id);
    setSelectedFiles(updatedFiles);
    addPropImage(updatedFiles); // Update context
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => handleUploadChange(acceptedFiles),
    accept: {
      'image/*': [],
    } as Accept,
    maxFiles: 6,
  });

  const handleReset = () => {
    setSelectedFiles([]);
    addPropImage([]); // Clear images from context
    onReset();
  };

  return (
    <div>
      <div {...getRootProps()} style={{ border: '2px dashed #cccccc', padding: '20px', textAlign: 'center' }}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>

      <div className="image-preview-container">
        {selectedFiles.map((file) => (
          <div key={file.id} className="image-preview">
            <img src={file.url} alt={file.name} onClick={() => {}} />
            <Button onClick={() => handleSetCover(file.id)}>
              {file.isCover ? 'Cover Image' : 'Set as Cover'}
            </Button>
           {proptype !== "Vacant Land" && <select onChange={(e) => handleTagSelect(file.id, e.target.value)} value={file.selectedTag || ''}>
              <option value="" disabled>Select Tag</option>
              {imageTags.map((tag) => (
                <option key={tag} value={tag}>{tag}</option>
              ))}
            </select>}
            <Button onClick={() => handleRemove(file.id)}>Remove</Button>
            <LinearProgress variant="determinate" value={file.progress} />
            <Typography variant="body2" color="textSecondary">{file.progress}%</Typography>
          </div>
        ))}
      </div>

      <div className="navigation-buttons">
        <Button onClick={onBack}>Back</Button>
        <Button onClick={onNext}>Next</Button>
        <Button onClick={handleReset}>Reset</Button>
      </div>
    </div>
  );
};

export default UploadPhoto;


