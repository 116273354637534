import React from 'react'
import { ServiceImage } from "../data";
import ImageCarousel from './ImageCarousel';
import { axiosInstance } from "../api/APIs";
import { useNavigate } from "react-router-dom";
import MNavbar from '../homepage/MNavber';



const ReliableServices = () => {
    const navigate = useNavigate()


    async function handleServices(label: any) {
        // const url = `/services/categoryBased/userlist/${label}`
        try {

            const response = await axiosInstance.get(`/services/categoryBased/userlist/${label}`)
            console.log(response.data.message);
            navigate('/servicesDetails', { state: { data: response.data.message, category: label } });

        } catch (error) {
            console.log("error", error)
        }
    }
    return (
        <div className='bg-[#F5F4F8] w-full  min-h-screen flex flex-col'>
            <div className='bg-white xl:mx-[20%] '>
                <p className='text-center justify-center items-center text-xl msm:hidden sm:flex md:flex font-semibold p-4'>Exciting New Services Coming Your Way!</p>
                <div className=''>
                    <ImageCarousel />
                    <div className='border border-[#000000] opacity-50 mt-4 '></div>
                    <div>
                        <p className='text-left ml-4 opacity-95 lg:mt-4 msm:ml-2 sm:ml-4 text-xl font-semibold'>Find Reliable Services</p>
                        <div className='md:grid md:grid-cols-3 msm:grid msm:grid-cols-2 sm:ml-10 mt-4 lg:ml-12  xl:mx-[15%] gap-3 msm:mx-2 lg:mx-0 justify-center mb-10 items-center '>
                            {
                                ServiceImage.map((item, index) => (
                                    <div key={index} className='sm:mt-5 msm:mt-0 '>
                                        <img onClick={() => { handleServices(item.label) }} src={item.image} alt={`Service ${index}`} className='' />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={`lg:hidden sm:hidden msm:flex fixed bottom-5  z-50`}>
                <MNavbar />
            </div>
            <div>
                
            </div>
        </div>
    )
}

export default ReliableServices