import React, { useEffect, useState } from 'react'
import filter from '../../assets/filterBlack.svg'
import sort from '../../assets/sortBlack.svg'
import cancel from '../../assets/cancelBlack.svg'
import exclusive from '../../compounts/images/exclusive.png'
import { axiosInstance } from "../api/APIs";
import { useSelector } from "react-redux";
import heart from "../../compounts/images/Heart.png";
import LikedHeart from "../images/LikedHeart.png";
import loaction from '../../compounts/images/location.png'
import { useDispatch } from 'react-redux';
import { setUsers } from '../../slices/filterSlice';
import SortBy from '../sortBy/SortBy'
import Filter from '../filter/Filter'
import { useLocation } from 'react-router-dom'
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel } from "react-bootstrap";
import { log } from 'util'
import { truncateString } from "../ReuseableCompounts/TruncateString";
import star from '../images/star.svg'
import goldtick from '../images/goldtick.png'
import { formatSQPrice } from "../ReuseableCompounts/PriceSQFormat";
import { handleChange } from "../ReuseableCompounts/PostDetail";
import { useNavigate } from "react-router-dom";


interface Image {
    id?: string;
    isCover?: boolean;
    isvisible?: boolean;
    name?: string;
    type?: any
    uri?: string;
}

interface Property {
    id: number;
    post_id: number;
    property_type?: any;
    looking_to?: string;
    rent_or_lease?: string;
    property_available_for?: string;
    images?: Image[];
    view_post_count: number;
    bhk: string;
    city: string;
    building_name: string;
    landmark_street: string;
    build_up_area?: string;
    built_up_area?: string;
    plot_area?: string;
    PriceperSqft?: string;
    price: number;
    cost?: number;
    monthly_rent?: number;
    enter_lease_amount?: number;
    lease_amount?: any
    liked?: any
    user_id?: any
    district?: string;
    kbl_property: any

}


const VattaraExclusive = () => {


    const loginData = useSelector((item: any) => (item.userData.userLoginData))
    const navigate = useNavigate(

    )
    const [sortedUsers, setSortedUsers] = useState<Property[]>([]);
    const dispatch = useDispatch();
    const users = useSelector((state: any) => state.usersInfo.users);
    const [value, setValue] = useState<any>()
    const [receivedValue, setReceivedValue] = useState<any>(value);
    const [likedState, setLikedState] = useState<Record<number, boolean>>({});
    const [filterProperty, setFilterProperty] = useState<string[]>([]);
    const [filterLoaction, setfilterLoaction] = useState<any[]>([])
    const [filterPrice, setFilterPrice] = useState<any>()
    const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
    const [sortOpen, setSortOpen] = useState<any>(false)
    const [kb, setKb] = useState<any>(true)
    const location = useLocation()
    const projects = location.state && location.state.data;
    // console.log("get", projects);

    console.log(users);


    useEffect(() => {
        const fetchData = async () => {
            try {
                // const config: any = {};
                // if (loginData && loginData.user_id) {
                //     config.params = { user_id: loginData.user_id };
                // }

                const response = await axiosInstance.get(`/api/vattara/app/ResidentailSell/Property/${loginData.user_id}`);
                console.log(response.data.message);

                const rows = response.data.message;




                dispatch(setUsers(rows));





            } catch (error) {

                console.error('Error fetching data:', error);
            }
        };

        fetchData()
    }, []);




    useEffect(() => {
        setSortedUsers([...users]); // Initialize sortedUsers with users array
        // setCurrentIndexList(Array(users.length).fill(0));

        const initialLikedState = users.reduce((acc: Record<number, boolean>, user: Property) => {
            acc[user.post_id] = user.liked || false;
            return acc;
        }, {});
        setLikedState(initialLikedState);
    }, [users]);


    const handleChange = async (id: number, property: string, looking_to?: string) => {
        const body = {
            post_id: id,
            selected_property_type: property,
            looking_to: looking_to || ''
        };

        try {
            const response = await axiosInstance.post("/filter/get/postDetail", body);

            // Convert response data to a query string
            const queryString = new URLSearchParams({
                data: JSON.stringify(response.data),
                look: looking_to || ''
            }).toString();

            // Open a new window with the query parameters
            const newWindow = window.open(`/PropertyDetails?${queryString}`, '_blank');

            if (newWindow) {
                newWindow.focus(); // Focus the new window/tab if needed
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };



    useEffect(() => {
        const handleChildData = () => {




            if (value === "Price (Low to High)") {
                const sortedItems = [...users].sort((a, b) => {
                    const costA = parseInt(
                        String(
                            a.cost || a.price || (a.monthly_rent == null || a.monthly_rent == "" ? a.lease_amount || a.enter_lease_amount : a.monthly_rent)
                        ).replace(/[^0-9.-]+/g, ""),
                        10
                    );
                    const costB = parseInt(
                        String(
                            b.cost || b.price || (b.monthly_rent == null || b.monthly_rent == "" ? b.lease_amount || b.enter_lease_amount : b.monthly_rent)
                        ).replace(/[^0-9.-]+/g, ""),
                        10
                    );

                    return costA - costB || 0;
                });
                setSortedUsers(sortedItems);

            } else if (value === "Price (High to Low)") {
                const sortedItems = [...users].sort((a, b) => {
                    const costA = parseInt(
                        String(
                            a.cost || a.price || (a.monthly_rent == null || a.monthly_rent == "" ? a.lease_amount || a.enter_lease_amount : a.monthly_rent)
                        ).replace(/[^0-9.-]+/g, ""),
                        10
                    );
                    const costB = parseInt(
                        String(
                            b.cost || b.price || (b.monthly_rent == null || b.monthly_rent == "" ? b.lease_amount || b.enter_lease_amount : b.monthly_rent)
                        ).replace(/[^0-9.-]+/g, ""),
                        10
                    );

                    // Reverse the order
                    return costB - costA || 0; // Add fallback to handle undefined or NaN values
                });
                setSortedUsers(sortedItems);

            } else if (value === "Newest Listings") {
                const sortedItems = [...users].sort((a, b) => {
                    // Check for createdAt property and convert to Date objects
                    const dateA = a.createdAt ? new Date(a.createdAt) : null;
                    const dateB = b.createdAt ? new Date(b.createdAt) : null;

                    // Compare dates
                    return dateA && dateB ? dateB.getTime() - dateA.getTime() : 0; // Add fallback to handle undefined dates
                });
                setSortedUsers(sortedItems);
            } else if (value === "Older Listings") {
                const sortedItems = [...users].sort((a, b) => {
                    const dateA = a.createdAt ? new Date(a.createdAt) : null;
                    const dateB = b.createdAt ? new Date(b.createdAt) : null;

                    // Compare dates in reverse order
                    return dateB && dateA ? dateA.getTime() - dateB.getTime() : 0; // Add fallback to handle undefined dates
                });
                setSortedUsers(sortedItems);
            } else {
                setSortedUsers([...users]);
            }
        };
        handleChildData()
        console.log(sortedUsers);
    }, [value])



    const handleSaved = async (id: number, property: any, look: any, index: any) => {

        if (loginData.user_id === undefined) {
            navigate('/contact')
        } else {
            const newLikedState = !likedState[id];
            setLikedState((prevState) => ({ ...prevState, [id]: newLikedState }));

            const body = {
                post_id: id,
                liked_user_id: loginData.user_id,
                property_type: property,
                rent_or_sell: look,
                liked: newLikedState,
            };

            try {
                const response = await axiosInstance.post('/like/postLike', body);
                console.log('response', response);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };
    function valueLabelFormat(value: number) {
        if (value >= 1000000000) {
            return (value / 10000000).toFixed(2) + ' Cr';
        }
        if (value >= 10000000) {
            return (value / 10000000).toFixed(2) + ' Cr';
        }
        if (value >= 1000000) {
            return (value / 100000).toFixed(2) + ' Lac';
        }
        if (value >= 100000) {
            return (value / 100000).toFixed(2) + ' Lac';
        }
        if (value >= 10000) {
            return (value / 1000).toFixed(0) + 'k';
        }
        if (value >= 1000) {
            return (value / 1000).toFixed(1) + 'k';
        }
        return value.toString();
    }



    // Handle string price
    const formatPrice = (price: string | number): string => {
        if (typeof price === 'number') {
            return valueLabelFormat(price);
        }

        // Handle string price
        const numericPrice = parseFloat(price.replace(/[^\d.-]/g, ''));
        return valueLabelFormat(numericPrice);
    };


    const toggleFilterVisibility = () => {
        setIsFilterVisible(!isFilterVisible);
        // setSortOpen(false)

    }

    function handleSort() {
        console.log("hello");
        setIsFilterVisible(false);
        setSortOpen(!sortOpen)
    }

    const gradientBorderStyle = {
        border: '5px solid transparent',
        borderImage: 'linear-gradient(to right, #0C3D5A, #1A82C0) 1',

        borderRadius: '24px'
    };

    return (
        <div className='bg-[#E4E0EE]'>
            <div className={`${sortOpen ? "flex" : "hidden"} `}>
                <SortBy setValue={setValue} handleSort={handleSort} />
            </div>
            <div className={`transition-all duration-1000 z-60 ${isFilterVisible ? 'flex' : 'hidden'}`}>
                <Filter toggleFilterVisibility={toggleFilterVisibility} kb={kb} setFilterProperty={setFilterProperty} setfilterLoaction={setfilterLoaction} setFilterPrice={setFilterPrice} />
            </div>
            <div className='   '>
                <div className='bg-[#E4E0EE] shadow-2xl p-3 flex items-center justify-center gap-10 '>
                    <div className='text-   flex gap-[2px] items-center mt-[1%]'>
                        <div onClick={handleSort} className='bg-[#FFFFFF] text-[#202020]   py-2 px-16 flex gap-2 items-center justify-center rounded-tl-3xl rounded-bl-3xl'>
                            <img src={sort} />
                            <p >Sort by</p>
                        </div>
                        <div onClick={toggleFilterVisibility} className='bg-[#FFFFFF] text-[#202020] gap-2 flex py-2 px-16 items-center justify-center rounded-tr-3xl rounded-br-3xl'>
                            <img src={filter} />
                            <p>Filter</p>
                        </div>
                    </div>
                    <div className='flex border-[#C1C1C1] border-solid border-1 border-l h-10 mt-[1%]'></div>
                    <div className=''>
                        <p className='text-[#202020]'>You are currently searching a property at</p>
                        <div className="flex gap-4 mt-2">
                            {filterLoaction.map((item) => (
                                <div className="flex gap-1 items-center">
                                    <p className="text-[#252B5C] font-semibold">{item}</p>
                                    <img src={cancel} />
                                </div>
                            ))}
                        </div>

                    </div>
                    <div className='flex border-[#C1C1C1] border-solid border-1 border-l h-10 mt-[1%]'></div>
                    <div>
                        <p className='text-[#202020]'>Filter by</p>
                        <div className='flex gap-3 items-center'>
                            <div onClick={toggleFilterVisibility} className="flex gap-3 mt-2 items-center ">
                                {filterProperty?.length > 0 &&
                                    <div className="flex bg-[#F5F4F8]  items-center py-1 px-2 rounded-2xl gap-2">
                                        <p className="text-[#252B5C] font-medium">Property Type</p>
                                        <img src={cancel} />
                                    </div>

                                }

                                <div>
                                    {filterProperty?.length > 0 &&
                                        <div className="flex bg-[#F5F4F8] items-center py-1 px-2 rounded-2xl gap-2">
                                            <p className="text-[#252B5C] font-medium">Price Range</p>
                                            <img src={cancel} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='border-3 border-[#E4E0EE] shadow-xl'></div>
                <Carousel className="mt-1 mx-[20%] w-[60%]" indicators={false}>
                    {projects && Array.isArray(projects) && projects.length > 0 ? (
                        projects.map((item) => (
                            <Carousel.Item key={item.id} interval={2000}>
                                <div className=''>

                                    {item.images && item.images.length > 0 ? (
                                        <div className=" relative flex justify-center rounded-[10px] items-center  h-[200px] md:h-[330px] lg:h-[360px] xl:h-[400px]">
                                            <div className='absolute right-3 bottom-5   '>
                                                <p className='text-[#202020] text-4xl  font-bold capitalize'>{item.bhk} {item.property_type} for {item.looking_to || item.rent_or_lease || item.property_available_for}</p>
                                                <p className='text-[#202020] text-2xl capitalize text-end font-semibold mt-1'>  {item.district}</p>
                                                <p className='text-[#202020] text-end text-2xl mt-4 font-semibold'> {item.price || item.cost || item.enter_lease_amount || item.lease_amount || item.monthly_rent}</p>
                                            </div>
                                            <img
                                                onClick={() => { handleChange(item.post_id, item.property_type, item.looking_to || item.rent_or_lease || item.property_available_for) }}
                                                className="object-cover rounded-[10px] w-full h-full"
                                                src={item.images[0].uri}
                                                alt="Project Cover"
                                            />

                                        </div>
                                    ) : (
                                        <div className="bg-gray-200 w-full h-full flex items-center justify-center">
                                            <p>No Image Available</p>
                                        </div>
                                    )}
                                </div>
                            </Carousel.Item>
                        ))
                    ) : (
                        <div className="bg-gray-200 w-full h-full flex items-center justify-center">
                            <p>No Projects Available</p>
                        </div>
                    )}
                </Carousel>
                <div className=''>
                    <div className=' mx-[20%] mt-4'>
                        <p className='text-[#202020] text-2xl font-bold flex justify-start'>Vattara</p>
                        <p className='text-[#202020] font-semibold text-xl'>Verified Properties for sale</p>
                        <div className='grid grid-cols-3 gap-0 mt-4'>
                            {
                                sortedUsers && Array.isArray(sortedUsers) && sortedUsers.length > 0 ? (
                                    sortedUsers.map((item, index) => {
                                        const price = item.price || item.cost || item.enter_lease_amount || item.lease_amount || item.monthly_rent;
                                        const formattedPrice = formatPrice(price);
                                        const coverimg = item.images && item.images.length > 0 ? item.images.find(img => img.isCover) || "" : "";
                                        const locality = truncateString(item.city, 20)
                                        const sqtv: any = item.PriceperSqft ? item.PriceperSqft : "";
                                        const priceSQTV = formatSQPrice(sqtv);

                                        const propertyView = {
                                            postId: item.post_id,
                                            property_type: item.property_type,
                                            looking: item.looking_to || item.rent_or_lease || item.property_available_for,
                                            userId: loginData.user_id
                                        }
                                        return (
                                            <div key={index} className='relative mt- bg-[#FFFFFF]   border-[#000080] border-opacity-30 border-2 p-2 rounded-2xl w-[65%] ' >

                                                <div className='relative '>

                                                    <p className='absolute right-0 mr-1  top-3 bg-[#2E368F] text-white py-1 px-3 rounded-2xl '>{item.looking_to || item.rent_or_lease || item.property_available_for}</p>
                                                    <img onClick={() => { handleChange(item.post_id, item.property_type, item.looking_to || item.rent_or_lease || item.property_available_for) }}
                                                        className='w-[15vw] h-[20vh]  flex justify-center bg-black items-center object-contain  rounded-3xl ' src={typeof coverimg === 'object' && coverimg?.uri ? coverimg.uri : ''} />

                                                    <div className={`${item.kbl_property ? "flex" : "hidden"}  items-center bg-[#6135DD] absolute bottom-0 rounded-bl-3xl p-1 rounded-tr-3xl left-0`}>
                                                        <img className="w-5 h-5" src={star} />
                                                        <p className="text-white text-sm mr-1">VATTARA VERIFIED</p>
                                                    </div>
                                                    <img
                                                        onClick={() => handleSaved(item.post_id, item.property_type, item.looking_to || item.rent_or_lease || item.property_available_for, index)}
                                                        src={likedState[item.post_id] ? LikedHeart : heart} className='absolute top-3 left-3 text-2xl bg-[#D9D9D9] rounded-2xl'

                                                    />
                                                </div>
                                                <div className='ml-3'>
                                                    <div className='flex items-center '>
                                                        <p className='text-base flex font-bold'>{item.bhk} {item.property_type}   </p>
                                                        <img src={goldtick} />
                                                    </div>
                                                    <div className='flex gap-2 items-center'>
                                                        <img className="h-5" src={loaction} />
                                                        <p className='text-sm capitalize'>{locality}</p>

                                                    </div>

                                                    <div className='flex items-center  w-[100%]'>
                                                        <p className='text-xl font-bold text-[#404040] w-[45%]'>₹ {formattedPrice} </p>
                                                        <div className='border-[#404040] h-6 border-1 border-l  '></div>
                                                        <div className='ml-2'>
                                                            <div className='flex items-center text-sm font-medium text-[#404040]  w-[50%]'>
                                                                <p>{item.built_up_area || item.plot_area} </p>
                                                                {item.built_up_area || item.plot_area ? <p className='text-sm'>sq.ft</p> : ""}
                                                            </div>
                                                            <div className='flex items-center text-sm  font-medium text-[#404040]'>

                                                                {item.PriceperSqft ? <p>₹ {priceSQTV}</p> : ""}
                                                                {item.PriceperSqft ? <p>/sq.ft</p> : ""}
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    })
                                ) : (
                                    <p className='bg-red-800'>No properties available.</p>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VattaraExclusive