/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  Box,
  IconButton,
  Typography,
  TextField,
  CircularProgress,
  Avatar,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  Phone as PhoneIcon,
  Send as SendIcon,
} from '@mui/icons-material';
import { io, Socket } from 'socket.io-client';
import axios from 'axios';
import { Message as ChatMessage } from 'react-chat-ui'; // Import Message type
import { useChat } from '../context/ChatContext';

const ENDPOINT = 'http://192.168.1.16:5000';

interface ExtendedMessage extends ChatMessage {
  image?: string | null;
  createdAt?: Date;
  senderPic?: string;
}

interface ChatProps {
  chat: {
    chatId: string;
    userId: string;
    username: string;
    userpic: string;
    initialMessageText?: string;
    imageUrl?: { uri: string };
    postId?: null;
  };
}

const SingleChat: React.FC<ChatProps> = ({ chat }) => {
  const [socketConnected, setSocketConnected] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [newMessage, setNewMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [messages, setMessages] = useState<ExtendedMessage[]>([]);
  const [socket, setSocket] = useState<Socket | null>(null);

  const { selectedChat } = useChat();

  if (!selectedChat) {
    return <div>No chat selected</div>;
  }

  const {
    chatId,
    userId,
    username,
    userpic,
    initialMessageText = 'No message text',
    imageUrl = { uri: 'default-image-url' },
    postId = null,
  } = chat;

  const selectedChatCompare = useRef<string | null>(null);

  useEffect(() => {
    if (!userId) return;

    console.log('Establishing socket connection...');
    const newSocket = io(ENDPOINT);
    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('Socket connected');
      newSocket.emit('setup', userId);
      setSocketConnected(true);
    });

    newSocket.on('typing', () => {
      console.log('Typing event received');
      setIsTyping(true);
    });

    newSocket.on('stop typing', () => {
      console.log('Stop typing event received');
      setIsTyping(false);
    });

    newSocket.on('message received', (newMessageReceived: any) => {
      console.log('Message received:', newMessageReceived);
      const formattedMessage: ExtendedMessage = {
        id: newMessageReceived.id,
        message: newMessageReceived.content,
        createdAt: new Date(newMessageReceived.createdAt),
        senderName: newMessageReceived.sender?.name || 'Unknown',
        senderPic: newMessageReceived.sender?.user_pic || '',
        image: newMessageReceived.image || null,
      };

      if (chatId && selectedChatCompare.current === chatId) {
        setMessages((prevMessages) => [...prevMessages, formattedMessage]);
      }
    });

    if (chatId) {
      fetchMessages();
      selectedChatCompare.current = chatId;
    }

    return () => {
      if (newSocket) {
        console.log('Disconnecting socket');
        newSocket.emit('leave room', chatId);
        newSocket.off('message received');
        newSocket.off('typing');
        newSocket.off('stop typing');
      }
    };
  }, [userId, chatId]);

  const fetchMessages = useCallback(async () => {
    if (!chatId) return;

    try {
      const { data } = await axios.get(`${ENDPOINT}/api/message/${chatId}`);
      console.log('Fetched messages:', data);

      const formattedMessages = data.map((message: any) => ({
        id: message.id,
        message: message.content,
        createdAt: new Date(message.createdAt),
        senderName: message.sender?.name || 'Unknown',
        senderPic: message.sender?.user_pic || '',
        image: message.image || null,
      }));

      const sortedMessages = formattedMessages.sort(
        (a: ExtendedMessage, b: ExtendedMessage) => {
          const dateA = a.createdAt ? new Date(a.createdAt) : new Date(0);
          const dateB = b.createdAt ? new Date(b.createdAt) : new Date(0);
          return dateA.getTime() - dateB.getTime();
        }
      );

      setMessages(sortedMessages);
      setLoading(false);

      const initialMessageExists = sortedMessages.some(
        (msg: ExtendedMessage) =>
          msg.message === initialMessageText ||
          (msg.image && msg.image === imageUrl?.uri)
      );

      if (!initialMessageExists && (initialMessageText || imageUrl)) {
        handleSend([
          {
            message: initialMessageText,
            image: imageUrl?.uri,
            id: chatId,
          },
        ]);
      }

      if (socket) {
        socket.emit('join chat', chatId);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
      setLoading(false);
    }
  }, [chatId, initialMessageText, imageUrl, socket]);

  const handleSend = async (newMessages: ExtendedMessage[] = []) => {
    if (newMessages.length === 0) return;

    const newMessage = newMessages[0];

    try {
      const messageData = {
        content: newMessage.message || '',
        chatId,
        image: newMessage.image || null,
        req_user_id: userId,
        senderName: username,
        senderPic: userpic,
        post_id: postId,
      };

      console.log('Sending message data:', messageData);

      const response = await axios.post(`${ENDPOINT}/api/message`, messageData);
      console.log('Message sent:', response.data);

      const formattedMessage: ExtendedMessage = {
        id: response.data.newMessage.id,
        message: response.data.newMessage.content,
        createdAt: response.data.newMessage.createdAt
          ? new Date(response.data.newMessage.createdAt)
          : new Date(), // Fallback to current time if undefined
        senderName:
          response.data.newMessage.sender?.newMessagename ||
          username ||
          'Unknown',
        senderPic: response.data.newMessage.sender?.user_pic || userpic || '',
        image: response.data.newMessage.image || null,
      };
      console.log('Message formatted:', formattedMessage);

      setMessages((prevMessages) => [...prevMessages, formattedMessage]);

      if (socket) {
        socket.emit('new message', response.data.newMessage);
        socket.emit('stop typing', chatId);
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(event.target.value);
    if (socketConnected && userId) {
      socket?.emit('typing', chatId);
    }
  };

  const handleSubmit = () => {
    if (newMessage.trim()) {
      handleSend([
        {
          message: newMessage,
          id: chatId,
        },
      ]);
      setNewMessage('');
    }
  };

  const renderHeader = () => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: 2,
        borderBottom: '1px solid #ddd',
      }}
    >
      <IconButton onClick={() => window.history.back()} sx={{ marginRight: 2 }}>
        <ArrowBackIcon />
      </IconButton>
      <Avatar src={userpic} sx={{ marginRight: 2 }} />
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6">{username}</Typography>
        {isTyping && (
          <Typography variant="body2" color="textSecondary">
            Typing...
          </Typography>
        )}
      </Box>
      <IconButton>
        <PhoneIcon />
      </IconButton>
    </Box>
  );

  const renderMessage = (message: ExtendedMessage) => {
    const isSender = message.senderName === username;

    return (
      <Box
        key={message.id}
        sx={{
          display: 'flex',
          justifyContent: isSender ? 'flex-end' : 'flex-start',
          marginBottom: 1,
        }}
      >
        <Box
          sx={{
            maxWidth: '60%',
            padding: 1,
            borderRadius: 2,
            backgroundColor: isSender ? '#dcf8c6' : '#fff',
            boxShadow: 1,
          }}
        >
          <Typography variant="body1">{message.message}</Typography>
          {message.image && (
            <img
              src={message.image}
              alt="Message"
              style={{ maxWidth: '100%' }}
            />
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      {renderHeader()}
      <Box sx={{ flex: 1, overflowY: 'auto', padding: 2 }}>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          messages.map(renderMessage)
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 1,
          borderTop: '1px solid #ddd',
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={newMessage}
          onChange={handleInputChange}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
        />
        <IconButton onClick={handleSubmit}>
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default SingleChat;
