import React from 'react'
import plan1 from "../images/plan1.png";
import plan2 from "../images/plan2.png";
import sub1 from "../images/sub1.svg";
import sub2 from '../images/sub2.svg'
import phone from "../images/Phonesub.png";

const Subscription = () => {
    return (
        <div className='bg-[#2E368F] min-h-screen '>
            <div className='bg-black min-h-screen mx-[20%]  rounded-xl '>
                <div className='pt-[15%] mx-[5%]'>
                    <p className='text-white text-4xl font-semibold'>Choose a plan</p>
                    <div className='flex justify-between items-center'>
                        <div className='pt-10'>
                            <p className='text-white pt-4 '>Plan for Property Seekers</p>
                            <img className='mt-6' src={sub1} />
                        </div>
                        <div className='border-white border-1 h-[390px]  mt-16'></div>
                        <div className='pt-10'>
                            <p className='text-white pt-4 '>Plan for Property Holders</p>
                            <img className='mt-6' src={sub2} />
                        </div>
                    </div>
                </div>
                <div className='flex justify-center items-center gap-1 mt-10 '>
                    <p className=' text-sm text-white '>Need help? </p>
                    <p className='  text-sm text-[#006CE7]'>Request Callback</p>
                    <img className='' src={phone}/>
                </div>
            </div>

        </div>
    )
}

export default Subscription