import React, { useState } from 'react'
import Map from './Map'
import Search from './Search'
import Scroll from './Scroll'

const Property = () => {
  const[sortOpen,setSortOpen]=useState<any>(false)
  const  [value,setValue]=useState<any>()
  return (
    <div><Map><Search sortOpen={sortOpen} setSortOpen={setSortOpen} setValue={setValue}/><Scroll value={value}/></Map></div>
  )
}

export default Property