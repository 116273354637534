import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader,
} from '@react-google-maps/api';
import mapIn from './images/mapIn.png';

interface MapProps {
  children: React.ReactNode;
}

interface MarkerData {
  title: string;
  latitude: number;
  longitude: number;
  image?: string;
  builtup: string;
  price: string;
  district: string;
}

const Map: React.FC<MapProps> = ({ children }) => {
  const [isMapVisible, setIsMapVisible] = useState<boolean>(true);
  const [isListVisible, setIsListVisible] = useState<boolean>(false);
  const [activeButton, setActiveButton] = useState<string>('Map');
  const [mapCenter, setMapCenter] = useState<
    google.maps.LatLngLiteral | undefined
  >(undefined);
  const [markers, setMarkers] = useState<MarkerData[]>([]);
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState<number | null>(
    null
  );
  const [addresses, setAddresses] = useState<string[]>([]);
  const [geofenceRegions, setGeofenceRegions] = useState<any[]>([]);
  const scrollViewRef = useRef<any>(null);

  const users = useSelector((state: any) => state.usersInfo.users);
  const apiKey = 'AIzaSyDz2gppwqEkyckilX-FDJkvgEtcoS2OEvE';

  const { isLoaded } = useJsApiLoader({ googleMapsApiKey: apiKey });

  const fetchAddress = async (latitude: number, longitude: number) => {
    // Replace with actual geocoding API call
    return 'Sample Address';
  };

  const fetchAddresses = async () => {
    const fetchedAddresses = await Promise.all(
      markers.map(async (marker) =>
        fetchAddress(marker.latitude, marker.longitude)
      )
    );
    setAddresses(fetchedAddresses);
  };

  useEffect(() => {
    if (users.length > 0) {
      const newMarkers = users.map((user: any) => ({
        title: `${user.bhk ? `${user.bhk} ` : ''}${user.property_type}`,
        latitude: parseFloat(user.latitude),
        longitude: parseFloat(user.longitude),
        image: user.images?.find((img: any) => img.isCover)?.uri,
        builtup: `${user.built_up_area} | ${
          user.PriceperSqft ? `${user.PriceperSqft}/sqft` : ''
        }`,
        price: user.cost || user.price,
        district: `${user.sublocality}, ${user.district}`,
      }));

      setMarkers(newMarkers);
      setMapCenter({
        lat: newMarkers[0]?.latitude || 0,
        lng: newMarkers[0]?.longitude || 0,
      });

      fetchAddresses();
      fetchGeofenceRegions(newMarkers);
    }
  }, [users]);

  const fetchGeofenceRegions = (markers: MarkerData[]) => {
    const geofenceData = markers.map((marker, index) => ({
      id: index + 1,
      points: [
        { latitude: marker.latitude, longitude: marker.longitude - 0.01 },
        { latitude: marker.latitude, longitude: marker.longitude + 0.01 },
        {
          latitude: marker.latitude + 0.01,
          longitude: marker.longitude + 0.01,
        },
        {
          latitude: marker.latitude + 0.01,
          longitude: marker.longitude - 0.01,
        },
      ],
      name: marker.title,
    }));
    setGeofenceRegions(geofenceData);
  };

  const isPointInsidePolygon = (
    point: { latitude: number; longitude: number },
    polygon: any[]
  ) => {
    const x = point.latitude;
    const y = point.longitude;

    let inside = false;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const xi = polygon[i].latitude;
      const yi = polygon[i].longitude;
      const xj = polygon[j].latitude;
      const yj = polygon[j].longitude;

      const intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  };

  const handleMarkerClick = (index: number) => {
    setSelectedMarkerIndex(index);
    setMapCenter({
      lat: markers[index].latitude,
      lng: markers[index].longitude,
    });

    scrollViewRef.current?.scrollTo({ x: index * 370, animated: true });

    const selectedMarker = markers[index];
    checkGeofenceRegions(selectedMarker);
  };

  const checkGeofenceRegions = (selectedMarker: MarkerData) => {
    geofenceRegions.forEach((geofence) => {
      if (
        isPointInsidePolygon(
          {
            latitude: selectedMarker.latitude,
            longitude: selectedMarker.longitude,
          },
          geofence.points
        )
      ) {
        console.log(`Entered ${geofence.name}`);
      }
    });
  };

  const toggleMapVisibility = (buttonId: string) => {
    setIsMapVisible(true);
    setIsListVisible(false);
    setActiveButton(buttonId === 'Map' ? 'Map' : 'List');
  };

  const toggleListVisibility = (buttonId: string) => {
    setIsMapVisible(false);
    setIsListVisible(true);
    setActiveButton(buttonId === 'List' ? 'List' : 'Map');
  };

  return (
    <div className="relative">
      <div className="Z-10 mt-1 lg:flex">
        {/* Map View */}
        <div
          className={`lg:flex  lg:w-[70%] ${isMapVisible ? 'flex' : 'hidden'}`}
        >
          {isLoaded && mapCenter && (
            <GoogleMap
              center={mapCenter}
              zoom={13}
              mapContainerStyle={{ height: '94vh', width: '100%' }}
            >
              {markers.map((marker, index) => (
                <Marker
                
                  key={index}
                  position={{ lat: marker.latitude, lng: marker.longitude }}
                  onClick={() => handleMarkerClick(index)}
                />
              ))}

              {selectedMarkerIndex !== null && (
                <InfoWindow
                  position={{
                    lat: markers[selectedMarkerIndex].latitude,
                    lng: markers[selectedMarkerIndex].longitude,
                  }}
                  onCloseClick={() => setSelectedMarkerIndex(null)}
                >
                  <div
                    className={`p-2 flex gap-2.5 transition-height duration-300 ${
                      selectedMarkerIndex === null ? 'h-[35px]' : 'h-[auto]'
                    }`}
                  >
                    <div>
                      {markers[selectedMarkerIndex]?.image && (
                        <img
                          src={markers[selectedMarkerIndex].image}
                          alt="Property"
                          style={{
                            width: '180px',
                            height: '100%',
                            borderRadius: 5,
                          }}
                        />
                      )}
                    </div>
                    <div className="">
                    <h4 className="font-bold text-[#252B5C]  text-lg">
                        {markers[selectedMarkerIndex]?.title}
                      </h4>
                      <p>{markers[selectedMarkerIndex]?.district}</p>
                      <p>Price: {markers[selectedMarkerIndex]?.price}</p>
                      <p>{markers[selectedMarkerIndex]?.builtup}</p>
                    </div>
                  </div>
                </InfoWindow>
              )}
            </GoogleMap>
          )}
          <img
            src={mapIn}
            className="absolute left-16 bottom-4 lg:left-[28%] lg:bottom-5"
            alt="Map decoration"
          />
        </div>

        {/* List View */}
        <div
          className={`lg:w-[55%] lg:grid ${
            isListVisible ? 'msm:grid sm:grid' : 'hidden'
          } `}
        >
          {React.Children.map(children, (child, index) =>
            React.cloneElement(child as React.ReactElement, {
              isSelected: index === selectedMarkerIndex,
              onClick: () => handleMarkerClick(index),
            })
          )}
        </div>
      </div>

      {/* Buttons */}
      <div className="bg-[#F5F4F8] msm:flex md:hidden z-60 fixed w-[100%] bottom-5  justify-center items-center">
        <div className="bg-white p-1 rounded-full border border-[#D6D3D1] flex flex-row">
          <button
            className={`lg:flex py-2 px-1 rounded-2xl ${
              activeButton === 'Map'
                ? 'bg-red-500 text-white'
                : 'bg-white text-black'
            }`}
            onClick={() => toggleMapVisibility('Map')}
          >
            Map
          </button>
          <button
            className={`lg:hidden py-2 px-1 rounded-2xl ${
              activeButton === 'List'
                ? 'bg-red-500 text-white'
                : 'bg-white text-black'
            }`}
            onClick={() => toggleListVisibility('List')}
          >
            List
          </button>
          <button
            className={`lg:hidden py-2 px-1 rounded-2xl ${
              activeButton === 'Map'
                ? 'bg-red-500 text-white'
                : 'bg-white text-black'
            }`}
            onClick={() => toggleMapVisibility('Map')}
          >
            Map
          </button>
        </div>
      </div>
    </div>
  );
};

export default Map;
