
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import MessageIcon from "../images/MessageIcon.png";
import { Avatar, Box, Button, Modal, Card, CardActions, CardContent, CardMedia, Checkbox, Divider, FormControlLabel, Icon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Default from "../images/default.png";
import AD from "../images/AD.png"
// import MessageIcon from "./images/chat.png";
import phone from '../images/phone.png';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import { useSelector } from 'react-redux';
import Axios from 'axios';
import { axiosInstance } from '../api/APIs';
import view from "../images/view.png";


import heart from '../images/Heart.png'
import LikedHeart from '../images/LikedHeart.png'
import { useLocation } from "react-router-dom";
import { dataAmenities } from '../data';
import { truncateString } from "../ReuseableCompounts/TruncateString";
import star from '../images/star.svg'
import { formatPrice } from "../ReuseableCompounts/PriceFormat";
import { formatSQPrice } from "../ReuseableCompounts/PriceSQFormat";
import DefultScroll from '../images/defaultScroll.svg'
import noPer from "../images/noProperty.svg";
// import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { getResponsiveSettings } from "../ReuseableCompounts/CarouselResponsive";
import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel } from "react-bootstrap";
import goldtick from '../images/goldtick.png'
import profile from '../images/profile.png'
import chat from '../images/chat.svg'

import loaction from '../images/location.png'


interface SimilarProperty {
    message: Property[];
    userDetails: {
        user_id: any;
        name: any;
        mobile_no: any
        category: any
        user_pic: any
    }
}
interface Property {
    id: number;
    post_id: number;
    property_type?: any;
    looking_to?: string;
    rent_or_lease?: string;
    property_available_for?: string;
    images?: Image[];
    view_post_count: number;
    bhk: string;
    city: string;
    building_name: string;
    landmark_street: string;
    build_up_area?: string;
    built_up_area?: string;
    plot_area?: string;
    PriceperSqft?: string;
    price: number;
    cost?: number;
    monthly_rent?: number;
    enter_lease_amount?: number;
    lease_amount?: any
    liked?: any
    user_id?: any
    kbl_property:any

}
interface Image {
    filename: string;
    id: string;
    isCover: boolean;
    isvisible: boolean;
    name: string;
    type: string;
    uri: any;
  }
interface scroll {

}

const Builder: React.FC<scroll> = () => {
    const location = useLocation()
    const users = location.state.rows
    const look = location.state.look
    console.log(look);

    const loginData = useSelector((state: any) => state.userData.userLoginData)
    const navigate = useNavigate();
    const [currentIndexList, setCurrentIndexList] = useState<number[]>([]);
    const [ad, setAd] = useState<any[]>([])
    const [sortedUsers, setSortedUsers] = useState<Property[]>([]);
    const [likedState, setLikedState] = useState<Record<number, boolean>>({});
    const [like, setlike] = useState<any>(users.map((item: any) => (item.liked)))
    const [similarProperty, setsimilarProperty] = useState<SimilarProperty>({
        message: [],
        userDetails: {
            user_id: '',
            name: '',
            mobile_no: '',
            category: '',
            user_pic: ''

        }
    });
    const [open, setOpen] = useState<any>(false);

    const handleClose = () => {
        // console.log("jheki");

        setOpen(false);
    }

    useEffect(() => {
        setSortedUsers([...users]); // Initialize sortedUsers with users array
        setCurrentIndexList(Array(users.length).fill(0));

        const initialLikedState = users.reduce((acc: Record<number, boolean>, user: Property) => {
            acc[user.post_id] = user.liked || false;
            return acc;
        }, {});
        setLikedState(initialLikedState);
    }, [users]);



    const handleChange = async (id: number, property: string, looking_to?: string) => {
        const body = {
            post_id: id,
            selected_property_type: property,
            looking_to: looking_to || ''
        };

        try {
            const response = await axiosInstance.post("/filter/get/postDetail", body);
            navigate('/BuilderProperty', { state: { data: response.data, look: looking_to } })
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };




    async function handleContact(property: any, looking: any, user: any, post: any) {
        console.log(property, looking);

        if (!loginData || loginData.phone === "" || loginData.phone === null) {
            navigate('/contact');
        } else {
            console.log("work");
            setOpen(true);

            const body = {
                user_id: loginData.user_id,
                property_type: property,
                looking_to: looking,
                post_id: post,
                post_user_id: user,
            };
            console.log(body);

            try {
                const response = await axiosInstance.post("/filter/contactUserDetail", body);
                setsimilarProperty(response.data);
                console.log(response.data);
                console.log(similarProperty);
            } catch (error) {
                console.error('Error fetching data:', error);
            }

            try {
                const Response = await axiosInstance.get(`/api/ads/allAds/${'Property Contact View Ads'}`)
                setAd(Response.data.message)
                console.log(Response.data.message)
              } catch (error) {
                console.log("error", error)
              }
        }
    }
    // console.log(liked);

    const handleSaved = async (id: number, property: any, look: any, index: any) => {

      if (loginData.user_id===undefined) {
        navigate('/contact')
      } else {
        const newLikedState = !likedState[id];
        setLikedState((prevState) => ({ ...prevState, [id]: newLikedState }));
        if (like === index) {
          setlike(false);
        } else {
          setlike(index);
        }
        const body = {
          post_id: id,
          liked_user_id: loginData.user_id,
          property_type: property,
          rent_or_sell: look,
          liked: newLikedState,
        };
  
        try {
          const response = await axiosInstance.post('/like/postLike', body);
          console.log('response', response);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };
    console.log(sortedUsers);

    function valueLabelFormat(value: number) {
        if (value >= 1000000000) {
            return (value / 10000000).toFixed(2) + ' Cr';
        }
        if (value >= 10000000) {
            return (value / 10000000).toFixed(2) + ' Cr';
        }
        if (value >= 1000000) {
            return (value / 100000).toFixed(2) + ' Lac';
        }
        if (value >= 100000) {
            return (value / 100000).toFixed(2) + ' Lac';
        }
        if (value >= 10000) {
            return (value / 1000).toFixed(0) + 'k';
        }
        if (value >= 1000) {
            return (value / 1000).toFixed(1) + 'k';
        }
        return value.toString();
    }



    // Handle string price
    const formatPrice = (price: string | number): string => {
        if (typeof price === 'number') {
            return valueLabelFormat(price);
        }

        // Handle string price
        const numericPrice = parseFloat(price.replace(/[^\d.-]/g, ''));
        return valueLabelFormat(numericPrice);
    };


    function handlechat() {
        navigate('/all-chat')
    }

    return (
        <div className=' mt-2 xl:mx-[15%]'>
            <div className='bg-[#F5F4F8] p-3'>
                <div className='flex gap-3 ml-5'>
                    <Avatar className='' alt={look?.name} src={look?.company_logo} sx={{ width: 56, height: 56 }} />
                    <div>
                        <p className='text-lg font-semibold'>{look.company_name}</p>
                        <div className='flex items-center gap-1'>
                            <p>{look.category}</p>
                            <p className='border-1 border-black h-4 opacity-50'></p>
                            <p>+91 91*******01</p>
                        </div>

                    </div>

                </div>
                <div className='border-1 border-[#8E8E8E] opacity-75 my-2 ml-5'></div>
                <p className='ml-5 text-lg font-semibold'>Properties listed by <span>{look.company_name}</span></p>
            </div>

            <div className='lg:grid  msm:flex msm:flex-col lg:grid-cols-3 gap-3 rounded-xl relative lg:whitespace-nowrap bg-white mt-2 p-4 '  >
                {sortedUsers && sortedUsers.length > 0 && users !== 'No property' ? (
                    sortedUsers.map((item: Property, index: number) => {
                        const price: any = item.price || item.cost || item.enter_lease_amount || item.lease_amount || (item.monthly_rent ? `${item.monthly_rent} / Month` : null);
                        const formattedPrice = formatPrice(price);
                        const coverimg: any = item.images && item.images.length > 0 ? item.images.find(img => img.isCover) || "" : "";
                        const sqtv: any = item.PriceperSqft ? item.PriceperSqft : "";
                        const priceSQTV = formatSQPrice(sqtv);

                        const locality = truncateString(item.city, 28)



                        return (

                            <div className=' xl:h-[51%]' key={item.post_id}>
                                <div className='bg-[#F5F5F5] relative p-2  rounded-2xl'>
                                    <div className='relative rounded-xl bg-black'>

                                        <img
                                            className='mr-3 z-60 h-[26vh] rounded-2xl object-contain mt-1 msm:w-full md:w-full sm:w-full cursor-pointer'
                                            onClick={() => { handleChange(item.post_id, item.property_type, item.looking_to || item.rent_or_lease || item.property_available_for) }}

                                            src={typeof coverimg === 'object' && coverimg?.uri ? coverimg.uri : DefultScroll}

                                            alt={`Property ${index} Image`}
                                        />
                                        <div className={`${item.kbl_property ? "flex" : "hidden"} z-10 items-center bg-[#6135DD] absolute bottom-0 rounded-bl-3xl p-1 rounded-tr-3xl left-0`}>
                                            <img className="w-5 h-5" src={star} />
                                            <p className="text-white text-sm mr-1">VATTARA VERIFIED</p>
                                        </div>
                                        <img
                                            onClick={() => handleSaved(item.post_id, item.property_type, item.looking_to || item.rent_or_lease || item.property_available_for, index)}
                                            src={likedState[item.post_id] ? LikedHeart : heart} className='absolute top-3 left-3 text-2xl bg-[#D9D9D9] rounded-2xl'

                                        />

                                    </div>
                                    <div className='absolute rounded-bl-xl h-6 top-7 right-2 flex items-center justify-center text-white bg-[#234F68B2] opacity-90 p-1'>
                                        <img src={view} className='mr-0.5' alt="View Icon" />
                                        <p>{item.view_post_count} Viewers</p>
                                    </div>
                                    <div>
                                        <div className='w-[100%] justify-between items-center mt-1 flex'>
                                            <div className='capitalize w-[70%] text-[#252B5C] ml-1 justify-center flex flex-col'>

                                                <div className='flex items-center w-[]'>
                                                    <p className='text-lg flex font-semibold'>{item.bhk} {item.property_type}   </p>
                                                    <img src={goldtick} />
                                                </div>
                                                <div>
                                                    <p className='text-sm text-[#404040]'>{locality} </p>
                                                </div>
                                            </div>

                                            <div className='flex gap-3 w-[40%]  '>
                                                <div className="border-solid border-0.5 border-l border-[#404040] lg:h-10 xl:my- my-"></div>
                                                <div className='flex flex-col '>
                                                    <div className='flex  gap-1'>
                                                        <p className='font-normal flex text-[#404040] '>{item.build_up_area || item.built_up_area} {item.plot_area}</p>
                                                        <p className='text-[#404040]'> sq.ft.</p>
                                                    </div>
                                                    <div className='flex gap-1'>

                                                        {item.PriceperSqft ? <p className='text-[#404040]'>RS.{priceSQTV}</p> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex justify-between ml-2 items-center mb-1'>
                                            <div>
                                                <p className='text-xl'>₹ {formattedPrice}</p>
                                            </div>
                                            <div className='flex items-center  gap-3'>
                                                <div onClick={handlechat} className='flex bg-[#D9D9D9] p-2 rounded-lg gap-1 items-center'>
                                                    <img className='h-4' src={MessageIcon} alt='Chat Icon' />
                                                </div>
                                                <button onClick={() => handleContact(
                                                    item.property_type,
                                                    item.looking_to || item.rent_or_lease || item.property_available_for, item.user_id, item.post_id
                                                )} className='h-7 p-1  rounded-lg flex gap-1 items-center  bg-[#006CE7] ' >
                                                    <p className='font-normal text-base p-1 text-[#FFF] opacity-95'>Contact</p>
                                                    <img src={phone} alt='Phone Icon' className='h-' />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className='flex absolute top-4 justify-center items-center '>
                        <img src={noPer} />
                        <p className='absolute bg-[#D1D1D1] py-3 px-6 rounded-3xl'>Can’t found properties nearby you</p>
                    </div>
                )}
                <div className='md:hidden sm:hidden hidden lg:hidden xl:grid fixed z-10'>

                </div>
            </div>
            <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="contact-modal-title"
        aria-describedby="contact-modal-description"
      >
        <Box >
          <div className='absolute top-[7%] right-[5%] w-[35%] xl:mx z-20 transform rounded-2xl bg-white   outline-none'>
            <div className="z-20  ">
              <div className='flex mt-10 flex-col mx-[20%]'>
                <div className='flex  gap-10'>
                  <div>
                    <img className='w-20 h-20 bg-black rounded-full' src={similarProperty.userDetails.user_pic ? similarProperty.userDetails.user_pic : profile} alt="User" />
                  </div>
                  <div>
                    <p className='text-lg font-semibold'>{similarProperty.userDetails?.name}</p>
                    <p>{similarProperty.userDetails?.category}</p>
                    <p className='text-lg font-semibold'>{`+91 ${similarProperty.userDetails?.mobile_no?.slice(3)}`}</p>
                  </div>
                </div>

                <div className='flex mt-10 justify-between' >
                  <div className='bg-[#2E368F] py-3 px-16 gap-1 rounded-full flex justify-center items-center'>
                    <img src={phone} alt="Phone" />
                    <p className='text-white'>Call</p>
                  </div>
                  <div onClick={handlechat} className='bg-[#2E368F] py-3 px-16 gap-1 rounded-full flex justify-center items-center'>
                    <img src={chat} alt="Chat" />
                    <p className='text-white flex items-center justify-center'>Chat</p>
                  </div>
                </div>
              </div>

              <div className="border-solid border-1 border-t mt-3 mx-3 border-[#DBDBDB] mr- msm:text-right "></div>



              <Carousel className=" mt-4 px-3  w-[100%]" indicators={false}>
                {ad.map((item) => (
                  <Carousel.Item key={item.id} interval={2000}>
                    <Link
                      className="flex "
                      to={`/upcoming-projects/${item.id}`}
                    >
                      {item ? (
                        <img
                          className="object-cover w-full rounded-2xl h-[25vh] "
                          src={item.link}
                          alt={`Project ${item.id}`}
                        />
                      ) : (
                        <div className="bg-gray-200 w-full  h-full flex items-center justify-center">
                          <p>No Image Available</p>
                        </div>
                      )}
                    </Link>
                  </Carousel.Item>
                ))}
              </Carousel>



              <div>
                {similarProperty.message?.length > 0 && (
                  <p className='text-xl font-semibold my-4 ml-5'>Similar Properties</p>
                )}
                <div className='flex gap-3 mx-3  overflow-x-scroll w-[90%]'>
                  {similarProperty.message?.map((item: Property, index: number) => {
                    const price = item.price || item.cost || item.enter_lease_amount || item.lease_amount || item.monthly_rent;
                    const formattedPrice = formatPrice(price);
                    const coverim: any = item.images && item.images.length > 0 ? item.images.find(img => img.isCover) || "" : "";
                    const locality = truncateString(item.city, 20)
                    const sqtv: any = item.PriceperSqft ? item.PriceperSqft : "";
                    const priceSQTV = formatSQPrice(sqtv);
                    return (
                      <div key={index} className=' '>
                        <div  className='relative mt- bg-[#FFFFFF]    w-[12vw] h-[30vh] border-[#000080] border-opacity-30 border-2 p-2 rounded-2xl  '>
                          <div className='relative '>

                            <p className='absolute right-0 mr-1  top-3 bg-[#2E368F] text-white py-1 px-3 rounded-2xl '>{item.looking_to || item.rent_or_lease || item.property_available_for}</p>
                            <img onClick={() => { handleChange(item.post_id, item.property_type, item.looking_to || item.rent_or_lease || item.property_available_for) }}
                              className='w-[15vw] h-[20vh]  flex justify-center bg-black items-center object-contain  rounded-3xl ' src={typeof coverim === 'object' && coverim?.uri ? coverim.uri : ''} />

                            <div className={`${item.kbl_property ? "flex" : "hidden"}  items-center bg-[#6135DD] absolute bottom-0 rounded-bl-3xl p-1 rounded-tr-3xl left-0`}>
                              <img className="w-5 h-5" src={star} />
                              <p className="text-white text-sm mr-1">VATTARA VERIFIED</p>
                            </div>
                          </div>
                          <div className='ml-3'>
                            <div className='flex items-center '>
                              <p className='text-base flex font-bold'>{item.bhk} {item.property_type}   </p>
                              <img src={goldtick} />
                            </div>
                            <div className='flex gap-2 items-center'>
                              <img className="h-5" src={loaction} />
                              <p className='text-sm capitalize'>{locality}</p>

                            </div>

                            <div className='flex items-center gap-2 '>
                              <p className='text-xl font-bold text-[#404040] '>₹ {formattedPrice} </p>
                              <div className='border-[#404040] h-6 border-1 border-l  '></div>
                              <div className='ml-2'>
                                <div className='flex flex-wra items-center text-sm font-medium text-[#404040]  '>
                                  <p>{item.built_up_area || item.plot_area} </p>
                                  {item.built_up_area || item.plot_area ? <p className='text-sm'>sq.ft</p> : ""}
                                </div>
                                <div className='flex items-center text-sm  font-medium text-[#404040]'>

                                  {item.PriceperSqft ? <p>₹ {priceSQTV}</p> : ""}
                                  {item.PriceperSqft ? <p>/sq.ft</p> : ""}
                                </div>

                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    )
                  })
                  }
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
        </div>
    );
};

export default Builder;