import * as React from 'react';

const RemoveComponent: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.949219} width={20} height={20} rx={10} fill="#202020" />
    <path
      d="M14.2829 6.66699L7.61621 13.3337"
      stroke="#F5F4F8"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.61621 6.66699L14.2829 13.3337"
      stroke="#F5F4F8"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RemoveComponent;
