


// import React from 'react';
// import { useStepperContext } from './StepperContext';
// import { Card, Typography, Button } from '@mui/material';

// const ReviewPage: React.FC = () => {
//   const { userData, propImages, costPerBuiltUpArea, costPerBuiltUpArea2, costPerBuiltUpArea3,  } = useStepperContext();
//   const transformKeysToLowerCaseWithUnderscores = (data) => {
//     return Object.keys(data).reduce((acc, key) => {
//       const newKey = key.toLowerCase().replace(/ /g, '_');
//       acc[newKey] = data[key];
//       return acc;
//     }, {});
//   };

//   const postData = async (url, data) => {
//     try {
//       const response = await fetch(url, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(data),
//       });

//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }

//       const result = await response.json();
//       console.log('Data submitted successfully:', result);
//     } catch (error) {
//       console.error('Error submitting data:', error);
//     }
//   };

//   // Extracting user data
//   const property = userData.find((item) => item?.["Property Type"])?.["Property Type"];
//   const looking_to = userData.find((item) => item?.["Looking To"])?.["Looking To"];
//   const propertytype = userData.find((item) => item?.["Property Subtype"])?.["Property Subtype"];
//   const availablefor = userData.find((item) => item?.["Property available for"])?.["Property available for"];
//   const rooms = userData.find((item) => item?.["BHK"])?.["BHK"];
//   const project = userData.find((item) => item?.["Building/Project(Optional)"])?.["Building/Project(Optional)"];
//   const bathroom = userData.find((item) => item?.["Bathroom"])?.["Bathroom"];
//   const city = userData.find((item) => item?.['Locate Your Property'])?.["Locate Your Property"];
//   const locality = userData.find((item) => item?.["Locality"])?.["Locality"];
//   const landMark = userData.find((item) => item?.["Landmark"])?.["Landmark"];
//   const Monthly = userData.find((item) => item?.["Monthly Rent"])?.["Monthly Rent"];
//   const Lease = userData.find((item) => item?.["Lease Amount"])?.["Lease Amount"];
//   const cost = userData.find((item) => item?.['Cost'])?.['Cost'];
//   const plotinsq = userData.find((item) => item?.["Plot Area"])?.["Plot Area"];
//   const plprice = userData.find((item) => item?.['Plot Area'])?.['Plot Area'];
//   const cprice = userData.find((item) => item?.['Price'])?.['Price'];

  
  
//   const additionalDetails = {
//     balcony:  userData.find((item) => item?.["Balcony"])?.["Balcony"],
//     bathroom: userData.find((item) => item?.["Bathroom"])?.["Bathroom"],
//     parking: "Yes",
//     preferred_tenant_type: "Bachelors",
//     facing: "West",
//     carpet_area: "4000",
//     maintenance_charges: "₹ 1,000",
//     servant_room: "Yes",
//     property_description: "This is a 2BHK Residential Villa available for Lease in a well-established construction in the prime locality of Vellore, Vellore. The Villa offers generous living space ideal for families or individuals seeking comfort and convenience. The spacious unit spans 55000 sq ft, offering ample living space for comfort. The Villa faces West, ensuring abundant natural light and a pleasant living environment. The Lease amount is set at ₹ 6,65,555, providing excellent value for such a desirable area."
//   };

//   const handlePostData = () => {
//     // const transformedValues = transformKeysToLowerCaseWithUnderscores(userData);
    
//     // Prepare the data object
//     const dataToPost = {
//       user_id: "2eedf810-58c3-4604-948d-1d4e3631e897", // Ensure to extract this if it's part of userData
//       property_subtype: propertytype,
//       building___project_name__optional_: project,
//       bhk: rooms,
//       built_up_area: transformedValues.built_up_area,
//       furnish_type: transformedValues.furnish_type,
//       flat_furnishing: transformedValues.flat_furnishing,
//       society_amenities: transformedValues.society_amenities,
//       monthly_rent: transformedValues.monthly_rent,
//       available_from: availablefor,
//       security_deposit: transformedValues.security_deposit,
//       additional_details: additionalDetails,
//       latitude: transformedValues.latitude,
//       longitude: transformedValues.longitude,
//       negotiable: transformedValues.negotiable,
//       bathroom:bathroom,
//       images: propImages.map(image => image.url), // Collecting image URLs
//       locality: transformedValues.locality,
//       city: transformedValues.city,
//       state: transformedValues.state, // Ensure this is extracted if part of userData
//       kbl_property: transformedValues.kbl_property,
//       property_available_for: transformedValues.property_available_for,
//       landmark_street: transformedValues.landmark_street,
//       lease_amount: transformedValues.lease_amount,
//     };
  
//     if (property === 'Residential') {
//       // Handling Residential properties
//       if (looking_to === 'Rent/Lease') {
//         postData(
//           'http://192.168.1.13:3000/rent/resRentDetail',
//           dataToPost
//         );
//       } else if (looking_to === 'Sell') {
//         postData(
//           'http://192.168.1.13:3000/sell/resSellDetail',
//           dataToPost
//         );
//       }
//     } else if (property === 'Commercial') {
//       // Handling Commercial properties
//       if (looking_to === 'Rent/Lease') {
//         switch (property_type) {
//           case 'Office Space':
//             postData(
//               'http://192.168.1.13:3000/rent/comOffRent',
//               dataToPost
//             );
//             break;
//           case 'Retail Shop':
//           case 'Showroom':
//             postData(
//               'http://192.168.1.13:3000/rent/comShopRent',
//               dataToPost
//             );
//             break;
//           case 'Warehouse':
//             postData(
//               'http://192.168.1.13:3000/rent/comWarehouseRent',
//               dataToPost
//             );
//             break;
//           case 'Industrial Space':
//             postData(
//               'http://192.168.1.13:3000/rent/comLandRent',
//               dataToPost
//             );
//             break;
//           default:
//             console.error('Unsupported subtype for Commercial Rent/Lease:', property_type);
//         }
//       } else if (looking_to === 'Sell') {
//         switch (property_type) {
//           case 'Office Space':
//             postData(
//               'http://192.168.1.13:3000/sell/comOfficeSellPost',
//               dataToPost
//             );
//             break;
//           case 'Retail Shop':
//           case 'Showroom':
//             postData(
//               'http://192.168.1.13:3000/sell/comShopSellPost',
//               dataToPost
//             );
//             break;
//           case 'Warehouse':
//             postData(
//               'http://192.168.1.13:3000/sell/comWarehouseSellPost',
//               dataToPost
//             );
//             break;
//           case 'Industrial Space':
//             postData(
//               'http://192.168.1.13:3000/sell/comLandSellPost',
//               dataToPost
//             );
//             break;
//           default:
//             console.error('Unsupported subtype for Commercial Sell:', property_type);
//         }
//       } else {
//         console.error('Unknown transaction type for Commercial:', looking_to);
//       }
//     } else {
//       console.error('Unknown property type:', property);
//     }
//   };
 

//   const GetCoverImage = () => {
//     const Image = propImages.filter((e) => e.isCover);
//     return Image[0]?.url;
//   };


//   return (
//     <div>      <Button onClick={handlePostData}>Submit</Button>

//       <h2>Review Page</h2>
//       <Card>
//         <img src={GetCoverImage()} alt="Cover Image" style={{ width: '100%', maxWidth: '300px', marginBottom: '10px' }} />
//       </Card>
//       <Typography>Basic Details</Typography>

//       {userData.map((item, index) => (
//         <div key={index}>
//           {item.formName === 'Basic Details' && (
//             <div key={index} style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '10px' }}>
//               {Object.keys(item).map((key) => (
//                 key !== 'formName' && key !== 'id' && (
//                   <p key={key}><strong>{key}:</strong> {item[key]}</p>
//                 )
//               ))}
//             </div>
//           )}
//         </div>
//       ))}
//       <Typography>Property Details</Typography>
//       {userData.map((item, index) => (
//         <div key={index}>
//           {item.formName === 'Property Details' && (
//             <div key={index}>
//               {Object.keys(item).map((key) => (
//                 key !== 'formName' && key !== 'id' && (
//                   <p key={key}><strong>{key}:</strong> {item[key]}</p>
//                 )
//               ))}
//             </div>
//           )}
//         </div>
//       ))}
    
//       <Typography>Price Details</Typography>
//       {userData.map((item, index) => (
//         <div key={index}>
//           {item.formName === 'Price Details' && (
//             <div key={index}>
//               {Object.keys(item).map((key) => (
//                 key !== 'formName' && key !== 'id' && (
//                   <p key={key}><strong>{key}:</strong> {item[key]}</p>
//                 )
//               ))}
//             </div>
//           )}
//         </div>
//       ))}
//       <Typography>Cost per Built up Area 1: {costPerBuiltUpArea}</Typography>
//       <Typography>Cost per Built up Area 2: {costPerBuiltUpArea2}</Typography>
//       <Typography>Cost per Plot Area: {costPerBuiltUpArea3}</Typography>
//       <Typography>Additional Details</Typography>
//       {userData.map((item, index) => (
//         <div key={index}>
//           {item.formName === 'Additional Details' && (
//             <div key={index}>
//               {Object.keys(item).map((key) => (
//                 key !== 'formName' && key !== 'id' && (
//                   <p key={key}><strong>{key}:</strong> {item[key]}</p>
//                 )
//               ))}
//             </div>
//           )}
//         </div>
//       ))}
//       <Typography>Photo</Typography>
//       {propImages && propImages.length > 0 && (
//         propImages.map((item, index) => (
//           <div key={index}>
//             <img src={item.url} alt={`Image ${index}`} style={{ width: '100%', maxWidth: '300px', marginBottom: '10px' }} />
//           </div>
//         ))
//       )}
//     </div>
//   );
// };

// export default ReviewPage;





import React from 'react';
import { useStepperContext } from './StepperContext';
import { Card, Typography, Button } from '@mui/material';
import dayjs from 'dayjs';

interface ReviewPageProps {
  onNext: () => void;
  onBack: () => void;
  onReset: () => void;
}

interface PropertyDetails {
  negotiable: boolean;
  property_available_for: string;
  building___project_name__optional_ : string;
}

const ReviewPage: React.FC<ReviewPageProps> = () => {
  const {
    userData,
    propImages,
    costPerBuiltUpArea,
    costPerBuiltUpArea2,
    costPerBuiltUpArea3,
  } = useStepperContext();
  const transformKeysToLowerCaseWithUnderscores = (data: Record<string, any>): Record<string, any> => {
    return Object.keys(data).reduce((acc, key) => {
      const newKey = key.toLowerCase().replace(/ /g, "_");
      acc[newKey] = data[key];
      return acc;
    }, {} as Record<string, any>);
  };

  console.log("userData", userData);

  const postData = async (url: string, data: Record<string, any>) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Data submitted successfully:", result);
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  // Extracting user data
  const property = userData.find((item) => item?.["Property Type"])?.[
    "Property Type"
  ];
  const looking_to = userData.find((item) => item?.["Looking To"])?.[
    "Looking To"
  ];
  const property_type = userData.find((item) => item?.["Property Subtype"])?.[
    "Property Subtype"
  ];
  const available_for = userData.find(
    (item) => item?.["Property available for"]
  )?.["Property available for"];
  const rooms = userData.find((item) => item?.["BHK"])?.["BHK"];
  // const project = userData.find((item) => item?.["Building/Project(Optional)"])?.["Building/Project(Optional)"];
  const buildingProject = userData.find(
    (item) => item?.["Building/Project(Optional)"]
  )?.["Building/Project(Optional)"];
  const city = userData.find((item) => item?.["Locate Your Property"])?.[
    "Locate Your Property"
  ];
  const locality = userData.find((item) => item?.["Locality"])?.["Locality"];
  const landMark = userData.find((item) => item?.["Landmark"])?.["Landmark"];
  const Monthly = userData.find((item) => item?.["Monthly Rent"])?.[
    "Monthly Rent"
  ];
  const Lease = userData.find((item) => item?.["Lease Amount"])?.[
    "Lease Amount"
  ];
  const cost = userData.find((item) => item?.["Cost"])?.["Cost"];
  const plotinsq = userData.find((item) => item?.["Plot Area"])?.["Plot Area"];
  const plprice = userData.find((item) => item?.["Plot Area"])?.["Plot Area"];
  const cprice = userData.find((item) => item?.["Price"])?.["Price"];

  const bhk = userData.find((item) => item?.["BHK"])?.["BHK"];
  const builtUpArea = userData.find((item) => item?.["Built up Area"])?.[
    "Built up Area"
  ];
  const BuiltUpArea = userData.find((item) => item?.["Built Up Area"])?.[
    "Built Up Area"
  ];
  const furnishType = userData.find((item) => item?.["Furnish Type"])?.[
    "Furnish Type"
  ];
  const flatFurnishing = userData.find((item) => item?.["Flat Furnishing"])?.[
    "Flat Furnishing"
  ];
  const societyAmenities = userData.find(
    (item) => item?.["Society Amenities"]
  )?.["Society Amenities"];
  const monthlyRent = userData.find((item) => item?.["Monthly Rent"])?.[
    "Monthly Rent"
  ];
  console.log(monthlyRent);

  const availableFrom = userData.find((item) => item?.["Available From"])?.[
    "Available From"
  ];
  const securityDeposit = userData.find((item) => item?.["Select"])?.["Select"];
  // const additionalDetails = userData.find((item) => item?.["Additional Details"])?.["Additional Details"];
  const latitude = userData.find((item) => item?.["Latitude"])?.["Latitude"];
  const longitude = userData.find((item) => item?.["Longitude"])?.["Longitude"];
  const negotiable = userData.find((item) => item?.["Negotiable"])?.[
    "Negotiable"
  ];
  const images = userData.find((item) => item?.["Images"])?.["Images"];

  const state = userData.find((item) => item?.["State"])?.["State"];
  const kblProperty = userData.find((item) => item?.["KBL Property"])?.[
    "KBL Property"
  ];
  const propertyAvailableFor = userData.find(
    (item) => item?.["Property available for"]
  )?.["Property available for"];
  const landmarkStreet = userData.find((item) => item?.["Landmark"])?.[
    "Landmark"
  ];
  const leaseAmount = userData.find((item) => item?.["Lease Amount"])?.[
    "Lease Amount"
  ];
  console.log(leaseAmount);

  const AMENITIES = userData.find((item) => item?.["AMENITIES"])?.["AMENITIES"];
  const Furnishing = userData.find((item) => item?.["Furnishing"])?.[
    "Furnishing"
  ];
  const Construction = userData.find((item) => item?.["Construction Status"])?.[
    "Construction Status"
  ];
  const price = userData.find((item) => item?.["Cost"])?.["Cost"];
  const Price = userData.find((item) => item?.["Price"])?.["Price"];
  const width = userData.find((item) => item?.["Width"])?.["Width"];
  const Length = userData.find((item) => item?.["Length"])?.["Length"];
  const Plot = userData.find((item) => item?.["Plot Area"])?.["Plot Area"];
  const Status = userData.find((item) => item?.["Property Status"])?.[
    "Property Status"
  ];
  const Patta = userData.find((item) => item?.["Patta Available"])?.[
    "Patta Available"
  ];
  const Plot_Price = userData.find((item) => item?.["Plot Price"])?.[
    "Plot Price"
  ];
  const Possession = userData.find((item) => item?.["Possession Status"])?.[
    "Possession Status"
  ];
  const carpet_area = userData.find((item) => item?.["Carpet Area"])?.[
    "Carpet Area"
  ];
  const floor = userData.find((item) => item?.["Select Your Floor"])?.[
    "Select Your Floor"
  ];
  const Sfloor = userData.find((item) => item?.["Total Floors"])?.[
    "Total Floors"
  ];
  const facing = userData.find((item) => item?.["Facing"])?.["Facing"];
  const Lifts = userData.find((item) => item?.["Lifts"])?.["Lifts"];
  const parking = userData.find((item) => item?.["Parking"])?.["Parking"];
  const Min = userData.find((item) => item?.["Min. Number of seats"])?.[
    "Min. Number of seats"
  ];
  const Max = userData.find((item) => item?.["Max. Number of seats"])?.[
    "Max. Number of seats"
  ];
  const Washroom = userData.find((item) => item?.["Washroom"])?.["Washroom"];
  const Meeting = userData.find((item) => item?.["Meeting Room"])?.[
    "Meeting Room"
  ];
  const Condition = userData.find((item) => item?.["Property Condition"])?.[
    "Property Condition"
  ];
  const Property_available = userData.find(
    (item) => item?.["Property available for"]
  )?.["Property available for"];
  const property_description = userData.find(
    (item) => item?.["Property Description"]
  )?.["Property Description"];
  const Suitable = userData.find((item) => item?.["Suitable For"])?.[
    "Suitable For"
  ];
  const Min_Seats = userData.find((item) => item?.["Min.Number of seats"])?.[
    "Min.Number of seats"
  ];
  const Max_Seats = userData.find((item) => item?.["Max.Number of seats"])?.[
    "Max.Number of seats"
  ];

  const additionalDetails = {
    balcony: userData.find((item) => item?.["Balcony"])?.["Balcony"],
    bathroom: userData.find((item) => item?.["Bathroom"])?.["Bathroom"],
    parking: userData.find((item) => item?.["Parking"])?.["Parking"],
    preferred_tenant_type: userData.find(
      (item) => item?.["Preferred Tenant Type"]
    )?.["Preferred Tenant Type"],
    facing: userData.find((item) => item?.["Facing"])?.["Facing"],
    carpet_area: userData.find((item) => item?.["Carpet Area"])?.[
      "Carpet Area"
    ],
    maintenance_charges: userData.find(
      (item) => item?.["Maintenance Charges"]
    )?.["Maintenance Charges"],
    servant_room: userData.find((item) => item?.["Servant Room"])?.[
      "Servant Room"
    ],
    property_description: userData.find(
      (item) => item?.["Property Description"]
    )?.["Property Description"],
    eligible_for_loan: userData.find((item) => item?.["Loan Availability"])?.[
      "Loan Availability"
    ],
  };
  const formattedAvailableFrom = availableFrom
    ? dayjs(availableFrom).format("MMM DD YYYY")
    : undefined;

  const formatPriceToIndianLocale = (price : string) => {
    if (price === undefined || price === null) {
      return "";
    }

    // Convert to string and reverse for easier processing
    let str = price.toString().split("").reverse().join("");
    let formattedStr = "";

    // Add commas every 2 digits for the first segment (hundreds, thousands)
    for (let i = 0; i < str.length; i++) {
      if (i > 2 && (i - 2) % 2 === 1) {
        formattedStr += ",";
      }
      formattedStr += str[i];
    }

    // Reverse back to the original order and return
    return `₹ ${formattedStr.split("").reverse().join("")}`;
  };

  const handlePostData = () => {
    // const transformedValues = transformKeysToLowerCaseWithUnderscores(userData);

    // Prepare the data object
    // const body = {
    //   user_id: "2eedf810-58c3-4604-948d-1d4e3631e897",
    //   property_subtype: property_type,
    //   building___project_name__optional_: buildingProject,
    //   bhk: bhk,
    //   built_up_area: builtUpArea,
    //   furnish_type: furnishType,
    //   flat_furnishing: Furnishing,
    //   society_amenities: AMENITIES,
    //   monthly_rent: `₹${monthlyRent} `,
    //   available_from: formattedAvailableFrom,
    //   security_deposit: securityDeposit,
    //   additional_details: additionalDetails,
    //   latitude: 13.0012,
    //   longitude: 80.2565,
    //   negotiable: negotiable,
    //   images: propImages,
    //   locality: locality,
    //   city: city,
    //   state: "TAMILNADU",
    //   property_available_for: available_for,
    //   landmark_street: landmarkStreet,
    //   lease_amount: leaseAmount,
    //   construction_status: Construction === "Under Construction" ? formattedAvailableFrom : Construction,
    //   price: `₹${price}`,
    //   plot_price: `₹`,
    //   // width,
    //   // length,
    //   // plot_area,
    //   // measure,
    //   // approval_status,
    //   // landmark_street,
    //   // patta_available

    // }
    // console.log(body);

    const postData1 = {
      user_id: "2eedf810-58c3-4604-948d-1d4e3631e897",
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      bhk: bhk,
      built_up_area: builtUpArea,
      furnish_type: furnishType,
      flat_furnishing: Furnishing,
      society_amenities: AMENITIES,
      monthly_rent:
        available_for === "Rent" ? formatPriceToIndianLocale(monthlyRent) : "",
      available_from: formattedAvailableFrom,
      security_deposit: securityDeposit,
      additional_details: additionalDetails,
      latitude: 13.0012,
      longitude: 80.2565,
      negotiable: negotiable,
      images: propImages,
      locality: locality,
      city: city,
      state: "TAMILNADU",
      property_available_for: available_for,
      landmark_street: landmarkStreet,
      lease_amount:
        available_for === "Lease" ? formatPriceToIndianLocale(leaseAmount) : "",
    };

    const postData2 = {
      user_id: "2eedf810-58c3-4604-948d-1d4e3631e897",
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      bhk: bhk,
      built_up_area: builtUpArea,
      furnish_type: furnishType,
      flat_furnishing: Furnishing,
      society_amenities: AMENITIES,
      additional_details: property_type === "Plot" ? "" : additionalDetails,
      plot_facing: property_type === "Plot" ? facing : "",
      latitude: 13.0012,
      longitude: 80.2565,
      negotiable: negotiable,
      images: propImages,
      locality: locality,
      city: city,
      state: "TAMILNADU",
      landmark_street: landmarkStreet,
      price: formatPriceToIndianLocale(price),
      construction_status:
        Construction === "Under Construction"
          ? formattedAvailableFrom
          : Construction,
      plot_price: formatPriceToIndianLocale(Plot_Price),
      width: width,
      length: Length,
      plot_area: Plot,
      measure: "Cent",
      property_status: Status,
      patta_available: Patta,
    };

    const postData3 = {
      user_id: "2eedf810-58c3-4604-948d-1d4e3631e897",
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      possession_status:
        Possession === "Under Construction"
          ? formattedAvailableFrom
          : Possession,
      built_up_area: BuiltUpArea,
      carpet_area: carpet_area,
      monthly_rent:
        available_for === "Rent" ? formatPriceToIndianLocale(monthlyRent) : "",
      security_deposit: securityDeposit,
      negotiable: negotiable,
      total_floors: Sfloor,
      select_your_floor: floor,
      facing: facing,
      lifts: Lifts,
      parking: parking,
      min__number_of_seats: Min,
      max__number_of_seats: Max,
      washroom: Washroom,
      meeting_room: Meeting,
      property_condition: Condition,
      property_available_for: Property_available||available_for,
      additional_details: {
        amenities: Array.isArray(AMENITIES) ? AMENITIES.join(", ") : AMENITIES,
      },
      latitude: 13.0012,
      longitude: 80.2565,
      images: propImages,
      locality: locality,
      lease_amount:
        available_for === "Lease" ? formatPriceToIndianLocale(leaseAmount) : "",
      city: city,
      available_from: formattedAvailableFrom,
      landmark_street: landmarkStreet,
      property_description: property_description,
    };

    const postData4 = {
      user_id: "2eedf810-58c3-4604-948d-1d4e3631e897",
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      built_up_area: BuiltUpArea,
      possession_status:
        Possession === "Under Construction"
          ? formattedAvailableFrom
          : Possession,
      suitable_for: Suitable,
      carpet_area: carpet_area,
      negotiable: negotiable,
      security_deposit: securityDeposit,
      monthly_rent:
        available_for === "Rent" ? formatPriceToIndianLocale(monthlyRent) : "",
      facing,
      total_floors: Sfloor,
      select_your_floor: floor,
      parking: parking,
      washroom: Washroom,
      additional_details: {
        amenities: Array.isArray(AMENITIES) ? AMENITIES.join(", ") : AMENITIES,
      },
      latitude: 13.0012,
      longitude: 80.2565,
      images: propImages,
      property_available_for: available_for,
      property_description: property_description,
      locality: locality,
      city: city,
      lease_amount:
        available_for === "Lease" ? formatPriceToIndianLocale(leaseAmount) : "",
      landmark_street: landmarkStreet,
    };

    const postData5 = {
      user_id: "2eedf810-58c3-4604-948d-1d4e3631e897",
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      possession_status:
        Possession === "Under Construction"
          ? formattedAvailableFrom
          : Possession,
      built_up_area: BuiltUpArea,
      carpet_area: carpet_area,
      security_deposit: securityDeposit,
      negotiable: negotiable,
      parking: parking,
      additional_details: {
        amenities: Array.isArray(AMENITIES) ? AMENITIES.join(", ") : AMENITIES,
      },
      monthly_rent:
        available_for === "Rent" ? formatPriceToIndianLocale(monthlyRent) : "",
      facing,
      property_available_for: available_for,
      property_description: property_description,
      lease_amount:
        available_for === "Lease" ? formatPriceToIndianLocale(leaseAmount) : "",
      latitude: 13.0012,
      longitude: 80.2565,
      images: propImages,
      locality: locality,
      city: city,
      // available_from:formattedAvailableFrom ,
      landmark_street: landmarkStreet,
    };

    const postData6 = {
      user_id: "2eedf810-58c3-4604-948d-1d4e3631e897",
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      possession_status:
        Possession === "Under Construction"
          ? formattedAvailableFrom
          : Possession,
      plot_area: Plot,
      monthly_rent:
        available_for === "Rent" ? formatPriceToIndianLocale(monthlyRent) : "",
      security_deposit: securityDeposit,
      negotiable: negotiable,
      facing,
      additional_details: {
        amenities: Array.isArray(AMENITIES) ? AMENITIES.join(", ") : AMENITIES,
      },
      latitude: 13.0012,
      longitude: 80.2565,
      images: propImages,
      locality: locality,
      property_available_for: available_for,
      lease_amount:
        available_for === "Lease" ? formatPriceToIndianLocale(leaseAmount) : "",
      property_description: property_description,
      city: city,
      //available_from,
      landmark_street: landmarkStreet,
    };

    const postData7 = {
      user_id: "2eedf810-58c3-4604-948d-1d4e3631e897",
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      property_condition: Condition,
      possession_status:
        Possession === "Under Construction"
          ? formattedAvailableFrom
          : Possession,
      built_up_area: builtUpArea,
      carpet_area: carpet_area,
      negotiable: negotiable,
      price: formatPriceToIndianLocale(Price),
      total_floors: Sfloor,
      select_your_floor: floor,
      lifts: Lifts,
      parking: parking,
      min_number_of_seats: Min_Seats,
      max_number_of_seats: Max_Seats,
      meeting_room: Meeting,
      washroom: Washroom,
      additional_details: {
        amenities: Array.isArray(AMENITIES) ? AMENITIES.join(", ") : AMENITIES,
      },
      facing,
      latitude: 13.0012,
      longitude: 80.2565,
      property_description: property_description,
      images: propImages,
      locality: locality,
      city: city,
      state: "Tamil Nadu",
      landmark_street: landmarkStreet,
    };

    const postData8 = {
      user_id: "2eedf810-58c3-4604-948d-1d4e3631e897",
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      possession_status:
        Possession === "Under Construction"
          ? formattedAvailableFrom
          : Possession,
      suitable_for: Suitable,
      built_up_area: builtUpArea,
      carpet_area: carpet_area,
      price: formatPriceToIndianLocale(Price),
      negotiable: negotiable,
      total_floors: Sfloor,
      select_your_floor: floor,
      facing,
      parking: parking,
      washroom: Washroom,
      additional_details: {
        amenities: Array.isArray(AMENITIES) ? AMENITIES.join(", ") : AMENITIES,
      },
      latitude: 13.0012,
      longitude: 80.2565,
      images: propImages,
      locality: locality,
      property_description: property_description,
      city: city,
      state: "Tamil Nadu",
      landmark_street: landmarkStreet,
    };

    const postData9 = {
      user_id: "2eedf810-58c3-4604-948d-1d4e3631e897",
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      possession_status:
        Possession === "Under Construction"
          ? formattedAvailableFrom
          : Possession,
      built_up_area: builtUpArea,
      carpet_area: carpet_area,
      price: formatPriceToIndianLocale(Price),
      negotiable: negotiable,
      facing,
      parking: parking,
      additional_details: {
        amenities: Array.isArray(AMENITIES) ? AMENITIES.join(", ") : AMENITIES,
      },
      property_description: property_description,
      latitude: 13.0012,
      longitude: 80.2565,
      images: propImages,
      locality: locality,
      city: city,
      landmark_street: landmarkStreet,
    };

    const postData10 = {
      user_id: "2eedf810-58c3-4604-948d-1d4e3631e897",
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      possession_status:
        Possession === "Under Construction"
          ? formattedAvailableFrom
          : Possession,
      plot_area: Plot,
      price: formatPriceToIndianLocale(Price),
      negotiable: negotiable,
      measure: "Cent",
      facing,
      additional_details: {
        amenities: Array.isArray(AMENITIES) ? AMENITIES.join(", ") : AMENITIES,
      },
      property_description: property_description,
      latitude: 13.0012,
      longitude: 80.2565,
      images: propImages,
      locality: locality,
      city: city,
      state: "TamilNadu",
      landmark_street: landmarkStreet,
    };
    console.log("Hello");

    console.log(postData8);
    if (property === "Residential") {
      // Handling Residential properties
      if (looking_to === "Rent/Lease") {
        postData("http://192.168.1.18:3000/rent/resRentDetail", postData1);
      } else if (looking_to === "Sell") {
        postData("http://192.168.1.18:3000/sell/resSellDetail", postData2);
      }
    } else if (property === "Commercial") {
      // Handling Commercial properties
      if (looking_to === "Rent/Lease") {
        switch (property_type) {
          case "Office Space":
            postData("http://192.168.1.18:3000/rent/comOffRent", postData3);
            break;
          case "Retail Shop":
          case "Showroom":
            postData("http://192.168.1.18:3000/rent/comShopRent", postData4);
            break;
          case "Warehouse":
            postData(
              "http://192.168.1.18:3000/rent/comWarehouseRent",
              postData5
            );
            break;
          case "Industrial Space":
            postData("http://192.168.1.18:3000/rent/comLandRent", postData6);
            break;
          default:
            console.error(
              "Unsupported subtype for Commercial Rent/Lease:",
              property_type
            );
        }
      } else if (looking_to === "Sell") {
        switch (property_type) {
          case "Office Space":
            postData(
              "http://192.168.1.18:3000/sell/comOfficeSellPost",
              postData7
            );
            break;
          case "Retail Shop":
          case "Showroom":
            postData(
              "http://192.168.1.18:3000/sell/comShopSellPost",
              postData8
            );
            break;
          case "Warehouse":
            postData(
              "http://192.168.1.18:3000/sell/comWarehouseSellPost",
              postData9
            );
            break;
          case "Industrial Space":
            postData(
              "http://192.168.1.18:3000/sell/comLandSellPost",
              postData10
            );
            break;
          default:
            console.error(
              "Unsupported subtype for Commercial Sell:",
              property_type
            );
        }
      } else {
        console.error("Unknown transaction type for Commercial:", looking_to);
      }
    } else {
      console.error("Unknown property type:", property);
    }
  };

  const GetCoverImage = () => {
    const Image = propImages.filter((e) => e.isCover);
    return Image[0]?.url;
  };

  return (
    <div>
      {" "}
      <Button onClick={handlePostData}>Submit</Button>
      <h2>Review Page</h2>
      <Card>
        <img
          src={GetCoverImage()}
          alt="Cover Image"
          style={{ width: "100%", maxWidth: "300px", marginBottom: "10px" }}
        />
      </Card>
      <Typography>Basic Details</Typography>
      {userData.map((item, index) => (
        <div key={index}>
          {item.formName === "Basic Details" && (
            <div
              key={index}
              style={{
                marginBottom: "20px",
                border: "1px solid #ccc",
                padding: "10px",
              }}
            >
              {Object.keys(item).map(
                (key) =>
                  key !== "formName" &&
                  key !== "id" && (
                    <p key={key}>
                      <strong>{key}:</strong> {item[key]}
                    </p>
                  )
              )}
            </div>
          )}
        </div>
      ))}
      <Typography>Property Details</Typography>
      {userData.map((item, index) => (
        <div key={index}>
          {item.formName === "Property Details" && (
            <div key={index}>
              {Object.keys(item).map(
                (key) =>
                  key !== "formName" &&
                  key !== "id" && (
                    <p key={key}>
                      <strong>{key}:</strong> {item[key]}
                    </p>
                  )
              )}
            </div>
          )}
        </div>
      ))}
      <Typography>Price Details</Typography>
      {userData.map((item, index) => (
        <div key={index}>
          {item.formName === "Price Details" && (
            <div key={index}>
              {Object.keys(item).map(
                (key) =>
                  key !== "formName" &&
                  key !== "id" && (
                    <p key={key}>
                      <strong>{key}:</strong> {item[key]}
                    </p>
                  )
              )}
            </div>
          )}
        </div>
      ))}
      <Typography>Cost per Built up Area 1: {costPerBuiltUpArea}</Typography>
      <Typography>Cost per Built up Area 2: {costPerBuiltUpArea2}</Typography>
      <Typography>Cost per Plot Area: {costPerBuiltUpArea3}</Typography>
      <Typography>Additional Details</Typography>
      {userData.map((item, index) => (
        <div key={index}>
          {item.formName === "Additional Details" && (
            <div key={index}>
              {Object.keys(item).map(
                (key) =>
                  key !== "formName" &&
                  key !== "id" && (
                    <p key={key}>
                      <strong>{key}:</strong> {item[key]}
                    </p>
                  )
              )}
            </div>
          )}
        </div>
      ))}
      <Typography>Photo</Typography>
      {propImages &&
        propImages.length > 0 &&
        propImages.map((item, index) => (
          <div key={index}>
            <img
              src={item.url}
              alt={`Image ${index}`}
              style={{ width: "100%", maxWidth: "300px", marginBottom: "10px" }}
            />
          </div>
        ))}
    </div>
  );
};

export default ReviewPage;
