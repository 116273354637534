import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import animis from './Animation - 1721114927422.json';
import image1 from '../images/login-2.png';
import image2 from '../images/log-1.png';

interface VerifiedProps {
    onClose: () => void;
    showVerification: any
    setShowVerification:any
}

const  Verified: React.FC<VerifiedProps> = ({ onClose, showVerification,setShowVerification }) => {
    const animi = {
        animationData: animis,
    };

    const navigate = useNavigate();

    // const handleClick = () => {
    //     navigate('/list');
    // };
    useEffect(() => {
        if (showVerification === "tick") {
            const timer = setTimeout(() => {
                setShowVerification("List"); 
                
            }, 1000); // 5 seconds

            return () => clearTimeout(timer); // Cleanup the timer on component unmount or state change
        }
    }, [showVerification, setShowVerification, navigate]);

    return (
        <div className="fixed flex justify-center items-center z-10 left-0 top-0 w-full h-full overflow-auto">
            <div className="bg-white m-auto p-6 border border-gray-300 border-solid w-[78%] max-w-4xl overflow-hidden max-h-[53%] h-[70%] rounded-[2%] flex flex-col relative sm:flex sm:flex-col sm:gap-2 sm:align-middle">
                <div className="fixed top-2 right-2 text-gray-500 text-2xl font-bold cursor-pointer z-20 md" onClick={onClose}>&times;</div>
                <div className="flex flex-row h-full p-0">
                    <div className='relative z-10'>
                        <div className="absolute top-[-100px] left-[-80px] mt-4 w-60 h-60 bg-[#FFF8DC] rounded-full"></div>
                    </div>



                    <div className="flex-1 flex gap-10 flex-col justify-center mt-[%] ml-[12%]">
                        <h4 className="ml-[20%] font-extrabold ">Phone Number Verified</h4>


                        <div className='ml- flex justify-center mt-3'>
                            <Lottie
                                animationData={animi.animationData}
                                loop={true}
                                autoplay={true}
                                style={{ width: 100, height: 100 }}
                            />
                        </div>
                        <div className='ml-[20%] opacity-70 '>
                            <span className=''>Congratulations, your Phone</span><br />
                            <span className='justify-start right-1' style={{ marginLeft: "-20px" }}>number has been Verified. You can</span><br />
                            <span className='text-right' style={{ marginLeft: "25px" }}>start using the app</span>
                        </div>
                    </div>

                    <div className="flex-1 flex justify-around items-center gap-3">
                        <div className="mt-60">
                            <img src={image1} alt='img' />
                        </div>
                        <div className="mt-40">
                            <img src={image2} alt='img' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Verified;
