import React, { useState, useEffect } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CallIcon from "@mui/icons-material/Call";
import NotificationsIcon from "@mui/icons-material/Notifications";

import card1 from "../assets/cardimg-1.png";
import card2 from "../assets/card2.png";
import card3 from "../assets/card3.png";
import card4 from "../assets/card4.png";
import card5 from "../assets/card5.png";
import card6 from "../assets/card6.png";
import bubblechat from "../assets/bubblechat.png";
import eye from "../assets/eye1.png";
import PropertiesComponent from "./PropertiesComponent";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { axiosInstance } from "../../src/compounts/api/APIs";


interface Property {
  id: number;
  post_id: number;
  property_type?: any;
  looking_to?: string;
  rent_or_lease?: string;
  property_available_for?: string;
  images?: { uri: string }[];
  view_post_count: number;
  bhk: string;
  city: string;
  building_name: string;
  landmark_street: string;
  build_up_area?: string;
  built_up_area?: string;
  plot_area?: string;
  PriceperSqft?: string;
  price: number;
  cost?: number;
  monthly_rent?: number;
  enter_lease_amount?: number;
  lease_amount?: any
  liked?: any
}
// const data: CardItem[] = [
//   {
//     img: card1,
//     BHK: "2BHK Independence House",
//     dist: "Apartment for sale in vellore",
//     price: "2.19cr",
//     sf: "2600 sq.ft",
//     sfprice: "8.45k/sq.ft",
//     bubblechat: bubblechat,
//     contact: "contact",
//     icon: <CallIcon />,
//     eyeicon: eye,
//     view: "112viewers",
//     category: "Buy",
//     type: "Residential",
//   },
//   {
//     img: card2,
//     BHK: "2BHK Independence House",
//     dist: "Apartment for sale in vellore",
//     price: "15000/month",
//     sf: "2600 sq.ft",
//     sfprice: "8.45k/sq.ft",
//     bubblechat: bubblechat,
//     contact: "contact",
//     icon: <CallIcon />,
//     eyeicon: eye,
//     view: "112viewers",
//     category: "Rent",
//     type: "Residential",
//   },
//   {
//     img: card3,
//     BHK: "2BHK Independence House",
//     dist: "Apartment for sale in vellore",
//     price: "2,19,50,000",
//     sf: "2600 sq.ft",
//     sfprice: "8.45k/sq.ft",
//     bubblechat: bubblechat,
//     contact: "contact",
//     icon: <CallIcon />,
//     eyeicon: eye,
//     view: "112viewers",
//     category: "Lease",
//     type: "Residential",
//   },
//   {
//     img: card4,
//     BHK: "2BHK Independence House",
//     dist: "Apartment for sale in vellore",
//     price: "2,19,50,000",
//     sf: "2600 sq.ft",
//     sfprice: "8.45k/sq.ft",
//     bubblechat: bubblechat,
//     contact: "contact",
//     icon: <CallIcon />,
//     eyeicon: eye,
//     view: "112viewers",
//     category: "Buy",
//     type: "Commercial",
//   },
//   {
//     img: card5,
//     BHK: "Office Space",
//     dist: "Commercial space for rent in vellore",
//     price: "50000/month",
//     sf: "5000 sq.ft",
//     sfprice: "10/sq.ft",
//     bubblechat: bubblechat,
//     contact: "contact",
//     icon: <CallIcon />,
//     eyeicon: eye,
//     view: "89viewers",
//     category: "Rent",
//     type: "Commercial",
//   },
//   {
//     img: card6,
//     BHK: "Warehouse",
//     dist: "Warehouse for lease in vellore",
//     price: "5,00,000",
//     sf: "15000 sq.ft",
//     sfprice: "33.33/sq.ft",
//     bubblechat: bubblechat,
//     contact: "contact",
//     icon: <CallIcon />,
//     eyeicon: eye,
//     view: "150viewers",
//     category: "Lease",
//     type: "Commercial",
//   },
//   {
//     img: card3,
//     BHK: "Warehouse",
//     dist: "Warehouse for lease in vellore",
//     price: "5,00,000",
//     sf: "15000 sq.ft",
//     sfprice: "33.33/sq.ft",
//     bubblechat: bubblechat,
//     contact: "contact",
//     icon: <CallIcon />,
//     eyeicon: eye,
//     view: "150viewers",
//     category: "Lease",
//     type: "Commercial",
//   },
// ];

const PropertiesCard: React.FC = () => {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState<"Commercial" | "Residential">("Residential");
  const [value, setValue] = useState<"Buy" | "Lease" | "Rent">("Buy");
  const [position, setPosition] = useState<boolean>(true);
  const [filteredData, setFilteredData] = useState<Property[]>([]);
  const loginData = useSelector((state: any) => state.userData.userLoginData);
  console.log(loginData);

  const token = loginData.token
  const fetchData = async (type: "Residential" | "Commercial", category: "Buy" | "Lease" | "Rent") => {
    const body = {
      liked_user_id: loginData.user_id,
      property_sub_type: type,
      looking_to: category
    };
    try {
      const response = await axiosInstance.post("/like/GetlikePost", body);
      console.log(response.data); // Check the structure of response.data

      setFilteredData(response.data.message);

    } catch (error) {
      console.error("Error fetching data:", error);
      setFilteredData([]); // Set an empty array in case of an error
    }
  };


  useEffect(() => {
    // Fetch data whenever activeButton or value changes
    fetchData(activeButton, value);
  }, [activeButton, value]);

  const handleCategoryChange = (newValue: "Buy" | "Lease" | "Rent") => {
    setValue(newValue);
  };

  const handleButtonClick = (buttonType: "Commercial" | "Residential") => {
    setPosition(buttonType === "Residential");
    setActiveButton(buttonType);
    setValue("Buy"); // Default to "Buy" when changing category
  };

  const handleclick = () => {
    navigate("/profile");
  };

  return (
    <div className="overflow-y-hidden relative lg:mt-5 xl:mx-[15%] overflow-x-hidden  ">
      <div className="font-semibold flex">
        <p>Home.</p>
        <p>Profile.</p>
        <p> Saved Properties</p>
      </div>
      <div className=" rounded-2xl">


        <div className="h-20 flex gap-5 items-center ">
          <div className="relative w-96 bg-[#F5F4F8] rounded-full  p-1">
            <div
              id="btn"
              className={`absolute top-0 h-full w-1/2 rounded-full transition-all duration-300 ${position
                ? "bg-[#2E368F] text-white left-0"
                : "bg-[#2E368F] text-white left-1/2"
                }`}
            ></div>
            <button
              type="button"
              className={`w-1/2 text-center p-2 outline-none bg-transparent cursor-pointer relative z-10 ${position ? "text-white" : "text-black"
                }`}
              onClick={() => handleButtonClick("Residential")}
            >
              Residential
            </button>
            <button
              type="button"
              className={`w-1/2 text-center p-2 outline-none bg-transparent cursor-pointer relative z-10 ${!position ? "text-white" : "text-black"
                }`}
              onClick={() => handleButtonClick("Commercial")}
            >
              Commercial
            </button>
          </div>
          <div className="border-1 border-[#E9E9E9] h-10"></div>

          <div className="gap-5 flex ml- mb-">
            <button
              className={`w-36 h-12 rounded-3xl p-1 ${value === "Buy"
                ? "bg-[#2E368F] text-white"
                : "bg-[#F5F4F8] text-black"
                }`}
              onClick={() => handleCategoryChange("Buy")}
            >
              Buy
            </button>
            <button
              className={`w-36 h-12 rounded-3xl p-1 ${value === "Lease"
                ? "bg-[#2E368F] text-white"
                : "bg-[#F5F4F8] text-black"
                }`}
              onClick={() => handleCategoryChange("Lease")}
            >
              Lease
            </button>
            <button
              className={`w-36 h-12 rounded-3xl p-1 ${value === "Rent"
                ? "bg-[#2E368F] text-white"
                : "bg-[#F5F4F8] text-black"
                }`}
              onClick={() => handleCategoryChange("Rent")}
            >
              Rent
            </button>
          </div>
        </div>


      </div>
      <div>


        <PropertiesComponent data={filteredData} />

      </div>

    </div>
  );
};

export default PropertiesCard;
