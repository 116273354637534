import React, { useState } from 'react'
import BasicRating from './BasicRating'
import { useSelector } from "react-redux";
import { axiosInstance } from "../../compounts/api/APIs";
import cancel from '../images/cancel.png'
import tick from '../images/thanks.png'

interface inter {
    setOpen: any
}

const RateingBox: React.FC<inter> = ({ setOpen }) => {

    const [RateValue, setRateValue] = useState<any>()
    const [value, setValue] = React.useState<number | null>(0);
    const loginData = useSelector((state: any) => state.userData.userLoginData)
    const [thank, setThank] = useState<any>(false)
    function handleOtherReasonText(event: React.ChangeEvent<HTMLTextAreaElement>) {
        setRateValue(event.target.value)
    }
    async function handleSubmit() {
        const body = {
            user_id: loginData.user_id,
            Rating_count: value,
            describe_issuse: RateValue

        }
        console.log(body);

        try {
            const response = await axiosInstance.put("/user/ratingUpdate", body)
            console.log(response);
            setThank(true)
        } catch (error) {

        }
    }
    function handleClose() {
        setOpen(false)
    }



    return (
        <div >
            <div
                className={`bg-white p-5 rounded-[19px] shadow-lg transition-opacity duration-300 absolute left-[28%] top-[15%]  z-20 transform     outline-none
               ${thank ? "hidden" : ""} `}> <img onClick={handleClose} className='absolute right-3 top-3' src={cancel} />
                <div className='flex flex-col justify-center items-center gap-4'>

                    <p className='text-xl font-bold'>Rate Our App</p>
                    <p className='text-lg font-medium'>Enjoying Our App?</p>
                </div>
                <div className='flex justify-center items-center font-medium mt-3'>
                    <p>We'd love to hear your feedback! Your rating helps us improve and provide a better experience.</p>

                </div>
                <div className='flex flex-col justify-center items-center mt-4'>
                    <p className='mb-3 font-medium'>How would you rate your experience so far?</p>
                    <BasicRating value={value} setValue={setValue} />
                </div>
                <div className='flex flex-col justify-center items-center mt-5'><textarea
                    onChange={handleOtherReasonText}
                    placeholder="Describe the issue in detail"
                    className="rounded-lg border-2 transition-colors duration-100 w-[85%] h-[150px] placeholder: placeholder:text-center placeholder:items-center border-gray-300 focus:border-gray-400 outline-none p-2"
                ></textarea>
                </div>
                <div className=' mt-5 flex justify-center items-center '>
                    <p onClick={handleSubmit} className='flex bg-[#2E368F] py-3 px-32 rounded-3xl text-white justify-center'>Submit</p>
                </div>
            </div>
            <div className={`bg-white p-5  rounded-[19px] shadow-lg transition-opacity duration-300 absolute left-[35%] top-[30%]  z-20 transform     outline-none
               ${thank ? "" : "hidden"} `}>
                 <img onClick={handleClose} className='absolute right-3 top-3' src={cancel} />
                <img src={tick} />
                <div className='flex flex-col justify-center items-center mt-4'>
                    <p className='text-xl font-bold'>Thank You for Your Feedback!</p>
                    <p className='font-semibold'>“Find Your Perfect Place”</p>
                </div>

            </div>
        </div>
    )
}

export default RateingBox