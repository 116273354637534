import { Button } from "@mui/material";
import Details from "./EditFrom/EditFormDetails";
import Header from "./EditFrom/EditFormHeader";
import propertyImg from "../assets/propertyImage.png"
import { useLocation } from "react-router-dom";
import EditBaisc from "./EditFrom/EditBaisc";
import FormPrice from "./EditFrom/FormPrice";
import EditAdditionalDetails from "./EditFrom/EditAdditionalDetails";
import EditImages from "./EditFrom/EditImages";
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { setUsers } from '../slices/filterSlice';

const EditForm: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation()

  const passedData = location.state
  console.log(passedData)
  dispatch(setUsers([passedData]));
  const [finalValue, setFinalValue] = useState<any>([passedData])

  console.log("call form final", finalValue)




  
  

  return (
    <div className="bg-white  md:w-[40%] mx-2 md:mx-auto mt-40 md:my-8 p-4 md:p-6 rounded-t-3xl md:rounded-3xl font-K2D">
      <Header passedData={passedData} />
      <EditBaisc passedData={finalValue} setFinalValue={setFinalValue} />
      <Details passedData={finalValue} setFinalValue={setFinalValue} />
      <FormPrice passedData={finalValue} setFinalValue={setFinalValue} />
      <EditAdditionalDetails passedData={finalValue} setFinalValue={setFinalValue}/>
      <EditImages passedData={finalValue} />

      {/* <Details section={passedData} />
      <Details section={passedData} />
      <Details section={passedData} />
      <Details section={passedData} />
      <Details section={passedData}/> */}

      <div className="md:flex md:justify-end mt-8">
        <Button
          style={{
            backgroundColor: "#2E368F",
            borderRadius: "3rem",
            padding: "5px 3rem",

          }}
          variant="contained"
          className="w-full md:w-auto"
        >
          Post
        </Button>
      </div>
    </div>
  );
};

export default EditForm;
