// store.ts
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import usersReducer from "../slices/filterSlice";
import userDataReducer from "../slices/userDataSlice";  // Import userDataReducer

// Configuration object for redux-persist
const persistConfig = {
    key: 'userData',
    storage,
};

// Create a persisted reducer
const persistedUserDataReducer = persistReducer(persistConfig, userDataReducer);

const store = configureStore({
    reducer: {
        usersInfo: usersReducer,
        userData: persistedUserDataReducer,  // Use persisted reducer for userData
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Create a persistor
export const persistor = persistStore(store);

export default store;