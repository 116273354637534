import React, { useState } from 'react'
import editIcon from '../../assets/editForm.svg'
import { PriceDetails } from "./dataJson";

import { Box, Modal } from '@mui/material';
import Price from './component/Price';


interface AdditionalDetails {
    bathroom: string;
    balcony: string;
    preferred_tenant_type: string;
    parking: string;
    carpet_area: string;
    // Add other fields if necessary
}

interface User {
    name: string;
    mobile_no: string;
    category: string;
    company_logo: string;
    company_name: string;
}

interface PropertyImage {
    filename: string;
    id: number;
    isCover: boolean;
    name: string;
    type: string;
    uri: string;
}

interface Property {
    possession_status: any
    construction_status: any
    rent_or_lease: any
    active: boolean;
    additional_details: AdditionalDetails;
    available_from: string;
    bhk: string;
    building_name: string;
    built_up_area: string;
    city: string;
    createdAt: string;
    delete_post_reason: string;
    district: string;
    enter_lease_amount: string;
    flat_furnishing: string;
    furnish_type: string;
    home_screen_post: boolean;
    id: number;
    images: PropertyImage[];
    kbl_property: boolean;
    landmark_street: string;
    latitude: string;
    liked: boolean;
    longitude: string;
    monthly_rent: string;
    negotiable: string;
    post_approval: string;
    post_id: string;
    propertyID: string;
    property_available_for: string;
    property_type: string;
    res_or_com: string;
    security_deposit: string;
    shareUrl: string;
    society_amenities: any; // Adjust type based on actual data or make it optional
    state: string | null;
    sublocality: string;
    tbl_user_reg: User;
    updatedAt: string;
    user_id: string;
    view_post_count: number;
    looking_to: any
    price: any
    cost: any
    lease_amount: any
    PriceperSqft: any

    property_description: any
    security_deposite: any
}

interface DetailsProps {
    passedData: Property;
    setFinalValue?: any
}




const FormPrice: React.FC<DetailsProps> = ({ passedData, setFinalValue }) => {
    const [formVisible, setFormVisible] = useState<any>(false)
    const [FullData, setFullData] = useState<any>(passedData)
    // setFinalValue(FullData)
    console.log("Price Dtails", FullData)




    const handleForm = () => {
        console.log("check price popup")
        setFormVisible(true);

    };

    const closeModal = () => {
        setFormVisible(false);

    };


    const handleNext = () => {
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        // setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        // setActiveStep(0);
    };


    const handlePriceDetails = () => {
        return Object.keys(PriceDetails).map((key: any) => {
            const fullData = FullData[0] || {};
            switch (key) {
                case "ResrCom":
                    return { [key]: fullData.res_or_com || "" }
                case "look_to":
                    return { [key]: fullData.looking_to || fullData.rent_or_lease || fullData.property_available_for || '' };
                case 'Property available for':
                    return { [key]: fullData.looking_to || fullData.rent_or_lease || fullData.property_available_for || "" };
                case 'Monthly Rent':
                    return { [key]: fullData.monthly_rent || "" }
                case "Lease Amount":
                    return { [key]: fullData.lease_amount || "" }
                case "Available From":
                    return { [key]: fullData.possession_status || fullData.available_from || fullData.construction_status || "" }
                case "Select":
                    return { [key]: fullData.security_deposit  || "" }
                case "Negotiable":
                    return { [key]: fullData.negotiable || "" }
                case "Construction Status":
                    return { [key]: fullData.possession_status || fullData.available_from || fullData.construction_status || "" }
                case "Property Description":
                    return { [key]: fullData.property_description || "" };
                case "Price":
                case "price":
                case "Cost":
                case "Plot Price":
                    return { [key]: removeSymbols(fullData.price || "") };
                default:
                    return { [key]: "" };
            }
        });
    };


    const removeSymbols = (value: string) => {
        // Remove any non-numeric characters except for dots (to keep decimals)
        return value.replace(/[^0-9.]/g, '');
    };
    const priceDetails = handlePriceDetails()

    console.log("priceDetails", priceDetails)


    return (
        <div className='mt-3 relative'>
            <p className='text-xl font-semibold mb-3 text-[#2E368F]'>Price Details</p>
            <img onClick={handleForm} src={editIcon} className='absolute right-0 top-0' />
            <div>
                <div className=''>
                    {FullData[0].res_or_com === 'Residential' &&
                        (FullData[0].property_type === 'Independent House' ||
                            FullData[0].property_type === 'Apartment' ||
                            FullData[0].property_type === 'Villa') &&
                        FullData[0].looking_to === 'Sale' && (
                            <div className="flex flex-col gap-2">
                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Price</p>
                                    <p className="font-medium w-[50%]">{FullData[0].price}</p>
                                </div>
                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Price/Sq.ft</p>
                                    <p className="font-medium w-[50%]">{FullData[0].PriceperSqft}</p>
                                </div>
                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Construction Status</p>
                                    <p className="font-medium w-[50%]">{FullData[0].construction_status || FullData[0].possession_status || FullData[0].available_from}</p>
                                </div>
                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Negotiable</p>
                                    <p className="font-medium w-[50%]">{FullData[0].negotiable}</p>
                                </div>

                            </div>
                        )}
                </div>

                <div>
                    {FullData[0].res_or_com === 'Residential' &&
                        (FullData[0].property_type === 'Independent House' ||
                            FullData[0].property_type === 'Apartment' ||
                            FullData[0].property_type === 'Villa') &&
                        (FullData[0].property_available_for === 'Rent' ||
                            FullData[0].property_available_for === 'Lease') && (
                            <div>
                                {FullData[0].property_available_for === 'Rent' && (
                                    <div className="flex flex-col gap-2">
                                        <div className='flex  '>
                                            <p className="w-[50%] font-bold">Monthly Rent</p>
                                            <p className="font-medium w-[50%]">{FullData[0].monthly_rent}</p>
                                        </div>
                                        <div className='flex  '>
                                            <p className="w-[50%] font-bold">Available From</p>
                                            <p className="font-medium w-[50%]">{FullData[0].available_from}</p>
                                        </div>
                                        <div className='flex  '>
                                            <p className="w-[50%] font-bold">Security Deposit</p>
                                            <p className="font-medium w-[50%]">{FullData[0].security_deposit}</p>
                                        </div>
                                        <div className='flex  '>
                                            <p className="w-[50%] font-bold">Negotiable</p>
                                            <p className="font-medium w-[50%]">{FullData[0].negotiable}</p>
                                        </div>

                                    </div>
                                )}
                                {FullData[0].property_available_for === 'Lease' && (
                                    <div className="flex flex-col gap-2">
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Lease Amount</p>
                                            <p className="font-medium w-[50%]">{FullData[0].lease_amount}</p>

                                        </div>

                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Available From</p>
                                            <p className="font-medium w-[50%]">{FullData[0].available_from}</p>

                                        </div>
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Negotiable</p>
                                            <p className="font-medium w-[50%]">{FullData[0].negotiable}</p>

                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                </div>

                <div>
                    {FullData[0].res_or_com === "Residential" &&
                        FullData[0].property_type === "Vacant Land" &&
                        FullData[0].looking_to === "Sale" && (
                            <div className="flex flex-col gap-2">
                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Plot Price</p>
                                    <p className="font-medium w-[50%]">{FullData[0].price}</p>

                                </div>

                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Price/Sq.ft</p>
                                    <p className="font-medium w-[50%]">{FullData[0].PriceperSqft}</p>

                                </div>
                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Negotiable</p>
                                    <p className="font-medium w-[50%]">{FullData[0].negotiable}</p>

                                </div>
                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Property Description</p>
                                    <p className="font-medium w-[50%]">{FullData[0].property_description}</p>

                                </div>
                            </div>
                        )}
                </div>

                <div>
                    {FullData[0].res_or_com === "Commercial" &&
                        FullData[0].property_type === "Office Space" &&
                        (FullData[0].property_available_for === "Rent" ||
                            FullData[0].property_available_for === "Lease") && (
                            <div>
                                {FullData[0].property_available_for === "Rent" && (
                                    <div className="flex flex-col gap-2">
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Expected Rent</p>
                                            <p className="font-medium w-[50%]">{FullData[0].monthly_rent}</p>
                                        </div>
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Negotiable</p>
                                            <p className="font-medium w-[50%]">{FullData[0].negotiable}</p>
                                        </div>
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Security Deposit</p>
                                            <p className="font-medium w-[50%]">{FullData[0].security_deposit}</p>
                                        </div>
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Property Description</p>
                                            <p className="font-medium w-[50%]">{FullData[0].property_description}</p>
                                        </div>

                                    </div>
                                )}
                                {FullData[0].property_available_for === "Lease" && (
                                    <div className="flex flex-col gap-2">
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Lease Amount</p>
                                            <p className="font-medium w-[50%]">{FullData[0].lease_amount}</p>
                                        </div>
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Negotiable</p>
                                            <p className="font-medium w-[50%]">{FullData[0].negotiable}</p>
                                        </div>
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Property Description</p>
                                            <p className="font-medium w-[50%]">{FullData[0].property_description}</p>
                                        </div>


                                    </div>
                                )}
                            </div>
                        )}
                </div>
                <div>
                    {FullData[0].res_or_com === "Commercial" &&
                        FullData[0].property_type === "Warehouse" &&
                        (FullData[0].property_available_for === "Rent" ||
                            FullData[0].property_available_for === "Lease") && (
                            <div>
                                {FullData[0].property_available_for === "Rent" && (
                                    <div className="flex flex-col gap-2">
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Expected Rent</p>
                                            <p className="font-medium w-[50%]">{FullData[0].monthly_rent}</p>
                                        </div>
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Negotiable</p>
                                            <p className="font-medium w-[50%]">{FullData[0].negotiable}</p>
                                        </div>
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Security Deposit</p>
                                            <p className="font-medium w-[50%]">{FullData[0].security_deposit}</p>
                                        </div>
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Property Description</p>
                                            <p className="font-medium w-[50%]">{FullData[0].property_description}</p>
                                        </div>

                                    </div>
                                )}
                                {FullData[0].property_available_for === "Lease" && (
                                    <div className="flex flex-col gap-2">
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Lease Amount</p>
                                            <p className="font-medium w-[50%]">{FullData[0].lease_amount}</p>
                                        </div>
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Negotiable</p>
                                            <p className="font-medium w-[50%]">{FullData[0].negotiable}</p>
                                        </div>
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Property Description</p>
                                            <p className="font-medium w-[50%]">{FullData[0].property_description}</p>
                                        </div>


                                    </div>
                                )}
                            </div>
                        )}
                </div>

                <div>
                    {FullData[0].res_or_com === "Commercial" &&
                        FullData[0].property_type === "Industrial Space" &&
                        (FullData[0].property_available_for === "Rent" ||
                            FullData[0].property_available_for === "Lease") && (
                            <div>
                                {FullData[0].property_available_for === "Rent" && (
                                    <div className="flex flex-col gap-2">
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Expected Rent</p>
                                            <p className="font-medium w-[50%]">{FullData[0].monthly_rent}</p>
                                        </div>
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Negotiable</p>
                                            <p className="font-medium w-[50%]">{FullData[0].negotiable}</p>
                                        </div>
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Security Deposit</p>
                                            <p className="font-medium w-[50%]">{FullData[0].security_deposit}</p>
                                        </div>
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Property Description</p>
                                            <p className="font-medium w-[50%]">{FullData[0].property_description}</p>
                                        </div>

                                    </div>
                                )}
                                {FullData[0].property_available_for === "Lease" && (
                                    <div className="flex flex-col gap-2">
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Lease Amount</p>
                                            <p className="font-medium w-[50%]">{FullData[0].lease_amount}</p>
                                        </div>
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Negotiable</p>
                                            <p className="font-medium w-[50%]">{FullData[0].negotiable}</p>
                                        </div>
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Property Description</p>
                                            <p className="font-medium w-[50%]">{FullData[0].property_description}</p>
                                        </div>


                                    </div>
                                )}
                            </div>
                        )}
                </div>



                <div>
                    {FullData[0].res_or_com === "Commercial" &&
                        (FullData[0].property_type === "Retail Shop" || FullData[0].property_type === "Showroom") &&
                        (FullData[0].property_available_for === "Rent" ||
                            FullData[0].property_available_for === "Lease") && (
                            <div>
                                {FullData[0].property_available_for === "Rent" && (
                                    <div className="flex flex-col gap-2">
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Expected Rent</p>
                                            <p className="font-medium w-[50%]">{FullData[0].monthly_rent}</p>
                                        </div>
                                        <div className='flex'>
                                            <p className="w-[50%] font-bold">Negotiable</p>
                                            <p className="font-medium w-[50%]">{FullData[0].negotiable}</p>
                                        </div>
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Security Deposit</p>
                                            <p className="font-medium w-[50%]">{FullData[0].security_deposit}</p>
                                        </div>
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Property Description</p>
                                            <p className="font-medium w-[50%]">{FullData[0].property_description}</p>
                                        </div>

                                    </div>
                                )}
                                {FullData[0].property_available_for === "Lease" && (
                                    <div className="flex flex-col gap-2">
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Lease Amount</p>
                                            <p className="font-medium w-[50%]">{FullData[0].lease_amount}</p>
                                        </div>
                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Negotiable</p>
                                            <p className="font-medium w-[50%]">{FullData[0].negotiable}</p>
                                        </div>

                                        <div className='flex '>
                                            <p className="w-[50%] font-bold">Property Description</p>
                                            <p className="font-medium w-[50%]">{FullData[0].property_description}</p>
                                        </div>


                                    </div>
                                )}
                            </div>
                        )}
                </div>


                <div className=''>
                    {FullData[0].res_or_com === 'Commercial' &&
                        (FullData[0].property_type === 'Office Space') &&
                        FullData[0].looking_to === 'Sale' && (
                            <div className="flex flex-col gap-2">
                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Price</p>
                                    <p className="font-medium w-[50%]">{FullData[0].price}</p>
                                </div>
                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Price/Sq.ft</p>
                                    <p className="font-medium w-[50%]">{FullData[0].PriceperSqft}</p>
                                </div>

                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Negotiable</p>
                                    <p className="font-medium w-[50%]">{FullData[0].negotiable}</p>
                                </div>

                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Property Description</p>
                                    <p className="font-medium w-[50%]">{FullData[0].property_description}</p>
                                </div>

                            </div>
                        )}
                </div>


                <div className='mt-2'>
                    {FullData[0].res_or_com === 'Commercial' &&
                        (FullData[0].property_type === "Warehouse") &&
                        FullData[0].looking_to === 'Sale' && (
                            <div className="flex flex-col gap-2">
                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Price</p>
                                    <p className="font-medium w-[50%]">{FullData[0].price}</p>
                                </div>
                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Price/Sq.ft</p>
                                    <p className="font-medium w-[50%]">{FullData[0].PriceperSqft}</p>
                                </div>

                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Negotiable</p>
                                    <p className="font-medium w-[50%]">{FullData[0].negotiable}</p>
                                </div>

                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Property Description</p>
                                    <p className="font-medium w-[50%]">{FullData[0].property_description}</p>
                                </div>

                            </div>
                        )}
                </div>


                <div className='mt-2'>
                    {FullData[0].res_or_com === 'Commercial' &&
                        (FullData[0].property_type === "Industrial Space") &&
                        FullData[0].looking_to === 'Sale' && (
                            <div className="flex flex-col gap-2">
                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Price</p>
                                    <p className="font-medium w-[50%]">{FullData[0].price}</p>
                                </div>
                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Price/Sq.ft</p>
                                    <p className="font-medium w-[50%]">{FullData[0].PriceperSqft}</p>
                                </div>

                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Negotiable</p>
                                    <p className="font-medium w-[50%]">{FullData[0].negotiable}</p>
                                </div>

                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Property Description</p>
                                    <p className="font-medium w-[50%]">{FullData[0].property_description}</p>
                                </div>

                            </div>
                        )}
                </div>

                <div className='mt-2'>
                    {FullData[0].res_or_com === 'Commercial' &&
                        (FullData[0].property_type === "Retail Shop" || FullData[0].property_type === "Showroom") &&
                        FullData[0].looking_to === 'Sale' && (
                            <div className="flex flex-col gap-2">
                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Price</p>
                                    <p className="font-medium w-[50%]">{FullData[0].price}</p>
                                </div>
                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Price/Sq.ft</p>
                                    <p className="font-medium w-[50%]">{FullData[0].PriceperSqft}</p>
                                </div>

                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Negotiable</p>
                                    <p className="font-medium w-[50%]">{FullData[0].negotiable}</p>
                                </div>

                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Property Description</p>
                                    <p className="font-medium w-[50%]">{FullData[0].property_description}</p>
                                </div>

                            </div>
                        )}
                </div>



            </div>
            <div className='border-1 border-[#8E8E8E] mt-3 '></div>

            <Modal
                open={formVisible}
                onClose={closeModal}
                aria-labelledby="basic-modal-title"
                aria-describedby="basic-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 800,
                        maxHeight: '90vh',
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                    }}
                >
                    <Price onNext={handleNext} onBack={handleBack} onReset={handleReset} setFullData={setFullData} passedData={FullData} priceDetails={priceDetails} />
                </Box>
            </Modal>

        </div>
    )
}

export default FormPrice