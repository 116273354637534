
import React, { useEffect, useState } from 'react'
import { useStepperContext } from './StepperContext';
import { Alert, Box, Button, FormControl, InputAdornment, InputLabel, MenuItem, Modal, NativeSelect, Select, SelectChangeEvent, Snackbar, TextField, Typography } from '@mui/material';
import Map from './Map';
interface TextFieldProps{
  //  title:string;
   label:string;
  //  placeholder:string; 
  formName:string;
  inputId?:number,
  label2?:string,
  keyboard?:any,
  required?:boolean
  userselectedData?:any
  initial:any

}
const TextBox:React.FC<TextFieldProps> = ({label,formName,inputId,label2,keyboard,required,userselectedData,initial}) => {
  const { addUserData,userData } = useStepperContext()
  const [MapVisible,setMapVisible] =useState(false)
  const [open,setOpen]=useState(false)
  const handleClose = () => setMapVisible(false);
  const [selectedValue, setSelectedValue] = useState(initial);
  const [measures,setMeasures]=useState("Sqft")
  const mydistrict = userData.find((item: any) =>
    item.hasOwnProperty('City')
  )?.['City'];
  
  const handleMap=()=>{
    if(mydistrict !== undefined){
    setMapVisible(true)
    }else{
      setOpen(true)
    }
  }
    const handleChange=(value: string)=>{
      setSelectedValue(value);
        const data={
            id:inputId,
            [label]:value,
            formName:formName,
        }
        const measured={
          id:785156,
          measure:measures,
          formName:formName
        }


addUserData(data as any)
addUserData(measured as any)
      }

      const hasError=userselectedData?.includes(label)

      const handleMeasure = (event: SelectChangeEvent) => {
        setMeasures(event.target.value);
        const measured={
          id:785156,
          measure:event.target.value,
          formName:formName
        }
        addUserData(measured as any)

      };

  return (
    <div>
 <Snackbar
      open={open}
      autoHideDuration={2000} // Duration in milliseconds (5 seconds)
      onClose={() => setOpen(false)}
      anchorOrigin={{ vertical: 'top', horizontal: "right" }}
    >
      <Alert
        onClose={() => setOpen(false)}
        severity="error"
        sx={{ width: '100%' }}
      >
        Select Locate Your Property
      </Alert>
    </Snackbar>
      <TextField variant='standard' id={label} placeholder={label}
        value={selectedValue}
      type={keyboard?keyboard:"text"}
      label={label} onChange={(e) => handleChange(e.target.value)} className='w-[100%]'  InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {label === "Locality" ? (
              <Button onClick={handleMap} variant="contained" size="small" sx={{ ml: 1 }}>
                Choose Location
              </Button>
            ):(label === "Plot Area")?(
              <>
               <Box sx={{ minWidth: 65 }}>
      <FormControl fullWidth>
      
      <Select
          value={measures}
          variant="standard"
          onChange={handleMeasure}
          sx={{minWidth:70}}
        >
          <MenuItem value={"Sqft"}>Sqft</MenuItem>
          <MenuItem value={"Cent"}>Cent</MenuItem>
          <MenuItem value={"Acre"}>Acre</MenuItem>
        </Select>
      </FormControl>
    </Box>
              </>
            ) : (
              label2
            )}
          </InputAdornment>
        ),
      }}>
        </TextField>
       
        <div>
          
          <Modal
        open={MapVisible}
        style={{display:"flex",alignItems:"center",justifyContent:"center"}}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{bgcolor:"#fff",justifyContent:"center",alignItems:"center",width:"50%",p:3}}>
        <Map onClose={handleClose} formName={formName}  onLocationSelect={handleChange} />
        </Box>
      </Modal>
        </div>


        {hasError && <Typography sx={{color:"#C91C1C"}}>This field is required</Typography>}
    

    </div>
  )
}

export default TextBox