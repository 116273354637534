import { Radio } from "@mui/material";
import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { reportReasons } from "../data";
import { axiosInstance } from "../api/APIs";

type RadioValue = string

type OtherReasonValue = string;

interface props {
    look: any;
    id: any;
    property: any
    setVisable:any
}


interface ReportPopUpProps {
    showReport: boolean;
    setReportSumit: (value: boolean) => void;
    handleShowReport: () => void;
}

const DeletePopUp: React.FC<props> = ({ look, id, property ,setVisable}) => {
    const [selectedValue, setSelectedValue] = useState<RadioValue>("");
    const [otherReason, setOtherReason] = useState<OtherReasonValue>("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showReport, setShowReport] = useState(true);
    const [reportSubmit, setReportSubmit] = useState(false);
    const handleRadioChange = (value: RadioValue) => {
        setSelectedValue(value);
        setShowErrorMessage(false);
    };

    const handleOtherReasonText = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setOtherReason(event.target.value);
    };

    const handleSummit = async () => {
        if (selectedValue === "other") {
            console.log(
                `Selected reason for the report : ${selectedValue} (${otherReason})`
            );
            setReportSubmit(true);
        } else if (selectedValue.length === 0) {
            setShowErrorMessage(true);
        } else {
            console.log("Selected reason for the report : ", selectedValue);
            setShowReport(true);
        }
        const body:any = {
            post_id: id,
            property_type: property,
            rent_or_sell: look,
            delete_reason:selectedValue==="other"?otherReason:selectedValue
        }
        try {

            const response = await axiosInstance.delete("/delete/post/deactive",{data:body})
            console.log(response);
            setVisable(false)

            
        } catch (error) {
            console.log(error);
        }
    };


    function handleShowReport() {
        setVisable(false)
    }




    return (
        <section
            className={`${showReport && "opacity-100"
                }  opacity-0 bg-white w-[100%] md:w-[60%] lg:w-[50%] mx-auto   my-9 p-5 rounded-[19px] shadow-lg transition-opacity duration-300`}
        >
            <header className="relative text-[#202020] text-center pb-4 border-b-[0.5px] border-gray-300">
                <div className="text-md md:text-lg lg:text-xl text-[#C91C1C] font-bold">
                    Please let us know the reason for deleting the listing <span onClick={handleShowReport} className="absolute top-0 right-0 md:right-2 lg:right-4 text-gray-500 text-2xl cursor-pointer"><IoClose /></span>
                </div>

            </header>
            <main className="">

                <div className="">
                    {reportReasons.map((report) => (
                        <div onClick={() => handleRadioChange(report.value as RadioValue)} className={` flex mt-6 cursor-pointer`} key={report.value}>
                            <Radio
                                value={report.value}
                                checked={selectedValue === report.value}
                                sx={{
                                    color: "#80848E",
                                    "&.Mui-checked": { color: "#2E368F" },
                                    "& svg": {
                                        fontSize: 20,
                                    },
                                    marginLeft: 2,
                                }}
                            />
                            <div className="ml-8">
                                <p className="font-semibold text-sm md:text-md lg:text-lg">
                                    {report.reason}
                                </p>

                            </div>
                        </div>
                    ))}
                </div>
                <div
                    className={`${selectedValue === "other"
                        ? "max-h-96 opacity-100"
                        : "max-h-0 opacity-0"
                        } transition-all duration-700 my-4 overflow-hidden`}
                >
                    <textarea
                        onChange={handleOtherReasonText}
                        placeholder="Describe the issue in detail"
                        className="rounded-lg border-2 transition-colors duration-100 w-full h-[150px] border-gray-300 focus:border-gray-400 outline-none p-2"
                    ></textarea>
                </div>
                <div
                    className={`${showErrorMessage && "opacity-100 max-h-8"
                        } opacity-0 max-h-0 overflow-hidden text-red-600 text-sm text-center font-semibold transition-opacity duration-200`}
                >
                    Please select any reason
                </div>
                <div className="flex justify-center mt-4">
                    <button
                        onClick={()=>{handleSummit()}}
                        className="bg-[#2E368F] hover:bg-opacity-95 active:bg-opacity-90 text-[#f5f5f5] p-3 w-[90%] lg:w-[75%]  mx-auto rounded-full"
                    >
                        Confirm
                    </button>
                </div>
            </main>
        </section>
    );
};

export default DeletePopUp;
