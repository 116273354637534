export const formatSQPrice = (price: string | number): string => {
    if (typeof price === 'number') {
      return valueFormat(price);
    }
  
    const numericPrice = parseFloat(price.replace(/[^\d.-]/g, ''));
    
    if (isNaN(numericPrice)) {
      return 'Invalid Price'; // Handle cases where parsing fails
    }
  
    return valueFormat(numericPrice);
  };
  

  
  function valueFormat(value: number): string {
    if (value >= 1000000000) {
      return (value / 1000000000).toFixed(2) + ' B';
    }
    if (value >= 10000000) {
      return (value / 10000000).toFixed(2) + ' Cr';
    }
    if (value >= 1000000) {
      return (value / 1000000).toFixed(2) + ' M';
    }
    if (value >= 100000) {
      return (value / 100000).toFixed(2) + ' Lac';
    }
    if (value >= 1000) {
      return (value / 1000).toFixed(2) + ' K';
    }
    return value.toString();
  }