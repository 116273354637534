import React, { useState } from 'react'
import editIcon from '../../assets/editForm.svg'
import { basics } from "./dataJson";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Basic from './component/Basic';
import { useSelector } from 'react-redux';


interface AdditionalDetails {
  bathroom: string;
  balcony: string;
  preferred_tenant_type: string;
  parking: string;
  carpet_area: string;
  // Add other fields if necessary
}

interface User {
  name: string;
  mobile_no: string;
  category: string;
  company_logo: string;
  company_name: string;
}

interface PropertyImage {
  filename: string;
  id: number;
  isCover: boolean;
  name: string;
  type: string;
  uri: string;
}

interface Property {
  active: boolean;
  additional_details: AdditionalDetails;
  available_from: string;
  bhk: string;
  building_name: string;
  built_up_area: string;
  city: string;
  createdAt: string;
  delete_post_reason: string;
  district: string;
  enter_lease_amount: string;
  flat_furnishing: string;
  furnish_type: string;
  home_screen_post: boolean;
  id: number;
  images: PropertyImage[];
  kbl_property: boolean;
  landmark_street: string;
  latitude: string;
  liked: boolean;
  longitude: string;
  monthly_rent: string;
  negotiable: string;
  post_approval: string;
  post_id: string;
  propertyID: string;
  property_available_for: string;
  property_type: string;
  res_or_com: string;
  security_deposit: string;
  shareUrl: string;
  society_amenities: any; // Adjust type based on actual data or make it optional
  state: string | null;
  sublocality: string;
  tbl_user_reg: User;
  updatedAt: string;
  user_id: string;
  view_post_count: number;
  looking_to: any
  price: any
  cost: any
  lease_amount: any
  rent_or_lease: any
}

interface DetailsProps {
  PassedData?:Property
  passedData?: Property;
  setFinalValue: any// Use the Property interface here
}



const EditBaisc: React.FC<DetailsProps> = ({ passedData, setFinalValue }) => {
  // const passedData = useSelector((state: any) => state.usersInfo.users);
  console.log(passedData)
  const [formVisible, setFormVisible] = useState<any>(false)
  const [FullData, setFullData] = useState<any>(passedData)


  console.log("baisc", FullData,passedData)
  
  setFinalValue(FullData)
  const handleForm = () => {
    setFormVisible(true);
    console.log("dvcrew")
  };

  const closeModal = () => {
    setFormVisible(false);
  };

  const renderBasicDetails = () => {
    const fullData = FullData[0] || {};

    return Object.keys(basics).map((key) => {
      switch (key) {
        case 'Property Type':
          return { [key]: fullData.res_or_com || '' };
        case 'Looking To':
          return { [key]: fullData.looking_to || fullData.rent_or_lease || fullData.property_available_for || '' };
        case 'Property Subtype':
          return { [key]: fullData.property_type || '' };
        case 'City':
          return { [key]: fullData.district || '' };
        case 'Locality':
          return { [key]: fullData.city || '' };
        case 'Landmark':
          return { [key]: fullData.landmark_street || '' };
        default:
          return { [key]: '' };
      }
    });
  };
  const handleNext = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    // setActiveStep(0);
  };

  // Get basic details
  const basicDetails = renderBasicDetails();

  // Log details to the console
  console.log('Basic Details:', basicDetails);

  return (
    <div className='my-4 flex flex-col gap- relative'>
      <p className='text-[#2E368F] text-xl font-semibold'>Basic Details</p>
      <img onClick={handleForm} src={editIcon} className='absolute right-0' />
      <div className='  mt-3'>
        <div className='flex flex-col gap-2 '>
          <div className='flex  '>
            <p className='w-[50%] font-bold'>Property Type</p>
            <p className='font-medium w-[50%]'> {FullData[0]?.property_type}</p>
          </div>
          <div className='flex '>
            <p className='w-[50%] font-bold'>Looking to</p>
            <p className='font-medium w-[50%]'>{FullData[0]?.looking_to || FullData[0]?.rent_or_lease || FullData[0]?.property_available_for} </p>
          </div>
          <div className='flex '>
            <p className='w-[50%] font-bold'>City</p>
            <p className='font-medium w-[50%]'>{FullData[0]?.district} </p>
          </div>
          <div className='flex '>
            <p className='w-[50%] font-bold'>Locality</p>
            <p className='font-medium w-[50%]'>{FullData[0]?.city} </p>
          </div>
          <div className='flex '>
            <p className='w-[50%] font-bold'>Landmark/Street</p>
            <p className='font-medium w-[50%]'> {FullData[0]?.landmark_street}</p>
          </div>





        </div>

      </div>
      <div className='border-1 border-[#8E8E8E] mt-3 '></div>

      <Modal
        open={formVisible}
        onClose={closeModal}
        aria-labelledby="basic-modal-title"
        aria-describedby="basic-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Basic onNext={handleNext} onBack={handleBack} FullData={FullData} setFinalValue={setFinalValue} onReset={handleReset} basicDetails={basicDetails} passedData={FullData} setFullData={setFullData} />
        </Box>
      </Modal>
    </div>
  )
}

export default EditBaisc