import { useState } from 'react';
import { Button, Divider, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import EditComponent from '../Icons/Edit';
import DeleteComponent from '../Icons/Delete';
import image from '../assets/Rectangle 477.png';
import image1 from '../assets/Rectangle 4771.png';
import PeopleComponent from '../Icons/People';
import deleteIcon from '../compounts/images/Delete.svg';
import edit from '../compounts/images/Edit.svg';
import People from '../compounts/images/icon_peoples.png';
import Listings from './Listings';
import arrow from '../compounts/images/arr.svg'
import { useSelector } from "react-redux";
import { axiosInstance } from "./api/APIs";
import { useLocation, useNavigate } from 'react-router-dom';
import DeletePopUp from './homepage/DeletePopUp';
import listing from "../compounts/images/myListing.png";
import { truncateString } from "./ReuseableCompounts/TruncateString";
import { formatPrice } from "../compounts/ReuseableCompounts/PriceFormat";
import { formatSQPrice } from "./ReuseableCompounts/PriceSQFormat";
import Default from "./images/defaultScroll.svg";
import { handleChange } from "../compounts/ReuseableCompounts/PostDetail";




interface Property {
    id: number;
    post_id: number;
    type: string;
    property_type?: any;
    looking_to?: string;
    rent_or_lease?: string;
    property_available_for?: string;
    images?: Image[];
    view_post_count: number;
    bhk: string;
    city: string;
    building_name: string;
    landmark_street: string;
    build_up_area?: string;
    built_up_area?: string;
    plot_area?: string;
    PriceperSqft?: string;
    price: number;
    cost?: number;
    monthly_rent?: number;
    enter_lease_amount?: number;
    lease_amount?: any
    liked?: any
    district: any
    user_id: any
    active: boolean;
}

interface ListingItemProps {
    list: Property[];
    filter: string;
    setVisable: any
    visiable: any
}
interface Image {
    filename: string;
    id: string;
    isCover: boolean;
    isvisible: boolean;
    name: string;
    type: string;
    uri: string;
}

const ListingItem: React.FC<ListingItemProps> = ({ list, filter, setVisable, visiable }) => {


    const [id, setId] = useState<any>("")
    const [Type, setType] = useState<any>("")
    const [look, setLook] = useState<any>("")

    console.log(list);
    const loginData = useSelector((item: any) => (item.userData.userLoginData))
    const navigate = useNavigate()


  async function handleNavigate(propertyView: any) {

        const body = {
            post_id: propertyView.postId,
            selected_property_type: propertyView.property_type,
            looking_to: propertyView.looking,
            user_id: propertyView.userId
        };
        try {
            const response = await axiosInstance.post("/filter/get/postDetail", body);
            navigate('/editform', { state: response.data   });
        } catch (error) {
            console.error('Error fetching data:', error);
        }


    }


    async function handleContactView(id: any) {
        const url = `/filter/contact/userData/get/${id}`
        console.log(url);


        try {
            const response = await axiosInstance.get(url)
            console.log(response.data.message)
            navigate('/contactUser', { state: { data: response.data.message } })

        } catch (error) {
            console.log("error", error)
        }
    }
    function handleDalete(id: any, type: any, look: any) {
        setId(id)
        setLook(look)
        setType(type)
        setVisable(true)
    }

    // const handleChange = async (id: number, property: string, looking_to?: string) => {
    //     const body = {
    //         post_id: id,
    //         selected_property_type: property,
    //         looking_to: looking_to || ''
    //     };
    //     try {
    //         const response = await axiosInstance.post("/filter/get/postDetail", body);
    //         navigate('/PropertyDetails', { state: { data: response.data, look: looking_to } });
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    // };

    const handleChange = async (id: number, property: string, looking_to?: string) => {
        const body = {
            post_id: id,
            selected_property_type: property,
            looking_to: looking_to || ''
        };

        try {
            const response = await axiosInstance.post("/filter/get/postDetail", body);
            navigate('/BuilderProperty', { state: { data: response.data, look: looking_to } })
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    return (
        <div>
            <div className={`${visiable ? "hidden" : "grid grid-cols-2 gap-3"}`}>
                {Array.isArray(list) ? (
                    list.map((item) => {
                        const price = item.price || item.cost || item.enter_lease_amount || item.lease_amount || (item.monthly_rent ? `${item.monthly_rent} / Month` : null);
                        const formattedPrice = formatPrice(price);
                        const locality = truncateString(item.city, 40)
                        const coverimg = item.images && item.images.length > 0 ? item.images.find(img => img.isCover) || "" : "";
                        const sqtv: any = item.PriceperSqft ? item.PriceperSqft : "";
                        const priceSQTV = formatSQPrice(sqtv);

                        const propertyView = {
                            postId: item.post_id,
                            property_type: item.property_type,
                            looking: item.looking_to || item.rent_or_lease || item.property_available_for,
                            userId: loginData.user_id
                        }


                        return (
                            <div
                                key={item.post_id}
                                className={`bg-[#F5F4F8] ${item.active == false ? "opacity-50" : ""} shadow-md rounded-lg p-2 m-2 mt-4 w-[35%] custom-xxl:w-[100%] custom-xl:w-[100%] custom-lg:w-[100%] xxl:w-[100%] sl:w-[100%] xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[100%]`}
                            >
                                <div className="flex">
                                    <img
                                        src={typeof coverimg === 'object' && coverimg?.uri ? coverimg.uri : Default}
                                       onClick={() => { handleChange(item.post_id, item.property_type, item.looking_to || item.rent_or_lease || item.property_available_for) }}
                                        className="custom-xxl:w-32 custom-xxl:h-32 custom-xl:w-32 custom-xl:h-32 custom-lg:w-32 custom-lg:h-32 xxl:w-28 xxl:h-28 sl:w-28 sl:h-28 xl:w-28 xl:h-28 lg:w-24 lg:h-24 md:w-24 md:h-24 sm:w-24 sm:h-24 object-cover rounded-lg"
                                    />
                                    <div
                                        onClick={() => {
                                            if (item.active) {
                                                handleChange(item.post_id, item.property_type, item.looking_to || item.rent_or_lease || item.property_available_for)
                                            }
                                        }}
                                        className="flex flex-col ml-10"
                                    >
                                        <span className="text-lg font-semibold">
                                            {item.bhk} {item.property_type}
                                        </span>
                                        <span className="  text-[#8E8E8E] mt-1">
                                            {locality}
                                        </span>
                                        <div className="flex gap-3 items-center mt-3">
                                            <span className="text-[#404040]">
                                                ₹ {item.monthly_rent ? `${formattedPrice} / Month` : formattedPrice}
                                            </span>
                                            <span className="mx-1 border-l h-5 border-gray-300"></span>
                                            <span className="md:text-xd  text-[#8E8E8E]">
                                                {item.built_up_area}  sq.ft.
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-end ml-auto mb-1">
                                        <IconButton
                                            onClick={() => handleNavigate(propertyView)}
                                            disabled={!item.active}
                                            size="small"
                                        >
                                            <img className='w-14 h-14' src={edit} />
                                        </IconButton>
                                        <IconButton
                                            disabled={!item.active}
                                            onClick={() => { handleDalete(item.post_id, item.property_type, item.looking_to || item.rent_or_lease || item.property_available_for) }} size="small" className="ml-1">
                                            <img className='w-14 h-14' src={deleteIcon} />
                                        </IconButton>
                                    </div>
                                </div>
                                <Divider
                                    orientation="horizontal"
                                    className="w-auto text-black "
                                />
                                <div className="flex items-center gap-10">
                                    <div

                                        className="custom-xxl:w-32 text-center font-medium  custom-xxl:h-6 custom-xl:w-32 custom-xl:h-6 xl:w-32 xl:h-6 lg:w-24 h-6 md:w-24 sm:w-24"
                                        style={{
                                            backgroundColor: '#FBB337',
                                            color: 'black',
                                            borderRadius: '25px',
                                        }}
                                    >
                                        {item.looking_to || item.rent_or_lease || item.property_available_for}
                                    </div>
                                    <div className="">
                                        <IconButton size="small">
                                            <PeopleComponent />
                                        </IconButton>
                                        <span className="text-sm font-semibold">
                                            {item.view_post_count} peoples watched
                                        </span>
                                    </div>
                                    <div>
                                        <IconButton onClick={() => { handleContactView(item.post_id) }} className='flex gap-1' size='small'>
                                            <img src={arrow} />
                                            <span className=" text-[#006CE7] text-sm"> peoples contacted you</span>
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                ) : (
                    <div>
                        <img className="absolute top-1/2 left-1/2 w-[50%] h-[50%] transform -translate-x-1/2 -translate-y-1/2" src={listing} />
                    </div>
                )}
            </div>
            <div className={`${visiable ? "block" : "hidden"} `}>
                <DeletePopUp look={look} id={id} property={Type} setVisable={setVisable} />
            </div>
        </div>

    );
};

export default ListingItem