import React from 'react'
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { AccountCircle, } from "@mui/icons-material";
import vattaraimg from "../../compounts/images/vattaraLogo.png";
import chat from '../../assets/chat.png';
import bell from '../../assets/bell.png'
import MNavbar from './MNavber';
import bell1 from '../images/bellmsm.svg'
import vattara from '../images/vattaralogomsm.svg'



const NavbarMain: React.FC = () => {


  const loginData = useSelector((state: any) => state.userData.userLoginData)
  const Navigate = useNavigate()


  const handleProfile = () => {
    if (loginData.phone !== "") {
      Navigate('/profile')
    }
    else {
      Navigate('/contact')
    }
  }


  const handlePost = () => {

    if (loginData.phone == "") {
      Navigate('/loginPost')
    }
    else if (loginData.category == "" || loginData.category == null) {
      Navigate('/loginPostList')
    }
    else if (loginData.name !== "" && loginData.category !== "" && loginData.category !== null) {
      console.log("post your add");

    }


  }

  const gradientStyle = {
    background: 'linear-gradient(to right, #9C55D3, #2D6B8E)',

  };

  function handleservices() {
    Navigate('/services')
  }
  function handleHome() {
    Navigate('/')
  }

  const handleChatClick = () => {


    if (loginData.user_id === undefined) {
      Navigate('/contact')
    }
    else {
      Navigate('/chatscreen');
    }

  };



  return (
    <div className=' xl:mb-14  lg:mb- md:mb-10 sm:mb-7 msm:mb-5'>
      <div className={`sm:fixed msm:sticky top-0 w-full z-20 msm:bg-[#FFFFFF] sm:bg-[#2E368F] sm:block lg:block border-b-slate-400  xl:h-14 lg:h-16 msm:h-`}>
        <ul className={`flex justify-between msm:p-2 lg:px-4 sm:px-2 sm:py-0 lg:py-0 xl:py- md:py-0  `}>
          <li onClick={handleHome} className="flex items-center">
            <img src={vattaraimg} className="w-10 h-10 msm:hidden sm:flex md:flex" alt="Vattara Image" />
            <img src={vattara} className='sm:hidden msm:flex' />

            <span className="ml-2 sm:font-bold lg:text-3xl md:text-xl  msm:hidden sm:flex  sm:text-white">Vattara</span>
          </li>

          <li className="flex  items-center gap- mt-1 sm:p-0 msm:p-2">
            <p onClick={handlePost} className='msm:bg-[#FBB337] py-1 sm:text-black  sm:bg-[#F5F4F8] msm:text-white rounded-xl px-4 msm:flex md:hidden sm:flex '> Post Ad</p>
            <p onClick={handlePost} className="text-[#121212] msm:hidden sm:hidden md:flex font-semibold text-lg py-1 px-3 bg-[#F5F4F8] rounded-lg ">
              Post Property Ad free
            </p >

            <div onClick={handleservices} className="sm:flex msm:hidden lg:gap-4 sm:gap-2 items-center relative lg:ml-5 sm:ml-2">

              <p className=" font-medium text-lg  mt-1 text-[#FFFFFF] lg:p-2 md:p-1">
                Services
              </p>
              <p style={gradientStyle} className='py-1 px-2 rounded-3xl text-[8px] text-white absolute lg:right-12 sm:right-1 top-1'>NEW</p>
              <li className="border h-9 text-white lg:ml-8 sm:ml-4 mr-5 opacity-30"></li>
            </div>


            {/* <div className='ml-2 '>
              <img className='msm:hidden sm:flex' src={bell} />
              <img src={bell1} className='msm:flex sm:hidden'/>
            </div> */}
            {/* <div className='border-1 border-white h-9 ml-2 opacity-30 lg:mr-4 sm:mr-2 msm:hidden sm:block'></div> */}
            <div onClick={handleChatClick}
              className=' gap-2 items-center msm:hidden sm:flex lg:mr-4'>
              <img src={chat} />
              <p className='text-white text-lg lg:flex sm:hidden'>Messages</p>
            </div>
            <div className='border-1 border-white h-9 ml-2 msm:hidden sm:flex opacity-30 lg:mr-4 sm:mr-1'></div>

            <div onClick={handleProfile} className='sm:flex msm:hidden justify-center items-center gap-2'>

              <AccountCircle className="text-white text-lg" />
              <span className="text-white capitalize text-lg font-medium" >{loginData.name !== "" ? loginData.name : "Login"}</span>
            </div>


          </li>
        </ul>
        <div className='border-1 border-[#F5F5F5] shadow-xl msm:flex sm:hidden'></div>
      </div>

    </div>
  )
}

export default NavbarMain