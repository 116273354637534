import React, { useState, useRef, useEffect, ChangeEvent } from 'react';
import { useStepperContext } from './StepperContext';
import { Button, TextField, Typography, Box } from '@mui/material';
import { updateValueInArray } from '../../PostForm/component';

interface TextFieldProps {
  label: string;
  formName: string;
  inputId?: number;
  value?: string;
  onChangeText?: (text: string) => void;
  required?: boolean;
  userselectedData?: any;
  initial?: string;
  label2?: any;
  baseData?:any
  setDataB:any
  passedData?:any
}

interface InitialValues {
  [key: string]: string;
}



const TextAreaBox: React.FC<TextFieldProps> = (props) => {
  const {
    label,
    formName,
    inputId,
    initial = '',
    onChangeText,
    required,
    baseData,
    setDataB,
    passedData
  } = props;

  const [textValue, setTextValue] = useState<string>(initial);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { addUserData, userData } = useStepperContext();
  const inputRef = useRef<HTMLTextAreaElement>(null);
console.log("hiuh",passedData)
  const MAX_CHAR_COUNT = 2000;

  // Update textValue when initial prop changes
  useEffect(() => {
    setTextValue(initial);
  }, [initial]);

  const generateParagraph = (data: any): string => {
    // const details = data.reduce((acc: Record<string, any>, item: any) => {
    //   const entry = Object.entries(item).find(([k]) => k !== 'formName' && k !== 'id' && k !== 'label');
    //   if (entry) {
    //     const [key, value] = entry;
    //     acc[key] = value;
    //   }
    //   return acc;
    // }, {});
    const transformValue = (value: any) => {
      if (value === 'Rent' || value === 'Lease') {
        return 'Rent/Lease';
      } else if (value === 'Sale') {
        return 'Sell';
      } else {
        return value; // or return a default value if needed
      }
    };


    const getInitialValues = (passedData: any): any => {
    const initialValues: any = {};

    // Use Object.entries to iterate over the key-value pairs of the object
    Object.entries(passedData).forEach(([key, value]) => {
      // Apply transformation if needed
      initialValues[key] = transformValue(value);
    });

    return initialValues;
   };
  
    const initialValues = getInitialValues(data[0]);
    console.log("auto initi",initialValues)

    const propertyType = initialValues.res_or_com    ;
   
    const lookingTo = initialValues.looking_to || initialValues.rent_or_lease || initialValues.property_available_for ;
    const propertysubtype = initialValues.property_type;

    console.log("check the avuto",propertyType,lookingTo,propertysubtype)
    let template = '';

    if (propertyType === 'Residential') {
      if (lookingTo === 'Rent/Lease') {
        template = `This is a ${initialValues.bhk} Residential ${initialValues.property_type} available for ${
          initialValues.looking_to || initialValues.rent_or_lease || initialValues.property_available_for 
        } in a well-established construction in the prime locality of ${initialValues.city }, ${initialValues.district}. The ${
          initialValues.property_type
        } offers generous living space ideal for families or individuals seeking comfort and convenience. The spacious unit spans ${
          initialValues.built_up_area
        } sq ft, offering ample living space for comfort. The ${initialValues.property_type} faces ${
          initialValues.Facing
        }, ensuring abundant natural light and a pleasant living environment. The ${
          initialValues.looking_to || initialValues.rent_or_lease || initialValues.property_available_for 
        } amount is set at ${initialValues.monthly_rent || ''} ${initialValues.lease_amount|| initialValues.enter_lease_amount|| ''}, providing excellent value for such a desirable area.`;

      }
      
      
      
      
      else if (lookingTo === 'Sell' && propertysubtype === 'Vacant Land') {
        template = `Welcome to an exceptional opportunity to build your dream home on this ${initialValues.built_up_area} square feet residential vacant land. Nestled in ${
          initialValues.locality
        }, ${initialValues.city  }, this plot faces ${initialValues.Facing}, offering abundant natural light and scenic views. Priced competitively ${
          initialValues.price
        }, this blank canvas awaits your vision for a custom-designed residence. Enjoy the conveniences such as parks, schools, shopping centers, etc. Don't miss out on securing this prime piece of real estate for your future!`;
      } else if (lookingTo === 'Sell') {
        template = `This ${initialValues.bhk} ${initialValues.property_type        } is available for sale in a prestigious apartment complex in ${
          initialValues.city
        }, ${initialValues.district}. The apartment offers a spacious living area of ${initialValues.built_up_area} sq ft. Experience a vibrant community lifestyle, complete with optional facilities ${
          initialValues.society_amenities
        }. Take this chance to own a premium property at an attractive price. The apartment faces ${initialValues.Facing}, ensuring abundant natural light and a pleasant living environment. It is attractively priced at ${
          initialValues.price
        }, with the option to negotiate the amount, making it an excellent opportunity for potential buyers to secure a desirable property in a prime location.`;
      }
    } 
    else if (propertyType === 'Commercial') {
      if (lookingTo === 'Rent/Lease') {
        template = `Discover the ideal commercial property for ${initialValues.property_available_for}, featuring spacious ${
          initialValues.property_type
        } spaces ranging from ${initialValues.built_up_area} sq ft, available at competitive rental as ${
          initialValues.monthly_rent
        } ${initialValues.lease_amount|| initialValues.enter_lease_amount|| ''}. The property is strategically oriented to optimize natural light, with options facing ${
          initialValues.Facing
        }. Situated in ${initialValues.city}, ${initialValues.district}, prime locations with excellent transport links, these spaces are perfect for various business needs. Designed to foster productivity and efficiency, they offer a solid foundation for your operations. Secure your optimal ${
          initialValues.property_type
        } today and enhance your business prospects with attractive terms.`;
      } else if (lookingTo === 'Sell') {
        template = `Explore this prime commercial property for sell in a vibrant location at ${
          initialValues.city
        }, ${initialValues.district}, offering versatile spaces ${initialValues.property_type}. Spanning ${
          initialValues.built_up_area
        } square feet, it boasts ${initialValues.Facing} facing for optimal visibility and natural light. Priced competitively at ${
          initialValues.price
        }, it presents a compelling investment opportunity in a rapidly growing commercial hub. With modern infrastructure and strategic accessibility, this property is ideal for expanding businesses or lucrative income. Don't miss out—schedule a viewing today to seize this promising investment in ${
          initialValues.city
        }.`;
      }
    }

    return template;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    setTextValue(value);

    const updatedData = updateValueInArray(baseData, label, event.target.value);
    console.log("new", updatedData)
    setDataB(updatedData)


    const data = {
      id: inputId,
      [label]: value,
      formName,
      required,
    };
    addUserData(data as any);
    onChangeText?.(value);
  };

  const handleAutoGenerate = () => {
    const paragraph = generateParagraph(passedData);
    console.log("auto",paragraph)
    
    setTextValue(paragraph);
    const updatedData = updateValueInArray(baseData, label, paragraph);
    console.log("new", updatedData)
    setDataB(updatedData)


    const data = {
      id: inputId,
      [label]: paragraph,
      formName,
      required,
    };
    addUserData(data as any);
    inputRef.current?.blur();
  };

  const handleTextChange = (text: string) => {
    if (text.length <= MAX_CHAR_COUNT) {
      setTextValue(text);
      handleChange({ target: { value: text } } as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
      onChangeText?.(text);
    }
  };

  return (
    <div>
      <TextField
        multiline
        rows={3}
        inputProps={{ maxLength: MAX_CHAR_COUNT }}
        variant='outlined'
        id={label}
        placeholder={label}
        label={label}
        value={textValue}
        onFocus={() => setIsFocused(true)}
        onChange={(event) => handleTextChange(event.target.value)}
        inputRef={inputRef}
      />
      <Box sx={{ mt: 1 }}>
        <Typography variant="caption" color="textSecondary">
          {textValue.length}/{MAX_CHAR_COUNT} characters
        </Typography>
      </Box>
      <Button onClick={handleAutoGenerate} variant="contained" color="primary">
        Auto Generate
      </Button>
    </div>
  );
};

export default TextAreaBox;
