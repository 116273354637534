import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import 'react-phone-input-2/lib/style.css';
import image1 from '../images/login-2.png';
import image2 from '../images/log-1.png';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

import 'react-phone-input-2/lib/style.css';
import Verification from './Verification';
import Verified from './Verified';
import List from './List'
import { axiosInstance } from "../api/APIs";



const Modal: React.FC = () => {
    const [phone, setPhone] = useState<string>('');
    const [isValid, setIsValid] = useState<boolean>(true);
    const [UserId,setUserId]=useState<any>("")
    const [showVerification, setShowVerification] = useState<string>('Number');
    const [token,setToken]=useState<any>()
    const [encryptedToken, setEncryptedToken] = useState<object | null>(null);
    console.log(UserId);
    
    console.log(token)
    console.log(encryptedToken)


    const handleStore = async () => {
        if (phone.length === 13) {
            setIsValid(true);
            const body = {
                mobile_no: phone,
                user_language: "en"
            };
            try {
                const response = await axiosInstance.post("/user/user/loginWithMobileno", body);
                console.log('Response:', response.data.message);
                setShowVerification("Otp");
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            console.log("Phone number must be exactly 10 digits");
            setIsValid(false);
        }
    };
    const handleClose = () => {
        setShowVerification("Number")
       
      };
    return (
        <div>
            {/* Modal content for entering phone number */}
            <div className={`fixed flex justify-center items-center z-10 left-0 top-0 w-full h-full overflow-auto ${showVerification === "Number" ? "flex" : "hidden"}`}>
                <div className="bg-white m-auto p-6 border border-gray-300 border-solid w-3/4 max-w-4xl overflow-hidden max-h-[58%] h-[75%] rounded-[2%] flex flex-col relative sm:flex sm:flex-col sm:gap-2 sm:align-middle">
                   
                    <div className="flex flex-row h-full p-0">
                        <div className="relative z-10">
                            <div className="absolute top-[-100px] left-[-80px] mt-5 w-56 h-56 rounded-full" style={{ backgroundColor: "#FCEAB6" }}></div>
                           
                        </div>
                        <div className="flex-1 flex flex-col justify-center ml-[5%] mt-[11%] gap-2.5">
                        <div className="absolute right-4  top-3 text-gray-500 text-2xl font-bold cursor-pointer z-20 md" onClick={handleClose}>&times;</div>
                            <h4 className="font-bold relative z-20 ml-12">Enter Your Mobile Number</h4>
                            <Box sx={{ '& > :not(style)': { m: 1 } }} className='sm:mr-70'>
                                <Box sx={{ justifyContent: "center", alignItems: "center" }}>
                                    <PhoneInput
                                        defaultCountry="in"
                                        value={phone}
                                        placeholder='Phone Number'
                                        onChange={(phone) => setPhone(phone)}
                                        inputStyle={{
                                            border: 'none',
                                            borderBottom: isValid ? '1px solid lightgray' : '1px solid red',
                                            borderRadius: '',
                                            width: "65%",
                                            whiteSpace: "none"
                                        }}
                                        countrySelectorStyleProps={{ buttonStyle: { border: 0, borderBottom: '1px solid lightgray' } }}
                                    />
                                </Box>
                                <div style={{ display: "flex", flexDirection: "column", gap: "1px", marginLeft: "18%", position: "relative", top: "-10px" }}>
                                    <Button
                                        variant="contained"
                                        onClick={handleStore}
                                        sx={{
                                            borderRadius: '20px',
                                            width: '50%',
                                            fontWeight: "bold",
                                            color: "black",
                                            mt: 5,
                                            backgroundColor: 'orange',
                                            textTransform: 'capitalize',
                                            '&:hover': {
                                                backgroundColor: 'darkorange'
                                            }
                                        }}
                                    >
                                        Verify
                                    </Button>
                                    {!isValid && <p className="error">Phone number must be exactly 10 digits.</p>}
                                </div>
                                <div className="w-80">
                                    <p className="mt-16 text-xs text-center">
                                        By continuing you agree to KB Realty & Associates<br />
                                        <span className="font-bold text-xs">Terms of Use & Privacy Policy</span>
                                    </p>
                                </div>
                            </Box>
                        </div>
                        <div className="flex-1 flex justify-around items-center gap-4">
                            <div className="mt-72">
                                <img src={image1} alt='img' />
                            </div>
                            <div className="mt-40">
                                <img src={image2} alt='img' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           
            <div className={`${showVerification === "Otp" ? "flex" : "hidden"}`}>
            <Verification onClose={handleClose} phone={phone} setUserId={setUserId} setToken={setToken} setEncryptedToken={setEncryptedToken} setShowVerification={setShowVerification} />
            </div>

            
            <div className={`${showVerification === "tick" ? "flex" : "hidden"}`}>
                <Verified onClose={handleClose} showVerification={showVerification} setShowVerification={setShowVerification}/>
            </div>
            <div className={`${showVerification === "List" ? "flex" : "hidden"}`}>
            <List onClose={handleClose} phone={phone} UserId={UserId} token={token} encryptedToken={encryptedToken}/>
            </div>
        </div>
    );
};

export default Modal;
