import React, { useEffect, useState } from "react";
import { useStepperContext } from "./StepperContext";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import TextBox from "./TextBox";
import CustomisedButton from "./CustomisedButton";
import Popup from "./Popup";

import {
  CommerRentRetailnShow,
  CommercialIndSpace,
  CommercialSellIndSp,
  CommercialSellOff,
  CommercialSellRetailnShow,
  CommercialSellWare,
  CommercialrentOffice,
  CommercialrentWareh,
  ResidentialSellPlot,
  Residentialrent,
  Residentialsell,

} from "../data";
import CustomButton from "./CustomButton";
import CustomBoxButton from "./CustomBoxButton";
import DropBox from "./DropBox";
import DropBox1 from "./DropBox1";
import FDropDown from "./FDropDown";
import DropDown1 from "./DropDown1";
import PriceTextField from "./PriceTextField";
import PriceTextField1 from "./PriceTextField1";
import PriceTextField2 from "./PriceTextField2";
import DatePick from "./DatePick";
import CustomisedButton1 from "./CustomisedButton1";
import AccessButton from "./AccessButton";
import TextAreaBox from "./TextAreaBox";
import YFDropdown from "./YFDropdown";
// import ModalDataForm from "./ModalDataForm";
import MultiSelectorAmenitiesButton from "../reusablecomponent/MultiSelectorAmenitiesButton";
import MultiSelectorButton2 from "../reusablecomponent/MultiSelectorButton2";
import { datafinder } from "../../PostForm/component";

interface FormField {
  id: number;
  title: string;
  icon: string;
}

export interface FormOffers {
  formName: string;
  fields: {
    type: string;
    data: FormField[];
    label: string;
    inputId: number;
    required: boolean;
  }[];
}

interface PropertyDetailsProps {
  // onNext?: () => void;
  // onBack?: () => void;
  // onReset: () => void;
  setFullData?: any
  propertyDetail: any
  passedData: any
  setFinalValue?: any
}

interface InitialValues {
  [key: string]: string;
}

const PropertyDetails: React.FC<PropertyDetailsProps> = ({
  // onNext,
  // onBack,
  // onReset,
  setFullData,
  passedData,
  propertyDetail,
  setFinalValue,


}) => {
  const {
    addUserData,
    userData,
    navprop,
    ammenties,
    addCurrentPage,
    customFormProps,
    addCustomFormDetails,
    customFormDetails,
    addnavprop,
  } = useStepperContext();


  const [userselectedData, setuserSelectedData] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false); // State for modal visibility
  const [mypass, setMypass] = useState(passedData)
  const [dataB, setDataB] = useState(propertyDetail)
  console.log("propertyDetail array", propertyDetail, mypass)

  const transformValue = (value: any) => {
    if (value === 'Rent' || value === 'Lease') {
      return 'Rent/Lease';
    } else if (value === 'Sale') {
      return 'Sell';
    } else {
      return value; // or return a default value if needed
    }
  };

  const getInitialValues = (passedData: any): any => {
    const initialValues: any = {};

    // Use Object.entries to iterate over the key-value pairs of the object
    Object.entries(passedData).forEach(([key, value]) => {
      // Apply transformation if needed
      initialValues[key] = transformValue(value);
    });

    return initialValues;
  };

  const initialValues = getInitialValues(passedData[0]);
  const InitialValues = getInitialValues(propertyDetail);
  console.log("initialValues from propertyDetail", initialValues, InitialValues);

  const Construction = initialValues?.["Possession Status"]
  const GetProperty = () => {
    if (passedData) {
      const Looking_To =
        initialValues?.["looking_to"] ||
        initialValues?.["rent_or_lease"] ||
        initialValues?.["property_available_for"];

      const select_property_type = initialValues?.["property_type"];
      console.log("look in details", Looking_To, select_property_type)

      if (Looking_To === "Rent/Lease") {
        switch (select_property_type) {
          case "Apartment":
          case "Independent House":
          case "Villa":
            return Residentialrent;
          case "Retail Shop":
          case "Showroom":
            return CommerRentRetailnShow;
          case "Office Space":
            return CommercialrentOffice;
          case "Warehouse":
            return CommercialrentWareh;
          case "Industrial Space":
            return CommercialIndSpace;
          default:
            return null;
        }
      } else if (Looking_To === "Sell") {
        switch (select_property_type) {
          case "Vacant Land":
            return ResidentialSellPlot;
          case "Retail Shop":
          case "Showroom":
            return CommercialSellRetailnShow;
          case "Office Space":
            return CommercialSellOff;
          case "Warehouse":
            return CommercialSellWare;
          case "Industrial Space":
            return CommercialSellIndSp;
          default:
            return Residentialsell;
        }
      }
    }
  };

  const data2 = GetProperty();
  console.log("my data", data2);
  useEffect(() => {
    if (data2) {
      // addCustomFormDetails(data2)
      addCustomFormDetails(data2);
    }
    if (Construction) {
      setSelectedValue(Construction)
    }
  }, [data2, customFormDetails, Construction]);



  const generateValidationSchema = (customFormDetails: any, userData: any) => {
    let requiredLabels = customFormDetails?.property_details
      ?.filter((item: any) => item.required)
      ?.map((item: any) => item.label);

    // If possession status is 'Under Construction', ensure 'calendar2' is included in required labels
    if (selectedValue === "Under Construction") {
      const calendar2Label = customFormDetails?.property_details?.find(
        (item: any) => item.Type === "calendar2"
      )?.label;
      if (calendar2Label) {
        requiredLabels.push(calendar2Label);
      }
    }

    const existingLabels = requiredLabels?.filter(
      (label: any) => !userData.some((item: any) => item.hasOwnProperty(label))
    );

    if (existingLabels?.length > 0) {
      setuserSelectedData(existingLabels);
    } else {
      // onNext();
    }
  };
  const amenitiesObject = dataB.find((item: any) => item.Amenities !== undefined);

  // Extract the value of 'Amenities'
  const Amenitie = amenitiesObject ? amenitiesObject.Amenities : "";

  const furnishTypeObject = dataB.find((item: any) => item['Furnishing'] !== undefined);

  // Extract the value of 'Furnish Type'
  const flat = furnishTypeObject ? furnishTypeObject['Furnishing'] : null;

  const handleSubmit = () => {
    console.log("proerty DataB", dataB)
    const update = mypass.map((item: any) => {
   


      const builtUpAreaValue = (item.property_type === "Vacant Land") || (item.property_type === "Industrial Space")
        ? datafinder(dataB as any, "Plot Area")
        : datafinder(dataB as any, "Built up Area" || "Built Up Area");


      return {
        ...item,
        building_name: datafinder(dataB as any, "Building/Project(Optional)"),
        bhk: datafinder(dataB as any, "BHK"),
        built_up_area: (item.property_type === "Office Space" ||
          item.property_type === "Warehouse" ||
          item.property_type === "Showroom" ||
          (item.property_type === "Retail Shop" &&
            (item.property_available_for === "Rent" || item.property_available_for === "Lease")))
          ? datafinder(dataB as any, "Built Up Area")
          : builtUpAreaValue,
        furnish_type: datafinder(dataB as any, "Furnish Type"),
        flat_furnishing: datafinder(dataB as any, "Furnishing"),
        society_amenities: datafinder(dataB as any, "Amenities"),
        possession_status: datafinder(dataB as any, "Possession Status"),
        Suitable_for: datafinder(dataB as any, "Suitable For"),
        parking: datafinder(dataB as any, "Parking"),
        washroom: datafinder(dataB as any, "Washroom"),
        total_floors: datafinder(dataB as any, "Total Floors"),
        select_your_floor: datafinder(dataB as any, "Select Your Floor"),
        width: datafinder(dataB as any, "Width"),
        length: datafinder(dataB as any, "Length"),
        approval_status: datafinder(dataB as any, 'Property Status'),
        patta_available: datafinder(dataB as any, "Patta Available"),
        facing: datafinder(dataB as any, "Facing"),
        carpet_area: datafinder(dataB as any, "Carpet Area"),
        property_condition: datafinder(dataB as any, "Property Condition"),
        meeting_room: datafinder(dataB as any, "Meeting Room"),
        min_num_seat: item.property_type === "Office Space"
          ? datafinder(dataB as any, "Min. Number of seats") : datafinder(dataB as any, "Min.Number of seats"),
        max_num_seat: item.property_type === "Office Space"
          ? datafinder(dataB as any, "Max. Number of seats") : datafinder(dataB as any, "Max.Number of seats"),
        lifts: datafinder(dataB as any, "Lifts"),



      }
    })
    console.log("for paassed", update, dataB)
    setFullData(update)



    // Log the filled fields

    // if (selectedValue === "Ready to Move") {

    //   // onNext(); // Go to the next page if possession status is "Ready to Move"
    // } else if (selectedValue === "Under Construction") {
    //   generateValidationSchema(customFormDetails, userData); // Validate if possession status is "Under Construction"
    // } else {
    //   generateValidationSchema(customFormDetails, userData);
    // }
  };


  console.log("userData", userData);

  const getInitialValue = (label: any) => {
    const entry = propertyDetail.find((obj: any) => obj.hasOwnProperty(label));
    return entry ? entry[label] : "";
  };

  const property = initialValues?.["res_or_com"]

  const select_type = initialValues?.["property_type"]


  const furnish = initialValues?.["flat_furnishing"]
  console.log("furnish", furnish)
  const amenity = initialValues?.["society_amenities"]

  console.log("amenities", amenity)


  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  console.log("check for under", selectedValue)
  const handleBoxButtonChange = (value: string) => {
    setSelectedValue(value);
    console.log("check for under", value)
  };



  return (
    <>
      <div>
        <Typography>Property Details</Typography>

        {data2?.property_details?.map((dataItem: any, index: number) => (
          <Box key={index}>
            {dataItem.Type === "Text Field" && (
              <div className="my-3">
                {/* <label>{item.label}</label> */}
                <TextBox
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName="Property Details"
                  baseData={dataB}
                  setDataB={setDataB}
                  initial={getInitialValue(dataItem.label)}
                  label2={dataItem.label2}
                  keyboard={dataItem.keyboard}
                  userselectedData={userselectedData}
                />
              </div>
            )}
            {dataItem.Type === "Text Field Built" && (
              <div className="my-3">
                {/* <label>{item.label}</label> */}
                <TextBox
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName="Property Details"
                  baseData={dataB}
                  setDataB={setDataB}
                  label2={dataItem.label2}
                  keyboard={dataItem.keyboard}
                  userselectedData={userselectedData}
                  initial={getInitialValue(dataItem.label)}
                />
              </div>
            )}
            {dataItem.Type === "Box Type" && (
              <div className="my-1">
                <Typography className="text-start " >
                  {dataItem.label}
                </Typography>
                <div className="flex flex-row flex-wrap ">
                  {/* {dataItem.data && dataItem.data.map((buttonItem,buttonIndex)=>( */}
                  <CustomisedButton
                    data={dataItem.data}
                    label={dataItem.label}
                    inputId={dataItem.inputID}
                    formName="Property Details"
                    baseData={dataB}
                    setDataB={setDataB}
                    required={dataItem.required}
                    userselectedData={userselectedData}
                    initial={getInitialValue(dataItem.label)}
                  />

                  {/* ))} */}
                </div>
              </div>
            )}
            {dataItem.Type === "Custom Box Type2" && (
              <div className="my-3">
                <Typography className="text-start">
                  {dataItem.label}
                  {dataItem.required && (
                    <span style={{ color: "red" }}> *</span>
                  )}{" "}
                  {/* Indicate required fields */}
                </Typography>
                <div className="flex flex-row flex-nowrap gap-2 my-5">
                  {/* {dataItem.data && dataItem.data.map((buttonItem,buttonIndex)=>( */}
                  <Box>
                    <CustomBoxButton
                      data={dataItem.data}
                      // title={buttonItem.title}
                      label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName="Property Details"
                      baseData={dataB}
                      setDataB={setDataB}
                      initial={getInitialValue(dataItem.label)}
                      required={dataItem.required}
                      userselectedData={userselectedData}
                      onSelectionChange={handleBoxButtonChange} // Pass the handler
                    />
                  </Box>
                  {/* ))} */}
                </div>
              </div>
            )}

            {dataItem.Type === "Custom Box Type" && (
              <div className="my-3">
                <Typography className="text-start">
                  {dataItem.label}
                  {dataItem.required && (
                    <span style={{ color: "red" }}> *</span>
                  )}{" "}
                  {/* Indicate required fields */}
                </Typography>
                <div className="flex flex-row flex-nowrap gap-2 my-5">
                  {/* {dataItem.data && dataItem.data.map((buttonItem,buttonIndex)=>( */}
                  <Box>
                    <CustomBoxButton
                      data={dataItem.data}
                      // title={buttonItem.title}
                      label={dataItem.label}
                      inputId={dataItem.inputID}
                      baseData={dataB}
                      setDataB={setDataB}
                      formName="Property Details"
                      initial={getInitialValue(dataItem.label)}
                      required={dataItem.required}
                      userselectedData={userselectedData}
                      onSelectionChange={handleBoxButtonChange} // Pass the handler
                    />
                  </Box>
                  {/* ))} */}
                </div>
              </div>
            )}
            {dataItem.Type === "Box Type10" && (
              <div className="my-3">
                <Typography className="text-start">
                  {dataItem.label}
                  {dataItem.required && (
                    <span style={{ color: "red" }}> *</span>
                  )}
                  {/* Indicate required fields */}
                </Typography>
                <div className="flex flex-row flex-wrap gap-4 my-5">
                  {/* {dataItem.data && dataItem.data.map((buttonItem,buttonIndex)=>( */}
                  <div>
                    <MultiSelectorAmenitiesButton
                      data={dataItem.data}
                      label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName="Property Details"
                      initial={getInitialValue(dataItem.label)}
                      required={dataItem.required}
                      userselectedData={userselectedData}
                    />
                  </div>
                  {/* ))} */}
                </div>
              </div>
            )}

            {dataItem.Type === "Box Type2" && (
              <div className="my-3">
                <Typography className="text-start">
                  {dataItem.label}
                  {dataItem.required && (
                    <span style={{ color: "red" }}> *</span>
                  )}{" "}
                  {/* Indicate required fields */}
                </Typography>
                <div className="flex flex-row flex-wrap gap-4 my-5">
                  {/* {dataItem.data && dataItem.data.map((buttonItem,buttonIndex)=>( */}
                  <div>
                    <CustomButton
                      data={dataItem.data}
                      label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName="Property Details"
                      initial={getInitialValue(dataItem.label)}
                      required={dataItem.required}
                      userselectedData={userselectedData}
                    />
                  </div>
                  {/* ))} */}
                </div>
              </div>
            )}

            {dataItem.Type === "calendar2" &&
              selectedValue === "Under Construction" && (
                <div>
                  <DatePick
                    label={dataItem.label}
                    inputId={dataItem.inputID}
                    formName="Property Details"
                    baseData={dataB}
                    setDataB={setDataB}
                    required={dataItem.required}
                    userselectedData={userselectedData}
                    initial={getInitialValue(dataItem.label)}
                  />
                </div>
              )}

            {dataItem.Type === "Box Type11" && (
              <div>
                <Typography className="text-start">{dataItem.label}</Typography>
                <div className="flex gap-7 my-5">
                  {/* {dataItem.data && dataItem.data.map((buttonItem,buttonIndex)=>( */}
                  <Box>
                    <MultiSelectorButton2
                      data={dataItem.data}
                      label={dataItem.label}
                      baseData={dataB}
                      setDataB={setDataB}
                      initial={getInitialValue(dataItem.label)}
                      inputId={dataItem.inputID}
                      formName="Property Details"
                      required={dataItem.required}
                    />
                  </Box>
                  {/* ))} */}
                </div>
              </div>
            )}
            {dataItem.Type === "Box Type3" && (
              <div>
                <Typography sx={{ marginY: "12px" }} className="text-start">
                  {dataItem.label}
                </Typography>
                <div className="flex flex-row gap-10">
                  <div className="my-3">
                    <AccessButton
                      data={dataItem.data}
                      label={dataItem.label}
                      baseData={dataB}
                      setDataB={setDataB}
                      inputId={dataItem.inputID}
                      formName="Property Details"
                      required={dataItem.required}
                      userselectedData={userselectedData}
                      initial={getInitialValue(dataItem.label)}
                    />
                  </div>
                  {/* ))} */}
                </div>
              </div>
            )}
            {dataItem.Type === "Drop down" && (
              <div>
                <DropBox1
                  label={dataItem.label}
                  options={dataItem.data}
                  inputId={dataItem.inputID}
                  formName="Property Details"
                  required={dataItem.required}
                  userselectedData={userselectedData}
                  initial={getInitialValue(dataItem.label)}
                />
              </div>
            )}

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ width: "60%" }}>
                {dataItem.Type === "FDrop down" && (
                  <div className="text-start" style={{ marginTop: "5%" }}>
                    <FDropDown
                      label={dataItem.label}
                      options={dataItem.data}
                      inputId={dataItem.inputID}
                      baseData={dataB}
                      setDataB={setDataB}
                      formName="Property Details"
                      required={dataItem.required}
                      userselectedData={userselectedData}
                      initial={getInitialValue(dataItem.label)}
                    />
                  </div>
                )}
              </div>
              <div style={{ width: "60%" }}>
                {dataItem.Type === "Drop down1" && (
                  <div className="text-end" style={{ marginTop: "-30%" }}>
                    <YFDropdown
                      label={dataItem.label}
                      options={dataItem.data}
                      inputId={dataItem.inputID}
                      baseData={dataB}
                      setDataB={setDataB}
                      formName="Property Details"
                      required={dataItem.required}
                      userselectedData={userselectedData}
                      initial={getInitialValue(dataItem.label)}
                    />
                  </div>
                )}
              </div>
            </div>
            {dataItem.Type === "Price Text Field" && (
              <div>
                {/* <label>{item.label}</label> */}
                <PriceTextField
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  baseData={dataB}
                  setDataB={setDataB}
                  formName="Property Details"
                  label2={dataItem.label2}
                  keyboard={dataItem.keyboard}
                  required={dataItem.required}
                  userselectedData={userselectedData}
                />
              </div>
            )}
            {dataItem.Type === "Price Text Field1" && (
              <div>
                {/* <label>{item.label}</label> */}
                <PriceTextField1
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName="Property Details"
                  label2={dataItem.label2}
                  keyboard={dataItem.keyboard}
                  required={dataItem.required}
                  userselectedData={userselectedData}
                />
              </div>
            )}
            {dataItem.Type === "Price Text Field2" && (
              <div>
                {/* <label>{item.label}</label> */}
                <PriceTextField2
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  baseData={dataB}
                  setDataB={setDataB}
                  formName="Property Details"
                  label2={dataItem.label2}
                  keyboard={dataItem.keyboard}
                  required={dataItem.required}
                  userselectedData={userselectedData}
                />
              </div>
            )}
            {dataItem.Type === "calender" && (
              <div>
                <DatePick
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName="Property Details"
                  required={dataItem.required}
                  userselectedData={userselectedData}
                />
              </div>
            )}

            {dataItem.Type === "Text Area" && (
              <div>
                <Typography className="text-start">{dataItem.label}</Typography>
                <TextAreaBox
                  label={dataItem.label}
                  baseData={dataB}
                  setDataB={setDataB}
                  inputId={dataItem.inputID}
                  formName="Property Details"
                  required={dataItem.required}
                  userselectedData={userselectedData}
                />
              </div>
            )}
            <></>
          </Box>
        ))}

        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 10 }}
        >
          <Box sx={{ bgcolor: "#fff", width: "40%", p: 2, justifyContent: "center", alignItems: "center" }}>
            {data2?.property_details?.map((dataItem: any, index: any) => (
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                {dataItem.Type === "Furnishing" && (
                  <div key={index}>
                    <Typography className="text-start text-black font-bold">
                      {dataItem.label}
                    </Typography>
                    <div style={{ display: "flex", flexWrap: "wrap", marginTop: 10, marginBottom: 10 }}>
                      <MultiSelectorButton2
                        data={dataItem.data}
                        label={dataItem.label}
                        inputId={dataItem.inputID}
                        baseData={dataB}
                        setDataB={setDataB}
                        formName="Property Details"
                        required={dataItem.required}
                        userselectedData={userselectedData}
                        initial={getInitialValue(dataItem.label)}
                      />
                    </div>
                  </div>
                )}
                {dataItem.Type === "AMENITIES" && (
                  <div key={index}>
                    <Typography className="text-start">
                      {dataItem.label}
                    </Typography>
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                      <MultiSelectorButton2
                        data={dataItem.data}
                        label={dataItem.label}
                        inputId={dataItem.inputID}
                        baseData={dataB}
                        setDataB={setDataB}
                        formName="Property Details"
                        required={dataItem.required}
                        userselectedData={userselectedData}
                        initial={getInitialValue(dataItem.label)}
                      />
                    </div>
                  </div>
                )}
              </Box>
            ))}
            <Button variant="contained" sx={{ width: "20%", justifyContent: "center", display: "flex", mx: "auto", my: 2 }} onClick={() => { setModalOpen(!modalOpen) }}>Save</Button>
          </Box>
        </Modal>


        {/* <Typography variant="h6">Built Up Area</Typography>
      <Typography>{getInitialValue("Built up Area")}</Typography>
      <Typography>{getInitialValue("Plot Area")}</Typography> */}
        {property !== "Commercial" && select_type !== "Vacant Land" && (
          <>
            <button style={{ color: "#0C3D5A", fontWeight: "400" }} onClick={() => setModalOpen(!modalOpen)}>+ Add Furnishings / Amenities</button>
            <Typography sx={{ my: 1 }}><span style={{ fontWeight: 600 }}>Furnishing:</span>{`${flat.length > 0 ? flat : furnish} `}</Typography>
            <Typography ><span style={{ fontWeight: 600 }}>Ammenities:</span>{`${Amenitie.length > 0 ? Amenitie : amenity}`}</Typography>
          </>)}


      </div>
      <Button sx={{}} variant="contained" onClick={handleSubmit} type="submit">
        Continue
      </Button>
    </>
  );
};

export default PropertyDetails;
