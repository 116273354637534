import React from "react";
import Navbar from "./Navbar";
import { useState } from "react";
import Card from './Card'
import PropertyHousing from "./PropertyHousing";
import Services from "./Services";
import PropertyCard from "./PropertyCard";
import WhyVattara from "./WhyVattara";
import Tagline from "./Tagline";
import Footer from "../Footer/Footer";
import AllFlats from "./AllFlats";
import ProjectsCarousel from "./Carousel";
import  AppPlayStore  from "./AppPlayStore";
import MNavbar from "./MNavber";
import NavbarMain from "./NavbarMain";
import RecommendedProperties from "./RecommendedProperties";
// import MobileNavbar from "../MobileNavbar";

export default function Main() {
  const [lookingToValue, setLookingToValue] = useState("Buy");
  console.log(lookingToValue)
  return (

    <div className="" >
    
      <div className="msm:block sm:block md:hidden">
      <NavbarMain  />
      </div>
      
      {/* <AllFlats /> */}
      <div className=" ">
      <Navbar setLookingToValue={setLookingToValue}/>
        <Card lookingToValue={lookingToValue}/>
        <RecommendedProperties lookingToValue={lookingToValue}/>
        <PropertyHousing lookingToValue={lookingToValue} />
        <ProjectsCarousel />
        <Services />
        <PropertyCard />
        <Tagline />
        <WhyVattara />
        <AppPlayStore/>

        <div className={`lg:hidden sm:hidden msm:flex fixed bottom-5  z-50`}>
          <MNavbar />
        </div>
      </div>
      <Footer />
    </div>
  );
}
