import React, { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import image1 from '../images/login-2.png';
import image2 from '../images/log-1.png';
import OtpInput from 'react-otp-input';
import { axiosInstance } from "../api/APIs";
import { useDispatch } from "react-redux";
import { setUpdateToken, setUpdateEncryptedToken } from '../../slices/userDataSlice';

interface ModalProps {
    onClose: () => void;
    phone?: string;
    setShowVerification?: (value: string) => void;
    setUserId?: (id: string) => void;
    setToken?: any
    setEncryptedToken?: any
}

const Verification: React.FC<ModalProps> = ({ onClose, phone, setShowVerification, setUserId, setToken, setEncryptedToken }) => {
    const [otp, setOtp] = useState('');
    const [counter, setCounter] = useState(30);
    const [canResend, setCanResend] = useState(false);

    const dispatch = useDispatch()

    let lastFourDigits = phone?.slice(-4);

    useEffect(() => {
        if (otp.length === 4) {
            tiggerApi();
        }
    }, [otp]);

    useEffect(() => {
        if (counter === 0) {
            setCanResend(true);
            return;
        }
        const timer = setInterval(() => {
            setCounter((prevCounter) => prevCounter - 1);
        }, 1000);
        return () => clearInterval(timer);
    }, [counter]);

    const tiggerApi = async () => {
        const body: any = {
            mobile_no: phone,
            otp: otp,
            device_name: "pixel"
        };

        try {
            const response = await axiosInstance.post("/user/user/OtpVerify", body);
            console.log('Response:', response.data);

            if (setUserId) setUserId(response.data.UserId);
            if (setShowVerification) setShowVerification("tick");
            setToken(response.data.token)
            setOtp("");
            setEncryptedToken(response.data.encryptedToken)
            dispatch(setUpdateToken(response.data.token));
            dispatch(setUpdateEncryptedToken(response.data.encryptedToken));

            // if (true) {

            // } else {

            // }

        } catch (error) {
            console.error('Error fetching data:', error);
            setOtp("");
        }
    };

    const handleResendOTP = async () => {
        // Logic to resend OTP goes here
        console.log("Resend OTP");

        // Reset counter and disable resend button
        setCounter(120);
        setCanResend(false);

        // Call the API to resend OTP
        try {
            await axiosInstance.post("/user/ResendOtp", { mobile_no: phone });
            console.log('OTP resent successfully');
        } catch (error) {
            console.error('Error resending OTP:', error);
        }
    };

    return (
        <div className="fixed flex justify-center items-center z-10 left-0 top-0 w-full h-full overflow-auto">
            <div className="bg-white m-auto p-6 border border-gray-300 border-solid w-[78%] max-w-4xl overflow-hidden max-h-[53%] h-[70%] rounded-[2%] flex flex-col relative sm:flex sm:flex-col sm:gap-2 sm:align-middle">
                <div className="flex flex-row h-full p-0">
                    <div className='relative z-10'>
                        <div className="absolute top-[-100px] left-[-80px] mt-4 w-60 h-60 bg-[#FFF8DC] rounded-full"></div>
                    </div>

                    <div className="flex-1 flex flex-col justify-center ml-[6%] mb-1 gap-3.5 mt-6">
                        <div className="absolute right-4 top-3 text-gray-500 text-2xl font-bold cursor-pointer z-20 md" onClick={onClose}>&times;</div>
                        <h4 className="relative z-20 ml-[20%] font-extrabold mb-5">Enter Verification Code</h4>
                        <div className="">
                            <p className="w-[75%] ml-[9%] font-normal gap-x-20">We are automatically deleting SMS <br /> <span> send to your mobile Number ********{lastFourDigits}</span></p>
                        </div>
                        <OtpInput
                            containerStyle={{ marginLeft: "70px", marginTop: "12px" }}
                            value={otp}
                            onChange={setOtp}
                            numInputs={4}
                            renderSeparator={<span></span>}
                            renderInput={(props) => <input {...props} />}
                            inputStyle={{
                                backgroundColor: "#FBB337",
                                height: "45px",
                                width: "45px",
                                borderRadius: "10px",
                                marginLeft: "10px",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        />
                        <div>
                            {canResend ? (
                                <h1 className='mt-6 ml-[8%]' style={{ fontWeight: "bold" }}>Don't receive the OTP? <span style={{ color: "#FBB337", cursor: "pointer" }} onClick={handleResendOTP}>RESEND OTP</span></h1>
                            ) : (
                                <h1 className='mt-6 ml-[8%]' style={{ fontWeight: "bold" }}>Resend OTP in {counter} seconds</h1>
                            )}
                        </div>
                    </div>
                    <div className="flex-1 flex justify-around items-center gap-3">
                        <div className="mt-60">
                            <img src={image1} alt='img' />
                        </div>
                        <div className="mt-40">
                            <img src={image2} alt='img' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Verification;
