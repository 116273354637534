import React, { useState } from 'react';
import cancel from '../images/cancel.png';
import notick from '../images/notick.png';
import tick from '../images/tick.png';
import rounded from '../images/rounded.png';
import unround from '../images/unround.png';
import greater from '../images/greater.png';
import { useDispatch } from 'react-redux';
import { setUsers } from '../../slices/filterSlice';
import { axiosInstance } from '../api/APIs';
import Axios from 'axios';
import SliderComponent from './SliderComponent';
import { filterData, districts } from '../data'
import { useSelector } from "react-redux";





interface LocationType {
  id: number;
  place: string;
}

interface FormErrors {
  propertyType?: any;
  locations?: any

}
interface Filter {
  selectedLook: string | null;
  Sub: string | null;
}
interface FilterProps {
  toggleFilterVisibility: () => void;
  setFilterProperty: any;
  setfilterLoaction: any
  setFilterPrice: any
  kb: any

}


const Filter: React.FC<FilterProps> = ({ kb, toggleFilterVisibility, setFilterProperty, setfilterLoaction, setFilterPrice }) => {
  const [ltext, setltext] = useState<LocationType[]>([{ id: 1, place: "Vellore" }]);
  const [etext, setetext] = useState<string>("");
  const [sliderValue, setSliderValue] = useState<[number, number]>([0, 100000000]);
  const [selectedProperties, setSelectedProperties] = useState<any[]>([]);
  const [Location, setLocation] = useState<string>('Vellore');
  const [selectedBhk, setSelectedBhk] = useState<string[]>([]);
  const [selectedLook, setSelectedLook] = useState<any>('Buy');
  const [Apply, setApply] = useState<string[]>([]);
  const [visableDistrict, setVisableDistrict] = useState<boolean>(false);
  const [round, setRound] = useState<number>(35);
  const [filterCancel, setFilterCancel] = useState<boolean>(false);
  const [Sub, setSub] = useState<string | null>('Buy');
  const dispatch = useDispatch();
  const [CommercialVisable, setCommercialVisable] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [receivedValue, setReceivedValue] = useState<any>([]);
  const loginData = useSelector((item: any) => (item.userData.userLoginData))


  function handleDeleteList(Id: number) {

    var arr = ltext.filter((item) => {
      if (item.id === Id) {
        return false;
      }
      else {
        return true;
      }
    });
    setltext(arr);
  }

  function hanglest(event: React.ChangeEvent<HTMLInputElement>) {
    setetext(event.target.value);
  }

  function handleAdd() {
    if (etext.trim() !== '' && ltext.length < 3) {
      setltext([...ltext, { id: ltext.length + 1, place: etext }]);
      setetext('');
      setFormErrors({ ...formErrors, locations: '' });
    } else if (ltext.length >= 3) {
      setFormErrors({ ...formErrors, locations: 'You can only add up to 3 locations.' });
    }
  }

  function handleChangeProperty(value: string) {
    if (selectedProperties.includes(value)) {
      setSelectedProperties(selectedProperties.filter(prop => prop !== value));
      setApply(Apply.filter(prop => prop !== value));
    } else {
      setSelectedProperties([...selectedProperties, value]);
      setApply([...Apply, value]);
    }
  }

  function handleChangeBhk(value: string) {
    if (selectedBhk.includes(value)) {
      setSelectedBhk(selectedBhk.filter(item => item !== value));
      setApply(Apply.filter(prop => prop !== value));
    }
    else {
      setSelectedBhk([...selectedBhk, value]);
      setApply([...Apply, value]);
    }
  }

  function handleChangeLook(Id: number, value: string) {
    if (value === "Commercial") {
      setCommercialVisable(true);
      setSelectedProperties([]);
    } else {
      setCommercialVisable(false);
      setSelectedProperties([]);
    }
    if (selectedLook === value) {
      setSelectedLook(null);
      setApply(Apply.filter(prop => prop !== value));
    } else {
      setSelectedLook(value);
      setApply([...Apply, value]);
    }
  }

  function handleChangeSub(Id: number, value: string) {
    if (Sub === value) {
      setSub(null);

    } else {
      setSub(value);

    }
  }

  async function handleApply() {
    let errors: FormErrors = {};



    // if (selectedProperties.length === 0) {
    //   errors.propertyType = "Please select at least one property type.";
    // }
    if (ltext.length === 0) {
      errors.locations = 'Please add at least one location.';
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 2000)
      return;
    }


    setFormErrors({});

    const body: any = {
      district: Location,
      city: ltext.map(item => item.place),
      property_type: selectedProperties.length === 0
        ? (selectedLook === "Commercial"
          ? ["Showroom", "Office Space", "Retail Shop", "Industrial Space", "Warehouse"]
          : (selectedLook === "Buy" || selectedLook === "Rent" || selectedLook === "Lease"
            ? ["Apartment", "Vacant Land", "Villa", "Independent House"]
            : selectedProperties))
        : selectedProperties,

      looking_to: selectedLook === "Commercial" ? Sub : selectedLook,
      price: sliderValue[1] === 0 ? 100000000000 : `${sliderValue[1]}`,
      user_id: loginData.user_id,
      kbl_property: kb
    };

    if (selectedBhk.length > 0) {
      body.bhk = selectedBhk;
    }

    console.log(body);
    console.log(selectedLook);
    const url = selectedLook === "Commercial" ? "/filter/commercialFilter" : "/filter/resbuyfilterData";

    try {
      const response = await axiosInstance.post(url, body);
      dispatch(setUsers(response.data.message));

      console.log('Response:', response.data.message);
      setFilterProperty(body.property_type)
      setFilterPrice(body.price)
      setfilterLoaction(body.city)




    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }



  function handleDistrictVisable() {
    setVisableDistrict(!visableDistrict);
  }

  function handleRound(index: number, value: string) {
    if (round === index) {
      setRound(0);
    } else {
      setRound(index);
      setLocation(value);
      setVisableDistrict(false       )
    }
  }

  function toggleFilter() {
    toggleFilterVisibility();


    setVisableDistrict(false);
  }

  function reset() {
    setSelectedLook("Buy");
    setSelectedProperties([]);
    setSelectedBhk([]);
    setLocation("Vellore");
    setltext([{ id: 1, place: 'Vellore' }]);
    setSliderValue([0, 100000000]);
  }

  const handleSliderChange = (event: Event, newValue: any | any[]) => {
    setSliderValue(newValue as [number, number]);
    console.log(newValue);

  };



  return (
    <div className={`absolute z-40 lg:block md:hidden msm:hidden w-[35%] h-[100%] top-[0%] left-0  bg-[white] ${filterCancel ? 'hidden' : 'block'}`}>
      <div className='bg-[#F5F4F8]'>
        <div className='flex items-center justify-between '>
          <div className='absolute top-2 right-4'>
            <img onClick={toggleFilter} src={cancel} alt='cancel' />
          </div>
          <div className='flex flex-col gap-2 ml-3 mt-4'>
            <p className='text-[#202020] text-xl font-bold'>Filter</p>
            <p className='text-[#202020]'>
              You are searching in <span className='font-semibold'>{Location}</span>
            </p>
          </div>
          <div className='flex flex-col gap-2 mr-1 mt-4'>
            <p className='cursor-pointer text-[#252B5C] font-bold' onClick={reset}>
              Reset filter
            </p>
            <div onClick={handleDistrictVisable} className='cursor-pointer flex font-semibold items-center gap-1'>
              <p className='text-[#202020]'>Change Location</p>
              <img className='w-2 h-3 mt-1' src={greater} alt='greater than' />
            </div>
            <div className={`w-[45%] h-[90%] bg-[#ffffff] overflow-y-scroll absolute -right-[45%] top-[10%] ${visableDistrict ? 'block' : 'hidden'}`}>
              {districts.map((item, index) => (
                <div key={index} className='flex justify-between py-2 px-6 border border-b-[#80848E]'>
                  <li className='list-none font-semibold '>{item}</li>
                  <img onClick={() => handleRound(index, item)} className='p-1 cursor-pointer' src={round === index ? rounded : unround} alt='round icon' />
                </div>
              ))}
            </div>

          </div>

        </div>
        <div className='border-1 mt-1 border-[F5F4F8] box-border shadow-2xl'></div>
      </div>
      <div className='mt-4'>
        <div>
          <p className='mb-2 ml-5 text-left font-semibold'>I’m Looking To</p>
        </div>
        <div>
          <div className='ml-5'>
            {filterData.map((data, index) => (
              <div className='flex flex-wrap xl:gap-10 items-center ' key={index}>
                {data.look.map((item) => (
                  <button
                    key={item.id}
                    onClick={() => handleChangeLook(item.id, item.button)}
                    className={`py-2 px-6 rounded-3xl ${selectedLook === item.button ? 'bg-[#515AA8] text-[#FFFFFF]' : 'bg-[#F5F4F8]'} `}
                  >
                    {item.button}
                  </button>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={`${CommercialVisable ? 'block' : 'hidden'}`}>
        <p className='mt-3 ml-5 mb-2 font-semibold'>Sub Category</p>
        <div className='ml-5 flex flex-wrap xl:gap-20'>
          {filterData[0].SubCategory.map((item) => (
            <div key={item.id}>
              <button
                className={`px-6 py-2 rounded-3xl ${Sub === item.button ? 'bg-[#515AA8] text-[#FFFFFF]' : 'bg-[#F5F4F8]'} `}
                onClick={() => handleChangeSub(item.id, item.button)}
              >
                {item.button}
              </button>
            </div>
          ))}
        </div>
      </div>

      <div>
        <div>
          <p className='font-semibold text-left mb-2 ml-5 mt-3'>Localities</p>
        </div>
        <div>
          <input
            value={etext}
            onChange={hanglest}

            onKeyDown={(e) => {
              if (e.key === "Enter")
                handleAdd();
            }}

            placeholder='Search up to 3 locations'
            className=' placeholder:text-[#000000] placeholder:font-medium bg-[#F5F4F8] p-2 ml-5 rounded-full w-[75%]'
          />
          <div className='flex gap-6 ml-7 mt-3'>

            {ltext.map((item) => (
              <li key={item.id} className='list-none flex gap-1 justify-center items-center'>
                {item.place}{' '}
                <img onClick={() => handleDeleteList(item.id)} className='' src={cancel} alt='cancel' />
              </li>
            ))}
          </div>
        </div>

        {showAlert && (
          <div className='absolute ml-4 z-10 font-semibold    text-red-500   '>
            {formErrors.locations}
          </div>
        )}
      </div>

      <div>
        <p className='font-semibold ml-5 mt-8 text-left'>Property Type</p>
        <div className={` gap-3 p-2 ${CommercialVisable ? 'hidden' : 'xl:grid xl:grid-cols-3 lg:flex ml-3  lg:flex-wrap'} `}>
          {filterData[0].property.map((item, index) => (
            <div
              key={index}
              onClick={() => handleChangeProperty(item.value)}
              className={`flex cursor-pointer items-center rounded-3xl ${selectedLook !== "Buy" && item.value === "Vacant Land" ? "invisible" : "block"} ${selectedProperties.includes(item.value) ? 'bg-[#515AA8] text-[#FFFFFF]' : 'bg-[#F5F4F8]'
                } xl:p-0 lg:px-1 `}
            >
              <img className='ml-1 xl:p-3 lg:p-2' src={selectedProperties.includes(item.value) ? tick : notick} alt='tick or notick' />
              <p className={`p-1 `}>{item.value}</p>

            </div>
          ))}
        </div>

        <div className={` gap-3 p-2  ${CommercialVisable ? 'xl:grid xl:grid-cols-3 lg:flex ml-3  lg:flex-wrap' : 'hidden'}`}>
          {filterData[0].propertyCommercial.map((item, index) => (
            <div
              key={index}
              onClick={() => handleChangeProperty(item.value)}
              className={`flex cursor-pointer items-center rounded-3xl ${selectedProperties.includes(item.value) ? 'bg-[#515AA8] text-[#FFFFFF]' : 'bg-[#F5F4F8]'
                } xl:p-0 lg:p-0`}
            >
              <img className='ml-1 xl:p-3 lg:p-2' src={selectedProperties.includes(item.value) ? tick : notick} alt='tick or notick' />
              <p className='p-1'>{item.value}</p>
            </div>
          ))}
        </div>

        {/* {showAlert && (
        <div className='absolute ml-4 z-10   text-red-500 font-semibold'>
          {formErrors.propertyType}
        </div>
      )} */}
      </div>

      <div className='font-semibold  mt-7'>
        <p className='text-left ml-5'> Price Range</p>
        <div className='ml-8 mt-4'>
          <SliderComponent onUpdateSlider={handleSliderChange} selectedLook={selectedLook} Sub={Sub} />


        </div>
      </div>

      <div className={`${CommercialVisable ? 'hidden' : 'block'}`}>
        <p className='font-semibold ml-5 mt-7 text-left'>BHK</p>
        <div className='flex flex-wrap gap-10 ml-5 mt-4'>
          {filterData[0].BHK.map((item, index) => (
            <div key={index} onClick={() => handleChangeBhk(item.value)}>
              <button
                className={`bg-[#F5F4F8] rounded-3xl px-4 p-2 ${selectedBhk.includes(item.value) ? 'text-white bg-[#4c56b3]' : 'bg-[#F5F4F8]'}`}
              >
                {item.value}
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className='flex absolute right-8 bottom-0 w-[100%] justify-center items-center'>
        <button onClick={handleApply} value={Apply} className='bg-[#515AA8] w-[45%] p-4 static bottom-0 rounded-full mb-2  text-[#FFFFFF] '>
          Apply Filter
        </button>
      </div>
    </div>
  );
};

export default Filter;
