


import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { useStepperContext } from './StepperContext'; // Adjust path if necessary

interface CustomBoxProps {
    title?: string;
    label: string;
    inputId?: number;
    formName: string;
    required?: boolean;
    userselectedData?: any;
    data:any;
    initial: string;
    onSelectionChange?: (selectedValue: string) => void;
}

const CustomBoxButton: React.FC<CustomBoxProps> = ({
    label,
    inputId,
    formName,
    data,
    required,
    userselectedData,
    initial,
    onSelectionChange
}) => {
    const { addUserData } = useStepperContext(); // Ensure this is available

    const [activeButton, setActiveButton] = useState<number | null>(null);

    useEffect(() => {
        const initValue = data.find((item:any) => item.title === initial);
        setActiveButton(initValue?.id || null);
    }, [initial, data]);

    const handleClick = (item: { id: number; title: string }) => {
        setActiveButton(item.id);

        const data = {
            id: inputId,
            [label]: item.title,
            formName: formName,
            required: required,
        };
        addUserData(data as any); // Call addUserData

        if (onSelectionChange) {
            onSelectionChange(item.title);
        }
    };

    const hasError = userselectedData?.includes(label);

    return (
        <div className='flex gap-5'>
            {data.map((item:any) => (
                <Button
                    key={item.id}
                    className='h-10'
                    onClick={() => handleClick(item)}
                    sx={{
                        borderRadius: '999px',
                        padding: '20px',
                        width: 'fit-content',
                        bgcolor: activeButton === item.id ? '#515AA8' : "#F5F4F8",
                        color: activeButton === item.id ? 'white' : 'black',
                        textTransform: 'capitalize',
                        '&:hover': {
                            bgcolor: activeButton === item.id ? '#515AA8' : "#F5F4F8",
                        },
                    }}
                >
                    {item.title}
                </Button>
            ))}
            {hasError && <div>This field is required</div>}
        </div>
    );
};

export default CustomBoxButton;
