import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import AWS from 'aws-sdk';
import { axiosInstance } from '../api/APIs';
import Resizer from 'react-image-file-resizer';
import drop from '../images/arrow_drop_down.svg'
import { error } from "console";
import { useSelector } from "react-redux";
import profile from '../images/profile.png'
import back from '../images/Back.svg'
import { Link } from "react-router-dom";



AWS.config.update({
    region: 'us-east-1',
    accessKeyId: 'AKIAQ3EGVP6CLIGM245V',
    secretAccessKey: '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',
});

const S3 = new AWS.S3();

const service = [
    { id: 1, value: "Electrician" },
    { id: 2, value: "Packers & Movers" },
    { id: 3, value: "Home Renovations" },
    { id: 4, value: "Painting" },
    { id: 5, value: "Home Interior" },
    { id: 6, value: "Construction Service" },
]

const PostService = () => {
    const loginData = useSelector((state: any) => state.userData.userLoginData)
    const [profileImage, setProfileImage] = useState<any>('');
    const [name, setName] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [district, setDistrict] = useState<string>('');
    const [town, setTown] = useState<string>('');
    const [submit, setSubmit] = useState<boolean>(false);
    const [textArea, setTextArea] = useState<string>('');
    const [addTown, setAddTown] = useState<{ id: number, place: string }[]>([]);
    const [visableTitle, setVisableTitle] = useState<any>(false)
    const [businessName, setBusinessName] = useState<any>()

    const [errors, setErrors] = useState({
        name: '',
        businessName: '',
        phone: '',
        district: '',
        addTown: '',
        textArea: '',
        title: ''
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleProfileImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            try {
                const resizedImage = await resizeImage(file);
                const fileUrl = await uploadFileToS3('vattara-image', resizedImage);
                setProfileImage(fileUrl);
            } catch (error) {
                console.error('Error uploading profile image:', error);
            }
        }
    };

    const resizeImage = (file: File): Promise<Blob> => {
        return new Promise((resolve, reject) => {
            Resizer.imageFileResizer(
                file,
                800, // New width
                600, // New height
                'JPEG', // Format
                90, // Quality
                0, // Rotation
                (uri) => {
                    resolve(uri as Blob);
                },
                'blob'
            );
        });
    };

    const uploadFileToS3 = async (bucketName: string, file: Blob) => {
        const fileName = `profile_${Date.now()}`; // Use a unique name
        const params = {
            Bucket: bucketName,
            Key: fileName,
            Body: file,
            ContentType: 'image/jpeg',
        };
        try {
            const uploadResponse = await S3.upload(params).promise();
            return uploadResponse.Location;
        } catch (error) {
            throw new Error(`Error uploading ${fileName} to S3: ${error}`);
        }
    };

    const validateFields = () => {
        let valid = true;
        const newErrors = { name: '', businessName: '', phone: '', district: '', addTown: '', textArea: '', title: '' };

        if (!name) {
            newErrors.name = 'Name is required';
            valid = false;
        }
        if (!businessName) {
            newErrors.businessName = 'Business Name is required';
            valid = false;
        }
        if (!phone) {
            newErrors.phone = 'Enter your vaild mobile number';
            valid = false;
        }
        if (!district) {
            newErrors.district = 'District is required';
            valid = false;
        }
        if (addTown.length === 0) {
            newErrors.addTown = 'Town/Locality is required';
            valid = false;
        }
        if (!textArea) {
            newErrors.textArea = 'Enter the Job Description ';
            valid = false;
        }
        if (!title) {
            newErrors.title = "Enter the your Job Title"
            valid = false;
        }
        setErrors(newErrors);
        return valid;
    };

    const handleTown = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTown(event.target.value)
    }

    const handleAddTown = () => {
        if (town.trim()) { // Ensure town is not empty
            const newTown = { id: addTown.length + 1, place: town };
            setAddTown([...addTown, newTown]);
            setTown(''); // Clear the input field
        }
    };

    let locality = ''
    const values = addTown.map((item) => item.place);
    for (let i = 0; i < values.length; i++) {
        locality += values[i];
        if (i < values.length - 1) {
            locality += ",";
        }
    }
    const handleSubmit = async () => {
        if (!validateFields()) {
            return;
        }
        const mobile = `+91${phone}`

        const body = {
            user_name: name,
            mobile_no: mobile,
            service_category: title,
            business_profile: profileImage,
            district: district,
            job_description: textArea,
            locality: locality,
            business_name: businessName,
            user_id: loginData.user_id


        };

        console.log(body);

        try {
            const response = await axiosInstance.post("/services/user/create", body);
            console.log(response.data);
            // setSubmit(true);
        } catch (error) {
            console.log("error", error);
        }
    };

    function handlevisableTitle() {
        setVisableTitle(!visableTitle)
    }
    function handleAddTitle(value: any) {
        setTitle(value)
        setVisableTitle(false)
    }

    return (
        <div className={`bg-[#F5F4F8] min-h-screen  flex flex-col`}>
            <div className={`${submit ? "bg-none" : "bg-white"} xl:ml-[25%] xl:mx-[20%]  lg:mx-[5%]  h-full w-full max-w-4xl  flex flex-col `}>
                <div className={`${submit ? "hidden" : "block msm:ml-5 md:ml-[5%]"} sm:ml-[5%] xl:mx-[15%]  xl:ml-[20%] `}>
                    <div className=" md:hidden msm:flex sm:hidden items-center gap-5">
                        
                        <Link to={'/servicesDetails'}>
                            <img className='   md:hidden msm:flex sm:hidden' src={back} />
                        </Link>
                        <p className="text-lg font-semibold">Add your service</p>
                    </div>
                    <div className="pt-3">
                        <p className="text-lg font-semibold   flex">Profile Information</p>
                    </div>
                    <div className="flex flex-col justify-start items-start">
                        <div className="flex flex-row mt-10 items-center">
                            <label htmlFor="profile-image-upload" className="cursor-pointer">
                                <img
                                    src={profileImage} // Replace with a default image path if profileImage is null
                                    alt="Profile"
                                    className="rounded-full bg-[#DDDDDD] w-20 h-20"
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleProfileImageUpload}
                                    className="hidden"
                                    id="profile-image-upload"
                                />
                            </label>
                            <p
                                onClick={() => {
                                    const inputElement = document.getElementById('profile-image-upload') as HTMLInputElement | null;
                                    if (inputElement) {
                                        inputElement.click();
                                    }
                                }}
                                className="ml-4 mt-8 text-[#006CE7] font-bold cursor-pointer"
                            >
                                Edit Image
                            </p>
                        </div>
                    </div>
                    <div className="">
                        <div className="mt-4">

                            <input
                                onChange={(e) => setName(e.target.value)}
                                className="border-none placeholder:text-[#404040] bg-[#F5F4F8] p-2 rounded-2xl w-[90%] "
                                placeholder="Name"
                            />
                            {errors.name && <p className="text-red-500">{errors.name}</p>}
                        </div>
                        <div className="mt-4">

                            <input
                                onChange={(e) => setBusinessName(e.target.value)}
                                className="border-none placeholder:text-[#404040] bg-[#F5F4F8] p-2 rounded-2xl w-[90%] "
                                placeholder="Business Name"
                            />
                            {errors.businessName && <p className="text-red-500">{errors.businessName}</p>}
                        </div>
                        <div className="mt-4">

                            <div className="relative ">
                                <input

                                    onChange={(e) => setPhone(e.target.value)}
                                    className="border-none placeholder:text-[#404040] bg-[#F5F4F8] p-2 rounded-2xl w-[90%] "
                                    placeholder="Contact Number"
                                />

                            </div>
                            {errors.phone && <p className="text-red-500">{errors.phone}</p>}
                        </div>
                        <div className="mt-4">
                            <p className="text=lg font-semibold mb-2">Service Information</p>
                            <div className="relative w-[90%]">

                                <input
                                    onClick={handlevisableTitle}
                                    value={title}
                                    className="border-none placeholder:text-[#404040] bg-[#F5F4F8] p-2 rounded-2xl  w-[100%]"
                                    placeholder="Select your sevice"
                                />
                                <img
                                    onClick={handlevisableTitle}
                                    src={drop}
                                    alt="icon"
                                    className={`absolute top-1/2 transform -translate-y-1/2 right-4 transition-transform duration-300 ${visableTitle ? 'rotate-0' : 'rotate-90'}`}
                                />
                                <div
                                    className={`transition-all duration-1000 ease-in-out transform ${visableTitle ? "opacity-100 scale-100" : "opacity-0 scale-95 hidden"} absolute z-10 bg-white w-full rounded-2xl shadow-lg mt-2`}
                                >
                                    {service.map((item, index) => (
                                        <div key={index} onClick={() => { handleAddTitle(item.value) }} className="flex flex-col p-2">
                                            <p className="ml-2">{item.value}</p>
                                            <div className="border-1 border-[#8E8E8E] opacity-75 mx-2"></div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                            {errors.title && <p className="text-red-500">{errors.title}</p>}
                        </div>
                        <div className="mt-4">

                            <textarea
                                onChange={(e) => setTextArea(e.target.value)}
                                className="w-[90%]  bg-[#F5F4F8]  h-[20vh] border-none p-2 rounded-2xl placeholder:text-[#404040]"
                                placeholder="Job Description"
                            />
                            {errors.textArea && <p className="text-red-500">{errors.textArea}</p>}
                        </div>
                        <div className="mt-4">

                            <input
                                onChange={(e) => setDistrict(e.target.value)}
                                className="border-none  placeholder:text-[#404040] bg-[#F5F4F8] p-2 rounded-2xl w-[90%] "
                                placeholder="District"
                            />
                            {errors.district && <p className="text-red-500">{errors.district}</p>}
                        </div>
                        <div className="mt-4">

                            <input
                                type="text"
                                value={town}
                                placeholder="Town/Locality"
                                onChange={(e) => setTown(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleAddTown();
                                    }
                                }}
                                className="border-none placeholder:text-[#404040] bg-[#F5F4F8] p-2 rounded-2xl w-[90%] "
                            />
                            {errors.addTown && <p className="text-red-500">{errors.addTown}</p>}
                        </div>
                        <div>
                            {addTown.length > 0 ? (
                                <div className="flex gap-2 flex-wrap">
                                    {addTown.map((t) => (
                                        <div key={t.id}>
                                            <p>{t.place}</p>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p></p>
                            )}
                        </div>
                        <div className="flex justify-center mt-9 mb-6">
                            <p onClick={handleSubmit} className="bg-[#2E368F] xl:py-3 msm:py-2  msm:px-10 xl:px-24 text-white rounded-3xl">Submit for Verification</p>
                        </div>
                    </div>
                </div>
                <div className={`flex flex-col justify-center items-center ${submit ? "block" : "hidden"}`}>
                    <div className="flex flex-col justify-center items-center mt-[30%]">
                        <p className="text-3xl font-semibold">Thank You!</p>
                        <p className="font-semibold">Your details will be verified before being listed.</p>
                        <p className="mt-5 text-white bg-[#515AA8] p-3 rounded-3xl" onClick={() => navigate('/')}>Return to Home</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PostService;
