import { InputAdornment, TextField } from '@mui/material'
import React from 'react'
import { useStepperContext } from './StepperContext'
interface PriceTextField1Props{
    label:string,
    formName:string,
    inputId?:number
    label2?:string
    keyboard?:any,
    required?:boolean
    userselectedData?:any,
    initial?:any
}

const PriceTextField1:React.FC<PriceTextField1Props> = ({label,formName,inputId,label2,keyboard,initial,required,userselectedData}) => {

    const {addUserData}=useStepperContext()

const handleChange=(event: React.ChangeEvent<HTMLInputElement | { value: unknown }>)=>{
    const data={
         id:inputId,
         [label]:event.target.value,
         formName:formName

    }
    addUserData(data as any)
}
const hasError=userselectedData?.includes(label)
  return (
    <div className='w-[100%]'>
        <TextField id={label}
        defaultValue={initial}
        type={keyboard?keyboard:"text"}
         label={label} onChange={handleChange} variant='standard' className='w-[100%]'
        InputProps={{
            endAdornment: <InputAdornment position="start">{label2}</InputAdornment>,
         }} >
 

        </TextField>



    </div>
  )
}

export default PriceTextField1