import React from "react";
import home from "../../assets/home.svg";
import construction from "../../assets/construction.svg";
import electrician from "../../assets/electrician.svg";
import homeInterior from "../../assets/homeInterior.svg";
import painting from "../../assets/painting.svg";
import truck from "../../assets/truck.svg";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../api/APIs";

const services = [
  {
    image: truck,
    name: "Packers and movers",
  },
  {
    image: home,
    name: "Home Renovation",
  },
  {
    image: construction,
    name: "Construction Service",
  },
  {
    image: electrician,
    name: "Electrician",
  },
  {
    image: homeInterior,
    name: "Home Interior",
  },
  {
    image: painting,
    name: "Painting",
  },
];



const Services = () => {

const Navigate =useNavigate()

 async function handleService(label:any){
    try {

      const response = await axiosInstance.get(`/services/categoryBased/userlist/${label}`)
      console.log(response.data.message);
      Navigate('/servicesDetails', { state: { data: response.data.message, category: label } });

  } catch (error) {
      console.log("error", error)
  }
  }


  return (
    <section className="flex flex-col msm:mx-2 xl:mx-[20%] overflow-hidden  whitespace-nowrap  sl:w-full ">
      <p className="text-xl font-bold mt-4 mb-2  sl:text-sm">Services</p>
      <div className="flex items-center overflow-x-scroll hide-scrollbar  whitespace-nowrap  gap-4 md:gap-6 lg:gap-8 xl:gap-12 py-2 sl:px-5">
        {services.map((service, index) => (
          <div onClick={()=>handleService(service.name)}
            className="flex flex-row items-center gap-2 md:gap-3 lg:gap-4 justify-between flex-shrink-0 bg-[#F5F4F7] rounded-full px-6 py-1"
            key={index}
          >
            <img
              className="w-8 h-8 lg:w-12 lg:h-12 sl:w-5 sl:h-5 flex-shrink-0" // Adjusted image sizes
              src={service.image}
              alt={service.name}
            />
            <p className="text-sm font-semibold text-[#242B5C] whitespace-nowrap text-center">
              {service.name}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;
