import React, { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import profilePic from "../../assets/user.png";
import edit from "../../assets/Edit.png";
import companydetailicon from "../../assets/companydetailsicon.png";
import solarupdown from "../images/listingIcon.svg";
import duotone from "../images/savedIcon.svg";
import language from "../../assets/languageicon.png";
import logout from "../../assets/Logout.png";
import { useNavigate } from "react-router-dom";
import question from "../images/quesIcon.svg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import contactsupport from "../images/ContactSupport.svg";
import settings from "../../assets/Settings.png";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Box, IconButton, Modal } from "@mui/material";
import Language from "./Language";
import CloseIcon from "@mui/icons-material/Close";
import commercial from "../images/PlanIcon2.svg";
import resedentail from "../images/PlanIcon1.svg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Profile: React.FC = () => {
  const [showmodal, setshowmodal] = useState(false);
  const loginData = useSelector((state: any) => (state.userData.userLoginData))
  const navigate = useNavigate();
  console.log(loginData);

  const handleChange = () => {

    navigate("/edit");
  };
  const handleClick = () => {
    navigate("/list");
  };

  const handleClick1 = () => {
    navigate("/cart");
  };

  const handlesetting = () => {
    navigate("/settings");
  };

  const handleopenmodel = () => {
    setshowmodal(true);
  };
  const handleCloseModal = () => {
    setshowmodal(false);
  };
  return (
    <div className=" bg-[#F5F4F8] min-h-screen flex flex-col     overflow-y-hidden w-full  ">


      <div className={` bg-white xl:mx-[26%]  lg:mx-[20%] pt-20  h-full w-full max-w-4xl  flex flex-col `}>
        <div className="     justify-center    rounded-lg    ">
          <div className="flex items-center   bg-gradient-to-r ml-14 from-gray-100 to-[#C0C3DD]   p-2  rounded-l-full sl:ml-3">
            <div className="flex flex-row items-center gap-4 ">
              <div>
                <img
                  src={loginData.company_logo ? loginData.company_logo : profilePic}
                  alt="Profile"
                  className="w-20 ml-1 h-20 rounded-full"
                />
              </div>
              <div className=" gap-1 flex flex-col ">

                {loginData?.name !== null && loginData.name !== undefined && loginData.name !== "" ? (
                  <p>{loginData.name}</p>
                ) : (
                  <p className="font-medium">Guest_84562</p>
                )}
                {loginData?.category ? (<p>{loginData.category}</p>) : ("")}
                {loginData?.phone ? (<p>{loginData.phone}</p>) : (<p className="text-xs text-[#80848E]"></p>)}
                {loginData?.email ? (<p>{loginData.email}</p>) : (<p className="text-xs text-[#80848E]"></p>)}







              </div>
            </div>
            <div className="ml-auto">
              <img
                onClick={handleChange}
                src={edit}
                alt="Edit"
                className="w-8 h-8 cursor-pointer mr-5"
              />
            </div>
          </div>
          <div className="lg:mx-14">
            <div className="border mt-4"></div>
            <div className={`${loginData.company_name?"block":"hidden"} mt-4 px-3`}>
              <p className="font-medium">Company details</p>
              <div className="flex items-center mt-2">
                <img
                  src={loginData.company_logo}
                  alt="Company Icon"
                  className="w-8 h-8 mr-2"
                />
                {loginData.company_name !== "" && <p className="font-medium">{loginData.company_name}</p>}
              </div>
              <div className="border mt-4"></div>
            </div>
           

            <div className="flex gap-4 mb-3 mt-4 px-3">
              <div className="flex items-center gap-3 bg-[#F5F4F8] rounded-xl py-3 px-6">
                <img
                  src={solarupdown}
                  alt="Listings Icon"
                  className=""
                />
                {/* Subscription */}
                <div
                  onClick={handleClick}
                  className="flex flex-col justify-center"
                >
                  <p className="font-semibold  text-gray-800">Listings</p>
                  <p className="text-gray-800 font-semibold text-xs">My Active Listings</p>
                </div>
              </div>
              <div className="flex items-center gap-3 bg-[#F5F4F8] rounded-xl py-3 px-6">
                <img src={duotone} alt="Saved Icon" />
                <div
                  onClick={handleClick1}
                  className="flex flex-col justify-center"
                >
                  <p className="font-semibold text-gray-800">Saved</p>
                  <p className="text-gray-800  font-semibold text-xs">My Favourite Properties</p>
                </div>
              </div>
            </div>
            <div className="border mt-4"></div>

            <div className="">
              <p className="ml-5 mt-1 text-[#2E368F] text-lg  font-bold">
                Choose Your Subscription Plan
              </p>

              <div className="flex flex-row gap-4 mt-4 px-3">
                <Link to={'/Subscription'}> <div className="flex flex-row bg-[#E4E0EE] p-3 rounded-full">
                  <img
                    src={resedentail}
                    className="w-6 h-6 mr-2"
                    alt="Residential Icon"
                  />
                  <p className="font-semibold">Residential Plans</p>
                </div></Link>
                <Link to={'/Subscription'}>
                  <div className="flex flex-row bg-[#E4E0EE] p-3 rounded-full font-k2d">
                    <img
                      src={commercial}
                      className="w-6 h-6 mr-2"
                      alt="Commercial Icon"
                    />
                    <p className="text-black font-semibold">Commercial Plans</p>
                  </div>
                </Link>
              </div>
            </div>

            <div className="border mt-4"></div>
            <div className="mt-4 px-3">
              <p className="text-[#80848E] font-">Language</p>
              <div className="flex items-center mt-2" onClick={handleopenmodel}>
                <img
                  src={language}
                  alt="Language Icon"
                  className="w-6 h-6 mr-2 sl:mt-3"
                />
                <p className="font-semibold text-gray-800 sl:mt-3">
                  Language Preference
                </p>
              </div>
              <Modal
                open={showmodal}
                onClose={handleCloseModal}
                aria-labelledby="thankyou-modal-title"
                aria-describedby="thankyou-modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 300,
                    maxHeight: "50vh",
                    overflow: "auto",
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    pt: 1,
                    px: 1,
                    pb: 3,
                  }}
                >
                  <Language />
                  <IconButton
                    onClick={handleCloseModal}
                    style={{
                      position: "absolute",
                      top: "8px",
                      right: "8px",
                      color: "#000",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Modal>
            </div>
            <div className="border mt-4"></div>

            <div className="mt-4 px-3">
              <p>Help and support</p>

              <div className="flex items-center mt-3">
                <img src={question} alt="Help Icon" className="w-4 h-4 mr-2" />
                <p className="font-semibold text-gray-800">Help Center</p>
              </div>

            </div>
            <div className="border mt-4"></div>

            <div className="flex items-center mt-4 px-3">
              <img src={settings} alt="Settings Icon" className="w-4 h-4 mr-2" />
              <p className="font-semibold text-gray-800" onClick={handlesetting}>
                Settings
              </p>
            </div>

            <div className="flex items-center mt-2 mb-4 px-3">
              <img src={logout} alt="Logout Icon" className="w-4 h-4 mr-2" />
              <p className="font-medium  text-red-600">Logout</p>
            </div>
            <div className="border mt-4"></div>
            <div className="my-4 px-3">
              <div className="flex gap-2">
                <img src={contactsupport} />
                <p className="font-semibold">Contact Support</p>
              </div>
              <p className="mt-4">Call: +91 9876543210</p>
              <p>E-mail: support@vattara.com</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
